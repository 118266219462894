import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { getValue } from "@utils/lodash";
import { Popover } from "antd";
import { motion } from "framer-motion";

// function DateOnHover(props) {
//   const POSITION_TYPES = [
//     "bottom right",
//     "bottom center",
//     "top center",
//     "left top",
//     "left bottom",
//     "right top",
//     "top left",
//     "top center",
//     "right center",
//     "right bottom",
//     "bottom left",
//     "left center",
//     "center center",
//   ];

//   const popupRef = useRef();
//   const handleClose = (e) => {
//     e.stopPropagation();
//     if (popupRef.current) {
//       popupRef.current.close();
//     }
//   };
//   const handleOpen = () => {
//     if (popupRef.current) {
//       popupRef.current.open();
//     }
//   };

//   const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
//     <div
//       className="d-flex justify-content-between align-items-center cursor-pointer"
//       {...props}
//       ref={ref}
//     >
//       {props.header}
//     </div>
//   ));
//   useEffect(() => {
//     if (props.closePopup) {
//       handleClose();
//       props.setClosePopup(false);
//     }
//   }, []);
//   return (
//     <Popup
//       trigger={(open) => <CustomButtons open={open} header={props.header} />}
//       position={POSITION_TYPES}
//       closeOnDocumentClick
//       on={getValue(props, `hover`, false) ? "hover" : ""}
//       contentStyle={{
//         width: getValue(props, `width`, "")
//           ? getValue(props, `width`, "")
//           : "400px",
//         minHeight: getValue(props, `height`, "")
//           ? getValue(props, `height`, "")
//           : "70px",
//         zIndex: 9999,
//         background: "#fff",
//         boxShadow:
//           "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
//       }}
//       nested
//       ref={popupRef}
//       onOpen={handleOpen}
//       onClick={handleClose}
//       arrowStyle={{
//         color: "#fff",
//       }}
//     >
//       <div className="position-relative">
//         <div className="p-2 position-relative small_text__13 text-white">
//           {props.render}
//         </div>
//       </div>
//     </Popup>
//   );
// }

// export default DateOnHover;

const DateOnHover = (props) => {
  // const [popoverVisible, setPopoverVisible] = useState(false);
  const { popoverVisible, setPopoverVisible } = props;
  const popoverContent = (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div
        className={"inline_tooltip_edit_container"}
        style={{ width: "450px" }}
      >
        {props.render}
      </div>
    </motion.div>
  );

  return (
    <Popover
      content={popoverContent}
      trigger={props.hover ? "hover" : "click"}
      placement="bottomLeft"
      arrow={false}
      overlayClassName="custom-popover"
      visible={popoverVisible} // Control visibility
      onVisibleChange={(visible) => setPopoverVisible(visible)} // Sync visibility
    >
      <div
        className={`inline_form_field border_input inline_field d-flex align-items-center cursor-pointer`}
      >
        {props.header}
      </div>
    </Popover>
  );
};

export default DateOnHover;

import { getValue } from "@utils/lodash";
import React from "react";
import { config } from "../../../../env";
import { convertCurrentDateToTime } from "@common/date-helpers";

function DocumentFormatReplyPreviews(props: any) {
  const { item } = props;

  const url = `https://${config.URL}/${getValue(item, `upload_file.key`, "")}`;
  const filename = url.substring(url.lastIndexOf("/") + 1);
  return (
    <>
      {getValue(item, `type`, "") === "image" && (
        <>
          <img
            src={
              getValue(item, `upload_file.key`, "")
                ? `https://${config.URL}/${getValue(
                    item,
                    `upload_file.key`,
                    ""
                  )}`
                : `https://${config.URL}/${getValue(item, `upload_file`, "")}`
            }
            alt="Selected"
            className="doc_preview_image pe-1"
          />
        </>
      )}
      {getValue(item, `type`, "") === "video" && (
        <>
          <video className="whatsapp_file-replay">
            <source
              src={
                getValue(item, `upload_file.key`, "")
                  ? `https://${config.URL}/${getValue(
                      item,
                      `upload_file.key`,
                      ""
                    )}`
                  : `https://${config.URL}/${getValue(item, `upload_file`, "")}`
              }
              type="video/mp4"
            />
          </video>
        </>
      )}
      {getValue(item, `type`, "") === "audio" && (
        <>
          <audio controls className="sender-message">
            <source
              src={
                getValue(item, `upload_file.key`, "")
                  ? `https://${config.URL}/${getValue(
                      item,
                      `upload_file.key`,
                      ""
                    )}`
                  : `https://${config.URL}/${getValue(item, `upload_file`, "")}`
              }
              type="audio/ogg"
            />
          </audio>
        </>
      )}
      {getValue(item, `type`, "") === "document" && (
        <>
          {/* <iframe
            src={`https://${config.URL}/${getValue(
              item,
              `upload_file.key`,
              ""
            )}`}
            title="PDF Viewer"
            width="100%"
            height="100px"
            style={{
              border: "none",
            }}
          /> */}
          {/* <p>{`https://${config.URL}/${getValue(
              item,
              `upload_file.key`,
              ""
            )}`}</p> */}
          <p className="me-4">{filename}</p>
        </>
      )}
    </>
  );
}

export default DocumentFormatReplyPreviews;

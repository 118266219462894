import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getFbAdManagerLeadFormsByID } from "@services/admanager.service";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import FacebookLeadList from "./pages";
import Navbar from "../../common/navbar";
import BackButton from "@components/custom/BrowserBack";

function AdsLeadInfo(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const views = [
    {
      id: "1",
      name: "Lead Forms",
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getFbAdManagerLeadFormsByID(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <HomeHeader />
      <CompanyProfileSubHeader />
      <div className="d-flex">
        <CompanyProfileNavigation />
        <div className="w-100">
          <div className="d-flex gap-1 align-items-center">
            <BackButton className="ms-4 mt-3" />
            <Navbar
              views={views}
              route={`/settings/ads/lead-forms/${getValue(params, `id`, "")}`}
            />
          </div>
          <FacebookLeadList isLoading={isLoading} list={info} />
        </div>
      </div>
    </div>
  );
}

export default AdsLeadInfo;

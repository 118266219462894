import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React from "react";

function CompanyInfoForm(props: any) {
  const { request, setRequest } = props;
  return (
    <div>
      {/* <h3 className="header_text__18 mt-2">Company Booking Info</h3> */}
      <div className="grid-container gap-3 border  border-radius__5 my-3 add-box-shadow">
        <div className="mx-1">
          <InputRuleForm
            inputType="TEXT"
            name="company_name"
            value={getValue(request, `company_booking_info.company_name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                company_booking_info: {
                  ...request.company_booking_info,
                  company_name: e.target.value,
                },
              })
            }
            placeholder="Enter Company Name"
            label="Company Name"
          />
        </div>
        <div className="mx-1">
          <InputRuleForm
            inputType="TEXT"
            name="company_email"
            value={getValue(request, `company_booking_info.company_email`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                company_booking_info: {
                  ...request.company_booking_info,
                  company_email: e.target.value,
                },
              })
            }
            placeholder="Enter Company Email"
            label="Company Email"
          />
        </div>
        <div className="mx-1">
          <InputRuleForm
            inputType="TEXT"
            name="address"
            value={getValue(request, `company_booking_info.address`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                company_booking_info: {
                  ...request.company_booking_info,
                  address: e.target.value,
                },
              })
            }
            placeholder="Enter Company Address"
            label="Company Address"
          />
        </div>
        <div className="mx-1">
          <InputRuleForm
            inputType="TEXT"
            name="company_phone"
            value={getValue(request, `company_booking_info.company_phone`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                company_booking_info: {
                  ...request.company_booking_info,
                  company_phone: e.target.value,
                },
              })
            }
            placeholder="Enter Company Phone"
            label="Company Phone"
          />
        </div>
        <div className="mx-1">
          <InputRuleForm
            inputType="TEXT"
            name="gst_number"
            value={getValue(request, `company_booking_info.gst_number`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                company_booking_info: {
                  ...request.company_booking_info,
                  gst_number: e.target.value,
                },
              })
            }
            placeholder="Enter GST Number"
            label="GST Number"
          />
        </div>
      </div>
    </div>
  );
}

export default CompanyInfoForm;

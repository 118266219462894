import AddSvgComponent from "@assets/svg/add";
import InfoSvgComponent from "@assets/svg/info";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { getValue } from "@utils/lodash";
import { Tooltip } from "antd";
import React, { useState } from "react";
import CarouselNavViews from "../../components/template-messages/components/carousel-nav-views";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { Link } from "react-router-dom";
import { formatString } from "@common/text-helpers";
import { config } from "env";
import InputRuleForm from "@components/InputRuleForm/form";
import {
  handlePhoneNestedRequestArrayChange,
  handleSelectNestedRequestArrayChange,
  handleSelectRequestArrayChange,
  handleTextNestedRequestArrayChange,
  handleTextRequestArrayChange,
} from "@common/handleChange";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";

const TemplateCarousel = (props: any) => {
  const {
    request,
    setRequest,
    editId,
    activeCarouselTab,
    setActiveCarouselTab,
    handleAddCarousel,
    handleRemoveCarousel,
    handleCarouselTab,
    handlChangeType,
    handleCarouseFileChange,
  } = props;

  const [carouselHeaderEnum] = useState([
    { value: "IMAGE", label: "Image Carousel" },
    { value: "VIDEO", label: "Video Carousel" },
  ]);
  const [buttonTypeEnum] = useState([
    { value: "QUICK_REPLY", label: "Quick Reply" },
    { value: "CALL_TO_ACTION", label: "Call to Action" },
    { value: "URL", label: "URL" },
  ]);

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <h6 className="">Carousel format</h6>
          <Tooltip
            title="The media header format and button types must be the same across all cards that make up a carousel template."
            className="mb-1"
          >
            <span>
              <InfoSvgComponent color="#676767" size="16" />
            </span>
          </Tooltip>
        </div>
        <div className="d-flex align-items-center">
          {getValue(request, `carousel.length`, 0) > 0 &&
            getValue(request, `carousel.length`, 0) < 10 && (
              <div
                className="d-flex cursor-pointer "
                onClick={() => {
                  handleAddCarousel();
                  setActiveCarouselTab(getValue(request, `carousel.length`, 0));
                }}
              >
                <AddSvgComponent color={"#1a73e8"} />
                <h6 className="header_blue_text__16">Add more</h6>
              </div>
            )}
          {getValue(request, `carousel.length`, 0) > 1 && (
            <div
              className="d-flex cursor-pointer  ms-4"
              onClick={() => {
                handleRemoveCarousel(activeCarouselTab - 1);
                setActiveCarouselTab(
                  activeCarouselTab === 1 ? 1 : activeCarouselTab - 1
                );
              }}
            >
              <RemoveCircleSvgComponent color={"red"} />
              <h6 className="header_blue_text__16 color_danger">Remove</h6>
            </div>
          )}
        </div>
      </div>
      <CarouselNavViews
        length={getValue(request, `carousel.length`, 0)}
        activeTab={activeCarouselTab}
        handleTab={handleCarouselTab}
        showIndexAsLabel
      />
      {getValue(request, `carousel`, []).map(
        (carousel: object, index: number) => {
          if (activeCarouselTab === index + 1)
            return (
              <>
                <div
                  key={index}
                  className=" gap-2 mt-2 mb-3 carousal-buttons-wrapper"
                >
                  <div className="carousal-button">
                    <SearchToolTip
                      name="Carousel type"
                      data={carouselHeaderEnum}
                      label="label"
                      value={getValue(carousel, `header.type`, "")}
                      selectKey={"value"}
                      onChange={(e: any) =>
                        handleSelectRequestArrayChange(
                          index,
                          e,
                          "carousel",
                          "header",
                          "type",
                          setRequest
                        )
                      }
                      hideClose
                    />
                  </div>
                  <div className="carousal-button">
                    <SearchToolTip
                      name="Button 1"
                      data={buttonTypeEnum}
                      label="label"
                      value={getValue(
                        carousel,
                        `buttons[${0}].action_type`,
                        ""
                      )}
                      selectKey={"value"}
                      onChange={(e: any) => {
                        handleSelectNestedRequestArrayChange(
                          index,
                          0,
                          e,
                          "carousel",
                          "buttons",
                          "action_type",
                          setRequest
                        );
                        handlChangeType(
                          index,
                          0,
                          getValue(e, `action_type`, ""),
                          "carousel",
                          "buttons",
                          "type",
                          setRequest,
                          getValue(e, `value`, "") === "URL"
                            ? "url"
                            : getValue(e, `value`, "") === "CALL_TO_ACTION"
                            ? "call"
                            : ""
                        );
                      }}
                    />
                  </div>
                  <div className="carousal-button">
                    <SearchToolTip
                      name="Button 2"
                      data={buttonTypeEnum}
                      label="label"
                      value={getValue(
                        carousel,
                        `buttons[${1}].action_type`,
                        ""
                      )}
                      selectKey={"value"}
                      onChange={(e: any) => {
                        handleSelectNestedRequestArrayChange(
                          index,
                          1,
                          e,
                          "carousel",
                          "buttons",
                          "action_type",
                          setRequest
                        );
                        handlChangeType(
                          index,
                          1,
                          getValue(e, `action_type`, ""),
                          "carousel",
                          "buttons",
                          "type",
                          setRequest,
                          getValue(e, `value`, "") === "URL"
                            ? "url"
                            : getValue(e, `value`, "") === "CALL_TO_ACTION"
                            ? "call"
                            : ""
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className="greyed-description">
                    <i>
                      Cards must have a media header (image or video), body
                      text, and at least 1 button. Supports 2 buttons. Buttons
                      can be the same or a mix of{" "}
                      <Link
                        to="https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#quick-reply-buttons"
                        target="__blank"
                      >
                        quick reply buttons
                      </Link>
                      ,{" "}
                      <Link
                        to="https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#phone-number-buttons"
                        target="__blank"
                      >
                        phone number buttons
                      </Link>
                      , or{" "}
                      <Link
                        to="https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#url-buttons"
                        target="__blank"
                      >
                        URL buttons
                      </Link>
                      .
                    </i>
                  </p>
                </div>

                <div className="d-flex align-items-start mt-4 gap-2">
                  <div className="w-30 mt-3">
                    <p className="small_text__14">
                      {formatString(getValue(carousel, `header.type`, ""))}
                    </p>
                    <div className="mt-2">
                      {getValue(carousel, `header.type`, "") === "IMAGE" && (
                        <div>
                          <p className="custom-file-upload mb-2 requirement-message">
                            Choose a{" "}
                            <span className="required-file-format">
                              .jpg or .png
                            </span>{" "}
                            file
                          </p>
                          <input
                            type="file"
                            onChange={(e) => handleCarouseFileChange(e, index)}
                            className="custom-upload-filename"
                            accept={".jpg,.jpeg,.png"}
                          />
                        </div>
                      )}
                      {getValue(carousel, `header.type`, "") === "VIDEO" && (
                        <div>
                          <p
                            // htmlFor="file-upload"
                            className="custom-file-upload mb-2 requirement-message"
                          >
                            Choose a{" "}
                            <span className="required-file-format">.mp4</span>{" "}
                            file
                          </p>
                          <input
                            type="file"
                            onChange={(e) => handleCarouseFileChange(e, index)}
                            className="custom-upload-filename"
                            accept={".mp4"}
                          />
                        </div>
                      )}
                    </div>
                    <div className="carousel_media_container mt-3">
                      {(getValue(carousel, `header.type`, "") &&
                      getValue(carousel, `header.link.key`, "")
                        ? getValue(carousel, `header.link.key`, "")
                        : getValue(carousel, `header.link`, "")) && (
                        <>
                          {getValue(carousel, `header.type`, "") === "VIDEO" ? (
                            <video
                              controls
                              style={{
                                height: "100px",
                                width: "100px",
                              }}
                            >
                              <source
                                src={`https://${config.URL}/${
                                  getValue(carousel, `header.link.key`, "")
                                    ? getValue(carousel, `header.link.key`, "")
                                    : getValue(carousel, `header.link`, "")
                                }`}
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <img
                              src={
                                getValue(carousel, `header.link.key`, "")
                                  ? `https://${config.URL}/${getValue(
                                      carousel,
                                      `header.link.key`,
                                      ""
                                    )}`
                                  : `https://${config.URL}/${getValue(
                                      carousel,
                                      `header.link`,
                                      ""
                                    )}`
                              }
                              alt="Selected"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-70">
                    <div className="px-2 mt-2">
                      {getValue(carousel, `buttons`, []).map(
                        (item: object, aIndex: number) => {
                          if (getValue(item, `action_type`, ""))
                            return (
                              <div className="mt-2">
                                <p className="small_text__14 mt-3">
                                  {formatString(
                                    getValue(item, `action_type`, "")
                                  )}
                                </p>
                                {getValue(item, `action_type`, "") ===
                                "QUICK_REPLY" ? (
                                  <InputRuleForm
                                    inputType="TEXT"
                                    onChange={(e: any) =>
                                      handleTextNestedRequestArrayChange(
                                        index,
                                        aIndex,
                                        e,
                                        "carousel",
                                        "buttons",
                                        "text",
                                        setRequest
                                      )
                                    }
                                    name="text"
                                    value={getValue(item, "text", "")}
                                    placeholder="eg. Yes"
                                    maxLength={25}
                                    showCount
                                  />
                                ) : (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="w-50 ">
                                      <InputRuleForm
                                        inputType="TEXT"
                                        onChange={(e: any) =>
                                          handleTextNestedRequestArrayChange(
                                            index,
                                            aIndex,
                                            e,
                                            "carousel",
                                            "buttons",
                                            "text",
                                            setRequest
                                          )
                                        }
                                        name={"text"}
                                        value={getValue(item, "text", "")}
                                        placeholder={
                                          getValue(item, `action_type`, "") ===
                                          "URL"
                                            ? "eg. Visit website"
                                            : "eg. Call us"
                                        }
                                        label={"Title"}
                                        maxLength={20}
                                        // showCount
                                      />
                                    </div>
                                    <div className="w-50 ps-2">
                                      {getValue(item, "type", "") === "call" ? (
                                        <PhoneInputComponent
                                          placeholder="Enter phone number (+91...)"
                                          value={getValue(item, "phone", "")}
                                          onChange={(e: any) => {
                                            handlePhoneNestedRequestArrayChange(
                                              index,
                                              aIndex,
                                              e,
                                              "carousel",
                                              "buttons",
                                              "phone",
                                              setRequest
                                            );
                                          }}
                                          label={"Phone Number"}
                                        />
                                      ) : (
                                        <InputRuleForm
                                          inputType="TEXT"
                                          onChange={(e: any) =>
                                            handleTextNestedRequestArrayChange(
                                              index,
                                              aIndex,
                                              e,
                                              "carousel",
                                              "buttons",
                                              getValue(item, "type", "") ===
                                                "url"
                                                ? "url"
                                                : "phone",
                                              setRequest
                                            )
                                          }
                                          name={"url"}
                                          value={getValue(item, "url", "")}
                                          placeholder={`Enter ${
                                            getValue(item, "type", "") === "url"
                                              ? "url"
                                              : "Phone Number"
                                          }`}
                                          label={
                                            getValue(item, "type", "") === "url"
                                              ? "URL"
                                              : "Phone"
                                          }
                                          // maxLength={20}
                                          // showCount
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                        }
                      )}
                    </div>
                    <div className="px-2 mt-2">
                      <InputRuleForm
                        inputType="TEXTAREA"
                        label="Body"
                        value={getValue(carousel, `body`, "")}
                        name="body"
                        onChange={(e: any) =>
                          handleTextRequestArrayChange(
                            index,
                            e,
                            "carousel",
                            setRequest
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <p className="greyed-description my-3">
                  Upload a sample{" "}
                  {getValue(carousel, `header.type`, "") === "IMAGE"
                    ? "image"
                    : "video"}{" "}
                  to help us understand what kind of image that you want to send
                  to yours users. You can change this image anytime later while
                  sending this template.
                </p>
              </>
            );
        }
      )}
    </div>
  );
};

export default TemplateCarousel;

import { getValue } from "@utils/lodash";
import React from "react";

function RoomPreferencesSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "20"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "20"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#1a73e8"
        }
      >
        <path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
      </svg>
    </div>
  );
}

export default RoomPreferencesSvgComponent;

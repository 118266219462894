import * as React from "react";
import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
interface IBotCloneConfirmModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
}
const BotCloneConfirmModal = (props: any) => {
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onCancel={props.handleModal}
      onOk={props.handleSubmit}
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      <section className="">
        <div className="">
          <div className="m-3 p-3">
            <img src="/images/info-delete.png" className="delete-icon" />
            <p className="delete_text mt-1">Are you sure ?</p>
            <p className="small_text__16 mt-2">
              {/* You are about to delete{" "} */}
              you want to clone
            </p>
          </div>
        </div>
        {/* <ModalFooter> */}
        {/* <div className="popup-submit-container">
          <ButtonComponent
            onClickHandler={props.handleModal}
            buttonType={"scecondary"}
            buttonText="No"
          />
          <ButtonComponent
            buttonType={"delete"}
            buttonText={"Yes"}
            onClickHandler={() => props.handleSubmit()}
          />
        </div> */}
        {/* </ModalFooter> */}
      </section>
    </Modal>
  );
};

export default BotCloneConfirmModal;

import { replaceDefaultPlaceholders } from "@common/markdown/markdown-helper";
import { getValue } from "@utils/lodash";

export const edgeRequestHelper = (list: any, handleDeleteEdge: any) => {
  return list.map((item: object, index: number) => {
    return {
      id: getValue(item, `id`, ""),
      source: getValue(item, `flow_id`, ""),
      target: getValue(item, `id`, ""),
      sourceHandle: getValue(item, `source`, ""),
      targetHandle: getValue(item, `target`, ""),
      animated: true,
      type: "buttonedge",
      data: {
        label: getValue(item, `name`, ""),
        description: replaceDefaultPlaceholders(
          getValue(item, `rendering_config.replies[${0}].text`, "")
        ),
        id: getValue(item, `id`, ""),
        onDelete: handleDeleteEdge,
      },
      // markerEnd: {
      //   type: "arrowclosed",
      //   width: 20,
      //   height: 20,
      //   color: "#0bc5ea",
      // },
      style: {
        strokeWidth: 1.5,
        stroke: "#0bc5ea",
      },
    };
  });
};

import AddCircleSvgComponent from "@assets/svg/add-circle";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";

const CounterButtons = ({
  label,
  value,
  maxValue,
  onAdd,
  onRemove,
  disableRemove,
  disableAdd,
}: any) => {
  return (
    <div className="mt-3 ms-2">
      <p className="form-label">
        {label} (max {maxValue})
      </p>
      <div className="d-flex align-items-center">
        <button
          onClick={onRemove}
          className="count-buttons"
          disabled={disableRemove}
        >
          <RemoveCircleSvgComponent
            size={30}
            color={disableRemove && "#aeaeae"}
          />
        </button>
        <h6 className="header_text__18 mx-3">{value}</h6>
        <button onClick={onAdd} className="count-buttons" disabled={disableAdd}>
          <AddCircleSvgComponent size={30} color={disableAdd && "#aeaeae"} />
        </button>
      </div>
    </div>
  );
};

export default CounterButtons;

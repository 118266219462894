import InfoSvgComponent from "@assets/svg/info";
import WarningSvgComponent from "@assets/svg/warning";
import { languageEnum } from "@common/enum";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Alert, Image, Radio, Tooltip } from "antd";
import { config } from "env";
import { useState } from "react";

const categoryDetails = [
  {
    category: "MARKETING",
    message: "New to Whatsapp marketing?",
    description: "Send with media and buttons",
  },
  {
    category: "UTILITY",
    message: "New to Whatsapp marketing?",
    description: "Send with media and buttons",
  },
  {
    category: "CAROUSEL",
    message: "New to Carousel template?",
    description:
      "Carousel templates allow you to send a single text message accompanied by a set of up to 10 carousel cards in a horizontally scrollable view.",
  },
];

const TemplateHeader = (props: any) => {
  const {
    request,
    setRequest,
    errorMessage,
    simpleValidator,
    editId,
    handleFileChange,
  } = props;

  const [categoryEnum] = useState([
    {
      value: "MARKETING",
      label: "Marketing",
      icon: "/images/icons/marketing-speaker.svg",
      tooltip:
        "Send promotions or informations about your products, servcie or business.",
    },
    {
      value: "UTILITY",
      label: "Utility",
      icon: "/images/icons/bell.svg",
      tooltip: "Send messages about existing order or account.",
    },
    {
      value: "CAROUSEL",
      label: "Carousel Marketing",
      icon: "/images/icons/bell.svg",
      tooltip: "Send carousel messages about existing order or account.",
    },
  ]);

  const [headerEnum] = useState([
    { value: "", label: "None" },
    { value: "TEXT", label: "Text" },
    { value: "IMAGE", label: "Image" },
    { value: "VIDEO", label: "Video" },
    { value: "DOCUMENT", label: "Document" },
  ]);

  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-center">
        {errorMessage && (
          <div className="warning-container">
            <WarningSvgComponent size="16" />
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
      <div className=" border-bottom">
        <div className="">
          <InputRuleForm
            label="Template Name"
            inputType="TEXT"
            name="name"
            placeholder="welcome_template, orderconfirmation"
            required
            className="mb-2"
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
            value={getValue(request, `name`, "")}
            validator={simpleValidator}
            disabled={editId ? true : false}
            validLeft
            showCount
            maxLength="60"
          />
        </div>
        <div className="mb-2 d-grid gap-1 ">
          <div>
            {/* <SearchToolTip
                      label="label"
                      data={categoryEnum}
                      selectKey={"value"}
                      value={getValue(request, `category`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          category: getValue(e, `value`, ""),
                        })
                      }
                      name="Category"
                      placeholder="Choose Langauge"
                      disabled={editId ? true : false}
                      validator={simpleValidator}
                      required
                    /> */}
            <p className="form-label">Category</p>

            <Radio.Group
              value={getValue(request, "category", "")}
              onChange={(e) =>
                setRequest({
                  ...request,
                  category: e.target.value,
                })
              }
              size="large"
              buttonStyle="solid"
              disabled={editId}
              className="d-flex"
            >
              {categoryEnum.map((item) => (
                <Radio.Button
                  key={item.value}
                  value={item.value}
                  className="flex-grow-1 text-center"
                >
                  {item.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
          {categoryDetails.map((item, index) => (
            <>
              {item.category === getValue(request, "category", "") && (
                <Alert
                  message={item.message}
                  description={item.description}
                  type="info"
                  closable
                  className="mt-1"
                  key={index}
                />
              )}
            </>
          ))}
          <div>
            <SearchToolTip
              label="label"
              data={languageEnum}
              selectKey={"value"}
              value={getValue(request, `language`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  language: getValue(e, `value`, ""),
                })
              }
              name="Language"
              placeholder="Choose Langauge"
              disabled={editId}
              validator={simpleValidator}
              required
            />
          </div>
        </div>
      </div>
      {getValue(request, `category`, "") !== "CAROUSEL" && (
        <div className="border-bottom">
          <div className="d-flex align-items-end gap-2">
            <h6 className="mt-3 ">
              Message Header <span className="optional-text">(Optional)</span>
            </h6>
            <Tooltip
              title="Highlight your brand here, use images or videos to stand
                    out."
              className="mb-1"
            >
              <span>
                <InfoSvgComponent color="#676767" size="16" />
              </span>
            </Tooltip>
          </div>
          {/* <p className="my-2 greyed-description">
                    Highlight your brand here, use images or videos to stand
                    out.
                  </p> */}
          <div className="my-3">
            {/* <SearchToolTip
                      label="label"
                      data={headerEnum}
                      selectKey={"value"}
                      value={getValue(request, `header.type`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          header: {
                            ...request.header,
                            type: getValue(e, `value`, ""),
                          },
                        })
                      }
                      name="Type"
                      placeholder="Choose type"
                    /> */}
            <p className="form-label">Type</p>
            <Radio.Group
              value={getValue(request, "header.type", "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  header: {
                    ...request.header,
                    type: getValue(e.target, "value", ""),
                  },
                })
              }
              size="large"
              // buttonStyle="solid"
              className="d-flex"
            >
              {headerEnum.map((item) => (
                <Radio.Button
                  key={item.value}
                  value={item.value}
                  className="flex-grow-1 text-center"
                >
                  {item.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>{" "}
          {getValue(request, `header.type`, "") === "TEXT" && (
            <div className=" input-with-length">
              <InputRuleForm
                inputType="TEXT"
                id="text_input"
                name="text_input"
                value={getValue(request, `header.text`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    header: {
                      ...request.header,
                      text: e.target.value,
                    },
                  })
                }
                hideFormLabel
                showCount
                maxLength="60"
                placeholder="Great offers only for you"
              />
            </div>
          )}
          {getValue(request, `header.type`, "") === "IMAGE" && (
            <div>
              <p
                // htmlFor="file-upload"
                className="custom-file-upload mb-2 requirement-message"
              >
                Choose a{" "}
                <span className="required-file-format">.jpg or .png</span> file
              </p>
              <input
                type="file"
                onChange={handleFileChange}
                className="custom-upload-filename"
                accept={".jpg,.jpeg,.png"}
              />
              <Image
                src={
                  getValue(request, `header.link.key`, "")
                    ? `https://${config.URL}/${getValue(
                        request,
                        `header.link.key`,
                        ""
                      )}`
                    : `https://${config.URL}/${getValue(
                        request,
                        `header.link`,
                        ""
                      )}`
                }
                width={200}
                height={200}
                className="object-cover"
              />
            </div>
          )}
          {getValue(request, `header.type`, "") === "VIDEO" && (
            <div>
              <p
                // htmlFor="file-upload"
                className="custom-file-upload mb-2 requirement-message"
              >
                Choose a <span className="required-file-format">.mp4</span> file
              </p>
              <input
                type="file"
                onChange={handleFileChange}
                className="custom-upload-filename"
                accept={".mp4"}
              />
            </div>
          )}
          {getValue(request, `header.type`, "") === "DOCUMENT" && (
            <div>
              <p
                // htmlFor="file-upload"
                className="custom-file-upload mb-2 requirement-message"
              >
                Choose a <span className="required-file-format">.pdf</span> file
              </p>
              <input
                type="file"
                onChange={handleFileChange}
                className="custom-upload-filename"
                accept={".pdf"}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TemplateHeader;

import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";
import LocationSvgComponent from "@assets/svg/location";
import InputRuleForm from "@components/InputRuleForm/form";
import { Spin, Tooltip } from "antd";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 278px)",
};

const libraries = ["places"];

function AutocompleteInput(props) {
  const isInitialLoad = useRef(true);

  const { request, setRequest, apiKey, initialCenter } = props;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(
    request.location.lat && request.location.long
      ? { lat: request.location.lat, lng: request.location.long }
      : initialCenter
  );
  const [loading, setLoading] = useState(false);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onAutocompleteLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const location = place.geometry.location;
        const components = place.address_components.reduce((acc, component) => {
          const types = component.types;
          if (types.includes("country")) acc.country = component.long_name;
          if (types.includes("administrative_area_level_1"))
            acc.state = component.long_name;
          if (types.includes("locality")) acc.city = component.long_name;
          if (types.includes("postal_code"))
            acc.pincode = parseInt(component.long_name, 10);
          return acc;
        }, {});

        setInputValue(place.formatted_address);
        setSelectedLocation({ lat: location.lat(), lng: location.lng() });
        setRequest((prevRequest) => ({
          ...prevRequest,
          ...components,
          address1: place.formatted_address,
          location: { lat: location.lat(), long: location.lng() },
        }));
      }
    }
  };

  const getAddressFromLatLng = (lat, lng) => {
    setLoading(true);
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const place = results[0];
        const address = place.formatted_address;
        const components = place.address_components.reduce((acc, component) => {
          const types = component.types;
          if (types.includes("country")) acc.country = component.long_name;
          if (types.includes("administrative_area_level_1"))
            acc.state = component.long_name;
          if (types.includes("locality")) acc.city = component.long_name;
          if (types.includes("postal_code"))
            acc.pincode = parseInt(component.long_name, 10);
          return acc;
        }, {});

        setInputValue(address);
        setRequest((prevRequest) => ({
          ...prevRequest,
          ...components,
          address1: address,
          location: { lat, long: lng },
        }));
        setSelectedLocation({ lat, lng });
      }
      setLoading(false);
    });
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    getAddressFromLatLng(lat, lng);
  };

  const handleAddCurrentLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getAddressFromLatLng(latitude, longitude);
        },
        (error) => {
          console.error("Error getting current location: ", error);
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (isLoaded) {
      if (request.location.lat && request.location.long) {
        setSelectedLocation({
          lat: request.location.lat,
          lng: request.location.long,
        });

        if (isInitialLoad.current) {
          // On initial load, set input value without triggering backend update
          setInputValue(request.address1 || "");
          isInitialLoad.current = false; // Mark initial load as complete
        } else {
          // For subsequent updates, fetch address and update state
          getAddressFromLatLng(request.location.lat, request.location.long);
        }
      } else {
        setSelectedLocation(initialCenter);
      }
    }
  }, [isLoaded, request.location.lat, request.location.long]);

  return isLoaded && apiKey ? (
    <div className="w-100">
      <div className="d-flex gap-3 align-items-center">
        <div className="w-90">
          <Autocomplete
            onLoad={onAutocompleteLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <InputRuleForm
              inputType="TEXT"
              type="text"
              placeholder="Enter the location"
              label="Select location"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Autocomplete>
        </div>
        <Tooltip title="Detect current location">
          <div
            onClick={handleAddCurrentLocation}
            className="mt-4 cursor-pointer get-current-location"
          >
            <LocationSvgComponent />
          </div>
        </Tooltip>
      </div>
      <div className="mb-3 mt-3 position-relative">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={selectedLocation}
          zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={handleMapClick}
        >
          <Marker position={selectedLocation} />
          {loading && (
            <div className="google-map-updation-status">
              <Spin>Updating location</Spin>
            </div>
          )}
        </GoogleMap>
      </div>
    </div>
  ) : null;
}

export default AutocompleteInput;

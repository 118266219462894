import { convertCurrentDateWithTime } from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import { Table, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";

function TemplateTable(props: any) {
  const {
    templateList,
    page_no,
    page_size,
    totalCount,
    handleChangePagination,
  } = props;

  const templateColumn = [
    {
      title: "TEMPLATE NAME",
      dataIndex: "name",
      key: "name",
      render: (text: any, item: any) => (
        <Link to={`template/${item.id}`}>{text}</Link>
      ),
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
      render: (_: any, { category }: any) => {
        let color;
        if (category === "UTILITY") {
          color = "magenta";
        } else if (category === "MARKETING") {
          color = "green";
        } else {
          color = "default";
        }

        return (
          // <Tag color={color} key={category}>
          <p className="small_text__14">{formatString(category)}</p>
          // </Tag>
        );
      },
    },

    {
      title: "LAST UPDATED",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at: any) => convertCurrentDateWithTime(updated_at),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (status: any) => {
        let color;
        switch (status) {
          case "PENDING":
            color = "blue";
            break;
          case "APPROVED":
            color = "green";
            break;
          case "REJECTED":
            color = "red";
            break;
          case "IN_APPEAL":
            color = "blue";
            break;
          case "PENDING_DELETION":
            color = "blue";
            break;
          case "PAUSED":
            color = "orange";
            break;
          case "LIMIT_EXCEEDED":
            color = "red";
            break;
          default:
            color = "default";
        }

        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "REJECT REASON",
      dataIndex: "error_reason_message",
      key: "error_reason_message",
      width: 300,
      render: (error_reason_message: any) => (
        <Tooltip title={error_reason_message}>
          <div>
            {error_reason_message
              ? error_reason_message.length > 40
                ? error_reason_message.slice(0, 40) + "..."
                : error_reason_message
              : ""}
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <Table
      size="middle"
      columns={templateColumn}
      dataSource={templateList}
      rowKey="id"
      pagination={{
        current: page_no,
        pageSize: page_size,
        total: totalCount,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50"],
        onChange: (page_no, pageSize) => {
          handleChangePagination(page_no, pageSize);
        },
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      bordered
      scroll={{
        y: 380,
        x: "100%",
      }}
    />
  );
}

export default TemplateTable;

import * as React from "react";
import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";
import bin from "./delete.json";
import "./addpopup.scss";
interface IDeleteModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
  deleteValue: string;
  deleteMessage?: string;
}
const DeleteModal: React.FunctionComponent<IDeleteModalProps> = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bin,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      // toggle={props.handleModal}
      onOk={props.handleSubmit}
      onCancel={props.handleModal}
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          className="submit__button"
        >
          Delete
        </Button>,
      ]}
    >
      {/* <ModalHeader>
        <h6 className="header_text__20 delete_text">Delete</h6>
      </ModalHeader> */}
      <section className="m-2">
        {/* <ModalHeader>
          <h6 className="header_text__16">DELETE</h6>
        </ModalHeader> */}
        <div className="">
          {/* <div style={{ justifyContent: "center", display: "flex" }}>
            <div
              style={{ width: "150px", height: "150px", alignSelf: "center" }}
            >
              <Lottie options={defaultOptions} />
            </div>
          </div> */}
          {/* <DeleteSvgComponent size={26} color={'red'}/> */}
          <div className="">
            {/* <img src="/images/info-delete.png"  className="delete-icon"/> */}
            <p className=" small_text__22 delete_text mb-4">Confirm delete </p>
            <p className="small_text__15">
              {props.deleteMessage ? (
                <>{props.deleteMessage}</>
              ) : (
                <span>
                  Are you sure you want to delete?
                  <b>{getValue(props, `deleteValue`, "")}</b>
                </span>
              )}
            </p>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default DeleteModal;

import * as React from "react";
import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
import { convertCurrentDate } from "@common/date-helpers";

const LogsPreviewModel = (props: any) => {
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onCancel={props.handleModal}
      onOk={props.handleModal}
      // toggle={getValue(props, `isOpen`, "")}
      footer={
        [
          // <Button
          //   key="cancel"
          //   onClick={props.handleModal}
          //   className="cancel__button"
          // >
          //   Cancel
          // </Button>,
          // <Button
          //   key="submit"
          //   type="primary"
          //   onClick={props.handleModal}
          //   className="submit__button"
          // >
          //   Submit
          // </Button>,
        ]
      }
    >
      <h6 className="header_text__18">Response Logs</h6>
      <div className="bot_preview_model_container">
        <section className="">
          <div className="">
            <div className="m-3 p-3">
              {getValue(props, `botInfo.length`, 0) > 0 &&
                getValue(props, `botInfo`, []).map(
                  (item: object, index: number) => {
                    const dateValue: any = new Date(
                      getValue(item, `value`, "")
                    );
                    const formattedValue = !isNaN(dateValue)
                      ? convertCurrentDate(dateValue)
                      : getValue(item, `value`, "");
                    return (
                      <div className="p-2">
                        <h6 className="header_text__16">
                          {index + 1}. {getValue(item, `key`, "")}
                        </h6>
                        <p className="small_text__14 mt-2 ms-3">
                          {formattedValue}
                        </p>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
          {/* <ModalFooter> */}
          {/* <div className="popup-submit-container">
            <ButtonComponent
              onClickHandler={props.handleModal}
              buttonType={"scecondary"}
              buttonText="Cancel"
            />
          </div> */}
          {/* </ModalFooter> */}
        </section>
      </div>
    </Modal>
  );
};

export default LogsPreviewModel;

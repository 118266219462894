export const sendEmailRequestObj = {
  id: "",
  action: "add",
  name: "",
  from: "",
  fromEmailType: "default",//org-email
  replyToMail: "",
  recipients: [],
  templateId: "",
  emailUsers: [],
  moduleId: "",
  displayName:''
};

export const updatesRequestObj = {
  id: "",
  action: "add",
  name: "",
  moduleName: "",
  moduleFieldId: "",
  valueToUse: "",
  isEmpty: false,
};

export const tasksRequestObj = {
  id: "",
  action: "add",
  name: "",
  owner: "",
  dueDate: "",
  dueDateDays: 0,
  reminderDate: "",
  priority: "",
};

export const whatsappRequestObj = {
  id: "",
  action: "add",
  name: "",
  templateId: "",
  userPreference: "",
  alternateNumbers:[{value:''}],
  waAccountId:""
};

import { config } from "../../../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
} from "../../helpers/http-handler";

//tax rate
export const createTaxRate = (payload: object) =>
  post(`${config.API_URL}/settings/tax/rate`, payload);

export const getAllTaxRate = (queryRequest: string) =>
  get(`${config.API_URL}/settings/tax/rate?${queryRequest}`);

export const getSpecificTaxRate = (id: string) =>
  get(`${config.API_URL}/settings/tax/rate/${id}`);

export const updateTaxRate = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/tax/rate/${id}`, payload);

export const bulkUpdateTaxRate = (payload: object) =>
  patch(`${config.API_URL}/settings/tax/rate/bulk-update`, payload);

export const associateTaxRate = (id: string, payload: object) =>
  post(`${config.API_URL}/settings/tax/${id}/rate/association/add`, payload);

export const removeAssociatedTaxRate = (id: string, payload: object) =>
  DeleteWithPayload(
    `${config.API_URL}/settings/tax/${id}/rate/associations/remove`,
    payload
  );

//tax category
export const createTaxCategory = (payload: object) =>
  post(`${config.API_URL}/settings/tax/category`, payload);

export const getAllTaxCategory = (queryRequest: string) =>
  get(`${config.API_URL}/settings/tax/category?${queryRequest}`);

export const getSpecificTaxCategory = (id: string) =>
  get(`${config.API_URL}/settings/tax/category/${id}`);

export const updateTaxCategory = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/tax/category/${id}`, payload);

export const bulkUpdateTaxCategory = (payload: object) =>
  patch(`${config.API_URL}/settings/tax/category/bulk-update`, payload);

export const associateTaxCategory = (id: string, payload: object) =>
  post(
    `${config.API_URL}/settings/tax/${id}/category/association/add`,
    payload
  );

export const removeAssociatedTaxCategory = (id: string, payload: object) =>
  DeleteWithPayload(
    `${config.API_URL}/settings/tax/${id}/category/associations/remove`,
    payload
  );

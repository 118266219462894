import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { emojisList } from "@common/emojis";
import { handleTextArrayChange } from "@common/handleChange";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React from "react";

function BotButtonsComponent(props: any) {
  const { request, setRequest } = props;
  const handleAddButton = () => {
    let obj = { text: "" };
    request.suggestions.push(obj);
    setRequest({ ...request });
  };
  const handleRemoveButton = (index: number) => {
    let filterdList = getValue(request, `suggestions`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      suggestions: filterdList,
    });
  };

  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    setRequest({
      ...request,
      description: value,
    });
  };

  return (
    <div>
      <div className="mb-2">
        {/* <InputRuleForm
          inputType="TEXT"
          value={getValue(request, `name`, "")}
          name="name"
          onChange={(e: any) =>
            setRequest({ ...request, name: e.target.value })
          }
          label="Name"
          placeholder="Enter Name"
          // required
        /> */}
      </div>
      <div className="mb-2">
        <DraftJSRefEditor
          editorState={getValue(request, `description`, "")}
          handleChangeEditor={handleChangeEditor}
          name="content"
          placeholder="Enter here"
          editorStyle={{
            background: "white",
            paddingLeft: "20px",
            paddingTop: "20px",
            // height: "30vh",
          }}
          focusColor={"#1a73e8"}
          toolbar={{
            options: ["inline", "emoji"],
            inline: {
              options: ["bold", "italic"],
            },
            emoji: {
              emojis: emojisList,
            },
          }}
          hideFocus
          focus
          editorClassName={'bot-editorClassName'}
        />
        {/* <InputRuleForm
          inputType="TEXTAREA"
          value={getValue(request, `description`, "")}
          name="description"
          onChange={(e: any) =>
            setRequest({ ...request, description: e.target.value })
          }
          label="Message"
          placeholder="Enter Message"
          // mandatory
        /> */}
      </div>
      <div className="mb-2 mt-5">
        <h6 className="header_text__16 mb-2">
          Button text - ({getValue(request, `suggestions.length`, 0)})
        </h6>
        {getValue(request, `suggestions`, []).map(
          (sug: object, index: number) => {
            return (
              <div className="d-flex align-items-center mb-2">
                <div className="mx-2">
                  <DragIndicatorSvgComponent />
                </div>
                <div className="w-100">
                  <InputRuleForm
                    inputType="TEXT"
                    value={getValue(sug, `text`, "")}
                    name="text"
                    onChange={(e: any) =>
                      handleTextArrayChange(index, e, "suggestions", setRequest)
                    }
                    label="Name"
                    hideLabel
                    placeholder="Enter Button Text"
                  />
                </div>
                <div className="mx-2" onClick={() => handleRemoveButton(index)}>
                  <RemoveCircleSvgComponent />
                </div>
              </div>
            );
          }
        )}

        <h6
          className="header_text__15 color_primary mt-4 cursor-pointer"
          onClick={handleAddButton}
        >
          Add button
        </h6>
      </div>
    </div>
  );
}

export default BotButtonsComponent;

import * as React from "react";
import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
interface IDraftConfirmModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
  handleCancel: () => void;
}
const DraftConfirmModal: React.FunctionComponent<IDraftConfirmModalProps> = (
  props
) => {
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onCancel={props.handleModal}
      onOk={props.handleSubmit}
      // toggle={getValue(props, `isOpen`, "")}
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      <section className="add-popup-wrapper">
        {/* <ModalHeader> */}
        <h6 className="header_text__16">Confirm</h6>
        {/* </ModalHeader> */}
        <div className="">
          <div className="">
            <p className="header_text__14 mt-3 mb-4 text-left px-3">
              Are you certain you wish to proceed with saving as a draft?
            </p>
          </div>
        </div>
        {/* <ModalFooter> */}
        {/* <div className="popup-submit-container">
            <ButtonComponent
              onClickHandler={props.handleCancel}
              buttonType={"scecondary"}
              buttonText="Cancel"
            />
            &nbsp;&nbsp;&nbsp;
            <ButtonComponent
              buttonType={"primary"}
              buttonText={"Save as Draft"}
              onClickHandler={() => props.handleSubmit()}
            />
          </div> */}
        {/* </ModalFooter> */}
      </section>
    </Modal>
  );
};

export default DraftConfirmModal;

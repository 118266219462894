import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import {
  createRole,
  getAllRolePermissions,
  getSpecificRoleData,
  updateRole,
} from "@services/roles.service";
import _ from "lodash";
import { toast } from "sonner";
import { useParams, useNavigate } from "react-router-dom";
import "./roles-details.scss";
import InputRuleForm from "@components/InputRuleForm/form";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { formatString } from "@common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";
import BackSvgComponent from "@assets/svg/back-link";

interface ISettingsRoleDetailsProps {}

const SettingsRoleDetails: React.FunctionComponent<ISettingsRoleDetailsProps> = (
  props: any
) => {
  let params = useParams();
  let navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [request, setRequest] = React.useState({
    title: "",
    description: "",
  });

  const [dirtyFields, setDirtyFields] = useState({
    title: "",
    description: "",
  });

  const [mainPermissions, setMainPermissions] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    // if (props.permissionAPITriggered) {
    getData();
    if (getValue(params, `id`, "")) {
      getSpecificRole(getValue(params, `id`, ""));
    }
    // }
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getSpecificRole = async (id: string) => {
    try {
      let resp = await getSpecificRoleData(id);
      if (resp) {
        setDirtyFields({
          ...dirtyFields,
          title: getValue(resp, `data.title`, ""),
          description: getValue(resp, `data.description`, ""),
        });
        setRequest({
          ...request,
          title: getValue(resp, `data.title`, ""),
          description: getValue(resp, `data.description`, ""),
        });
        setSelectedPermissionList(getValue(resp, `data.permissions`, []));
      }
    } catch (error) {}
  };
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getAllRolePermissions("");
      if (resp) {
        console.log(resp);
        let arr: any = [];
        getValue(resp, `data`, []).filter((item: any) => {
          if (item.is_tab_group) {
            arr.push(item);
          } else {
            arr.push({
              component: item.component,
              is_tab_group: true,
              items: [
                { component: item.component, permissions: item.permissions },
              ],
            });
          }
        });
        setMainPermissions(arr);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (getValue(selectedPermissionList, `length`, 0) > 0) {
        setSubmitLoading(true);
        try {
          let payload = {
            title: request.title,
            description: request.description,
            permissions: selectedPermissionList,
          };
          if (getValue(params, `id`, "")) {
            let resp = await updateRole(getValue(params, `id`, ""), payload);
            if (resp) {
              navigate(`/${getValue(params, `ordId`, "")}/settings/roles`);
              toast.success("Updated successfully");
              // window.location.reload();
              setSubmitLoading(false);
            } else {
              setSubmitLoading(false);
            }
          } else {
            let resp = await createRole(payload);
            if (resp) {
              navigate(`/${getValue(params, `ordId`, "")}/settings/roles`);
              toast.success("Created successfully");
              setSubmitLoading(false);
            } else {
              setSubmitLoading(false);
            }
          }
        } catch (error) {
          setSubmitLoading(false);
        }
      } else {
        toast.error("All fields are mandatory");
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });

  /* -------------------------------------------------------------------------- */
  /*                         New Permission Section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedPermissionList, setSelectedPermissionList] = useState<any>([]);
  const handleSelectAll = () => {
    let allPermissions = extractPermissions(mainPermissions);
    if (
      getValue(allPermissions, `length`, 0) > 0 &&
      getValue(allPermissions, `length`, 0) ===
        getValue(selectedPermissionList, `length`, 0)
    ) {
      setSelectedPermissionList([]);
    } else {
      setSelectedPermissionList(allPermissions);
    }
  };

  const handleCheckAllStatus = () => {
    // debugger;
    let allPermissions = extractPermissions(mainPermissions);
    if (
      getValue(allPermissions, `length`, 0) > 0 &&
      getValue(allPermissions, `length`, 0) ===
        getValue(selectedPermissionList, `length`, 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const extractPermissions = (data: any) => {
    const result: any = [];

    const processItem = (item: any) => {
      if (getValue(item, `value`, "")) {
        // Individual permission format
        result.push(item.value);
      } else if (getValue(item, `permissions.length`, 0) > 0) {
        // Array of permissions format
        item.permissions.forEach((permission: any) => {
          result.push(permission.value);
        });
      }

      if (getValue(item, `items.length`, 0) > 0) {
        item.items.forEach((subItem: any) => processItem(subItem));
      }
    };

    data.forEach(processItem);

    return result;
  };

  const handleChangeSelectedPermission = (data: any) => {
    let permissions: any = extractPermissions(data);
    // Create a Set to eliminate duplicates
    const uniquePermissionsSet = new Set([
      ...selectedPermissionList,
      ...permissions,
    ]);
    // Convert the Set back to an array
    const uniquePermissionsArray = Array.from(uniquePermissionsSet);
    setSelectedPermissionList(uniquePermissionsArray);
  };

  const handleChangeRemoveSelectedPermission = (data: any) => {
    let permissions: any = extractPermissions(data);
    let removedPermissions = selectedPermissionList.filter(
      (item: string) => !permissions.includes(item)
    );
    setSelectedPermissionList(removedPermissions);
  };
  const handleChangeSinglePermission = (per: string) => {
    if (selectedPermissionList.includes(per)) {
      let list = selectedPermissionList.filter((item: string) => item !== per);
      setSelectedPermissionList(list);
    } else {
      selectedPermissionList.push(per);
      setSelectedPermissionList([...selectedPermissionList]);
    }
  };
  const checkSelectedPermission = (data: any) => {
    let permissions: any = extractPermissions(data);
    const result =
      getValue(selectedPermissionList, "length", 0) > 0 &&
      permissions.every((permission: string) =>
        selectedPermissionList.includes(permission)
      );
    return result;
  };

  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = (value: any) => {
    setIsOpen(value);
  };

  const handletogglerCheck = () => {
    // if (getValue(selectedPermissionList, `length`, 0)) {
    //   return true;
    // }
  };

  console.log(getValue(props, `subTabPermissionList`, []));

  return (
    <>
      <HomeHeader />

      <>
        <div className="user-details-wrapper user-details-wrapper__sticky">
          <div className="user-details-wrapper__header d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <NavLink
                to={`/${getValue(params, `orgId`, "")}/settings/roles`}
                className="user-details-wrapper__header-back-link"
              >
                <BackSvgComponent />
              </NavLink>
              <div className="user-details-wrapper__add-user-title">Roles</div>
            </div>
            {getValue(params, `id`, "") ? (
              <div className="d-flex align-items-center justify-content-end">
                <ButtonComponent
                  buttonType={"scecondary"}
                  buttonText={"Cancel"}
                  onClickHandler={() =>
                    navigate(`/${getValue(params, `orgId`, "")}/settings/roles`)
                  }
                />
                <ButtonComponent
                  buttonType={"primary"}
                  buttonText={submitLoading ? "Please wait..." : "Submit"}
                  onClickHandler={handleSubmit}
                />
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-end">
                <ButtonComponent
                  buttonType={"scecondary"}
                  buttonText={"Cancel"}
                  onClickHandler={() =>
                    navigate(`/${getValue(params, `orgId`, "")}/settings/roles`)
                  }
                />
                <ButtonComponent
                  buttonType={"primary"}
                  buttonText={submitLoading ? "Please wait..." : "Submit"}
                  onClickHandler={handleSubmit}
                />
              </div>
            )}
          </div>
        </div>
        <div className="pt-5 roles-detail-page-section">
          <div className="rolls-wrapper">
            <div className="rolls-wrapper__form-wrapper">
              <div className="rolls-wrapper__form role-name-padding">
                <div className="rolls-wrapper__form-group form-group">
                  <label htmlFor="title" className="rolls-label">
                    Role Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Role Name"
                    className="form-control rolls-form-control"
                    id="title"
                    // validator={simpleValidator}
                    autoComplete="off"
                    value={getValue(request, `title`, "")}
                    onChange={(e: any) =>
                      setRequest({ ...request, title: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="rolls-wrapper__form-group form-group role-desc-padding">
                <label htmlFor="descriptiom" className="rolls-label">
                  Description <span>*</span>
                </label>
                <InputRuleForm
                  inputType="TEXTAREA"
                  // rows={7}
                  placeholder="Enter Description"
                  className="form-control w-100"
                  name="description"
                  id="description"
                  value={getValue(request, `description`, "")}
                  onChange={(e: any) =>
                    setRequest({ ...request, description: e.target.value })
                  }
                  label="Description"
                  validator={simpleValidator}
                />
              </div>
              <div className="rolls-wrapper__permission role-permission-padding">
                <h4 className="rolls-wrapper__permission-title">Permissions</h4>
                <label
                  className="position-relative form-group d-flex align-items-center cursor-pointer"
                  htmlFor={`select-1-z2`}
                  key={`select-1-z2`}
                >
                  <div>
                    <label className="checkbox-container-value">
                      <input
                        type="checkbox"
                        id={`select-1-z2`}
                        onChange={() => handleSelectAll()}
                        checked={handleCheckAllStatus()}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <p className="mx-1"> {"Select All Permissions"}</p>
                </label>
                <p className="rolls-wrapper__permission-text">
                  Applications - Define all allowed actions for the applicable
                  tab
                </p>
              </div>
              {isLoading ? (
                <p className="text-center mt-2">Please wait....</p>
              ) : (
                <div>
                  {mainPermissions.map((item: any, index1: number) => {
                    return (
                      <div key={index1}>
                        <div className="mb-5">
                          <div className="d-flex align-items-center toggle-switch-wrap-roles-holder">
                            <h5>
                              {formatString(getValue(item, `component`, ""))}
                            </h5>

                            <div className="choicelist-wrapper custom-switch-wrapper">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id={`index1-${index1}`}
                                  onChange={() => {
                                    const isChecked = checkSelectedPermission(
                                      getValue(item, `items`, [])
                                    );

                                    checkSelectedPermission(
                                      getValue(item, `items`, [])
                                    )
                                      ? handleChangeRemoveSelectedPermission(
                                          getValue(item, `items`, [])
                                        )
                                      : handleChangeSelectedPermission(
                                          getValue(item, `items`, [])
                                        );
                                    // console.log(checkSelectedPermission(
                                    //   getValue(item, `items`, [])
                                    // ))
                                    if (isChecked) {
                                      toggleCollapse(false);
                                    } else {
                                      toggleCollapse(true);
                                    }
                                  }}
                                  checked={checkSelectedPermission(
                                    getValue(item, `items`, [])
                                  )}
                                  // checked={handletogglerCheck()}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          {/* <Collapse
                              id={`collapseIndex1_${index1}${item.component}`}
                              isOpen={isOpen}
                              className="rolesCollapseWrapper"
                            > */}
                          <div className="ms-2 position-relative">
                            {getValue(item, `is_tab_group`, false) &&
                              getValue(item, `items`, []).map(
                                (perItem: any, index2: number) => {
                                  let array =
                                    getValue(perItem, `permissions.length`, 0) >
                                    0
                                      ? getValue(perItem, `permissions`, [])
                                      : getValue(perItem, `items`, []);
                                  return (
                                    <div key={index2}>
                                      <div className="d-flex justify-content-between align-items-center p-2">
                                        <div className="d-flex align-items-center title-checkbox">
                                          <label className="checkbox-container">
                                            <input
                                              type="checkbox"
                                              id={`index2-${index2}`}
                                              onChange={() =>
                                                checkSelectedPermission(array)
                                                  ? handleChangeRemoveSelectedPermission(
                                                      array
                                                    )
                                                  : handleChangeSelectedPermission(
                                                      array
                                                    )
                                              }
                                              checked={checkSelectedPermission(
                                                array
                                              )}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <p
                                            className={`ms-2 title-wrapper ${
                                              getValue(
                                                perItem,
                                                `is_tab_group`,
                                                false
                                              )
                                                ? "color_primary"
                                                : ""
                                            }`}
                                          >
                                            {formatString(
                                              getValue(perItem, `component`, "")
                                            )}
                                          </p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                          {getValue(
                                            perItem,
                                            `permissions`,
                                            []
                                          ).map(
                                            (per: object, index3: number) => {
                                              return (
                                                <div className="d-flex align-items-center mx-2 role-permission-checkbox-wrap mw-70">
                                                  <label className="checkbox-container-value">
                                                    <input
                                                      id={`index3-${index3}`}
                                                      type="checkbox"
                                                      checked={selectedPermissionList.includes(
                                                        getValue(
                                                          per,
                                                          `value`,
                                                          ""
                                                        )
                                                      )}
                                                      onChange={() =>
                                                        handleChangeSinglePermission(
                                                          getValue(
                                                            per,
                                                            `value`,
                                                            ""
                                                          )
                                                        )
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <p className="label-holder">
                                                    {getValue(per, `text`, "")}
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                      {getValue(
                                        perItem,
                                        `is_tab_group`,
                                        false
                                      ) &&
                                        getValue(perItem, `items`, []).map(
                                          (subItem: object, index4: number) => {
                                            return (
                                              <div className="d-flex justify-content-between align-items-center  p-2 ml-32">
                                                <div className="d-flex align-items-center title-checkbox">
                                                  <label className="checkbox-container">
                                                    <input
                                                      type="checkbox"
                                                      id={`index4-${index4}`}
                                                      onChange={() =>
                                                        checkSelectedPermission(
                                                          getValue(
                                                            subItem,
                                                            `permissions`,
                                                            []
                                                          )
                                                        )
                                                          ? handleChangeRemoveSelectedPermission(
                                                              getValue(
                                                                subItem,
                                                                `permissions`,
                                                                []
                                                              )
                                                            )
                                                          : handleChangeSelectedPermission(
                                                              getValue(
                                                                subItem,
                                                                `permissions`,
                                                                []
                                                              )
                                                            )
                                                      }
                                                      checked={checkSelectedPermission(
                                                        getValue(
                                                          subItem,
                                                          `permissions`,
                                                          []
                                                        )
                                                      )}
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <p className="ms-2 title-wrapper">
                                                    {formatString(
                                                      getValue(
                                                        subItem,
                                                        `component`,
                                                        ""
                                                      )
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                  {getValue(
                                                    subItem,
                                                    `permissions`,
                                                    []
                                                  ).map(
                                                    (
                                                      perSub: object,
                                                      index5: number
                                                    ) => {
                                                      return (
                                                        <div className="d-flex align-items-center mx-2 role-permission-checkbox-wrap mw-70">
                                                          <label className="checkbox-container-value">
                                                            <input
                                                              type="checkbox"
                                                              id={`index5-${index5}`}
                                                              checked={selectedPermissionList.includes(
                                                                getValue(
                                                                  perSub,
                                                                  `value`,
                                                                  ""
                                                                )
                                                              )}
                                                              onChange={() =>
                                                                handleChangeSinglePermission(
                                                                  getValue(
                                                                    perSub,
                                                                    `value`,
                                                                    ""
                                                                  )
                                                                )
                                                              }
                                                            />
                                                            <span className="checkmark"></span>
                                                          </label>

                                                          <p className="label-holder">
                                                            {getValue(
                                                              perSub,
                                                              `text`,
                                                              ""
                                                            )}
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  );
                                }
                              )}
                            <div></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <br />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SettingsRoleDetails;

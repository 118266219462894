import { getValue } from "@utils/lodash";
import DocumentFormatPreviews from "./doc-formats-preview";
import DocumentSvgComponent from "@assets/svg/document";
import ArrowdownSvgComponent from "@assets/svg/arrowdown";
import DocumentFormatReplyPreviews from "./doc-formats-reply-preview";
import AddSvgComponent from "@assets/svg/add";
import { handleDownloadOnClick } from "@common/downloadfile";
import { config } from "env";
import { replaceDefaultPlaceholders } from "@common/markdown/markdown-helper";
import WhatsappIconChatViewPage from "@pages/Private/Broadcast/components/common/whatsapp-icon-chat";
import { Dropdown, Space } from "antd";

function ChatSenderSection(props) {
  const { item, setSelectedReplyMessage } = props;
  const statusColor =
    getValue(item, "status", "") === "failed" ? "#ffc8c8" : "#d9fdd4";

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => setSelectedReplyMessage(item)}
          className=" small_text__13 d-flex gap-2 align-items-center"
        >
          <img src="/images/icons/whatsapp-reply.svg" />
          Reply
        </div>
      ),
    },
    ...(item.type === "image" ||
    item.type === "video" ||
    item.type === "document"
      ? [
          {
            type: "divider",
          },
          {
            key: "2",
            label: (
              <div
                className="small_text__13 d-flex gap-2 align-items-center"
                onClick={() =>
                  handleDownloadOnClick(
                    `https://${config.URL}/${item.upload_file.key}`,
                    item.upload_file.filename
                  )
                }
              >
                <img
                  src="/images/icons/whatsapp-download.svg"
                  alt="Download"
                  className="download-icon"
                  width={16}
                  height={16}
                />
                Download
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <div className="sender-details-wrapper">
        <div className="sender-details">
          <div className="sender-left-section">
            <p
              className="sender-message"
              style={{ "--status-color": statusColor }}
            >
              <div className="arrow-container">
                <Dropdown menu={{ items }} placement="bottomRight">
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <ArrowdownSvgComponent color={"gray"} class="arrow" />
                    </Space>
                  </a>
                </Dropdown>
              </div>
              {getValue(item, `replied_message.id`, "") && (
                <div className="reply_message_container mb-2">
                  <div className="d-flex align-items-center ps-2 justify-content-between replayed-background w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <DocumentSvgComponent />
                      {/* <p className="small_text__14 mx-2">
                        {getValue(item, `replied_message.media_caption`, "")
                          ? getValue(item, `replied_message.media_caption`, "")
                          : getValue(item, `replied_message.text_body`, "")}
                      </p> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: replaceDefaultPlaceholders(
                            getValue(item, `replied_message.media_caption`, "")
                              ? getValue(
                                  item,
                                  `replied_message.media_caption`,
                                  ""
                                )
                              : getValue(item, `replied_message.text_body`, "")
                          ),
                        }}
                        className="small_text__14 px-2"
                      ></p>
                    </div>
                    {getValue(item, `replied_message.upload_file.id`, "") && (
                      <DocumentFormatReplyPreviews
                        item={getValue(item, `replied_message`, {})}
                        upload_file={getValue(
                          item,
                          `replied_message.upload_file`,
                          ""
                        )}
                      />
                    )}
                  </div>
                </div>
              )}
              {getValue(item, `broadcast_id`, "") ||
                (getValue(item, `template.id`, "") && (
                  <div>
                    <WhatsappIconChatViewPage
                      request={getValue(item, `template`, {})}
                    />
                  </div>
                ))}

              <DocumentFormatPreviews item={item} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatSenderSection;

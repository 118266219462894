// import "../../PendingTaskMainBody.scss";
import { getValue } from "@utils/lodash";
import AddSvgComponent from "@assets/svg/add";
import { Button } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

function MainBodyHeaderFilter(props: any) {
  const { urlParams, handleOpenForm, handleOpenNotes, handleEditNote } = props;
  const { isEventOpen, setIsEventOpen, isCallOpen, setIsCallOpen } = props;
  return (
    <div
      className={`pending-wrapper__main-section-filter d-flex aign-items-center justify-content-between`}
    >
      <div></div>
      {(!getValue(urlParams, `activeTab`, "") ||
        getValue(urlParams, `activeTab`, "") == 1) && (
        <div
          className="d-flex align-items-center"
          onClick={() => handleEditNote()}
        >
          {/* <button className={`create-filter-button`}>
            <div className="">
              <AddSvgComponent size={20} theme />
            </div>
            <p className="ms-1">Note</p>
          </button> */}
          {/* <Button icon={<PlusCircleFilled style={{ fontSize: "18px" }} />}>
            Note
          </Button> */}
        </div>
      )}
      {getValue(urlParams, `activeTab`, "") == 2 && (
        <div
          className="d-flex align-items-center"
          onClick={() => handleOpenForm("tasks")}
        >
          {/* <button className={`create-filter-button`}>
            <div className="">
              <AddSvgComponent size={20} theme />
            </div>
            <p className="ms-1">Task</p>
          </button> */}
          <Button icon={<PlusCircleFilled style={{ fontSize: "18px" }} />}>
            Task
          </Button>
        </div>
      )}

      {getValue(urlParams, `activeTab`, "") == 3 && (
        <>
          <div className="d-flex align-items-center">
            {(!getValue(urlParams, `activeActivitySubTab`, "") ||
              getValue(urlParams, `activeActivitySubTab`, "") == 1) && (
              <div
                // className={`create-filter-button`}
                onClick={() => setIsEventOpen(!isEventOpen)}
              >
                {/* <div className="">
                  <AddSvgComponent size={20} theme />
                </div>
                <p className="ms-1">Meeting</p> */}
                <Button
                  icon={<PlusCircleFilled style={{ fontSize: "18px" }} />}
                >
                  Meeting
                </Button>
              </div>
            )}
            {getValue(urlParams, `activeActivitySubTab`, "") == 2 && (
              <div
                // className={`create-filter-button ms-2`}
                onClick={() => setIsCallOpen(!isCallOpen)}
              >
                {/* <div className="">
                  <AddSvgComponent size={20} theme />
                </div>
                <p className="ms-1">Call</p> */}

                <Button
                  icon={<PlusCircleFilled style={{ fontSize: "18px" }} />}
                >
                  Call
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      {getValue(urlParams, `activeTab`, "") == 4 && (
        <div
          className="d-flex align-items-center"
          onClick={() => props.handleSendEmail()}
        >
          {/* <div className="">
              <AddSvgComponent size={20} theme />
            </div>
            <p className="ms-1">Compose</p> */}
          <Button icon={<PlusCircleFilled style={{ fontSize: "18px" }} />}>
            Compose
          </Button>
        </div>
      )}
    </div>
  );
}

export default MainBodyHeaderFilter;

import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useNavigate, useParams } from "react-router-dom";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { toast } from "sonner";
import {
  assignAmenitiesToRoomType,
  AssignImageToRoomType,
  createRoomType,
  deleteAmenitiesFromRoomType,
  DeleteRoomTypeImage,
  getAllRoomType,
  getAllRoomTypeImage,
  getSpecificRoomType,
} from "@services/properties/room-type.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { updateRoomType } from "@services/properties/room-type.service";
import AddRoomPopup from "./components/add-rooms-popup";
import PropertBookingTypes from "./property-booking-type";
import PropertyRoomTypes from "./room-types";
import SimpleReactValidator from "simple-react-validator";
import { mediaUpload } from "@services/upload.service";
import { getAllRoomAmenities } from "@services/properties/master/room-amenites.service";
import { convertNumberStringsToNumbers } from "@common/object-helper";
import { getAllTaxCategory } from "@services/properties/settings/tax.service";

const PropertyRoomTypesPage = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (getValue(params, `id`, "")) {
      getData();
      getTaxGroupData();
      getRoomAmentiesData();
      if (getValue(UrlParams, `room_id`, "")) {
        getSpecificData(getValue(UrlParams, `room_id`, ""));
      }
    }
  }, []);

  const [roomsLength, setRoomsLength] = useState<any>(1);

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  const [isOpen3, setIsOpen3] = useState(false);

  const handleModal3 = () => {
    setIsOpen3(!isOpen3);
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomType(queryRequest);
      if (resp) {
        setIsLoading(false);
        setRooms(getValue(resp, `data.properties`, []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const [list, setList] = useState([]);
  const getTaxGroupData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTaxCategory(queryRequest);
      if (resp) {
        setList(
          getValue(resp, `data.taxCategories`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `category_name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDelete = (id: string) => {
    setDeleteId(id);
    handleModal();
  };

  const handleDeleteRoomType = async () => {
    try {
      // let resp = await deleteRo
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Creation Section                             */
  /* -------------------------------------------------------------------------- */

  const [editRoomId, setEditRoomId] = useState("");
  const [modifyRoom, setModifyRoom] = useState(false);
  const handleModifyRoom = () => {
    setModifyRoom(!modifyRoom);
  };
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [roomTypeActivetab, setRoomTypeActiveTab] = useState(1);

  const triggerAllFunctionsWhileEdit = (id: string) => {
    getSpecificData(id);
    getSpecificAmenityData(id);
    getRoomImages(id);
  };

  /* ------------------------------- Room Profile Section --------------------- */
  const intialState = {
    name: "",
    description: "",
    max_occupancy: 1,
    max_adult: 1,
    max_children: 0,
    default_price: 0,
    min_price: 0,
    child_price: 0,
    tax_category_id: "",
    property_id: "",
    rate_plan_id: "",
    rooms: [{ value: "" }],
  };
  const [request, setRequest] = useState<any>(intialState);
  const reset = () => {
    setRequest(intialState);
    setEditRoomId("");
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitRoom = async () => {
    if (
      parseInt(getValue(request, `max_adult`, "")) +
        parseInt(getValue(request, `max_children`, "")) ===
      parseInt(getValue(request, `max_occupancy`, ""))
    ) {
      try {
        setSubmitLoading(true);
        let requestPayload = JSON.parse(JSON.stringify(request));
        requestPayload["rooms"] = requestPayload["rooms"].map((item: object) =>
          getValue(item, `value`, "")
        );
        requestPayload["property_id"] = getValue(params, `id`, "");
        let resp;
        let reqPayload = convertNumberStringsToNumbers(
          removeNullOrUndefinedProperties(requestPayload)
        );
        if (!editRoomId) {
          resp = await createRoomType(reqPayload);
        } else {
          resp = await updateRoomType(editRoomId, reqPayload);
        }
        if (resp) {
          getData();
          toast.success("Updated successfully");
          setSubmitLoading(false);
          setEditRoomId(
            editRoomId ? editRoomId : getValue(resp, `data.id`, "")
          );
          setRoomTypeActiveTab(2);
          // reset()
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        console.error(error);
        setSubmitLoading(false);
      }
    } else {
      toast.error("Adult and child count must match maximum occupancy.");
    }
  };

  const handleEditRoomProfile = (id: string) => {
    triggerAllFunctionsWhileEdit(id);
    setEditRoomId(id);
    setRoomTypeActiveTab(1);
    setModifyRoom(true);
  };
  const [isLoading1, setIsLoading1] = useState(true);
  const getSpecificData = async (id: string) => {
    try {
      setIsLoading1(true);
      let resp = await getSpecificRoomType(id);
      if (resp) {
        setRoomsLength(getValue(resp, `data.room.length`, 0));
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          description: getValue(resp, `data.description`, ""),
          max_occupancy: getValue(resp, `data.max_occupancy`, ""),
          max_adult: getValue(resp, `data.max_adult`, ""),
          max_children: getValue(resp, `data.max_children`, ""),
          default_price: getValue(resp, `data.default_price`, ""),
          min_price: getValue(resp, `data.min_price`, ""),
          child_price: getValue(resp, `data.child_price`, ""),
          tax_category_id: getValue(resp, `data.tax_category_id`, ""),
          property_id: getValue(resp, `data.property_id`, ""),
          rate_plan_id: getValue(resp, `data.rate_plan_id`, ""),
          rooms: getValue(resp, `data.room`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `room_id`, ""),
          })),
        });
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  /* ------------------------------- Room Amenities Section --------------------- */
  const handleEditRoomAmenities = (id: string) => {
    triggerAllFunctionsWhileEdit(id);
    setEditRoomId(id);
    setRoomTypeActiveTab(2);
    setModifyRoom(true);
  };
  const [amentitesList, setAmentitesList] = useState([]);
  const getRoomAmentiesData = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomAmenities(queryRequest);
      if (resp) {
        setAmentitesList(getValue(resp, `data.amenities`, []));
      } else {
      }
    } catch (error) {}
  };
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const getSpecificAmenityData = async (id: string) => {
    try {
      setIsLoading1(true);
      let resp = await getSpecificRoomType(id);
      if (resp) {
        setSelectedAmenities(
          getValue(resp, `data.room_amenities`, []).map((item: object) =>
            getValue(item, `id`, "")
          )
        );
        setIsLoading1(false);
        getRoomImages(editRoomId);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  // const handleAddRemoveAmenities = async (ids: any) => {
  //   let resp = await assignAmenitiesToRoomType(editRoomId, {
  //     amenity_ids: [ids],
  //   });
  //   if (resp) {
  //     getSpecificAmenityData(editRoomId);
  //     toast.success("Updated successfully");
  //     setSubmitLoading(false);
  //   } else {
  //     setSubmitLoading(false);
  //   }
  // };
  const handleAmenityChange = async (
    id: string | string[],
    action: "add" | "remove"
  ) => {
    let resp;
    setSubmitLoading(true);

    try {
      if (action === "add") {
        resp = await assignAmenitiesToRoomType(editRoomId, {
          amenity_ids: Array.isArray(id) ? id : [id],
        });
        if (resp) {
          // toast.success("Amenity added successfully");
        } else {
          toast.error("Failed to add amenity");
        }
      } else if (action === "remove") {
        resp = await deleteAmenitiesFromRoomType(editRoomId, {
          amenity_ids: [id],
        });
        if (resp) {
          // toast.success("Amenity deleted successfully");
          setDeleteId(""); // Reset the deleteId if needed
        } else {
          toast.error("Failed to delete amenity");
        }
      }

      if (resp) {
        getSpecificAmenityData(editRoomId);
      }
    } catch (error) {
      console.error("Error while updating amenities:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  // const handleDeleteAmenity = async (deleteId: string) => {
  //   try {
  //     let resp = await deleteAmenitiesFromRoomType(editRoomId, {
  //       amenity_ids: [deleteId],
  //     });
  //     if (resp) {
  //       toast.success("Deleted Successfully");
  //       getSpecificAmenityData(editRoomId);
  //       setDeleteId("");
  //     }
  //   } catch (error) {}
  // };

  /* ------------------------------- Room Images Section --------------------- */
  const handleEditRoomImages = (id: string) => {
    triggerAllFunctionsWhileEdit(id);
    setEditRoomId(id);
    setRoomTypeActiveTab(3);
    setModifyRoom(true);
  };
  const [uploadLoading, setUploadLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const getRoomImages = async (id: string) => {
    if (id) {
      try {
        let payload = {
          room_type_id: id,
        };
        setImageLoading(true);
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getAllRoomTypeImage(queryRequest);
        if (resp) {
          setImages(getValue(resp, `data.roomTypeImages`, []));
          setImageLoading(false);
        } else {
          setImageLoading(false);
        }
      } catch (error) {
        setImageLoading(false);
      }
    }
  };
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          let response = await AssignImageToRoomType(editRoomId, {
            images: [
              {
                file_upload_id: getValue(resp, `data.id`, ""),
                order: getValue(images, `length`, 0) + 1,
              },
            ],
          });
          if (response) {
            // getData();
            getRoomImages(editRoomId);
          }
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };
  const handleDeleteImage = async (deleteId: string) => {
    try {
      let resp = await DeleteRoomTypeImage(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        setDeleteId("");
        getRoomImages(editRoomId);
        // props.getData(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <HomeHeader />
      {modifyRoom ? (
        <>
          <PropertyRoomTypes
            roomTypeActivetab={roomTypeActivetab}
            setRoomTypeActiveTab={setRoomTypeActiveTab}
            handleModifyRoom={handleModifyRoom}
            setModifyRoom={setModifyRoom}
            reset={reset}
            //step1
            request={request}
            setRequest={setRequest}
            simpleValidator={simpleValidator}
            roomsLength={roomsLength}
            setRoomsLength={setRoomsLength}
            handleSubmitRoom={handleSubmitRoom}
            submitLoading={submitLoading}
            taxGroupList={list}
            //step2
            amentitesList={amentitesList}
            selectedAmenities={selectedAmenities}
            getSpecificAmenityData={getSpecificAmenityData}
            handleAddRemoveAmenities={handleAmenityChange}
            // handleDeleteAmenity={handleDeleteAmenity}
            //step3
            imageLoading={imageLoading}
            images={images}
            uploadLoading={uploadLoading}
            handleUploadDocuments={handleUploadDocuments}
            handleDeleteImage={handleDeleteImage}
            //submit section
            editRoomId={editRoomId}
            setEditRoomId={setEditRoomId}
          />
        </>
      ) : (
        <>
          <PropertBookingTypes
            UrlParams={UrlParams}
            rooms={rooms}
            submitLoading={submitLoading}
            isLoading={isLoading}
            handleModifyRoom={handleModifyRoom}
            handleEditRoomProfile={handleEditRoomProfile}
            handleDelete={handleDelete}
            handleEditRoomAmenities={handleEditRoomAmenities}
            handleEditRoomImages={handleEditRoomImages}
            setRoomTypeActiveTab={setRoomTypeActiveTab}
            setEditRoomId={setEditRoomId}
            reset={reset}
          />
        </>
      )}
      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDeleteRoomType}
        deleteValue={deleteValue}
      />
    </>
  );
};

export default PropertyRoomTypesPage;

import CloseMainSvgComponent from "@assets/svg/close-main";
import {
  handlePhoneNestedRequestArrayChange,
  handleSelectArrayChange,
  handleSelectNestedRequestArrayChange,
  handleSelectRequestArrayChange,
  handleTextArrayChange,
  handleTextNestedRequestArrayChange,
  handleValueArrayChange,
  handleValueNestedArrayChange,
} from "@common/handleChange";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { PhoneInput } from "react-international-phone";

function BookingInfoForm(props: any) {
  const {
    index,
    item,
    childIndex,
    setRequest,
    handleUploadDocuments,
    name,
    key_name,
    uploadLoading,
  } = props;
  const relationShipList = [
    { value: "self", label: "Self" },
    { value: "father", label: "Father" },
    { value: "mother", label: "Mother" },
    { value: "wife", label: "Wife" },
    { value: "husband", label: "Husband" },
    { value: "Sister", label: "Sister" },
    { value: "son", label: "son" },
    { value: "daughter", label: "Daughter" },
    { value: "other", label: "Other" },
  ];

  const triggerFileInput = (inputId: string): void => {
    const fileInput = document.getElementById(
      inputId
    ) as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  const removeImage = (
    index: number,
    childIndex: number,
    key: string,
    name: string,
    subName: string,
    setObject: any
  ): void => {
    setObject((prevRequest: any) => ({
      ...prevRequest,
      [key]: prevRequest[key].map((x: any, i: number) => {
        if (i !== index) return x;
        return {
          ...x,
          [name]: x[name].map((y: any, cIndex: number) => {
            if (cIndex !== childIndex) return y;
            return {
              ...y,
              [subName]: "",
            };
          }),
        };
      }),
    }));
  };

  return (
    <div className={`grid-container gap-3  p-2 border-radius__5 `} key={index}>
      <InputRuleForm
        inputType="TEXT"
        name="full_name"
        value={getValue(item, `full_name`, "")}
        placeholder="Enter full name"
        label="Customer Name"
        required
        onChange={(e: any) =>
          handleTextNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "full_name",
            setRequest
          )
        }
      />

      <InputRuleForm
        inputType="TEXT"
        name="email"
        placeholder="Enter customer email"
        label="Customer Email"
        value={getValue(item, `email`, "")}
        onChange={(e: any) =>
          handleTextNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "email",
            setRequest
          )
        }
      />
      {/* <InputRuleForm
        inputType="TEXT"
        name="mobile"
        value={getValue(item, `mobile`, "")}
        onChange={(e: any) =>
          handleTextNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "mobile",
            setRequest
          )
        }
        placeholder="Enter Mobile Number"
        label="Mobile Number"
        marginZero
      /> */}

      <div>
        <p className="form-label">Mobile Number</p>
        <PhoneInput
          // containerClass="custom-dropdown"
          defaultCountry="in"
          value={getValue(item, `mobile`, "")}
          onChange={(e: any) =>
            handlePhoneNestedRequestArrayChange(
              index,
              childIndex,
              e,
              key_name,
              name,
              "mobile",
              setRequest
            )
          }
          placeholder="Enter Mobile Number"
          className="input-number-field"
        />
      </div>

      <InputRuleForm
        inputType="DATE"
        name="dob"
        value={getValue(item, `dob`, "")}
        onChange={(e: any) =>
          handleValueNestedArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "dob",
            setRequest
          )
        }
        placeholder="Enter Date of birth"
        label="Date of Birth"
        marginZero
      />
      <SearchToolTip
        label="label"
        data={relationShipList}
        selectKey={"value"}
        value={getValue(item, `relationship`, "")}
        onChange={(e: any) =>
          handleSelectNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "relationship",
            setRequest
          )
        }
        name="Relationship"
      />

      <SearchToolTip
        label="label"
        data={[
          { value: "male", label: "Male" },
          {
            value: "female",
            label: "Female",
          },
        ]}
        selectKey={"value"}
        value={getValue(item, `gender`, "")}
        onChange={(e: any) =>
          handleSelectNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "gender",
            setRequest
          )
        }
        name="Gender"
      />

      <InputRuleForm
        inputType="TEXT"
        name="country"
        value={getValue(item, `country`, "")}
        onChange={(e: any) =>
          handleTextNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "country",
            setRequest
          )
        }
        placeholder="Enter Country"
        label="Country"
        marginZero
      />

      <InputRuleForm
        inputType="TEXT"
        name="state"
        value={getValue(item, `state`, "")}
        onChange={(e: any) =>
          handleTextNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "state",
            setRequest
          )
        }
        placeholder="Enter State"
        label="State"
        marginZero
      />

      <InputRuleForm
        inputType="TEXT"
        name="city"
        value={getValue(item, `city`, "")}
        onChange={(e: any) =>
          handleTextNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "city",
            setRequest
          )
        }
        placeholder="Enter City"
        label="City"
        marginZero
      />
      <InputRuleForm
        inputType="NUMBER"
        name="pincode"
        value={getValue(item, `pincode`, "")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleTextNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "pincode",
            setRequest
          )
        }
        placeholder="Enter Pincode"
        label="Pincode"
        marginZero
      />

      <div className="grid-full-width">
        <InputRuleForm
          inputType="TEXTAREA"
          name="address"
          value={getValue(item, `address`, "")}
          onChange={(e: any) =>
            handleTextNestedRequestArrayChange(
              index,
              childIndex,
              e,
              key_name,
              name,
              "address",
              setRequest
            )
          }
          placeholder="Enter Address"
          label="Address"
          marginZero
        />
      </div>

      <InputRuleForm
        inputType="TEXT"
        name="emergency_contact_name"
        value={getValue(item, `emergency_contact_name`, "")}
        placeholder="Enter emrgency contact name"
        label="Emergency Contact Name"
        onChange={(e: any) =>
          handleTextNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "emergency_contact_name",
            setRequest
          )
        }
      />
      <InputRuleForm
        inputType="NUMBER"
        name="emergency_contact"
        value={getValue(item, `emergency_contact`, "")}
        placeholder="Enter emrgency contact no"
        label="Emergency Contact No"
        onChange={(e: any) =>
          handleTextNestedRequestArrayChange(
            index,
            childIndex,
            e,
            key_name,
            name,
            "emergency_contact",
            setRequest
          )
        }
      />
      <div>
        <p className="form-label">Identity Card Type</p>
        <SearchToolTip
          label="label"
          data={[
            { value: "passport", label: "Passport" },
            {
              value: "drivers_license",
              label: "Driver License",
            },
            { value: "aadhar", label: "Aadhar" },
            { value: "voter_id", label: "Voter ID" },
            { value: "others", label: "Other" },
          ]}
          selectKey={"value"}
          value={getValue(item, `identity_type`, "")}
          onChange={(e: any) =>
            handleSelectNestedRequestArrayChange(
              index,
              childIndex,
              e,
              key_name,
              name,
              "identity_type",
              setRequest
            )
          }
        />
      </div>
      <div>
        <InputRuleForm
          inputType="TEXT"
          name="identity_value"
          value={getValue(item, `identity_value`, "")}
          onChange={(e: any) =>
            handleTextNestedRequestArrayChange(
              index,
              childIndex,
              e,
              key_name,
              name,
              "identity_value",
              setRequest
            )
          }
          placeholder="Enter Govt ID card no"
          label="Govt ID Number"
        />
      </div>
      <div>
        <p className="form-label">Identity Upload Type</p>
        <SearchToolTip
          label="label"
          data={[
            {
              value: "device_upload",
              label: "Device Upload",
            },
            {
              value: "camera_upload",
              label: "Camera Upload",
            },
          ]}
          selectKey={"value"}
          value={getValue(item, `identity_upload_type`, "")}
          onChange={(e: any) =>
            handleSelectNestedRequestArrayChange(
              index,
              childIndex,
              e,
              key_name,
              name,
              "identity_upload_type",
              setRequest
            )
          }
        />
      </div>
      <div></div>
      <div>
        <p className="form-label">Upload Front ID</p>
        <input
          type="file"
          id="frontFileInput"
          className="custom-upload-filename"
          accept=".jpg,.jpeg,.png"
          onChange={(e) =>
            handleUploadDocuments(
              index,
              childIndex,
              e,
              key_name,
              name,
              "identity_front_img",
              setRequest
            )
          }
          style={{ display: "none" }}
        />
        <ButtonComponent
          buttonType="smart"
          onClickHandler={() => triggerFileInput("frontFileInput")}
          buttonText={
            uploadLoading["identity_front_img"] ? "Uploading" : "Upload"
          }
          disabled={uploadLoading["identity_front_img"]}
        />
        {getValue(item, `identity_front_img`, "") && (
          <div className="d-flex gap-2 align-items-center my-3">
            <img
              src={getValue(item, `identity_front_img`, "")}
              height={50}
              alt="Front ID"
              className="object-fit-contain"
            />
            <div
              onClick={() =>
                removeImage(
                  index,
                  childIndex,
                  key_name,
                  name,
                  "identity_front_img",
                  setRequest
                )
              }
              className=""
            >
              <CloseMainSvgComponent size="20" />
            </div>
          </div>
        )}
      </div>
      <div>
        <p className="form-label">Upload Back ID</p>
        <input
          type="file"
          id="backFileInput"
          className="custom-upload-filename"
          accept=".jpg,.jpeg,.png"
          onChange={(e) =>
            handleUploadDocuments(
              index,
              childIndex,
              e,
              key_name,
              name,
              "identity_back_img",
              setRequest
            )
          }
          style={{ display: "none" }}
        />
        <ButtonComponent
          buttonType="smart"
          onClickHandler={() => triggerFileInput("backFileInput")}
          buttonText={
            uploadLoading["identity_back_img"] ? "Uploading" : "Upload"
          }
          disabled={uploadLoading["identity_back_img"]}
        />
        {getValue(item, `identity_back_img`, "") && (
          <div className="d-flex gap-2 align-items-center my-3">
            <img
              src={getValue(item, `identity_back_img`, "")}
              height={50}
              alt="Front ID"
              className="object-fit-contain"
            />
            <div
              onClick={() =>
                removeImage(
                  index,
                  childIndex,
                  key_name,
                  name,
                  "identity_back_img",
                  setRequest
                )
              }
              className=""
            >
              <CloseMainSvgComponent size="20" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BookingInfoForm;

import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { config } from "../../../../env";
import { convertCurrentDateToTime } from "@common/date-helpers";
import TickSvgComponent from "@assets/svg/tick";
import DoubleTickSvgComponent from "@assets/svg/double-tick";
import InfoSvgComponent from "@assets/svg/info";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import AccessTimeSvgComponent from "@assets/svg/accesstime";
import { Button, Modal } from "antd";
import {
  replaceDefaultPlaceholders,
  replacePlaceholders,
} from "@common/markdown/markdown-helper";

function DocumentFormatPreviews(props: any) {
  const { item } = props;

  const imageUrl = getValue(item, "upload_file.key", "")
    ? `https://${config.URL}/${getValue(item, "upload_file.key", "")}`
    : `https://${config.URL}/${getValue(item, "upload_file", "")}`;

  const images = [
    {
      original: imageUrl,
      thumbnail: imageUrl,
    },
  ];

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const getReadIcon = (item: object) => {
    if (!getValue(props, `receiver`, false)) {
      if (getValue(item, `is_read`, false) || getValue(item, `read`, false)) {
        return <DoubleTickSvgComponent color={"#34B7F1"} size={14} />;
      } else if (
        getValue(item, `is_delivered`, false) ||
        getValue(item, `delivered`, true) ||
        getValue(item, `received`, false) ||
        getValue(item, `is_received`, false)
      ) {
        return <DoubleTickSvgComponent color={"gray"} size={14} />;
      } else if (
        getValue(item, `is_sent`, false) ||
        getValue(item, `sent`, false)
      ) {
        return <TickSvgComponent color={"gray"} size={14} />;
      } else {
        return <AccessTimeSvgComponent size={14} />;
      }
      // else if (getValue(item, `is_received`, false)) {
      //   return <DoubleTickSvgComponent color={"green"} size={14} />;
      // }
    } else {
      return null;
    }
  };
  return (
    <>
      {(getValue(item, `type`, "") === "text" ||
        getValue(item, `type`, "") === "button") && (
        <div className="d-flex gap-2 justify-content-between">
          {/* <p className="mb-3">{getValue(item, `text_body`, "")}</p> */}

          <p
            dangerouslySetInnerHTML={{
              __html: replaceDefaultPlaceholders(
                getValue(item, `text_body`, "")
              ),
            }}
            className="text-black small_text__14 px-2 pb-2 message-line-height "
          ></p>
          <p className="send-time d-flex align-items-end mb-1">
            {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
            <span className="ms-2">{getReadIcon(item)}</span>
          </p>
        </div>
      )}

      {getValue(item, `type`, "") === "image" && (
        <>
          {/* <img
            src={
              getValue(item, `upload_file.key`, "")
                ? `https://${config.URL}/${getValue(
                    item,
                    `upload_file.key`,
                    ""
                  )}`
                : `https://${config.URL}/${getValue(item, `upload_file`, "")}`
            }
            alt="Selected"
            className="whatsapp_file"
          /> */}
          <div className="whatsapp_file">
            <ImageGallery
              items={images}
              showFullscreenButton={true}
              showThumbnails={false}
              showBullets={false}
              autoPlay={false}
              showPlayButton={false}
            />
          </div>
          <p className="small_text__14  text-start mt-2">
            {getValue(item, `media_caption`, "")}
          </p>
          <p className="send-time p-1 mt-1 d-flex justify-content-end">
            {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
            <span className="ms-2">{getReadIcon(item)}</span>
          </p>
        </>
      )}
      {getValue(item, `type`, "") === "video" && (
        <>
          <video controls className="whatsapp_file">
            <source
              src={
                getValue(item, `upload_file.key`, "")
                  ? `https://${config.URL}/${getValue(
                      item,
                      `upload_file.key`,
                      ""
                    )}`
                  : `https://${config.URL}/${getValue(item, `upload_file`, "")}`
              }
              type="video/mp4"
            />
          </video>
          <p className="small_text__14 mt-1 text-start">
            {getValue(item, `media_caption`, "video")}
          </p>
          <p className="send-time p-1 mt-1 d-flex justify-content-end">
            {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
            <span className="ms-2">{getReadIcon(item)}</span>
          </p>
        </>
      )}
      {getValue(item, `type`, "") === "audio" && (
        <div className="d-flex flex-column">
          <audio controls className="sender-message">
            <source
              src={
                getValue(item, `upload_file.key`, "")
                  ? `https://${config.URL}/${getValue(
                      item,
                      `upload_file.key`,
                      ""
                    )}`
                  : `https://${config.URL}/${getValue(item, `upload_file`, "")}`
              }
              type="audio/ogg"
            />
          </audio>
          {getValue(item, `media_caption`, "") && (
            <p className="small_text__14 mt-2 text-start">
              {getValue(item, `media_caption`, "")}
            </p>
          )}
          <p className="send-time p-1 mt-1 d-flex justify-content-end">
            {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
            <span className="ms-2">{getReadIcon(item)}</span>
          </p>
        </div>
      )}
      {getValue(item, `type`, "") === "document" && (
        <>
          <iframe
            src={`https://${config.URL}/${getValue(
              item,
              `upload_file.key`,
              ""
            )}`}
            title="PDF Viewer"
            width="100%"
            height="200px"
            style={{
              border: "none",
            }}
          />

          <p className="small_text__14 mt-2 text-start">
            {getValue(item, `media_caption`, "")}
          </p>
          <p className="send-time p-1 mt-1 d-flex justify-content-end">
            {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
            <span className="ms-2">{getReadIcon(item)}</span>
          </p>
        </>
      )}
      {getValue(item, `error_reason`, "") &&
        getValue(item, `status`, "") === "failed" && (
          <div className="d-flex justify-content-end">
            <button
              onClick={toggle}
              className="small_text__12 color_danger d-flex gap-1 align-items-center  mb-2 mt-1 me-2"
              style={{ marginRight: "10px", background: "none" }}
            >
              <InfoSvgComponent color="red" size="16" />
              Failed
            </button>
          </div>
        )}
      <Modal
        open={modal}
        onCancel={toggle}
        onOk={toggle}
        footer={[
          <Button key="cancel" onClick={toggle} className="cancel__button">
            Close
          </Button>,
        ]}
      >
        {/* <ModalHeader toggle={toggle}> */}
        <h5 className="header_text__18">Cannot send message</h5>
        {/* </ModalHeader>
        <ModalBody className="small_text__14 color_danger"> */}
        {getValue(item, `error_reason`, "")}
        {/* </ModalBody>
        <ModalFooter> */}
        {/* <button
          onClick={toggle}
          className="dashboard-button dashboard-button--scecondary px-4 mx-1  d-flex justify-content-center align-items-center"
        >
          Close
        </button>{" "} */}
        {/* <button
            onClick={toggle}
            className="mx-4 create-user-button create-user-button--department hover-text touchable-text-white px-4 py-2"
          >
            Choose Template
          </button> */}
        {/* </ModalFooter> */}
      </Modal>
    </>
  );
}

export default DocumentFormatPreviews;

// import { TabContent, TabPane, Nav, NavItem, NavLink } from "antd";
import classnames from "classnames";
import { useParams } from "react-router-dom";

export default function ManageFieldsDetails(props: any) {
  const params = useParams();

  const buttonArray = [
    {
      title: "Search",
      image: "search.svg",
    },
  ];

  const { activeTab, toggle } = props;
  return (
    <div className="d-flex">
      {/* <ManagefieldsLeft /> */}
      <div className={`${"manageFieldRight"} w-100`}>
        {/* <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                Status
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                Fields
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className={"module-tab-wrapper"}>
                <p className={"module-tab-wrapper__text"}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <ModulesEditInfo
                  request={props.request}
                  setRequest={props.setRequest}
                />
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className={"module-tab-wrapper"}>
                <p className={"module-tab-wrapper__text"}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <EditManageStages
                  request={props.stageRequest}
                  setRequest={props.setStageRequest}
                />
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className={"module-tab-wrapper"}>
                <p className={"module-tab-wrapper__text"}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
               
                <ModulesEditFields
                  usedFields={getValue(props, `selectedFields`, [])}
                  handleOnDragEnd={props.handleOnDragEnd}
                  unUsedFields={props.unUsedFields}
                />
              </div>
            </TabPane>
            
          </TabContent>
        </div> */}
      </div>
    </div>
  );
}

import DeleteSvgComponent from "@assets/svg/delete";
import InfoSvgComponent from "@assets/svg/info";
import InputRuleForm from "@components/InputRuleForm/form";
import {
  associateTaxCategory,
  associateTaxRate,
  removeAssociatedTaxCategory,
  removeAssociatedTaxRate,
} from "@services/properties/settings/tax.service";
import { getValue } from "@utils/lodash";
import { useState } from "react";
import { toast } from "sonner";

const AddTaxCategory = (props: any) => {
  const { request, setRequest, editId, selectedIds } = props;

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleAddCategory = async (id: any) => {
    setSubmitLoading(true);
    let resp = await associateTaxRate(editId, {
      ids: [id],
    });
    if (resp) {
      toast.success("Updated successfully");
      props.getData();
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
    }
  };

  const handleRemoveCategory = async (id: string) => {
    try {
      let resp = await removeAssociatedTaxRate(editId, { ids: [id] });
      if (resp) {
        toast.success("Removed successfully");
        props.getData();
        setSubmitLoading(false);
        // props.getData(false);
      }
    } catch (error) {}
  };
  return (
    <div className="subpipeline-wrapper px-5 py-4">
      <div className="d-flex flex-column gap-3 mb-4">
        <div>
          <InputRuleForm
            inputType="TEXT"
            name="tax_name"
            value={getValue(request, `tax_name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                tax_name: e.target.value,
              })
            }
            placeholder="Enter tax name"
            label="Tax name"
            required
          />
          <div className="mt-2 small_text__13 d-flex align-items-center gap-2 color_desc">
            <InfoSvgComponent size="16" />
            <p>
              Name of the group tax category. For e.g <b>Room Booking</b> or{" "}
              <b>Hotel Amenity</b>. This name will also appear when adding items
              in folios.
            </p>
          </div>
        </div>
        <div>
          <div className="position-relative">
            <InputRuleForm
              inputType="NUMBER"
              name="number"
              value={getValue(request, `tax_percentage`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  tax_percentage: e.target.value,
                })
              }
              placeholder="Enter tax percentage"
              required
              label="Tax percentage"
              marginZero
            />
            <span
              className="position-absolute"
              style={{ bottom: "12px", right: "16px" }}
            >
              %
            </span>
          </div>
          <p className="mt-2 small_text__13 d-flex align-items-center gap-2 color_desc">
            <InfoSvgComponent size="16" />
            Tax Percentage number for this category without % sign. For eg 18
          </p>
          {editId ? (
            <>
              <h6 className="header_text__16 mt-3">Tax Categories</h6>
              <div className="amenity_container mt-3">
                {getValue(props, `categoryList`, []).map((item: object) => {
                  return (
                    <div className="position-relative mb-3">
                      {getValue(props, `selectedIds`, []).includes(
                        getValue(item, `id`, "")
                      ) && (
                        <div
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "-7px",
                            background: "white",
                            borderRadius: "50%",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #efefef",
                          }}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            handleRemoveCategory(getValue(item, `id`, ""));
                          }}
                        >
                          <DeleteSvgComponent />
                        </div>
                      )}
                      <div
                        className="amenity_text "
                        style={{
                          border: getValue(props, `selectedIds`, []).includes(
                            getValue(item, `id`, "")
                          )
                            ? "1px solid #408dfb"
                            : "",
                        }}
                        onClick={() =>
                          handleAddCategory(getValue(item, `id`, ""))
                        }
                      >
                        <p className="">
                          {getValue(item, `category_name`, "")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="mt-2">
              <InputRuleForm
                inputType="REACT_SELECT"
                label={"Tax Categories"}
                placeholder="Enter Tax Categories"
                name={"tax_category_ids"}
                options={getValue(props, `categoryList`, [])}
                value={getValue(request, `tax_category_ids`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    tax_category_ids: e,
                  })
                }
                isMulti
                // required
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddTaxCategory;

import { config } from "../../../env";
import { Delete, get, patch, post } from "../../helpers/http-handler";

export const getBillingConfigSettings = (id: string) =>
  get(`${config.API_URL}/settings/billing-config/property/${id}`);

export const createBillingConfigSettings = (payload: object) =>
  post(`${config.API_URL}/settings/billing-config`, payload);

export const updateBillingConfigSettings = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/billing-config/${id}`, payload);

export const createPropertySettings = (payload: object) =>
  post(`${config.API_URL}/property-booking/price`, payload);

import CloseMainSvgComponent from "@assets/svg/close-main";
import ButtonComponent from "@components/Form/Button/Button";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { Button, Modal } from "antd";

const OTP_INPUTS_PER_GROUP = 3; // Number of OTP inputs per group
function AssignPopup(props: any) {
  const assignTypes = [
    { value: "team", label: "Team" },
    { value: "chat-bot", label: "Chat BOT" },
  ];

  const {
    setAssignRequest,
    assignRequest,
    chatbotList,
    submitLoading,
    handleAssignConversation,
  } = props;
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={props.handleSubmit}
      onCancel={() => {
        props.handleModal();
        setAssignRequest((preState: any) => ({
          ...preState,
          waAccountId: "",
          managedBy: "team",
          chatBotId: "",
        }));
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            props.handleModal();
            props.setAssignId("");
          }}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleAssignConversation}
          className="submit__button"
        >
          Assign
        </Button>,
      ]}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h6 className="header_text__16">Assign</h6>
          {/* <p className="small_text__12 mt-1"></p> */}
        </div>
        {/* <div
          onClick={() => {
            props.handleModal();
            props.setAssignId("");
          }}
        >
          <CloseMainSvgComponent size={24} />
        </div> */}
      </div>
      {/* <ModalBody> */}
      <div className=" p-3 px-3 py-3">
        <div className="w-100">
          <SearchToolTip
            label="label"
            selectKey={"value"}
            name="Select Assignee"
            data={assignTypes}
            value={getValue(assignRequest, `managedBy`, "")}
            onChange={(e: object) =>
              setAssignRequest({
                ...assignRequest,
                managedBy: getValue(e, `value`, ""),
              })
            }
          />
        </div>
        {getValue(assignRequest, `managedBy`, "") === "chat-bot" && (
          <div className="w-100">
            <SearchToolTip
              label="label"
              selectKey={"value"}
              name="Select Chatbot"
              data={chatbotList}
              value={getValue(assignRequest, `chatBotId`, "")}
              onChange={(e: object) =>
                setAssignRequest({
                  ...assignRequest,
                  chatBotId: getValue(e, `value`, ""),
                })
              }
            />
          </div>
        )}
      </div>
      {/* </ModalBody>
      <ModalFooter> */}
      {/* <div className="popup-submit-container">
          <ButtonComponent
            onClickHandler={() => {
              props.handleModal();
              setAssignRequest((preState: any) => ({
                ...preState,
                waAccountId: "",
                managedBy: "team",
                chatBotId: "",
              }));
            }}
            buttonType={"scecondary"}
            buttonText="Cancel"
          />
          <ButtonComponent
            buttonType={"primary"}
            buttonText={submitLoading ? "Please wait..." : "Save"}
            disabled={submitLoading}
            onClickHandler={handleAssignConversation}
          />
        </div> */}
      {/* </ModalFooter> */}
    </Modal>
  );
}

export default AssignPopup;

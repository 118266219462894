import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import { useState } from "react";
import { Button, Modal } from "antd";
// import "./edit-popup.scss";

function PropertyAmenitiesPopup(props: any) {
  const { request, setRequest, simpleValidator } = props;
  const [editOptions] = useState([
    { value: "owner_id", label: "Owner" },
    { value: "stage_id", label: "Status" },
  ]);
  return (
    <div>
      <Modal
        open={getValue(props, `isOpen`, "")}
        onCancel={() => {
          props.handleModal();
          props.setEditId("");
        }}
        onOk={() => props.handleSubmit()}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              props.handleModal();
              props.setEditId("");
            }}
            className="cancel__button"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => props.handleSubmit()}
            className="submit__button"
          >
            Submit
          </Button>,
        ]}
      >
        {/* <CommonPopupHeader
          title={"Property Amenity"}
          handleBackButton={props.handleModal}
          height={"60px"}
        /> */}
        <h6 className="header_text__16">Property Amenity</h6>
        <section className="edit-popup-wrapper">
          <div className="">
            <InputRuleForm
              inputType="TEXT"
              label="Label"
              placeholder={"Enter Label"}
              name="identifiers"
              value={getValue(props, `request`, "")}
              onChange={(e: any) => setRequest(e.target.value)}
              validator={simpleValidator}
              validLeft
            />
            {/* <div className="edit-footer-wrapper">
              <ButtonComponent
                onClickHandler={() => {
                  props.handleModal();
                  props.setEditId("");
                }}
                buttonType={"scecondary"}
                buttonText="Cancel"
              />
              <ButtonComponent
                buttonType={"primary"}
                buttonText={
                  props.isLoading
                    ? "Please wait..."
                    : props.editId
                    ? "Update"
                    : "Save"
                }
                disable={props.isLoading}
                onClickHandler={() => props.handleSubmit()}
              />
            </div> */}
          </div>
        </section>
      </Modal>
    </div>
  );
}

export default PropertyAmenitiesPopup;

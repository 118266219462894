import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import "./PendingTaskCommonTitle.scss";
import { useState } from "react";
import { Badge } from "antd";
import ChevronDown from "@assets/svg/chevron-down";
import ChevronRight from "@assets/svg/chevron-right";
import ChevronUp from "@assets/svg/chevron-up";

export default function PendingTaskCommonTitle(props: any) {
  const [isActive, setStateActive] = useState(false);
  return (
    <div
      className={`d-flex align-items-center justify-content-between cursor-pointer `}
      onClick={() => {
        props.handleToggle();
        setStateActive(!isActive);
      }}
    >
      <div
        className="d-flex align-items-center cursor-pointer "
        // onClick={() => {
        //   props.handleToggle();
        //   setStateActive(!isActive);
        // }}
      >
        <img
          src="/images/task/icons/header/drag-indicator.svg"
          className={`img-fluid cursor-pointer`}
        />
        <div className="mx-2">
          {!isActive ? <ChevronDown size="12px" /> : <ChevronUp size="12px" />}
        </div>
        <div className="d-flex align-items-center">
          {/* <img
            src="/images/icons/arrow-down.svg"
            className={`${
              isActive ? "active" : ""
            } cursor-pointer img-fluid ${"pending-wrapper__downarrow-icon"}
            }`}
            onClick={() => {
              props.handleToggle();
              setStateActive(!isActive);
            }}
          /> */}
          <h6 className={"pending-wrapper__main-section-title"}>
            {props.commonTitle}
            <Badge count={props.count} className="ms-1"></Badge>
            {/* <span className={"pending-wrapper__main-section-title-count"}>
              ({props.count})
            </span> */}
          </h6>
        </div>
      </div>

      {/* one more component */}
      {props.isLinkVisible && (
        <div
          onClick={() => {
            props.triggerAddPopupSlidingFromRight();
          }}
          className="d-flex align-items-center"
        >
          <div className={"pending-task-commontitle__link touchable-text"}>
            <img
              src="/images/icons/add-blue.svg"
              className={"pending-task-commontitle__link-add"}
            />
          </div>
        </div>
      )}
      {props.isTextVisible && (
        <p className={"pending-task-commontitle__text"}>Hide Blank</p>
      )}
    </div>
  );
}

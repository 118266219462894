import CompanyPipelineDetails from "@components/Pages/Pipeline/Detail/companies";
import Companies from "@pages/Private/CRM/Companies";

export const CrmCompaniesRoutes = [
  {
    path: "/:orgId/crm/companies",
    name: "crm_company",
    component: Companies,
  },
  {
    path: "/:orgId/crm/companies/detail/:id",
    name: "crm_company",
    component: CompanyPipelineDetails,
  },
];

import { convertCurrentDateWithTime } from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import BotNoDataPage from "@components/common/NoData/bot-nodata";
import { getValue } from "@utils/lodash";
import LogsPreviewModel from "./preview-model";
import { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Table, Tag } from "antd";

const BotLogTableData = (props: any) => {
  const { list, limit, page_no, totalCount, handleChangePagination } = props;

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const [botInfo, setBotInfo] = useState([]);

  const WhatsppColumns = [
    {
      title: "WHATSAPP NAME",
      dataIndex: "whatsapp_name",
      key: "whatsapp_name",
      render: (whatsapp_name: any, data: any) => {
        return formatString(whatsapp_name);
      },
    },
    {
      title: "WHATSAPP NUMBER",
      dataIndex: "whatsapp_number",
      key: "whatsapp_number",
      render: (whatsapp_number: any, data: any) => {
        return whatsapp_number;
      },
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: any) => formatString(name),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      render: (date: any) => convertCurrentDateWithTime(date),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any) => (
        <Tag
          color={
            status === "COMPLETED"
              ? "success"
              : status === "DROPPED"
              ? "error"
              : "orange"
          }
        >
          {formatString(status)}
        </Tag>
      ),
    },
    {
      title: "CREATED ON",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => convertCurrentDateWithTime(created_at),
    },
    {
      title: "MORE INFO",
      dataIndex: "additional_info",
      key: "additional_info",
      render: (additional_info: any) => (
        <div
          className="cursor-pointer d-flex justify-content-center"
          onClick={() => {
            handleModal();
            setBotInfo(additional_info);
          }}
        >
          {getValue(additional_info, `length`, 0) > 0 && (
            <EyeOutlined style={{ fontSize: "20px" }} />
          )}
        </div>
      ),
    },
  ];
  const WebColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: any) => formatString(name),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      render: (date: any) => convertCurrentDateWithTime(date),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any) => (
        <Tag
          color={
            status === "COMPLETED"
              ? "success"
              : status === "DROPPED"
              ? "error"
              : "orange"
          }
        >
          {formatString(status)}
        </Tag>
      ),
    },
    {
      title: "CREATED ON",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => convertCurrentDateWithTime(created_at),
    },
    {
      title: "MORE INFO",
      dataIndex: "additional_info",
      key: "additional_info",
      render: (additional_info: any) => (
        <div
          className="cursor-pointer d-flex justify-content-center"
          onClick={() => {
            handleModal();
            setBotInfo(additional_info);
          }}
        >
          {getValue(additional_info, `length`, 0) > 0 && (
            <EyeOutlined style={{ fontSize: "20px" }} />
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      {getValue(list, `length`, 0) > 0 ? (
        <Table
          size="middle"
          className="px-5 "
          bordered
          columns={
            getValue(props, `info.platform`, "") === "WEB"
              ? WebColumns
              : WhatsppColumns
          }
          dataSource={list}
          rowKey="id"
          pagination={{
            current: page_no,
            pageSize: limit,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            onChange: (page_no, pageSize) => {
              handleChangePagination(page_no, pageSize);
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{
            y: `calc(100vh - 100px)`,
            x: 1400,
          }}
        />
      ) : (
        <BotNoDataPage />
      )}

      <LogsPreviewModel
        isOpen={isOpen}
        handleModal={handleModal}
        botInfo={botInfo}
      />
    </div>
  );
};

export default BotLogTableData;

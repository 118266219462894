import {
  associateAmenityToProperty,
  removeAssociatedAmenityFromProperty,
} from "@services/properties/property.service";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

const EditAmenities = (props: any) => {
  const params = useParams();
  let list = getValue(
    props,
    `info.property_amenities`,
    []
  ).map((item: object) => getValue(item, `id`, ""));

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleAddAmenities = async (id: number) => {
    let resp = await associateAmenityToProperty(getValue(params, `id`, ""), {
      amenity_ids: [id],
    });
    if (resp) {
      toast.success("Updated successfully");
      props.getData();
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
    }
  };

  const handleRemoveAmenities = async (id: number) => {
    try {
      let resp = await removeAssociatedAmenityFromProperty(
        getValue(params, `id`, ""),
        { amenity_ids: [id] }
      );
      if (resp) {
        toast.success("Updated successfully");
        props.getData();
        setSubmitLoading(false);
        // props.getData(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <div className="subpipeline-wrapper px-5 py-4">
      {getValue(props, `amenityList`, []).map((item: object) => {
        return (
          <div className="d-flex align-items-center  justify-content-between border-bottom pb-3">
            <p>{getValue(item, `lable`, "")}</p>
            <label className={"switch"}>
              <input
                type="checkbox"
                checked={list.includes(getValue(item, `id`, "")) ? true : false}
                onChange={() =>
                  !list.includes(getValue(item, `id`, ""))
                    ? handleAddAmenities(getValue(item, `id`, ""))
                    : handleRemoveAmenities(getValue(item, `id`, ""))
                }
              />
              <span className={`${"slider"} ${`round`} d-flex`}></span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default EditAmenities;

import DeleteSvgComponent from "@assets/svg/delete";
import { handleNumberChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { removeAssociatedRatePlan } from "@services/properties/settings/rate-plan.service";
import { associateRatePlan } from "@services/properties/settings/rate-plan.service";
import { getValue } from "@utils/lodash";
import { useState } from "react";
import { toast } from "sonner";

const AddRatePlanPopup = (props: any) => {
  const { request, setRequest, editId } = props;

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleAdd = async (id: any) => {
    setSubmitLoading(true);
    let resp = await associateRatePlan(editId, {
      ids: [id],
    });
    if (resp) {
      toast.success("Updated successfully");
      props.getData();
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
    }
  };

  const handleRemove = async (id: string) => {
    try {
      let resp = await removeAssociatedRatePlan(editId, { ids: [id] });
      if (resp) {
        toast.success("Removed successfully");
        props.getData();
        setSubmitLoading(false);
        // props.getData(false);
      }
    } catch (error) {}
  };
  return (
    <div className="subpipeline-wrapper px-5 py-4 overflow-x-hidden">
      <div className="d-flex flex-column gap-3 mb-4">
        <div>
          <InputRuleForm
            inputType="TEXT"
            name="rate_name"
            value={getValue(request, `rate_name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                rate_name: e.target.value,
              })
            }
            placeholder="Enter Rate Name"
            label="Rate Name"
            // required
          />
        </div>
        <div>
          <InputRuleForm
            inputType="TEXT"
            name="plan_display_name"
            value={getValue(request, `plan_display_name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                plan_display_name: e.target.value,
              })
            }
            placeholder="Enter Plan Display Name"
            label="Plan Display Name"
            // required
          />
        </div>
        <div className="d-flex gap-3">
          <div className="w-30">
            <SearchToolTip
              inputType="TEXT"
              label="label"
              data={[
                { value: "plus", label: "+" },
                { value: "minus", label: "-" },
              ]}
              value={getValue(request, `discount_direction`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  discount_direction: getValue(e, `value`, ""),
                })
              }
              selectKey={"value"}
              placeholder="Choose Discount Direction"
              name="Discount Direction"
              // required
            />
          </div>

          <div className="w-30">
            <InputRuleForm
              inputType="TEXT"
              name="discount_value"
              value={getValue(request, `discount_value`, "")}
              onChange={(e: any) =>
                // setRequest({
                //   ...request,
                //   discount_value: handleNumberChange(e.target.value),
                // })
                handleNumberChange(e, request, setRequest)
              }
              placeholder="Enter Discount Value"
              label="Discount Value"
              // required
            />
          </div>
          <div className="w-30">
            <SearchToolTip
              inputType="TEXT"
              label="label"
              data={[
                { value: "percent", label: "%" },
                { value: "flat", label: "Flat" },
              ]}
              value={getValue(request, `mode`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  mode: getValue(e, `value`, ""),
                })
              }
              selectKey={"value"}
              placeholder="Choose Mode"
              name="Mode"
              // required
            />
          </div>
        </div>
        {!getValue(props, `editId`, "") ? (
          <div>
            <InputRuleForm
              inputType="REACT_SELECT"
              label={"Room Types"}
              placeholder="Select Room Types"
              name={"room_type_ids"}
              options={getValue(props, `rooms`, [])}
              value={getValue(request, `room_type_ids`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  room_type_ids: e,
                })
              }
              isMulti
              // required
            />
          </div>
        ) : (
          <>
            <h6 className="header_text__16 mt-2">Room Types</h6>
            <div className="amenity_container mt-2">
              {getValue(props, `rooms`, []).map((item: object) => {
                return (
                  <div className="position-relative mb-3">
                    {getValue(props, `selectedIds`, []).includes(
                      getValue(item, `id`, "")
                    ) && (
                      <div
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "-7px",
                          background: "white",
                          borderRadius: "50%",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #efefef",
                        }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleRemove(getValue(item, `id`, ""));
                        }}
                      >
                        <DeleteSvgComponent />
                      </div>
                    )}
                    <div
                      className="amenity_text"
                      style={{
                        border: getValue(props, `selectedIds`, []).includes(
                          getValue(item, `id`, "")
                        )
                          ? "1px solid #408dfb"
                          : "",
                      }}
                      onClick={() => handleAdd(getValue(item, `id`, ""))}
                    >
                      <p className="">{getValue(item, `name`, "")}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <div>
          <p className="form-label">Pay at Property :</p>
          <label className={"switch mt-2"}>
            <input
              type="checkbox"
              checked={getValue(request, `pay_at_property`, false)}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  pay_at_property: !getValue(request, `pay_at_property`, ""),
                })
              }
            />
            <span className={`${"slider"} ${`round`} d-flex`}></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default AddRatePlanPopup;

import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import BookingInformation from "../settings/components/booking-information";
import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import "./index.scss";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllReservation } from "@services/properties/reservation.service";
import ChevronCircleLeft from "@assets/svg/chevron-circle-left";
import ChevronCircleRight from "@assets/svg/chevron-circle-right";
import { Tooltip } from "react-tooltip";
import BedRoomSvgComponent from "@assets/svg/bedroom";
import { concatString } from "@common/text-helpers";
import { allParams } from "@common/url-params-helper";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import DateSvgComponent from "@assets/svg/custom/date";
import ReactDatePicker from "react-datepicker";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const ReservationCalender = () => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      let start = moment().startOf("week").toDate();
      let end = moment().endOf("week").endOf("day").toDate();
      setStartDate(start);
      setEndDate(end);
      setDateRange([start, end]);
      getAllRoomList("");
    }
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (getValue(UrlParams, `type`, "")) {
        setSelectedType(getValue(UrlParams, `type`, ""));
        let start = moment().startOf(getValue(UrlParams, `type`, "")).toDate();
        let end = moment()
          .endOf(getValue(UrlParams, `type`, ""))
          .endOf("day")
          .toDate();
        setStartDate(start);
        setEndDate(end);
        setDateRange([start, end]);
        getAllRoomList(start);
      } else {
        if (
          getValue(UrlParams, `startDate`, "") &&
          getValue(UrlParams, `endDate`, "") &&
          !getValue(UrlParams, `type`, "")
        ) {
          let start = moment(getValue(UrlParams, `startDate`, "")).toDate();
          let end = moment(getValue(UrlParams, `endDate`, ""))
            .endOf("day")
            .toDate();
          setStartDate(start);
          setEndDate(end);
          setDateRange([start, end]);
          getAllRoomList(start);
        } else {
          let start = moment().startOf("week").toDate();
          let end = moment().endOf("week").endOf("day").toDate();
          setStartDate(start);
          setEndDate(end);
          setDateRange([start, end]);
          getAllRoomList(start);
        }
        setSelectedType("");
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  /* -----------------------------  Rooms Section ---------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [reservationList, setReservationList] = useState([]);

  console.log("res room", rooms);

  const getAllRoomList = async (start: any) => {
    const startDate = moment().startOf("month").toDate();
    const daysInMonth = moment().daysInMonth();
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
        start_date: moment(start ? start : startDate).format("YYYY-MM-DD"),
        num_of_days: daysInMonth,
      };
      let queryRequest = QueryRequestHelper(payload);
      // let resp = await getAllRoomType(queryRequest);
      let resp = await getAllReservation(queryRequest);
      if (resp) {
        setIsLoading(false);
        setRooms(
          getValue(resp, `data`, []).map((item: object) => ({
            // ...item,
            title: `Room - ${getValue(item, `room_id`, "")}`,
            group: getValue(item, `room_type_id`, ""),
            id: getValue(item, `id`, ""),
            room_type_id: getValue(item, `room_type_id`, ""),
            room_id: getValue(item, `room_id`, ""),
            room_type: getValue(item, `room_type`, {}),
          }))
        );

        const consolidatedReservations = getValue(resp, `data`, []).flatMap(
          (item: any) =>
            item.reservation.map((reservation: any) => ({
              ...reservation,
              // title:getValue(reservation,`res_id`,''),
              title: getValue(reservation, `customer_name`, ""),
              start_time: new Date(getValue(reservation, `checkin`, "")),
              end_time: new Date(getValue(reservation, `checkout`, "")),
              group: getValue(item, `id`, ""),
              room_type_id: getValue(item, `room_type_id`, ""),
              room_id: getValue(item, `room_id`, ""),
            }))
        );

        setReservationList(consolidatedReservations);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [openBookingInfo, setOpenBookignInfo] = useState(false);
  const toggleBookingInfo = () => {
    setOpenBookignInfo(!openBookingInfo);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Calender section                             */
  /* -------------------------------------------------------------------------- */
  const [reservationInfo, setReservationInfo] = useState({});

  const [selected, setSelected] = useState([]);
  const handleItemClick = (itemId: any, e: any, time: any) => {
    setSelected([]);
  };

  const handleItemSelect = (itemId: any, e: any, time: any) => {
    let findObjInfo: any = reservationList.find(
      (item: object) => getValue(item, `id`, "") === itemId
    );
    setReservationInfo(findObjInfo);
    toggleBookingInfo();
  };

  const handleCanvasClick = (type: any, time: any) => {
    const formattedDate = moment.utc(new Date(time)).format("YYYY-MM-DD");
    let findObjInfo: any = rooms.find(
      (item: object) => getValue(item, `id`, "") === type
    );
    navigate(
      `/${getValue(params, `orgId`, "")}/property/details/${getValue(
        params,
        `id`,
        ""
      )}/create/walk-in/${getValue(findObjInfo, `room_type_id`, "")}/${getValue(
        findObjInfo,
        `id`,
        ""
      )}/${formattedDate}/${getValue(findObjInfo, `id`, "")}?${allParams()}`
    );
  };

  const [selectedType, setSelectedType] = useState("week");
  const handleChangeCalander = (type: any) => {
    setSelectedType(type);
    // setStartDate(moment().startOf(type).toDate());
    // setEndDate(moment().endOf(type).toDate());
    // getAllRoomList(moment().startOf(type).toDate());
    let payload = {
      ...UrlParams,
      type: type,
      startDate: moment(moment().startOf(type).toDate()).format("YYYY-MM-DD"),
      endDate: moment(moment().endOf(type).toDate()).format("YYYY-MM-DD"),
    };
    if (!type) {
      delete UrlParams.type;
    }
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeCalanderDirection = (type: any, direction: string) => {
    let start = moment(startDate).startOf(type);
    let end = moment(endDate).endOf(type);
    // Adjust for previous or next month based on the currently selected date
    if (direction === "previous") {
      start = start.subtract(1, "month");
      end = end.subtract(1, "month");
    } else if (direction === "next") {
      start = start.add(1, "month");
      end = end.add(1, "month");
    }
    delete UrlParams.type;
    let payload = {
      ...UrlParams,
      startDate: moment(moment(start).startOf(type).toDate()).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(moment(end).endOf(type).toDate()).format("YYYY-MM-DD"),
    };
    if (!type) {
      delete UrlParams.type;
    }
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const defaultTimeRange = endDate - startDate;
  const handleTimeChange = (
    visibleTimeStart: any,
    visibleTimeEnd: any,
    updateScrollCanvas: any
  ) => {
    updateScrollCanvas(moment(startDate).valueOf(), moment(endDate).valueOf());
  };

  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [start, end] = dateRange;

  // const handleDateRange = (date: any) => {
  //   setDateRange(date);

  //   let startDate = getValue(date, `[${0}]`, "");
  //   let endDate = getValue(date, `[${1}]`, "");

  //   if (startDate && endDate) {
  //     delete UrlParams.type;
  //     let payload = {
  //       ...UrlParams,
  //       startDate: moment(startDate).format("YYYY-MM-DD"),
  //       endDate: moment(endDate).format("YYYY-MM-DD"),
  //     };
  //     let queryRequest = QueryRequestHelper(payload);
  //     navigate(`${window.location.pathname}?${queryRequest}`);
  //   }
  // };

  const handleDateRange = (dates: any) => {
    if (!dates || dates.length !== 2) {
      return;
    }

    const [startDate, endDate] = dates;

    if (startDate && endDate) {
      delete UrlParams.type;

      // Convert dayjs to moment
      const payload = {
        ...UrlParams,
        startDate: moment(startDate.toDate()).format("YYYY-MM-DD"),
        endDate: moment(endDate.toDate()).format("YYYY-MM-DD"),
      };

      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };

  const customGroupRenderer = ({ group }: any) => {
    return (
      <div className="d-flex align-items-center">
        <BedRoomSvgComponent size={30} />
        <div className="ms-2">
          <p className="small_text__16">
            {concatString(getValue(group, `title`, ""), 18)}
          </p>
          <p className="small_text__12">
            {concatString(getValue(group, `room_type.name`, ""), 18)}
          </p>
        </div>
      </div>
    );
  };

  const handleCreateBooking = (value: string) => {
    let findObjInfo: any = getValue(reservationList, `[${0}]`, {});
    console.log("findObjInfo", findObjInfo);
    switch (value) {
      case "walkin":
        return navigate(
          `/${getValue(params, `orgId`, "")}/property/details/${getValue(
            params,
            `id`,
            ""
          )}/create/walk-in/${getValue(
            findObjInfo,
            `room_type_id`,
            ""
          )}/${getValue(findObjInfo, `id`, "")}/${moment().format(
            "YYYY-MM-DD"
          )}/${getValue(findObjInfo, `group`, "")}?${allParams()}`
        );

      case "group_booking":
        return navigate(
          `/${getValue(params, `orgId`, "")}/property/details/${getValue(
            params,
            `id`,
            ""
          )}/create/group-booking/${moment().format("YYYY-MM-DD")}`
        );

      default:
        return;
    }
  };
  const [activeTab, setActiveTab] = useState<any>("1");

  return (
    <PropertyDetailsLayout>
      <div className="manageFieldRight">
        <div className="other-content subheader_filter_section open px-4">
          <div className="filter-wrapper d-flex align-items-center justify-content-between my-1 mx-4">
            <div className="d-flex gap-3 align-items-center w-100 justify-content-between">
              <div className="w-25"></div>
            </div>
            <div>
              {rooms.length > 0 ? (
                <TextHeaderTooltip
                  title={`Create`}
                  placeholder="Create"
                  data={[
                    { label: "Walk-in", value: "walkin" },
                    { label: "Group Booking", value: "group_booking" },
                  ]}
                  onChange={(e: any) =>
                    handleCreateBooking(getValue(e, `value`, ""))
                  }
                  label="label"
                  selectKey={"id"}
                  width={"270px"}
                  showActiveInline
                  disabled
                />
              ) : (
                <Link
                  className="header_blue_text__16 create-walk-in me-4"
                  to={`/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/property/room-types/${getValue(params, "id", "")}`}
                >
                  Create
                  <Tooltip className="tooltip" anchorSelect={`.create-walk-in`}>
                    No rooms added, please add a room
                  </Tooltip>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="px-5 pt-2 pb-2 ">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <div
                onClick={() =>
                  handleChangeCalanderDirection("month", "previous")
                }
                className="calender-button-group-  cursor-pointer last-month"
              >
                <Tooltip className="tooltip" anchorSelect={`.last-month`}>
                  Previous month
                </Tooltip>
                <ChevronCircleLeft color="#7890a1" size={30} />
              </div>
              <div className="ms-2">
                <SearchToolTip placeholder="Filters" width="250px" />
              </div>
            </div>

            <div className="d-flex align-items-center gap-2">
              <div>
                <DateSvgComponent color={"#7890a1"} size={20} />
              </div>
              <RangePicker
                onChange={handleDateRange}
                format="D MMM YYYY" // Ant Design uses 'format' instead of 'dateFormat'
              />
            </div>

            <div className="d-flex gap-2 align-items-center">
              <div>
                <button
                  onClick={() => handleChangeCalander("day")}
                  className={`${
                    selectedType === "day" && "selected-calender-button"
                  } calender-button-group-left py-2 px-3`}
                >
                  Today
                </button>
                <button
                  onClick={() => handleChangeCalander("week")}
                  className={`${
                    selectedType === "week" && "selected-calender-button"
                  } calender-button-group-center py-2 px-3`}
                >
                  This Week
                </button>
                <button
                  onClick={() => handleChangeCalander("month")}
                  className={`${
                    selectedType === "month" && "selected-calender-button"
                  } calender-button-group-right py-2 px-3`}
                >
                  This Month
                </button>
              </div>

              <div className="d-flex gap-3">
                <div
                  onClick={() => handleChangeCalanderDirection("month", "next")}
                  className="calender-button-group- cursor-pointer next-month"
                >
                  <Tooltip className="tooltip" anchorSelect={`.next-month`}>
                    Next month
                  </Tooltip>
                  <ChevronCircleRight color="#7890a1" size={30} />
                </div>
              </div>
            </div>
          </div>
          {startDate && endDate && (
            <Timeline
              groups={rooms}
              groupRenderer={customGroupRenderer}
              items={reservationList}
              visibleTimeStart={startDate.getTime()}
              visibleTimeEnd={endDate.getTime()}
              minZoom={defaultTimeRange}
              maxZoom={defaultTimeRange}
              onTimeChange={handleTimeChange}
              lineHeight={60}
              sidebarWidth={180}
              selected={selected}
              onItemSelect={handleItemSelect}
              onItemClick={handleItemClick}
              onCanvasClick={handleCanvasClick}
              timeSteps={{
                second: 1,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1,
              }}
              stackItems
              itemHeightRatio={0.6}
              canMove={false}
              canResize={false}
              buffer={1} // prevent mouse scrolling
              itemTouchSendsClick={false}
            />
          )}
        </div>
      </div>
      {openBookingInfo && (
        <CommonRightPopup
          isPopupVisible={openBookingInfo}
          toggle={toggleBookingInfo}
          width="840px"
          title="Booking information"
          hideNew
          second_title="Save"
          handleSubmit={toggleBookingInfo}
          hideFooter={activeTab == 2 ? true : false}
        >
          <BookingInformation
            reservationInfo={reservationInfo}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </CommonRightPopup>
      )}
    </PropertyDetailsLayout>
  );
};

export default ReservationCalender;

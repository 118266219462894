import React from "react";
import "./button.scss";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
import ButtonOnHover from "../Tooltip/button-on-hover";
import { Button, Dropdown, MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";

function SubmitButtonContainer(props: any) {
  const items: MenuProps["items"] = [
    {
      label: "Submit and continue",
      key: "1",
    },
  ];

  return (
    <div
      className={`submit-button__footer d-flex align-items-center justify-content-between`}
    >
      <div className={"submit-button__container"}>
        <div></div>
        <div className="d-flex align-items-center gap-2">
          {/* <ButtonComponent
            buttonType={"scecondary"}
            buttonText={
              getValue(props, `first_title`, "")
                ? getValue(props, `first_title`, "")
                : "Cancel"
            }
            onClickHandler={() => props.handleBackButton()}
          /> */}
          <Button size="large" onClick={() => props.handleBackButton()}>
            {getValue(props, `first_title`, "")
              ? getValue(props, `first_title`, "")
              : "Cancel"}
          </Button>
          {getValue(props, `disableValidation`, false) ? (
            <ButtonOnHover
              header={
                // <ButtonComponent
                //   buttonType={props.submitLoading ? "scecondary" : "primary"}
                //   buttonText={
                //     getValue(props, `second_title`, "")
                //       ? getValue(props, `second_title`, "")
                //       : "Create"
                //   }
                //   onClickHandler={() => props.handleSubmit()}
                //   disabled={props.submitLoading || props.disabled}
                // />
                <Button
                  size="large"
                  onClick={() => props.handleSubmit()}
                  disabled={props.disabled}
                  loading={props.submitLoading}
                >
                  {getValue(props, `first_title`, "")
                    ? getValue(props, `first_title`, "")
                    : "Cancel"}
                </Button>
              }
              text={"Please add all required information"}
              width={"200px"}
              height={"50px"}
              hover={props.disabled}
            />
          ) : (
            <Button
              type="primary"
              size="large"
              onClick={() => props.handleSubmit()}
              loading={props.submitLoading}
              disabled={props.disabled}
            >
              {getValue(props, `second_title`, "")
                ? getValue(props, `second_title`, "")
                : "Create"}
            </Button>
            // <ButtonComponent
            //   buttonType={props.submitLoading ? "scecondary" : "primary"}
            //   buttonText={
            //     getValue(props, `second_title`, "")
            //       ? getValue(props, `second_title`, "")
            //       : "Create"
            //   }
            //   // buttonText={
            //   //   props.submitLoading
            //   //     ? "Please wait..."
            //   //     : getValue(props, `second_title`, "")
            //   //     ? getValue(props, `second_title`, "")
            //   //     : "Create"
            //   // }
            //   onClickHandler={() => props.handleSubmit()}
            //   disabled={props.submitLoading || props.disabled}
            // />
          )}

          {/* {!getValue(props, `hideNew`, false) && (
            <Dropdown.Button
              type="primary"
              size="large"
              menu={{ items }}
              onClick={() => props.handleSubmit()}
            >
              Submit
            </Dropdown.Button>
          )} */}
          {!getValue(props, `hideNew`, false) && (
            // <ButtonComponent
            //   buttonType={props.submitLoading ? "scecondary" : "primary"}
            //   buttonText={"Create & Save New"}
            //   onClickHandler={() => props.handleSubmit("new")}
            //   disabled={props.submitLoading || props.disabled}
            // />
            <Button
              type="primary"
              size="large"
              onClick={() => props.handleSubmit("new")}
              loading={props.submitLoading}
              disabled={props.disabled}
            >
              {"Create & Save New"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubmitButtonContainer;

import { getValue } from "@utils/lodash";
import { config } from "env";
import MarkdownPreview from "@uiw/react-markdown-preview";
import moment from "moment";
import OpenNewSvgComponent from "@assets/svg/open-new";
import CallSvgComponent from "@assets/svg/phone";
import UrlSvgComponent from "@assets/svg/custom/url";
import { replaceDefaultPlaceholders } from "@common/markdown/markdown-helper";

function WhatsappIconChatViewPage(props: any) {
  const { request } = props;
  return (
    <>
      <div className="">
        <div className="message-box px-2 pb-2">
          {getValue(request, `header_type`, "") === "TEXT" && (
            <p className="message-title small_text__16">
              {getValue(request, `header.text`, "")}
            </p>
          )}
          {getValue(request, `header_type`, "") === "IMAGE" &&
            (getValue(request, `header_file.key`, "") ||
              getValue(request, `header.link`, "")) && (
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={
                    getValue(request, `header_file.key`, "")
                      ? `https://${config.URL}/${getValue(
                          request,
                          `header_file.key`,
                          ""
                        )}`
                      : `https://${config.URL}/${getValue(
                          request,
                          `header.link`,
                          ""
                        )}`
                  }
                  alt="Selected"
                  className="main_chat_image"
                />
              </div>
            )}
          {getValue(request, `header_type`, "") === "VIDEO" &&
            (getValue(request, `header_file.key`, "") ||
              getValue(request, `header.link`, "")) && (
              <div className="d-flex justify-content-center align-items-center">
                <video controls style={{ height: "100px", width: "100%" }}>
                  <source
                    src={`https://${config.URL}/${
                      getValue(request, `header_file.key`, "")
                        ? getValue(request, `header_file.key`, "")
                        : getValue(request, `header.link`, "")
                    }`}
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          {getValue(request, `header_type`, "") === "DOCUMENT" &&
            (getValue(request, `header_file.key`, "") ||
              getValue(request, `header.link`, "")) && (
              <div className="d-flex justify-content-center align-items-center">
                {/* <img
                                  src={`https://${config.URL}/${getValue(
                                    request,
                                    `header_file.key`,
                                    ""
                                  )}`}
                                  alt="Selected"
                                  className="main_image"
                                /> */}
                <img
                  src="/images/icons/document-blue.svg"
                  className="dummy-image-icon"
                />
              </div>
            )}

          <div className="mt-2">
            <p
              className="text-black small_text__14 message-line-height"
              style={{
                textAlign: "left",
                fontFamily:
                  '"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "Kohinoor Devanagari", sans-serif',
              }}
              // contentEditable="true"
              dangerouslySetInnerHTML={{
                __html: replaceDefaultPlaceholders(
                  getValue(request, `body`, "")
                ),
              }}
            ></p>
          </div>
        </div>

        <div
          className={`d-flex justify-content-between align-items-center mt-1 mb-1 px-2 `}
        >
          <p className="small_text__12 ">{getValue(request, `footer`, "")}</p>
          <div>
            <span className="small_text__12 ">
              {moment().format("hh:mm a")}
            </span>
          </div>
        </div>
        {getValue(request, `buttons`, []).length > 0 && (
          <div className="grid-container mb-2" style={{ width: "100%" }}>
            {getValue(request, `buttons`, []).map(
              (item: object, index: number) => {
                return (
                  getValue(item, `text`, "") && (
                    <div
                      className="d-flex justify-content-center align-items-center template_button "
                      key={index}
                    >
                      {getValue(item, `action_type`, "") ===
                        "CALL_TO_ACTION" && (
                        <>
                          {getValue(item, `type`, "") === "call" ? (
                            <CallSvgComponent color="#408dfb" size={14} />
                          ) : (
                            <UrlSvgComponent color="#408dfb" size={14} />
                          )}
                        </>
                      )}
                      <p className="ms-2 ">{getValue(item, `text`, "")}</p>
                    </div>
                  )
                );
              }
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default WhatsappIconChatViewPage;

import ButtonComponent from "@components/Form/Button/Button";
import "./CompanyProfile.scss";
import { getValue } from "@utils/lodash";
import SettingsSvgComponents from "assets/svg/settings";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import { Button } from "antd";

export default function CompanyProfileSubHeader(props: any) {
  const { isLoading, handleSubmit, submitText } = props;
  return (
    <div className={"company-profile-subheader-wrapper"}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <SettingsSvgComponents color={"#202124"} size={28} />
          <h6
            className={`company-profile-subheader-wrapper__submenu-name mx-2`}
          >
            Settings
          </h6>
        </div>
        {getValue(props, `showSubmit`, false) && (
          <div className="d-flex align-items-center gap">
            <Button type="primary" onClick={handleSubmit} loading={isLoading}>
              {submitText ? submitText : "Save"}
            </Button>
          </div>
        )}

        {getValue(props, `addWorkflow`, false) && (
          <Button
            size="large"
            type="primary"
            onClick={() =>
              // navigate(
              //   `/${getValue(params, `orgId`, "")}/settings/workflows/create`
              // )
              props.toggle()
            }
          >
            Create Workflow
          </Button>
        )}
        {getValue(props, `addForm`, false) && (
          <Button size="large" type="primary" onClick={() => props.toggle()}>
            Create Form
          </Button>
        )}
         {getValue(props, `addRole`, false) && (
          <Button size="large" type="primary" onClick={() => props.toggle()}>
            Create Role
          </Button>
        )}
      </div>
    </div>
  );
}

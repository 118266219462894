import { replaceDefaultPlaceholders } from "@common/markdown/markdown-helper";
import { getValue } from "@utils/lodash";

const commonNodeRequestHelper = (item: object, index: number) => {
  return {
    id: getValue(item, `id`, ""),
    source: getValue(item, `source`, ""),
    flow_id: getValue(item, `flow_id`, ""),
    position:
      getValue(item, `position.x`, 0) || getValue(item, `position.y`, 0)
        ? getValue(item, `position`, {})
        : { x: index * 400, y: 50 },
    sourcePosition: "right",
    targetPosition: "left",
    type: "customNode",
  };
};

const commonNodeRequestDataHelper = (
  item: object,
  handleDeleteNode: any,
  edges: any,
  addNodeByID: any
) => {
  return {
    label: getValue(item, `name`, ""),
    description: replaceDefaultPlaceholders(
      getValue(item, `rendering_config.replies[${0}].text`, "")
    ),
    id: getValue(item, `id`, ""),
    onDelete: handleDeleteNode,
    component: getValue(item, `component`, ""),
    edges: edges,
    addNodeByID: addNodeByID,
  };
};

export const nodeRequestHelper = (
  list: any,
  handleDeleteNode: any,
  edges: any,
  addNodeByID: any
) => {
  return list.map((item: object, index: number) => {
    if (
      getValue(item, `component`, "") === "reply" ||
      getValue(item, `component`, "") === "is_end_chat" ||
      getValue(item, `component`, "") === "visitor_name" ||
      getValue(item, `component`, "") === "visitor_email" ||
      getValue(item, `component`, "") === "visitor_phone" ||
      getValue(item, `component`, "") === "company_name" ||
      getValue(item, `component`, "") === "visitor_datetime"
    ) {
      return {
        ...commonNodeRequestHelper(item, index),
        data: {
          ...commonNodeRequestDataHelper(
            item,
            handleDeleteNode,
            edges,
            addNodeByID
          ),
        },
      };
    } else if (getValue(item, `component`, "") === "suggestions") {
      return {
        ...commonNodeRequestHelper(item, index),
        is_end_chat:
          getValue(item, `component`, "") === "is_end_chat" ? true : false,
        data: {
          ...commonNodeRequestDataHelper(
            item,
            handleDeleteNode,
            edges,
            addNodeByID
          ),
          options:
            getValue(item, `rendering_config.suggestions.length`, []) > 0
              ? getValue(item, `rendering_config.suggestions`, []).map(
                  (item: object) => ({
                    ...item,
                    id: getValue(item, `next_action_id`, ""),
                  })
                )
              : [],
        },
      };
    } else if (getValue(item, `component`, "") === "singleselect") {
      return {
        ...commonNodeRequestHelper(item, index),
        is_end_chat:
          getValue(item, `component`, "") === "is_end_chat" ? true : false,
        data: {
          ...commonNodeRequestDataHelper(
            item,
            handleDeleteNode,
            edges,
            addNodeByID
          ),
          options:
            getValue(item, `rendering_config.input.options.length`, []) > 0
              ? getValue(item, `rendering_config.input.options`, []).map(
                  (item: object) => ({
                    ...item,
                    id: getValue(item, `next_action_id`, ""),
                  })
                )
              : [],
        },
      };
    } else if (getValue(item, `component`, "") === "multiselect") {
      return {
        ...commonNodeRequestHelper(item, index),
        is_end_chat:
          getValue(item, `component`, "") === "is_end_chat" ? true : false,
        data: {
          ...commonNodeRequestDataHelper(
            item,
            handleDeleteNode,
            edges,
            addNodeByID
          ),
          options:
            getValue(item, `rendering_config.input.options.length`, []) > 0
              ? getValue(item, `rendering_config.input.options`, []).map(
                  (item: object) => ({
                    ...item,
                    id: getValue(item, `next_action_id`, ""),
                  })
                )
              : [],
        },
      };
    } else if (getValue(item, `component`, "") === "links") {
      return {
        ...commonNodeRequestHelper(item, index),
        data: {
          ...commonNodeRequestDataHelper(
            item,
            handleDeleteNode,
            edges,
            addNodeByID
          ),
          links:
            getValue(item, `rendering_config.suggestions.length`, []) > 0
              ? getValue(item, `rendering_config.suggestions`, []).map(
                  (item: object) => ({
                    ...item,
                  })
                )
              : [],
        },
      };
    } else if (getValue(item, `component`, "") === "file_upload_component") {
      return {
        ...commonNodeRequestHelper(item, index),
        file_id: getValue(item,`file_id`,''),
        file_path: getValue(item, `file_path`, ""),
        file_name: getValue(item, `file_name`, ""),
        file_extension: getValue(item, `file_extension`, ""),
        data: {
          ...commonNodeRequestDataHelper(
            item,
            handleDeleteNode,
            edges,
            addNodeByID
          ),
        },
      };
    } else if (getValue(item, `component`, "") === "template") {
      return {
        ...commonNodeRequestHelper(item, index),
        template_id: getValue(item, `template_id`, ""),
        is_whatsapp_template: true,
        data: {
          ...commonNodeRequestDataHelper(
            item,
            handleDeleteNode,
            edges,
            addNodeByID
          ),
          options:
            getValue(item, `rendering_config.suggestions.length`, []) > 0
              ? getValue(item, `rendering_config.suggestions`, []).map(
                  (item: object) => ({
                    ...item,
                    id: getValue(item, `next_action_id`, ""),
                  })
                )
              : [],
        },
      };
    }
  });
};

export const findConnectedEdgeInfo = (data: any) => {
  let obj =
    getValue(data, `edges.length`, 0) > 0
      ? getValue(data, `edges`, []).find((item: object) =>
          getValue(item, `sourceHandle`, "")
            ? getValue(item, `sourceHandle`, "") === getValue(data, `id`, "")
            : getValue(item, `source`, "") === getValue(data, `id`, "")
        )
      : {};
  return getValue(obj, `sourceHandle`, "")
    ? getValue(obj, `sourceHandle`, "")
    : getValue(obj, `source`, "");
};

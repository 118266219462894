import useDraggableInPortal from "@common/useDraggableInPortal";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import {
  updateModuleColumns,
  updatePipelineColumns,
  updatePipelineInfo,
} from "@services/pipeline.service";
import { getValue } from "@utils/lodash";
import DragIndicatorSvgComponent from "assets/svg/drag-indicator";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "sonner";
import { useStateContext } from "@context/profileProvider";
import { Checkbox } from "antd";

function EditFieldsPopup(props: any) {
  const { id, isOpen, toggle, headers, setHeaders } = props;

  const { selectedModuleId } = useStateContext();

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const sourceArrayCopy: any = [...headers];
    // Moving from same area in top to botomm direction
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "source") {
        const [removed] = sourceArrayCopy.splice(source.index, 1);
        sourceArrayCopy.splice(destination.index, 0, removed);
        setHeaders(sourceArrayCopy);
      }
    }
  };
  const renderDraggable = useDraggableInPortal();
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    border: isDragging && "1px solid #dadce0",
    borderRadius: isDragging && "5px",
    padding: isDragging && "10px",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const handleChangeCheckBox = (name: string, index: number) => {
    setHeaders((options: any) => {
      return options.map((x: object, i: number) => {
        if (index !== i) return x;
        return {
          ...x,
          is_selected: !getValue(x, `is_selected`, ""),
        };
      });
    });
  };
  const [loading, setLoading] = useState(false);
  const handleUpdateFields = async () => {
    try {
      setLoading(true);
      let resp;
      if (
        getValue(props, `module`, "") === "tasks" ||
        getValue(props, `module`, "") === "calls" ||
        getValue(props, `module`, "") === "meetings"
      ) {
        resp = await updateModuleColumns(getValue(props, `moduleId`, ""), {
          columns: props.headers,
        });
      } else {
        resp = await updatePipelineColumns(await selectedModuleId(), id, {
          columns: props.headers,
        });
      }
      if (resp) {
        toast.success("Updated Successfully");
        toggle();
        props.getData(id);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div>
      <SlideFromRightPopup
        isPopupVisible={isOpen}
        addCustomClass={"rolls-popup"}
        title={"Edit Table Columns"}
        toggle={toggle}
        width={"520px"}
      >
        <div className="w-100 ">
          <div className="dnd_not_selected_container__main px-3 pt-3">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <div>
                <Droppable droppableId="source">
                  {(provided) => (
                    <div
                      className={"draganddrop_list"}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {getValue(props, `headers`, []).map(
                        (item: any, index: number) => (
                          <Draggable
                            key={getValue(item, `api_name`, "")}
                            draggableId={getValue(item, `api_name`, "")}
                            index={index}
                            isDragDisabled={getValue(item, `api_name`, "")}
                          >
                            {renderDraggable((provided: any, snapshot: any) => (
                              <div
                                className={`source-item d-flex justify-content-between mb-2`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div className="d-flex align-items-center justify-content-between w-100">
                                  <div className="d-flex align-items-center">
                                    {getValue(item, `is_selected`, false) ? (
                                      <DragIndicatorSvgComponent
                                        size={22}
                                        color={"lightgray"}
                                      />
                                    ) : (
                                      <DragIndicatorSvgComponent
                                        size={22}
                                        color={"transparent"}
                                      />
                                    )}
                                    <div
                                      className={index !== 0 ? "ms-1" : "ms-1"}
                                    >
                                      <Checkbox
                                        disabled={index === 0 ? true : false}
                                        checked={getValue(
                                          item,
                                          `is_selected`,
                                          false
                                        )}
                                        onChange={() =>
                                          handleChangeCheckBox(
                                            getValue(item, `api_name`, ""),
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                    <p
                                      className={
                                        index !== 0
                                          ? "desc_text__14 ms-3"
                                          : "ms-3"
                                      }
                                    >
                                      {getValue(item, `label`, "") === "Stage"
                                        ? "Status"
                                        : getValue(item, `label`, "")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </div>
        </div>
        <SubmitContainer
          first_title={"Cancel"}
          second_title={"Update"}
          submitLoading={loading}
          handleSubmit={handleUpdateFields}
          handleBackButton={toggle}
        />
      </SlideFromRightPopup>
    </div>
  );
}

export default EditFieldsPopup;

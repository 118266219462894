import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import ButtonComponent from "@components/Form/Button/Button";
import PropertySettingsLayout from "@layouts/PropertyLayout/property-settings-layout";
import { useEffect, useState } from "react";
import EditSvgComponent from "@assets/svg/edit";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { toast } from "sonner";
import Loader from "@components/common/Loader/loading";
import {
  createRatePlan,
  getAllRatePlan,
  updateRatePlan,
} from "@services/properties/settings/rate-plan.service";
import AddRatePlanPopup from "./components/rate-plan-popup";
import { getAllRoomType } from "@services/properties/room-type.service";

const PropertyRatePlanSettings = () => {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
    getAllRoomList();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRatePlan(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.ratePlans`, []));
        setIsLoading(false);
        if (editId) {
          let filtered = getValue(resp, `data.ratePlans`, []).find(
            (item: object) => getValue(item, `id`, "") === editId
          );
          setSelectedIds(
            getValue(filtered, `room_type`, []).map((item: object) =>
              getValue(item, `id`, "")
            )
          );
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [rooms, setRooms] = useState([]);
  const getAllRoomList = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomType(queryRequest);
      if (resp) {
        setIsLoading(false);
        setRooms(
          getValue(resp, `data.properties`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Edit Section                                 */
  /* -------------------------------------------------------------------------- */

  const [editId, setEditId] = useState("");
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const handleEdit = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    setRequest({
      ...request,
      rate_name: getValue(obj, `rate_name`, ""),
      plan_display_name: getValue(obj, `plan_display_name`, ""),
      discount_direction: getValue(obj, `discount_direction`, ""), //plus,minus
      mode: getValue(obj, `mode`, ""), //percent,flat
      discount_value: getValue(obj, `discount_value`, ""),
      property_id: getValue(obj, `property_id`, ""),
      parent_rate_id: getValue(obj, `parent_rate_id`, ""),
      pay_at_property: getValue(obj, `pay_at_property`, ""),
      room_type_ids: getValue(obj, `room_type`, []),
    });
    setSelectedIds(
      getValue(obj, `room_type`, []).map((item: object) =>
        getValue(item, `id`, "")
      )
    );
    toggleRateplan();
  };

  /* -------------------------------------------------------------------------- */
  /*                          Create/Edit Section                               */
  /* -------------------------------------------------------------------------- */
  const [openRatePlan, setopenRatePlan] = useState(false);
  const toggleRateplan = () => {
    setopenRatePlan(!openRatePlan);
  };
  const intialState = {
    rate_name: "",
    plan_display_name: "",
    discount_direction: "plus", //plus,minus
    mode: "percent", //percent,flat
    discount_value: 0,
    property_id: getValue(params, `id`, ""),
    parent_rate_id: "",
    pay_at_property: true,
    room_type_ids: [],
  };
  const [request, setRequest] = useState(intialState);

  const reset = () => {
    setRequest(intialState);
    setEditId("");
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let requestPayload = JSON.parse(JSON.stringify(request));

      requestPayload["room_type_ids"] = getValue(
        request,
        `room_type_ids`,
        []
      ).map((item: object) => getValue(item, `id`, ""));
      requestPayload["discount_value"] = getValue(request, `discount_value`, "")
        ? parseFloat(getValue(request, `discount_value`, ""))
        : 0;
      if (editId) {
        delete requestPayload.room_type_ids;
      }
      let resp;
      if (!editId) {
        resp = await createRatePlan(
          removeNullOrUndefinedProperties(requestPayload)
        );
      } else {
        resp = await updateRatePlan(
          editId,
          removeNullOrUndefinedProperties(requestPayload)
        );
      }
      if (resp) {
        toast.success(`${editId ? "Updated" : "Created"} successfully`);
        reset();
        setSubmitLoading(false);
        getData();
        toggleRateplan();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <PropertyDetailsLayout>
      <PropertySettingsLayout>
        <div className=" w-100 overflow-y-auto">
          <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
            <h3 className="header_text__20">Add Rate Plans</h3>
            <ButtonComponent
              buttonType="primary"
              buttonText="Add New Rate Plan"
              onClickHandler={toggleRateplan}
            />
          </div>
          <p className="my-3">
            Create, edit or delete any rate plans on this page. Start by
            creating a plan, based on the Standard Plan. The rate difference
            indicates how much higher(or lower) that rate will be than the
            actual tariff of the roomtype.
          </p>

          {isLoading ? (
            <Loader />
          ) : getValue(list, `length`, 0) > 0 ? (
            list.map((item: object) => {
              return (
                <div className="my-4  p-3 rounded-3 cursor-pointer room-box-shadow mx-3">
                  <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
                    <h3 className="header_text__16">
                      {getValue(item, `plan_display_name`, "")}
                    </h3>
                    <div onClick={() => handleEdit(item)}>
                      <EditSvgComponent />
                    </div>
                  </div>
                  <div className="">
                    <div className="row mt-3">
                      <p className="col-4 small_text__14">Discount Value:</p>
                      <p className=" col-6 small_text__16">
                        {getValue(item, `discount_value`, "")}
                        {getValue(item, `mode`, "") === "percent" ? "%" : ""}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : null}
        </div>
      </PropertySettingsLayout>
      <CommonRightPopup
        isPopupVisible={openRatePlan}
        toggle={() => {
          toggleRateplan();
          setEditId("");
          editId && reset();
          editId && setSelectedIds([]);
        }}
        width="500px"
        title="Add Rate Plan"
        hideNew
        second_title={
          submitLoading ? "Please wait..." : editId ? "Update" : "Save"
        }
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
      >
        <AddRatePlanPopup
          request={request}
          setRequest={setRequest}
          selectedIds={selectedIds}
          editId={editId}
          getData={getData}
          rooms={rooms}
        />
      </CommonRightPopup>
    </PropertyDetailsLayout>
  );
};

export default PropertyRatePlanSettings;

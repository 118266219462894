import AddSvgComponent from "@assets/svg/add";
import ResendSvgComponent from "@assets/svg/resend";
import { emojisList } from "@common/emojis";
import WhatsappAddHover from "@components/common/Tooltip/whats-app-add";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import EmojiPicker from "emoji-picker-react";
import { useEffect, useRef, useState } from "react";

function ChatFooter(props) {
  const {
    showEmojiPicker,
    handleEmojiClick,
    message,
    handleSendMessages,
    handleUploadDocument,
    setMessage,
  } = props;

  const handleChangeEditor = (name, value, editorState) => {
    setMessage(value);
  };

  const editorRef = useRef(null);
  const handleSubmit = () => {
    setMessage(`<p></p>`);
    handleSendMessages();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div>
      <div className="message-bottom-section p-2">
        <div
          style={{
            position: "absolute",
            bottom: "60px",
            left: "60px",
          }}
        >
          {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
        </div>
        <div className="d-flex align-items-center">
          <div className="cursor-pointer mx-2">
            <WhatsappAddHover
              header={<AddSvgComponent color={"#54656f"} size={32} />}
              text={"Please add all required information"}
              width={"200px"}
              height={"200px"}
              hover
              handleUploadDocument={handleUploadDocument}
            />
          </div>
          <div className="w-100">
            <div
              onKeyDown={handleKeyDown}
              style={{
                outline: "none",
              }}
              ref={editorRef}
            >
              <DraftJSRefEditor
                editorState={message}
                handleChangeEditor={handleChangeEditor}
                placeholder="Enter here"
                editorStyle={{
                  background: "white",
                  paddingLeft: "20px",
                  paddingTop: "20px",
                  // height: "10vh",
                }}
                // ref={props.reference}
                focusColor={"#1a73e8"}
                toolbar={{
                  options: ["inline", "emoji"],
                  inline: {
                    options: ["bold", "italic"],
                  },
                  emoji: {
                    emojis: emojisList,
                  },
                }}
                // hideFocus
                autoFocus
                focus
              />
            </div>
          </div>
          <div
            onClick={!message || message === "<p></p>\n" ? null : handleSubmit}
            className="cursor-pointer mx-2 ms-3"
          >
            <ResendSvgComponent
              color={
                !message || message === "<p></p>\n" ? "#8696a0" : "#408dfb"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatFooter;

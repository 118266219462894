import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import React from "react";
import { Button, Modal } from "antd";

function SettingsEditInfoPopup(props: any) {
  const { request, setRequest, simpleValidator } = props;
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={props.handleSubmit}
      onCancel={props.toggle}
      footer={[
        <Button key="cancel" onClick={props.toggle} className="cancel__button">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      {/* <CommonPopupHeader
        height={"70px"}
        title={"Update Pipeline Info"}
        handleBackButton={props.toggle}
      /> */}
      <h6 className="header_text__16">Update Pipeline Info</h6>

      <div className={"m-4 edit-info-popup-container"}>
        <div className={`form_wrapper-pipeline`}>
          <InputRuleForm
            inputType="TEXT"
            label={"Pipeline Name"}
            placeholder="Enter Pipeline Name"
            name={"label"}
            value={getValue(request, `label`, "")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            validator={simpleValidator}
            validLeft
            mandatory
          />
          {/* <div className="mt-2"></div>
          <InputRuleForm
            inputType="TEXT"
            label={"Prefix Singular"}
            placeholder="Deal, Ticket, Request etc"
            name={"singular_label"}
            value={getValue(request, `singular_label`, "")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            validator={simpleValidator}
            validLeft
            mandatory
          />
          <div className="mt-2"></div>
          <InputRuleForm
            inputType="TEXT"
            label={"Prefix Plural"}
            placeholder="Deals, Tickets, Requests etc"
            name={"plural_label"}
            value={getValue(request, `plural_label`, "")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            validator={simpleValidator}
            validLeft
            mandatory
          /> */}
        </div>
        {/* <div className={"popup-submit-container"}>
          <ButtonComponent
            buttonType={"scecondary"}
            buttonText="Cancel"
            onClickHandler={props.toggle}
          />
          <ButtonComponent
            buttonType={"primary"}
            buttonText={props.isLoading ? "Please wait..." : "Save"}
            onClickHandler={() => props.handleSubmit()}
            disabled={props.isLoading}
          />
        </div> */}
      </div>
    </Modal>
  );
}

export default SettingsEditInfoPopup;

import DeleteSvgComponent from "@assets/svg/delete";
import {
  assignAmenitiesToRoomType,
  deleteAmenitiesFromRoomType,
} from "@services/properties/room-type.service";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllRoomAmenities } from "@services/properties/master/room-amenites.service";

function PropertyRoomTypeAmenityPopup(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const id = getValue(UrlParams, "room_id", "");

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (id) {
      getData();
      // getSpecificData();
    }
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [isLoading1, setIsLoading1] = useState(true);
  const [list1, setList1] = useState([]);
  const getData = async () => {
    try {
      setIsLoading1(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomAmenities(queryRequest);
      if (resp) {
        setList1(getValue(resp, `data.amenities`, []));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  // const getSpecificData = async () => {
  //   try {
  //     setIsLoading1(true);
  //     let payload = {};
  //     let queryRequest = QueryRequestHelper(payload);
  //     let resp = await getSpecificRoomType(id);
  //     if (resp) {
  //       setList1(getValue(resp, `data.amenities`, []));
  //       setIsLoading1(false);
  //     } else {
  //       setIsLoading1(false);
  //     }
  //   } catch (error) {
  //     setIsLoading1(false);
  //   }
  // };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal1();
  };
  const handleDeleteAmenity = async (deleteId: string) => {
    try {
      let resp = await deleteAmenitiesFromRoomType(id, {
        amenity_ids: [deleteId],
      });
      if (resp) {
        toast.success("Deleted Successfully");
        // handleModal1();
        props.getSpecificAmenityData(id);
        setDeleteId("");
        // props.getData(false);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                              Submit Section                                */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleAddRemoveAmenities = async (ids: any) => {
    let resp = await assignAmenitiesToRoomType(id, {
      amenity_ids: [ids],
    });
    if (resp) {
      props.getSpecificAmenityData(id);
      toast.success("Updated successfully");
      getData();
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
    }
  };

  return (
    <div>
      <div className="">
        <div className="test-bot-container px-4 py-3">
          <div className={`w-100`}>
            <div className="amenity_container">
              {list1.map((item: object) => {
                return (
                  <div className="position-relative mb-3">
                    {getValue(props, `selectedAmenities`, []).includes(
                      getValue(item, `id`, "")
                    ) && (
                      <div
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "-7px",
                          background: "white",
                          borderRadius: "50%",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #efefef",
                        }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleDeleteAmenity(getValue(item, `id`, ""));
                        }}
                      >
                        <DeleteSvgComponent />
                      </div>
                    )}
                    <div
                      className="amenity_text "
                      style={{
                        border: getValue(
                          props,
                          `selectedAmenities`,
                          []
                        ).includes(getValue(item, `id`, ""))
                          ? "1px solid #408dfb"
                          : "",
                      }}
                      onClick={() =>
                        handleAddRemoveAmenities(getValue(item, `id`, ""))
                      }
                    >
                      <p className="">{getValue(item, `lable`, "")}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyRoomTypeAmenityPopup;

import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../createbot.scss";
import { getValue } from "@utils/lodash";
import ChatbotNavviews from "./chatbot-navviews";
import GlobeSvgComponent from "@assets/svg/globe";
import WordpressSvgComponent from "@assets/svg/wordpress";
import { CopyToClipboard } from "@common/text-helpers";
import CopySvgComponents from "@assets/svg/copy";
import ChatbotSubHeader from "./subheader";
import { getSpecificChatbot } from "@services/chatbot.service";

const BotsSharePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getChatbot();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      getChatbot();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleNavigateBack = () => {
    navigate(`/${getValue(params, `orgId`, "")}/chatbot`);
  };

  const clipBoardData = `<script
      type="text/javascript"
      src="https://st-beta-storage.blr1.cdn.digitaloceanspaces.com/sm-tools/supercrm-bot-plugin.js"
      id="supercrm-wa-widget"
      widget-id="${getValue(params, "id", "")}"
      defer
    ></script>`;

  const [info, setInfo] = useState({});
  const getChatbot = async () => {
    try {
      let resp = await getSpecificChatbot(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  };

  return (
    <div>
      <HomeHeader />
      <ChatbotSubHeader
        subHeaderListTitle={getValue(info, `name`, "")}
        platform={getValue(info, `platform`, "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
        showBack={true}
        handleNavigateBack={handleNavigateBack}
        params={params}
      />
      <div className="d-flex chatbot_share_container">
        <div className="w-100">
          <ChatbotNavviews />
          <div className="w-100 p-5">
            <div className="row">
              <div className="col-2 d-flex flex-column gap-4">
                <h6
                  className={`tab-item ${
                    activeTab === "tab1"
                      ? "active header_blue_text__14"
                      : "header_text__14"
                  } d-flex align-items-center gap-2 cursor-pointer `}
                  onClick={() => handleTabClick("tab1")}
                >
                  <GlobeSvgComponent
                    size="20"
                    color={activeTab === "tab1" && "#1a73e8"}
                  />{" "}
                  Website
                </h6>
                <h6
                  className={`tab-item ${
                    activeTab === "tab2"
                      ? "active header_blue_text__14"
                      : "header_text__14"
                  } d-flex align-items-center gap-2 cursor-pointer `}
                  onClick={() => handleTabClick("tab2")}
                >
                  <WordpressSvgComponent
                    size="20"
                    color={activeTab === "tab2" && "#1a73e8"}
                  />{" "}
                  Wordpress website
                </h6>
              </div>

              <div className="col-9 chatbot-sharelink ps-4">
                {activeTab === "tab1" && (
                  <div>
                    <h6 className="header_text__20 mb-4">Add to Website</h6>
                    <p className="small_text__14 mt-2 mb-3">
                      Copy the below code snippet and paste it into your HTML
                      code, preferably before the closing head tag ({`</head>`}
                      ).
                    </p>
                    <div className="d-flex align-items-center gap-3">
                      <pre className="shareable-code m-0">
                        <code className="small_text__14">{clipBoardData}</code>
                      </pre>
                      <span
                        className="cursor-pointer"
                        onClick={() => CopyToClipboard(clipBoardData)}
                      >
                        <CopySvgComponents size={18} color={"gray"} />
                      </span>
                    </div>
                    <p className="small_text__14 mt-3 mb-2">
                      <b>Bot ID:</b> {getValue(params, `id`, "")}
                    </p>
                    <p className="small_text__14 mt-2 mb-2">
                      <b>Get help</b> with step by step instructions
                    </p>
                    <p className="small_text__14 mt-2 mb-2">
                      <b>Perfect for lead</b> collection, customer education,
                      customer feedback.
                    </p>
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div className="">
                    <div className="">
                      <h6 className="header_text__20 mb-4">
                        Add to WordPress Website
                      </h6>
                      <p className="small_text__14 mt-2 mb-3">
                        <b>For Embed-</b> Go to your post or page editor. Copy
                        the following URL and paste where required.
                      </p>
                      <div className="d-flex align-items-center gap-3">
                        <pre className="shareable-code m-0">
                          <code className="small_text__14">
                            {clipBoardData}
                          </code>
                        </pre>
                        <span
                          className="cursor-pointer"
                          onClick={() => CopyToClipboard(clipBoardData)}
                        >
                          <CopySvgComponents size={18} color={"gray"} />
                        </span>
                      </div>

                      <p className="small_text__14 mt-3 mb-2">
                        <b>Bot ID:</b> {getValue(params, `id`, "")}
                      </p>
                      <p className="small_text__14 mt-2 mb-2">
                        <b>Get help</b> with step by step instructions
                      </p>
                      <p className="small_text__14 mt-2 mb-2">
                        <b>Perfect for lead</b> collection, customer education,
                        customer feedback.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotsSharePage;

import { DeleteOutlined } from "@ant-design/icons";
import DeleteSvgComponent from "@assets/svg/delete";
import PenSvgComponent from "@assets/svg/pen";
import { formatDateToHoursAgo } from "@common/date-helpers";
import { HTMLconcatString, concatString } from "@common/text-helpers";
import NotFoundDetail from "@components/common/NoData/Not-Found-Detail";
import ProfileCircle from "@components/common/ProfileCircle";
import { getValue } from "@utils/lodash";
import { Card } from "antd";
import React, { useState } from "react";

function NotesListPage(props: any) {
  const {} = props;
  const [expandMore, setExpandMore] = useState("");
  return (
    <div className={"pending-wrapper__main-body-upcoming"}>
      {props.loading ? (
        <p className="text-center mt-2">Please wait...</p>
      ) : getValue(props, `notes.length`, 0) > 0 ? (
        getValue(props, `notes`, []).map((taskObj: any, index: number) => {
          return (
            <Card className=" mb-2">
              <div className="w-100">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <ProfileCircle
                      value={getValue(taskObj, `created_by.first_name`, "")}
                      value2={getValue(taskObj, `created_by.last_name`, "")}
                    />
                    <div className="ms-2">
                      <p className="header_text__14">
                        {getValue(taskObj, `created_by.first_name`, "")}{" "}
                        {getValue(taskObj, `created_by.last_name`, "")}
                      </p>
                    </div>
                    <div className="dot mx-2"></div>
                    <p className="sub_text__14 ">
                      {formatDateToHoursAgo(
                        getValue(taskObj, `updated_at`, "")
                      )}
                    </p>
                  </div>
                  <div className="d-flex notes_action_container">
                    <div
                      className="image_hover_background"
                      onClick={() => {
                        // props.handleEditNotes(taskObj);
                        props.handleInputClick();
                        props.showEditNote(taskObj);
                      }}
                    >
                      <PenSvgComponent />
                    </div>
                    <div
                      className=" image_hover_background cursor-pointer"
                      onClick={() =>
                        props.handleDeleteView(getValue(taskObj, `id`, ""))
                      }
                    >
                      <DeleteOutlined style={{ fontSize: "16px" }} />
                    </div>
                  </div>
                </div>
              </div>
              <p
                className="ms-5 small_text__15 cursor-pointer"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setExpandMore("");
                }}
              >
                {/* {expandMore === getValue(taskObj, `id`, "")
                    ? getValue(taskObj, `content`, "")
                    : concatString(getValue(taskObj, `content`, ""), 130)} */}
                {/* <div
                    dangerouslySetInnerHTML={{
                      __html: getValue(taskObj, `content`, ""),
                    }}
                  /> */}
                <ul className="custom-list">
                  <li
                    dangerouslySetInnerHTML={{
                      __html:
                        expandMore === getValue(taskObj, `id`, "")
                          ? getValue(taskObj, `content`, "")
                          : HTMLconcatString(
                              getValue(taskObj, `content`, ""),
                              340
                            ).__html,
                    }}
                  ></li>
                </ul>

                {!expandMore && getValue(taskObj, `content.length`, 0) > 340 ? (
                  <span
                    className="color_primary cursor-pointer text-decoration-underline"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setExpandMore(getValue(taskObj, `id`, ""));
                    }}
                  >
                    more
                  </span>
                ) : getValue(taskObj, `content.length`, 0) > 340 ? (
                  <span
                    className="color_primary cursor-pointer text-decoration-underline ms-2"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setExpandMore("");
                    }}
                  >
                    show less
                  </span>
                ) : null}
              </p>
            </Card>
          );
        })
      ) : (
        <NotFoundDetail title={"No additional notes have been included."} />
      )}
    </div>
  );
}

export default NotesListPage;

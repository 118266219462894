import * as React from "react";
import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";
import bin from "./delete.json";
import "./addpopup.scss";
import ButtonComponent from "@components/Form/Button/Button";
import CloseMainSvgComponent from "@assets/svg/close-main";
import { useNavigate, useParams } from "react-router-dom";

const AddRoomConfirmation = (props: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bin,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const navigate = useNavigate();
  const params = useParams();
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      // toggle={props.handleModal}
      // size="lg"
      onCancel={() => props.handleModal()}
      onOk={() => props.handleSubmit()}
      footer={[
        <Button
          key="cancel"
          onClick={() =>
            navigate(
              `/${getValue(params, `orgId`, "")}/property/details/${getValue(
                params,
                `id`,
                ""
              )}/settings`
            )
          }
          className="cancel__button"
        >
          Home
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => props.handleSubmit()}
          className="submit__button"
        >
          Add Room Details
        </Button>,
      ]}
    >
      <section className="m-3">
        <div className="">
          <div className="m-2 p-2 d-flex align-items-center">
            <div className="w-50">
              <video
                src="/property/add-room-video.mp4"
                loop
                autoPlay
                className="w-100"
              />
            </div>
            <div>
              <h6 className=" small_text__22  mb-2">
                Successfully added property{" "}
              </h6>
              <p className="small_text__15">Lets add room details</p>
            </div>
          </div>
        </div>
        {/* <ModalFooter> */}

        {/* </ModalFooter> */}
      </section>
    </Modal>
  );
};

export default AddRoomConfirmation;

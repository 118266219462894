import CloseMainSvgComponent from "@assets/svg/close-main";
import DownloadSvgComponent from "@assets/svg/download";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { Image } from "antd";
import { Link } from "react-router-dom";

function GuestDetailsForm(props: any) {
  const { request, setRequest, userDetails, handleUploadId, uploadLoading } =
    props;
  const relationShipList = [
    { value: "self", label: "Self" },
    { value: "father", label: "Father" },
    { value: "mother", label: "Mother" },
    { value: "wife", label: "Wife" },
    { value: "husband", label: "Husband" },
    { value: "Sister", label: "Sister" },
    { value: "son", label: "son" },
    { value: "daughter", label: "Daughter" },
    { value: "other", label: "Other" },
  ];

  const triggerFileInput = (inputId: any) => {
    const fileInput = document.getElementById(inputId);
    if (fileInput) {
      fileInput.click();
    }
  };

  const FileUpload = ({
    label,
    idType,
    request,
    userDetails,
    handleUploadId,
    triggerFileInput,
    removeImage,
  }: any) => {
    const existingImageUrl = getValue(
      userDetails,
      `identity_${idType}_img`,
      ""
    );
    const newImageUrl = getValue(request, `identity_${idType}_img`, "");

    return (
      <div>
        <p className="form-label">{label === "front" ? "Front" : "Back"} Id</p>
        {existingImageUrl && (
          <div className="d-flex gap-3 align-items-center mb-3">
            <img
              src={existingImageUrl}
              height={100}
              alt={`${label} ID`}
              className="object-fit-contain"
            />
            <Link to={existingImageUrl} target="__blank" className="">
              <DownloadSvgComponent size="20" />
            </Link>
          </div>
        )}
        <input
          type="file"
          id={`hiddenFileInput${label}`}
          className="custom-upload-filename"
          accept=".jpg,.jpeg,.png"
          onChange={(e) => handleUploadId(e, idType)}
          style={{ display: "none" }}
        />
        <div className="d-flex gap-3 align-items-center mt-3">
          <ButtonComponent
            buttonType="smart"
            onClickHandler={() => triggerFileInput(`hiddenFileInput${label}`)}
            buttonText={
              uploadLoading[idType]
                ? "Uploading"
                : newImageUrl
                ? "Update Id"
                : "Upload"
            }
            disabled={uploadLoading[idType]}
          />

          {newImageUrl && newImageUrl !== existingImageUrl && (
            <div className="d-flex gap-2 align-items-center mb-3">
              <Link to={newImageUrl} target="__blank">
                <img
                  src={newImageUrl}
                  height={50}
                  alt={`${label} ID`}
                  className="object-fit-contain"
                />
              </Link>
              {/* <Image width={100} src={newImageUrl} /> */}
              <div onClick={() => removeImage(idType)} className="">
                <CloseMainSvgComponent size="20" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const removeImage = (idType: any) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      [`identity_${idType}_img`]: "",
    }));
  };

  return (
    <div className={`grid-container gap-3  py-4 px-2 border-radius__5 mb-3}`}>
      <InputRuleForm
        inputType="TEXT"
        name="full_name"
        value={getValue(request, `full_name`, "")}
        placeholder="Enter full name"
        label="Customer name"
        required
        onChange={(e: any) =>
          setRequest({
            ...request,
            full_name: e.target.value,
          })
        }
      />

      <InputRuleForm
        inputType="TEXT"
        name="email"
        placeholder="Enter customer email"
        label="Customer email"
        value={getValue(request, `email`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            email: e.target.value,
          })
        }
      />
      <InputRuleForm
        inputType="TEXT"
        name="mobile"
        value={getValue(request, `mobile`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            mobile: e.target.value,
          })
        }
        placeholder="Enter Mobile Number"
        label="Mobile Number"
        marginZero
      />
      <InputRuleForm
        inputType="DATE"
        name="dob"
        value={getValue(request, `dob`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            dob: e,
          })
        }
        minDate={new Date()}
        placeholder="Enter Date of birth"
        label="Date of birth"
        marginZero
      />
      <SearchToolTip
        label="label"
        data={relationShipList}
        selectKey={"value"}
        value={getValue(request, `relationship`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            relationship: getValue(e, `value`, ""),
          })
        }
        name="Relationship"
      />

      <SearchToolTip
        label="label"
        data={[
          { value: "male", label: "Male" },
          {
            value: "female",
            label: "Female",
          },
        ]}
        selectKey={"value"}
        value={getValue(request, `gender`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            gender: getValue(e, `value`, ""),
          })
        }
        name="Gender"
      />

      <InputRuleForm
        inputType="TEXT"
        name="country"
        value={getValue(request, `country`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            country: e.target.value,
          })
        }
        placeholder="Enter Country"
        label="Country"
        marginZero
      />

      <InputRuleForm
        inputType="TEXT"
        name="state"
        value={getValue(request, `state`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            state: e.target.value,
          })
        }
        placeholder="Enter State"
        label="State"
        marginZero
      />

      <InputRuleForm
        inputType="TEXT"
        name="city"
        value={getValue(request, `city`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            city: e.target.value,
          })
        }
        placeholder="Enter City"
        label="City"
        marginZero
      />
      <InputRuleForm
        inputType="NUMBER"
        name="pincode"
        value={getValue(request, `pincode`, "")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const pincodeValue = Number(e.target.value);
          setRequest({
            ...request,
            pincode: pincodeValue,
          });
        }}
        placeholder="Enter Pincode"
        label="Pincode"
        marginZero
      />

      <div className="grid-full-width">
        <InputRuleForm
          inputType="TEXTAREA"
          name="address"
          value={getValue(request, `address`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              address: e.target.value,
            })
          }
          placeholder="Enter Address"
          label="Address"
          marginZero
        />
      </div>
      <div>
        <p className="form-label">Identity card type</p>
        <SearchToolTip
          label="label"
          data={[
            { value: "passport", label: "Passport" },
            {
              value: "drivers_license",
              label: "Driver License",
            },
            { value: "aadhar", label: "Aadhar" },
            { value: "voter_id", label: "Voter ID" },
            { value: "others", label: "Other" },
          ]}
          selectKey={"value"}
          value={getValue(request, `identity_type`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              identity_type: getValue(e, `value`, ""),
            })
          }
        />
      </div>
      <div>
        <InputRuleForm
          inputType="TEXT"
          name="identity_value"
          value={getValue(request, `identity_value`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              identity_value: e.target.value,
            })
          }
          placeholder="Enter Govt ID card no"
          label="Govt ID number"
        />
      </div>
      <div className="">
        <p className="form-label">Identity Upload Type</p>
        <SearchToolTip
          label="label"
          data={[
            {
              value: "device_upload",
              label: "Device Upload",
            },
            {
              value: "camera_upload",
              label: "Camera Upload",
            },
          ]}
          selectKey={"value"}
          value={getValue(request, `identity_upload_type`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              identity_upload_type: getValue(e, `value`, ""),
            })
          }
        />
      </div>
      <div></div>

      <FileUpload
        label="front"
        idType="front"
        request={request}
        userDetails={userDetails}
        handleUploadId={handleUploadId}
        triggerFileInput={triggerFileInput}
        removeImage={removeImage}
      />

      <FileUpload
        label="back"
        idType="back"
        request={request}
        userDetails={userDetails}
        handleUploadId={handleUploadId}
        triggerFileInput={triggerFileInput}
        removeImage={removeImage}
      />
    </div>
  );
}

export default GuestDetailsForm;

import DeleteSvgComponent from "@assets/svg/delete";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { getValue } from "@utils/lodash";
import { config } from "../../../../../../../env";
import React, { useState } from "react";
import Loader from "@components/common/Loader/loading";
import { GetProp, Image, Upload, UploadFile, UploadProps } from "antd";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function PropertyRoomImages(props: any) {
  const {
    handleUploadDocuments,
    uploadLoading,
    images,
    handleDeleteImage,
    imageLoading,
  } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-2",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-3",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-4",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-xxx",
      percent: 50,
      name: "image.png",
      status: "uploading",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-5",
      name: "image.png",
      status: "error",
    },
  ]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {/* <PlusOutlined /> */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  return (
    <div>
      <div className="">
        <div className="test-bot-container px-4 py-3">
          <div className={`w-100`}>
            <p className="text-center small_text__13">Add room images</p>
            {/* <Upload
              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{ display: "none" }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )} */}

            <DragAndDropFileComponent
              uploadFile={handleUploadDocuments}
              name="file"
              classes="bulk-upload_drag-n-drop-wrapper position-absolute"
              isLoading={uploadLoading}
              supportedFormats=".jpg, .png, .jpeg"
            />
          </div>
          <div className="property_image_container">
            {imageLoading ? (
              <Loader />
            ) : getValue(images, `length`, 0) > 0 ? (
              images.map((item: object) => {
                return (
                  <div className="position-relative">
                    <div
                      className="property_delete_icon"
                      onClick={() =>
                        // handleDeleteView(getValue(item, `id`, ""))
                        handleDeleteImage(getValue(item, `id`, ""))
                      }
                    >
                      <DeleteSvgComponent />
                    </div>
                    <img
                      src={
                        getValue(item, `url`, "")
                          ? getValue(item, `url`, "")
                          : `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                              item,
                              `file_upload.key`,
                              ""
                            )}`
                      }
                      className="property_image mb-3"
                    />
                  </div>
                );
              })
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyRoomImages;

import React from "react";
import ImportCheckbox from "./import-checkbox";
import { formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button } from "antd";
import { FileDoneOutlined } from "@ant-design/icons";
function ImportStep1(props: any) {
  const { selectedOption, handleChange } = props;
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div>
      <div className="import-contact-selector">
        <h3>
          Select a way to import your{" "}
          {formatString(getValue(props, `module`, ""))}
        </h3>
        <p>
          {formatString(getValue(props, `module`, ""))} are people you engage
          with
        </p>
        <div className="options-wrapper">
          <ImportCheckbox
            value="CSV"
            label="CSV or Excel"
            iconPath={"/images/icons/excel.svg"}
            selectedOption={selectedOption}
            onChange={handleChange}
          />
          <div className="position-relative">
            <Badge.Ribbon text="Coming Soon" color="#efa265">
              <ImportCheckbox
                value="Google"
                label="Google Contacts"
                iconPath={"/images/icons/google-contacts.svg"}
                onChange={handleChange}
                selectedOption={selectedOption}
                disabled
              />
            </Badge.Ribbon>
          </div>
          <div className="position-relative">
            <Badge.Ribbon text="Coming Soon" color="#efa265">
              <ImportCheckbox
                value="Outlook"
                label="Outlook Contacts"
                iconPath={"/images/icons/outlook.svg"}
                onChange={handleChange}
                selectedOption={selectedOption}
                disabled
              />
            </Badge.Ribbon>
          </div>
          <div className="position-relative">
            <Badge.Ribbon text="Coming Soon" color="#efa265">
              <ImportCheckbox
                value="Salesforce"
                label="Salesforce"
                iconPath={"/images/icons/salesforce-icon.svg"}
                onChange={handleChange}
                selectedOption={selectedOption}
                disabled
              />
            </Badge.Ribbon>
          </div>
          {/* <ImportCheckbox
            value="HubSpot"
            label="HubSpot Contacts"
            iconPath={"/images/icons/hubspot.svg"}
            onChange={handleChange}
            selectedOption={selectedOption}
          />
          <ImportCheckbox
            value="Pipedrive"
            label="Pipedrive"
            iconPath={"/images/icons/pipedrive-icon.svg"}
            onChange={handleChange}
            selectedOption={selectedOption}
          />
          <ImportCheckbox
            value="Zoho"
            label="Zoho"
            iconPath={"/images/icons/zoho-icon.svg"}
            onChange={handleChange}
            selectedOption={selectedOption}
          /> */}

          {/* <ImportCheckbox
            value="Insightly"
            label="Insightly"
            iconPath={"/images/icons/insightly.png"}
            onChange={handleChange}
            selectedOption={selectedOption}
          /> */}
        </div>
        <div className="mt-5 text-center header_blue_text__16">
          {getValue(props, `getImportLog`, false) && (
            <div className="d-flex justify-content-center">
              <Button
                icon={<FileDoneOutlined />}
                onClick={() =>
                  navigate(
                    `/${getValue(params, `orgId`, "")}/crm/${getValue(
                      props,
                      `module`,
                      ""
                    )}/imports`
                  )
                }
              >
                Import History Logs
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImportStep1;

import ProgressSteps from "@pages/Private/Property/components/addRoomType/room-progress-bar";
import { getValue } from "@utils/lodash";
import { useParams } from "react-router-dom";
import PropertyRoomForm from "./components/room-form";
import PropertyRoomAmenities from "./components/room-amentites";
import PropertyRoomImages from "./components/room-images";
import CloseMainSvgComponent from "@assets/svg/close-main";
import ButtonComponent from "@components/Form/Button/Button";
import BackSvgComponent from "@assets/svg/back-link";
import { Steps } from "antd";

function PropertyRoomTypes(props: any) {
  const {
    handleModifyRoom,
    roomTypeActivetab,
    setRoomTypeActiveTab,
    //step1
    request,
    setRequest,
    simpleValidator,
    roomsLength,
    setRoomsLength,
    taxGroupList,
    //step2
    selectedAmenities,
    getSpecificAmenityData,
    amentitesList,
    handleAddRemoveAmenities,
    handleDeleteAmenity,
    //step3
    handleUploadDocuments,
    uploadLoading,
    images,
    handleDeleteNote,
    handleDeleteImage,
    handleEditRoomImages,
    imageLoading,
    //submit section
    handleSubmitRoom,
    submitLoading,
    editRoomId,
    setEditRoomId,
    setModifyRoom,
    reset,
  } = props;
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  const params = useParams();
  /* -------------------------------------------------------------------------- */
  /*                               Stepper Section                              */
  /* -------------------------------------------------------------------------- */
  const currentConfig = getValue(params, "config", "");
  const progressSteps = [
    {
      label: "Configure Room",
      identifier: "edit",
      stepNo: 1,
    },
    {
      label: "Room Amenities",
      identifier: "amenities",
      stepNo: 2,
    },
    {
      label: "Photos",
      identifier: "images",
      stepNo: 3,
    },
  ];


  return (
    <div>
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() => {
                handleModifyRoom();
                setEditRoomId("");
                setModifyRoom(false);
                reset();
              }}
            >
              <CloseMainSvgComponent />
            </div>
            <p className="small_text__16 ms-3">{"Property room details"}</p>
          </div>
          <div className="d-flex align-items-center">
            {roomTypeActivetab === 1 && (
              <>
                <ButtonComponent
                  buttonType="scecondary"
                  buttonText={"Back"}
                  onClickHandler={() => setModifyRoom(false)}
                />
                <ButtonComponent
                  buttonType="primary"
                  buttonText={"Save & Next"}
                  onClickHandler={handleSubmitRoom}
                  disabled={submitLoading === true}
                />
              </>
            )}
            {roomTypeActivetab === 2 && (
              <>
                <ButtonComponent
                  buttonType="scecondary"
                  buttonText={"Back"}
                  onClickHandler={() => setRoomTypeActiveTab(1)}
                />
                <ButtonComponent
                  buttonType="primary"
                  buttonText={"Next"}
                  onClickHandler={() => setRoomTypeActiveTab(3)}
                />
              </>
            )}
            {roomTypeActivetab === 3 && (
              <>
                <ButtonComponent
                  buttonType="scecondary"
                  buttonText={"Back"}
                  onClickHandler={() => setRoomTypeActiveTab(2)}
                />
                <ButtonComponent
                  buttonType="primary"
                  buttonText={"Finish"}
                  onClickHandler={() => {
                    setModifyRoom(false);
                    setRoomTypeActiveTab(1);
                    reset();
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`w-100 manageFieldRight  `}>
        <div className="progress-bar-wrapper">
          <Steps
            size="small"
            current={roomTypeActivetab - 1}
            items={[
              {
                title: "Configure room",
              },
              {
                title: "Room amenities",
              },
              {
                title: "Photoes",
              },
            ]}
          />
        </div>
        {/* <ProgressSteps
          steps={progressSteps}
          totalSteps={3}
          activeStep={roomTypeActivetab}
          setRoomTypeActiveTab={setRoomTypeActiveTab}
        /> */}

        <div className="d-flex justify-content-center add-room-details">
          {roomTypeActivetab === 1 && (
            <PropertyRoomForm
              request={request}
              setRequest={setRequest}
              simpleValidator={simpleValidator}
              roomsLength={roomsLength}
              setRoomsLength={setRoomsLength}
              taxGroupList={taxGroupList}
              editRoomId={editRoomId}
            />
          )}
          {roomTypeActivetab === 2 && (
            <PropertyRoomAmenities
              amentitesList={amentitesList}
              selectedAmenities={selectedAmenities}
              getSpecificAmenityData={getSpecificAmenityData}
              handleAddRemoveAmenities={handleAddRemoveAmenities}
              handleDeleteAmenity={handleDeleteAmenity}
              roomTypeActivetab={roomTypeActivetab}
            />
          )}
          {roomTypeActivetab === 3 && (
            <PropertyRoomImages
              imageLoading={imageLoading}
              handleUploadDocuments={handleUploadDocuments}
              uploadLoading={uploadLoading}
              images={images}
              handleDeleteImage={handleDeleteImage}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PropertyRoomTypes;

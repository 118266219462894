import HomeHeader from "@components/common/Header/HomeHeader/Header";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import Navbar from "../common/navbar";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllRoles } from "@services/roles.service";
import { getValue } from "@utils/lodash";
import RolesList from "./components/list";
import RolesNavViews from "./components/nav-views";
import ListLoader from "@components/common/Loader";

function SettingsRoles(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [rolesList, setRolesList] = React.useState<[]>([]);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0) {
      getData();
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0) {
      getData();
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let payload: any = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      search_text: params.search_text,
      status: params.status,
      sort_by: params.sort_by,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllRoles(queryRequest);
      if (resp) {
        console.log(resp);
        setRolesList(
          getValue(resp, `data.roles`, []).map((item: object) => ({
            ...item,
            active: getValue(item, `is_active`, false) ? "Active" : "Inactive",
            id: getValue(item, `id`, ""),
          }))
        );
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      // removing null values
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSortBy = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.sort_by = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const handleChangeStatus = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.status = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleEdit = (id: string) => {
    navigate(`/edit-role/${id}`);
  };

  const views = [
    {
      id: "1",
      name: "Roles",
    },
  ];

  const toggle = () => {
    navigate(`/${getValue(params, `orgId`, "")}/settings/roles/add`);
  };
  const [activeTab, setActiveTab] = useState(1);
  const updateUserStatus = () => {};
  return (
    <div>
      <HomeHeader />
      <CompanyProfileSubHeader addRole toggle={toggle} />
      <div className={`${"users_container"} w-100`}>
        <CompanyProfileNavigation />
        <div className="w-100">
          {isLoading ? (
            <ListLoader />
          ) : (
            <div className={`d-flex`}>
              <div className={`${"manageFieldRight"} w-100 add-overflow-y p-3`}>
                <RolesNavViews
                  views={views}
                  route={"/settings/roles"}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <RolesList
                  isLoading={isLoading}
                  rolesList={rolesList}
                  selectedIDs={selectedIDs}
                  updateUserStatus={updateUserStatus}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SettingsRoles;

import { isValidPhoneNumber } from "libphonenumber-js";

export function convertNumberStringsToNumbers(data) {
  return Object.keys(data).reduce((acc, key) => {
    // Check if the value is a string and can be converted to a number
    if (typeof data[key] === "string" && !isNaN(data[key])) {
      acc[key] = Number(data[key]);
    }
    // else if (Array.isArray(data[key])) {
    //   // If the value is an array, iterate over it to convert each element
    //   acc[key] = data[key].map(item => (typeof item === 'string' && !isNaN(item)) ? Number(item) : item);
    // }
    else {
      acc[key] = data[key];
    }
    return acc;
  }, {});
}

export const cleanObject = (obj) => {
  if (Array.isArray(obj)) {
    const cleanedArray = obj
      .map((item) => cleanObject(item))
      .filter(
        (item) =>
          item !== null &&
          item !== undefined &&
          (typeof item !== "object" || Object.keys(item).length > 0) &&
          !(Array.isArray(item) && item.length === 0) // Filter out empty arrays
      );

    // Return the cleaned array only if it's not empty
    return cleanedArray.length > 0 ? cleanedArray : null;
  } else if (obj !== null && typeof obj === "object") {
    const cleanedObject = Object.entries(obj).reduce((acc, [key, value]) => {
      const cleanedValue = cleanObject(value);

      if (
        cleanedValue !== null &&
        cleanedValue !== undefined &&
        cleanedValue !== ""
      ) {
        if (
          typeof cleanedValue === "string" &&
          !isNaN(cleanedValue) &&
          !cleanedValue.includes("+")
        ) {
          acc[key] = Number(cleanedValue); // Convert string to number if possible
        } else {
          if (
            typeof cleanedValue === "string" &&
            !isNaN(cleanedValue) &&
            cleanedValue.includes("+")
          ) {
            if (isValidPhoneNumber(cleanedValue)) {
              acc[key] = cleanedValue;
            }
          } else {
            acc[key] = cleanedValue;
          }
        }
      }

      return acc;
    }, {});

    // Return the cleaned object only if it's not empty
    return Object.keys(cleanedObject).length > 0 ? cleanedObject : null;
  }
  return obj;
};

import { useState, useCallback, useEffect } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  applyEdgeChanges,
  applyNodeChanges,
} from "reactflow";
import CustomNode from "./components/CustomNode";
import "reactflow/dist/style.css";
import "./index.scss";
import {
  deleteChatbotSetup,
  getSpecificChatbot,
  getSpecificChatbotGroup,
  updateChatbotPosition,
} from "@services/chatbot.service";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { getAllChatbotSetup } from "@services/chatbot.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { createChatbotSetup } from "@services/chatbot.service";
import {
  tempButtonRequest,
  tempCompanyNameRequest,
  tempDateTimeRequest,
  tempEmailRequest,
  tempEndChatRequest,
  tempFileRequest,
  tempMultiSelectRequest,
  tempNameRequest,
  tempPhoneRequest,
  tempSendLinkRequest,
  tempSendMessageRequest,
  tempSingleSelectRequest,
  tempTemplateRequest,
} from "./helpers/flow-payload";
import { sortJSONObjectArray } from "@common/text-helpers";
import {
  getChatbotRequest,
  handleChatbotRequest,
} from "./helpers/request-helper";
import { chatbotRequestPayload } from "./helpers/payload-helper";
import _ from "lodash";
import { toast } from "sonner";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import ButtonEdge from "./components/ButtonEdge";
import ModifyNodePopup from "./popup/modify-node-popup";
import AddNodePopup from "./popup/add-node-popup";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { edgeRequestHelper } from "./helpers/edge-helper";
import { nodeRequestHelper } from "./helpers/node-helper";
import RocketSvgComponent from "@assets/svg/rocket";
import TestChatbotPopup from "./popup/test-bot";
import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";

const nodeTypes = {
  customNode: (props: any) => <CustomNode {...props} />,
};
const edgeTypes = {
  buttonedge: ButtonEdge,
};

export default function ChatbotBuilder() {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [nodes, setNodes] = useNodesState<any>([]);
  const [edges, setEdges] = useEdgesState<any>([]);
  const [selectedNode, setSelectedNode] = useState(null);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getData();
    getChatbotSetupInfo();
  }, []);

  const [botInfo, setBotInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      // let resp = await getSpecificChatbotGroup(getValue(params, `id`, ""));
      let resp = await getSpecificChatbot(getValue(params, `id`, ""));
      if (resp) {
        setBotInfo(getValue(resp, `data`, {}));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* ----------------------------  Chatbot Setup  --------------------------- */

  const [allChatSetupInfo, setAllChatSetupInfo] = useState([]);
  const [chatRequest, setChatRequest] = useState<any>(null);
  const getChatbotSetupInfo = async () => {
    try {
      let payload = {
        chatbot_id: getValue(params, `id`, ""),
        // chatbot_group_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      // let resp = await getAllChatbotSetup(queryRequest);
      let resp = await getAllChatbotSetup(queryRequest);
      if (resp) {
        let infoList: any = getValue(resp, `data`, []);
        let list = sortJSONObjectArray(infoList, "seq_num");
        setAllChatSetupInfo(list);
        if (getValue(resp, `data.length`, 0) > 0) {
          let edges = edgeRequestHelper(list, handleDeleteEdge);
          let nodes = nodeRequestHelper(
            list,
            handleDeleteNode,
            edges,
            addNodeByID
          );
          setNodes(nodes);
          setEdges(edges);
        }
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                         Reactflow Helper Section                           */
  /* -------------------------------------------------------------------------- */

  const onEdgesChange = (changes: any) => {
    const filteredData = _.filter(changes, (item) => item.type !== "remove");
    if (getValue(filteredData, `length`, 0) > 0) {
      setEdges((eds) => {
        const newEdges = applyEdgeChanges(changes, eds);
        return newEdges;
      });
    }
  };

  const onNodesChange = (changes: any) => {
    const filteredData = _.filter(changes, (item) => item.type !== "remove");
    if (getValue(filteredData, `length`, 0) > 0) {
      setNodes((nds) => {
        const newNodes = applyNodeChanges(changes, nds);
        return newNodes;
      });
    }
  };

  const onConnect = useCallback(
    async (connectParams: any) => {
      let selectedEdgeInfo: any = allChatSetupInfo.find(
        (item: object) =>
          getValue(item, `id`, "") === getValue(connectParams, `target`, "")
      );
      let obj = getChatbotRequest(
        getValue(selectedEdgeInfo, `component`, ""),
        selectedEdgeInfo,
        params
      );

      let selectedEdge: any =
        getValue(edges, `length`, 0) > 0
          ? edges.find((item: object) =>
              getValue(connectParams, `sourceHandle`, "")
                ? getValue(item, `sourceHandle`, "") ===
                  getValue(connectParams, `sourceHandle`, "")
                : getValue(item, `source`, "") ===
                  getValue(connectParams, `source`, "")
            )
          : {};

      let changeActionIDForExisting;
      if (getValue(connectParams, `sourceHandle`, "")) {
        changeActionIDForExisting = allChatSetupInfo.filter(
          (item: object) =>
            getValue(item, `action_id`, "") ===
            getValue(connectParams, `sourceHandle`, "")
        );
      } else {
        changeActionIDForExisting = allChatSetupInfo.filter(
          (item: object) =>
            getValue(item, `action_id`, "") ===
            getValue(connectParams, `source`, "")
        );
      }
      if (getValue(changeActionIDForExisting, `length`, 0) > 0) {
        let filtered = changeActionIDForExisting.filter(
          (item: object) =>
            getValue(item, `id`, "") !== getValue(connectParams, `target`, "")
        );
        if (getValue(filtered, `length`, 0) > 0) {
          filtered.map(async (item: object) => {
            let objc = getChatbotRequest(
              getValue(item, `component`, ""),
              item,
              params
            );
            let payload: any = chatbotRequestPayload(
              getValue(objc, `component`, ""),
              objc,
              params,
              allChatSetupInfo,
              selectedFlowID,
              selectedHandleFlowID
            );

            if (!getValue(selectedEdge, `id`, "")) {
              payload["action_id"] = getValue(params, `id`, "");
              let resp = await createChatbotSetup(payload);
              if (resp) {
                getChatbotSetupInfo();
              }
            } else {
              toast.error("Multiple connections will not be supported");
            }
          });
        }
        handleConnectEdges(obj, connectParams, selectedEdge);
      } else {
        handleConnectEdges(obj, connectParams, selectedEdge);
      }
    },
    [setEdges, nodes]
  );

  const handleConnectEdges = async (
    obj: any,
    connectParams: any,
    selectedEdge: any
  ) => {
    let payload: any = chatbotRequestPayload(
      getValue(obj, `component`, ""),
      obj,
      params,
      allChatSetupInfo,
      selectedFlowID,
      selectedHandleFlowID
    );

    let id = getValue(connectParams, `sourceHandle`, "")
      ? getValue(connectParams, `sourceHandle`, "")
      : getValue(connectParams, `source`, "");

    let findCurrentSource: any;
    if (!getValue(connectParams, `sourceHandle`, "")) {
      findCurrentSource = allChatSetupInfo.find(
        (item: object) =>
          getValue(item, `id`, "") === getValue(connectParams, `source`, "")
      );
    }

    if (getValue(findCurrentSource, `component`, "")) {
      let findActionId: any = allChatSetupInfo.find(
        (item: object) =>
          getValue(item, `id`, "") === getValue(connectParams, `source`, "")
      );
      payload["action_id"] = getValue(findActionId, `next_action_id`, "");
    } else {
      payload["action_id"] = getValue(connectParams, `sourceHandle`, "");
    }
    payload["flow_id"] = getValue(connectParams, `source`, "");
    payload["source"] = getValue(connectParams, `sourceHandle`, "");
    if (!getValue(selectedEdge, `id`, "")) {
      let resp = await createChatbotSetup(payload);
      if (resp) {
        getChatbotSetupInfo();
        toast.success("Updated sucessfully");
      }
    } else {
      toast.error("Multiple connections will not be supported");
    }
  };

  let proOptions = { hideAttribution: true };
  // const [defaultViewport, setDefaultViewport] = useState({
  //   x: 100,
  //   y: 80,
  //   // zoom:0
  //   zoom: 0.8,
  // });
  const defaultViewport = {
    x: 100,
    y: 80,
    // zoom:0
    zoom: 0.8,
  };

  /* -------------------------------------------------------------------------- */
  /*                                Modal Section                               */
  /* -------------------------------------------------------------------------- */
  const [openNodeModal, setOpenNodeModal] = useState(false);
  const handleNodeModal = () => {
    setOpenNodeModal(!openNodeModal);
  };

  const [openAddNodeModal, setOpenAddNodeModal] = useState(false);
  const handleAddNodeModal = () => {
    setOpenAddNodeModal(!openAddNodeModal);
  };

  const navigateBack = () => {
    setOpenNodeModal(false);
    setOpenAddNodeModal(true);
  };
  /* -------------------------------------------------------------------------- */
  /*                               OnChange Section                             */
  /* -------------------------------------------------------------------------- */
  const addNode = (name: string) => {
    switch (name) {
      case "send_message":
        setChatRequest(tempSendMessageRequest);
        break;
      case "links":
        setChatRequest(tempSendLinkRequest);
        break;
      case "suggestions":
        setChatRequest(tempButtonRequest);
        break;
      case "singleselect":
        setChatRequest(tempSingleSelectRequest);
        break;
      case "multiselect":
        setChatRequest(tempMultiSelectRequest);
        break;
      case "template":
        setChatRequest(tempTemplateRequest);
        break;
      case "visitor_name":
        setChatRequest(tempNameRequest);
        break;
      case "visitor_email":
        setChatRequest(tempEmailRequest);
        break;
      case "visitor_phone":
        setChatRequest(tempPhoneRequest);
        break;
      case "company_name":
        setChatRequest(tempCompanyNameRequest);
        break;
      case "visitor_datetime":
        setChatRequest(tempDateTimeRequest);
        break;
      case "is_end_chat":
        setChatRequest(tempEndChatRequest);
        break;
      case "file_upload_component":
        setChatRequest(tempFileRequest);
        break;
      default:
        setChatRequest({});
    }
    setOpenAddNodeModal(false);
    setOpenNodeModal(true);
  };

  const onNodeClick = (event: any, node: any) => {
    setSelectedNode(node);
    let findSelectedNode: any = allChatSetupInfo.find(
      (item: object) => getValue(item, `id`, "") === getValue(node, `id`, "")
    );
    setSelectedFlowID(getValue(node, `flow_id`, ""));
    setSelectedHandleFlowID(getValue(node, `source`, ""));
    handleChatbotRequest(
      getValue(findSelectedNode, `component`, ""),
      chatRequest,
      setChatRequest,
      findSelectedNode,
      params
    );
    handleNodeModal();
  };

  const closeSettingsPanel = () => {
    setChatRequest(null);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Delete Section                               */
  /* -------------------------------------------------------------------------- */

  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteNode = (id: string) => {
    setDeleteId(id);
    setIsOpen(true);
  };
  const handleDelete = async () => {
    try {
      let resp = await deleteChatbotSetup(deleteId);
      if (resp) {
        toast.success("Deleted sucessfully");
        getChatbotSetupInfo();
        handleModal();
        getData();
      }
    } catch (error) {}
  };

  const [deleteId1, setDeleteId1] = useState("");
  const [deleteValue1, setDeleteValue1] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleDeleteEdge = (id: string) => {
    setDeleteId1(id);
    setIsOpen1(true);
  };
  const handleDeleteEdges = async () => {
    let selectedEdgeInfo: any = allChatSetupInfo.find(
      (item: object) => getValue(item, `id`, "") === deleteId1
    );
    let obj = getChatbotRequest(
      getValue(selectedEdgeInfo, `component`, ""),
      selectedEdgeInfo,
      params
    );
    let payload: any = chatbotRequestPayload(
      getValue(obj, `component`, ""),
      obj,
      params,
      allChatSetupInfo,
      selectedFlowID,
      selectedHandleFlowID
    );
    payload["flow_id"] = "";
    payload["source"] = "";
    payload["action_id"] = "-";
    let resp = await createChatbotSetup(payload);
    if (resp) {
      setChatRequest(null);
      getChatbotSetupInfo();
      toast.success("Updated sucessfully");
      handleModal1();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Submit Section                               */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitNode = async () => {
    try {
      let payload: any = chatbotRequestPayload(
        getValue(chatRequest, `component`, ""),
        chatRequest,
        params,
        allChatSetupInfo,
        selectedFlowID,
        selectedHandleFlowID
      );

      let selectedFlowIDFromSetup: any = allChatSetupInfo.find(
        (item: object) => getValue(item, `id`, "") === selectedFlowID
      );

      let newPostions;
      if (!getValue(chatRequest, `id`, "")) {
        if (selectedHandleFlowID) {
          const suggestions = getValue(
            selectedFlowIDFromSetup,
            `rendering_config.suggestions`,
            []
          );
          const findHandleID: any = suggestions.findIndex(
            (suggestion: { next_action_id: string }) =>
              getValue(suggestion, `next_action_id`, "") ===
              selectedHandleFlowID
          );
          if (findHandleID > 0) {
            let idInfo = getValue(
              selectedFlowIDFromSetup,
              `rendering_config.suggestions[${findHandleID - 1}]`,
              {}
            );

            let finalHandleID: any = allChatSetupInfo.find(
              (item: object) =>
                getValue(item, `action_id`, "") ===
                getValue(idInfo, `next_action_id`, "")
            );

            newPostions = {
              x: getValue(finalHandleID, `position.x`, 0),
              y: getValue(finalHandleID, `position.y`, 0) + 200,
            };
          } else {
            newPostions = {
              x: getValue(selectedFlowIDFromSetup, `position.x`, 0) + 400,
              y: getValue(selectedFlowIDFromSetup, `position.y`, 0) + 100,
            };
          }
        } else {
          newPostions = {
            x: getValue(selectedFlowIDFromSetup, `position.x`, 0) + 400,
            y: getValue(selectedFlowIDFromSetup, `position.y`, 0),
          };
        }
      } else {
        newPostions = {
          x: getValue(selectedFlowIDFromSetup, `position.x`, 0) + 400,
          y: getValue(selectedFlowIDFromSetup, `position.y`, 0) + 100,
        };
      }

      let prevSourceInfo: any = allChatSetupInfo.find(
        (item: object) => getValue(item, `id`, "") === selectedFlowID
      );

      if (!getValue(chatRequest, `id`, "")) {
        payload["position"] = newPostions;
      }
      setSubmitLoading(true);
      let resp = await createChatbotSetup(payload);
      if (resp) {
        setChatRequest(null);
        getChatbotSetupInfo();
        setSubmitLoading(false);
        toast.success("Updated sucessfully");
        setSelectedFlowID("");
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const [selectedFlowID, setSelectedFlowID] = useState("");
  const [selectedHandleFlowID, setSelectedHandleFlowID] = useState("");

  const addNodeByID = (id: string, handleId: string) => {
    setSelectedFlowID(id);
    setSelectedHandleFlowID(handleId);
    handleAddNodeModal();
  };

  const [saveLoading, setSaveLoading] = useState(false);
  const handleSaveFlow = async () => {
    try {
      setSaveLoading(true);
      let resp = await updateChatbotPosition({
        positions: nodes.map((item: object) => ({
          id: getValue(item, `id`, ""),
          position: getValue(item, `position`, {}),
        })),
      });
      if (resp) {
        setSaveLoading(false);
        getChatbotSetupInfo();
        toast.success("Updated position successfully");
      } else {
        setSaveLoading(false);
      }
    } catch (error) {
      setSaveLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Test Bot Section                             */
  /* -------------------------------------------------------------------------- */
  const [isOpen2, setIsOpen2] = useState(false);

  const handleModal2 = () => {
    setIsOpen2(!isOpen2);
  };
  return (
    <>
      <HomeHeader />
      <div className="app-container position-relative">
        <button
          className="reactflow_start_button px-3 py-2"
          onClick={handleAddNodeModal}
          disabled={getValue(nodes, `length`, 0) > 0 ? true : false}
        >
          <div className="rocket-wrapper">
            <RocketSvgComponent size={18} color={"#32CD32"} />
          </div>
          <h6 className=" header_text__14 text-black mx-2">Start</h6>
        </button>
        <div className="flow-container">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={onNodeClick}
            nodeTypes={nodeTypes}
            elementsSelectable={true}
            // fitView
            proOptions={proOptions}
            edgeTypes={edgeTypes}
            defaultViewport={defaultViewport}
          >
            <MiniMap
              pannable
              zoomable
              className="react-flow-mini-map"
              nodeColor="#99ceff"
            />
            <Controls className="react-flow-controls"></Controls>
            <Background />
            <button
              className="reactflow_test_button d-flex align-items-center justify-content-center"
              onClick={() => {
                handleModal2();
              }}
            >
              <img
                src={
                  getValue(botInfo, `avatar`, "")
                    ? getValue(botInfo, `avatar`, "")
                    : "/images/bot-loader.gif"
                }
                className="bot-profile"
              />
              {isOpen2 && (
                <TestChatbotPopup
                  title={getValue(botInfo, `name`, "")}
                  botInfo={botInfo}
                  show={isOpen2}
                  onClose={handleModal2}
                />
              )}
            </button>
            <div className="reactflow_save_button d-flex justify-content-center align-items-center gap-2">
              <ButtonComponent
                buttonText="Cancel"
                buttonType="secondary"
                onClickHandler={() =>
                  navigate(
                    `/${getValue(params, `orgId`, "")}/chatbot/logs/${getValue(
                      params,
                      `id`,
                      ""
                    )}`
                  )
                }
              />
              <ButtonComponent
                buttonText={saveLoading ? "Saving..." : "Save Position"}
                buttonType="primary"
                onClickHandler={() => {
                  handleSaveFlow();
                }}
                disabled={getValue(nodes, `length`, 0) === 0 ? true : false}
              />
            </div>
          </ReactFlow>
        </div>
        {openAddNodeModal && (
          <AddNodePopup
            selectedNode={selectedNode}
            isOpen={openAddNodeModal}
            setIsOpen={setOpenAddNodeModal}
            handleModal={handleAddNodeModal}
            addNode={addNode}
            botInfo={botInfo}
          />
        )}
        {getValue(chatRequest, `component`, "") && openNodeModal && (
          <ModifyNodePopup
            isOpen={openNodeModal}
            setIsOpen={setOpenNodeModal}
            handleModal={handleNodeModal}
            request={chatRequest}
            setRequest={setChatRequest}
            handleSubmit={handleSubmitNode}
            handleCancel={closeSettingsPanel}
            submitLoading={submitLoading}
            closePanel={closeSettingsPanel}
            navigateBack={navigateBack}
          />
        )}
        <DeleteModal
          isOpen={isOpen}
          handleModal={handleModal}
          handleSubmit={handleDelete}
          deleteValue={deleteValue}
        />
        <DeleteModal
          isOpen={isOpen1}
          handleModal={handleModal1}
          handleSubmit={handleDeleteEdges}
          deleteValue={deleteValue1}
        />
      </div>
    </>
  );
}

import { getValue, setValue } from "@utils/lodash";
import React, { useState } from "react";
import { handleTextArrayChange } from "common/handleChange";
import AddCircleSvgComponent from "assets/svg/add-circle";
import RemoveCircleSvgComponent from "assets/svg/remove-circle";
import ThumbsUpSvgComponent from "assets/svg/thumbsup";
import ThumbsdownSvgComponent from "assets/svg/thumbsdown";
import DragIndicatorSvgComponent from "assets/svg/drag-indicator";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./createPipeline.scss";
import useDraggableInPortal from "@common/useDraggableInPortal";
import InputRuleForm from "@components/InputRuleForm/form";
import {
  BlockPicker,
  ChromePicker,
  SketchPicker,
  TwitterPicker,
} from "react-color";
import { REACT_COLORS } from "@common/colors";

function PipelineStep2(props: any) {
  const { request, setRequest, activeStep, simpleValidator } = props;
  const addStage = (key: string) => {
    request[key].push({
      type:
        key === "openStages"
          ? "OPEN"
          : key === "closedLostStages"
          ? "CLOSED_LOST"
          : "CLOSED_WON",
      color_code:
        key === "openStages"
          ? "#4cc793"
          : key === "closedLostStages"
          ? "#f27476"
          : "#f27914",
    });
    setRequest({ ...request });
  };

  const removeStage = (i: number, key: string) => {
    let filtered = request[key].filter(
      (_item: object, index: number) => index !== i
    );
    setRequest({ ...request, [key]: filtered });
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // Moving from same area in top to botomm direction
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "source") {
        const sourceOpenArrayCopy: any = [
          ...getValue(request, `openStages`, []),
        ];
        const [removed] = sourceOpenArrayCopy.splice(source.index, 1);
        sourceOpenArrayCopy.splice(destination.index, 0, removed);
        setValue(request, `openStages`, sourceOpenArrayCopy);
        setRequest({ ...request });
      }
    }
  };

  const handleOnDragEnd1 = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // Moving from same area in top to botomm direction
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "source") {
        const sourceOpenArrayCopy: any = [
          ...getValue(request, `closedWonStages`, []),
        ];
        const [removed] = sourceOpenArrayCopy.splice(source.index, 1);
        sourceOpenArrayCopy.splice(destination.index, 0, removed);
        setValue(request, `closedWonStages`, sourceOpenArrayCopy);
        setRequest({ ...request });
      }
    }
  };

  const handleOnDragEnd2 = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // Moving from same area in top to botomm direction
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "source") {
        const sourceOpenArrayCopy: any = [
          ...getValue(request, `closedLostStages`, []),
        ];
        const [removed] = sourceOpenArrayCopy.splice(source.index, 1);
        sourceOpenArrayCopy.splice(destination.index, 0, removed);
        setValue(request, `closedLostStages`, sourceOpenArrayCopy);
        setRequest({ ...request });
      }
    }
  };
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: isDragging ? 4 * 2 : 0,
    margin: isDragging ? `0 0 ${4}px 0` : 0,
    borderRadius: "5px",
    // change background colour if dragging
    border: isDragging ? "1px solid lightgreen" : "none",
    position: isDragging && "absolute",
    bottom: isDragging && 0,
    top: isDragging && 0,
    content: isDragging && "in portal",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const renderDraggable = useDraggableInPortal();
  /* -------------------------------------------------------------------------- */
  /*                           Color Picker section                             */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const [activeColor, setActiveColor] = useState<any>(null);
  const handleChangePicker = (e: any, index: any, key: string) => {
    setRequest((object: any) => {
      return {
        ...object,
        [key]: object[key].map((x: any, i: number) => {
          if (i !== index) return x;
          return {
            ...x,
            color_code: e.hex,
          };
        }),
      };
    });
    setIsOpen(false);
    setActiveColor(null);
  };

  return (
    <div className={`step_2_container position-relative`}>
      <div className={`form_wrapper-pipeline`} style={{}}>
        <p className="mb-2 header_blue_text__16">Open Status</p>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="source">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {getValue(request, `openStages`, []).map(
                  (obj: any, index: number) => {
                    return (
                      <Draggable
                        key={index}
                        draggableId={`${index}open`}
                        index={index}
                        // isDragDisabled={!index}
                      >
                        {renderDraggable((provided: any) => (
                          <div
                            className={`d-flex align-items-center mb-2 w-100`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="">
                              <DragIndicatorSvgComponent color={"lightgray"} />
                            </div>
                            <div className="position-relative">
                              <div
                                className="stage_color_picker"
                                style={{
                                  backgroundColor: getValue(
                                    obj,
                                    "color_code",
                                    "#000000"
                                  ),
                                }}
                                onClick={() => {
                                  setIsOpen(!isOpen);
                                  setActiveColor(`${index}-openStages`);
                                }}
                              ></div>

                              {isOpen && activeColor === `${index}-openStages` && (
                                <div className="popover">
                                  <SketchPicker
                                    presetColors={REACT_COLORS}
                                    onChangeComplete={(e) =>
                                      handleChangePicker(e, index, "openStages")
                                    }
                                    color={getValue(obj, `color_code`, "")}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="inline-input-drag ms-2">
                              <InputRuleForm
                                inputType="TEXT"
                                placeholder="Enter Open Status"
                                value={getValue(obj, `label`, "")}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleTextArrayChange(
                                    index,
                                    e,
                                    "openStages",
                                    setRequest
                                  )
                                }
                                name={"label"}
                                validator={activeStep === 2 && simpleValidator}
                                validLeft
                                hideLabel
                                required
                                label={"Open Status"}
                                style={{ marginTop: "8px" }}
                              />
                            </div>
                            <div className="d-flex align-items-center">
                              <div
                                onClick={() => addStage("openStages")}
                                className="cursor-pointer ms-2"
                              >
                                <AddCircleSvgComponent size={26} />
                              </div>
                              {index !== 0 && (
                                <div
                                  onClick={() =>
                                    removeStage(index, "openStages")
                                  }
                                  className="cursor-pointer ms-2"
                                >
                                  <RemoveCircleSvgComponent size={26} />
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </Draggable>
                    );
                  }
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <p className="mt-4 mb-2 header_blue_text__16">Closed Won Status</p>
        <DragDropContext onDragEnd={handleOnDragEnd1}>
          <Droppable droppableId="source">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {getValue(request, `closedWonStages`, []).map(
                  (obj: any, index: number) => {
                    return (
                      <Draggable
                        key={index}
                        draggableId={`${index}closedwon`}
                        index={index}
                        // isDragDisabled={!index}
                      >
                        {renderDraggable((provided: any) => (
                          <div
                            className={`d-flex align-items-center mb-2 w-100`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="">
                              <DragIndicatorSvgComponent color={"lightgray"} />
                            </div>
                            <div className="position-relative">
                              <div
                                className="stage_color_picker"
                                style={{
                                  backgroundColor: getValue(
                                    obj,
                                    "color_code",
                                    "#000000"
                                  ),
                                }}
                                onClick={() => {
                                  setIsOpen(!isOpen);
                                  setActiveColor(`${index}-closedWonStages`);
                                }}
                              ></div>

                              {isOpen &&
                                activeColor === `${index}-closedWonStages` && (
                                  <div className="popover">
                                    <SketchPicker
                                      presetColors={REACT_COLORS}
                                      onChangeComplete={(e) =>
                                        handleChangePicker(
                                          e,
                                          index,
                                          "closedWonStages"
                                        )
                                      }
                                      color={getValue(obj, `color_code`, "")}
                                    />
                                  </div>
                                )}
                            </div>
                            <div className="inline-input-drag ms-2">
                              <InputRuleForm
                                inputType="TEXT"
                                placeholder="Enter Closed Won Status"
                                value={getValue(obj, `label`, "")}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleTextArrayChange(
                                    index,
                                    e,
                                    "closedWonStages",
                                    setRequest
                                  )
                                }
                                name={"label"}
                                validator={activeStep === 2 && simpleValidator}
                                validLeft
                                hideLabel
                                required
                                label={"Closed Won Status"}
                                style={{ marginTop: "8px" }}
                              />
                              <div className="img">
                                <ThumbsUpSvgComponent />
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div
                                onClick={() => addStage("closedWonStages")}
                                className="cursor-pointer ms-2"
                              >
                                <AddCircleSvgComponent size={26} />
                              </div>
                              {index !== 0 && (
                                <div
                                  onClick={() =>
                                    removeStage(index, "closedWonStages")
                                  }
                                  className="cursor-pointer ms-2"
                                >
                                  <RemoveCircleSvgComponent size={26} />
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </Draggable>
                    );
                  }
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <p className="mt-4 mb-2 header_blue_text__16">Closed Lost Status</p>
        <DragDropContext onDragEnd={handleOnDragEnd2}>
          <div className="position-relative">
            <Droppable droppableId="source">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {getValue(request, `closedLostStages`, []).map(
                    (obj: any, index: number) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={`${index}closedlost`}
                          index={index}
                          // isDragDisabled={!index}
                        >
                          {renderDraggable((provided: any) => (
                            <div
                              className={`d-flex align-items-center mb-2 w-100`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="">
                                <DragIndicatorSvgComponent
                                  color={"lightgray"}
                                />
                              </div>
                              <div className="position-relative">
                                <div
                                  className="stage_color_picker"
                                  style={{
                                    backgroundColor: getValue(
                                      obj,
                                      "color_code",
                                      "#000000"
                                    ),
                                  }}
                                  onClick={() => {
                                    setIsOpen(!isOpen);
                                    setActiveColor(`${index}-closedLostStages`);
                                  }}
                                ></div>

                                {isOpen &&
                                  activeColor ===
                                    `${index}-closedLostStages` && (
                                    <div className="popover">
                                      <SketchPicker
                                        presetColors={REACT_COLORS}
                                        onChangeComplete={(e) =>
                                          handleChangePicker(
                                            e,
                                            index,
                                            "closedLostStages"
                                          )
                                        }
                                        color={getValue(obj, `color_code`, "")}
                                      />
                                    </div>
                                  )}
                              </div>
                              <div className="inline-input-drag ms-2">
                                <InputRuleForm
                                  inputType="TEXT"
                                  placeholder="Enter Closed Lost Status"
                                  value={getValue(obj, `label`, "")}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleTextArrayChange(
                                      index,
                                      e,
                                      "closedLostStages",
                                      setRequest
                                    )
                                  }
                                  name={"label"}
                                  validator={
                                    activeStep === 2 && simpleValidator
                                  }
                                  validLeft
                                  hideLabel
                                  required
                                  label={"Closed Lost Status"}
                                  style={{ marginTop: "8px" }}
                                />
                                <div className="img">
                                  <ThumbsdownSvgComponent />
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div
                                  onClick={() => addStage("closedLostStages")}
                                  className="cursor-pointer ms-2"
                                >
                                  <AddCircleSvgComponent size={26} />
                                </div>
                                {index !== 0 && (
                                  <div
                                    onClick={() =>
                                      removeStage(index, "closedLostStages")
                                    }
                                    className="cursor-pointer ms-2"
                                  >
                                    <RemoveCircleSvgComponent size={26} />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </Draggable>
                      );
                    }
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
}

export default PipelineStep2;

import Property from "@pages/Private/Property";
import Bookingfolio from "@pages/Private/Property/BookingFolio";
import CreateProperty from "@pages/Private/Property/CreateProperty";
import PropertyAddress from "@pages/Private/Property/CreateProperty/step2";
import PropertyImages from "@pages/Private/Property/CreateProperty/step3";
import PropertyAmenities from "@pages/Private/Property/CreateProperty/step4";
import PropertySettingsDetails from "@pages/Private/Property/settings";
import EditSlotMode from "@pages/Private/Property/settings/EditSlotMode";
import FolioConfiguration from "@pages/Private/Property/settings/FolioConfiguration";
import InvoiceConfiguration from "@pages/Private/Property/settings/InvoiceConfiguration";
import PropertyGroupTaxSettings from "@pages/Private/Property/settings/PropertyGroupTaxDetails";
import PropertyTaxSettings from "@pages/Private/Property/settings/PropertyTaxDetails";
import CreateWalkIN from "@pages/Private/Property/ReservationCalender/Bookings/WalkIn";
import PropertySummary from "@pages/Private/Property/PropertySumary";
import ReservationCalender from "@pages/Private/Property/ReservationCalender";
import PropertyConfiguration from "@pages/Private/Property/configuration";
import PropertyRoomTypes from "@pages/Private/Property/CreateProperty/step5";
import PropertyRatePlanSettings from "@pages/Private/Property/settings/PropertyRatePlan";
import PropertyEditSlotTime from "@pages/Private/Property/settings/EditSlotTime";
import PropertyAddRoomTypes from "@pages/Private/Property/CreateProperty/step5/components/add-room-type";
import CreateGroupBooking from "@pages/Private/Property/ReservationCalender/Bookings/GroupBooking";

export const PropertyRoutes = [
  {
    path: "/:orgId/property/property-configuarion",
    name: "Property",
    component: PropertyConfiguration,
  },
  {
    path: "/:orgId/property",
    name: "Property",
    component: Property,
  },
  {
    path: "/:orgId/property/create",
    name: "Property",
    component: CreateProperty,
  },
  {
    path: "/:orgId/property/edit/:id",
    name: "Property",
    component: CreateProperty,
  },
  {
    path: "/:orgId/property/address/:id",
    name: "Property",
    component: PropertyAddress,
  },
  {
    path: "/:orgId/property/images/:id",
    name: "Property",
    component: PropertyImages,
  },
  {
    path: "/:orgId/property/amenities/:id",
    name: "Property",
    component: PropertyAmenities,
  },
  {
    path: "/:orgId/property/room-types/:id",
    name: "Property",
    component: PropertyRoomTypes,
  },
  {
    path: "/:orgId/property/room-types/:config/:id",
    name: "Property",
    component: PropertyAddRoomTypes,
  },

  // {
  //   path: "/:orgId/property/details/:id",
  //   name: "Property",
  //   component: PropertyDetails,
  // },
  {
    path: "/:orgId/property/details/:id/summary",
    name: "Property",
    component: PropertySummary,
  },
  {
    path: "/:orgId/property/details/:id/reservations",
    name: "Property",
    component: ReservationCalender,
  },
  {
    path: "/:orgId/property/details/:id/settings",
    name: "Property",
    component: PropertySettingsDetails,
  },
  // {
  //   path: "/:orgId/property/details/calender/:id",
  //   name: "Property",
  //   component: PropertyDetails,
  // },
  // {
  //   path: "/:orgId/property/details/settings/:id",
  //   name: "Property",
  //   component: PropertySettingsDetails,
  // },

  {
    path: "/:orgId/property/details/:id/create/walk-in/:typeId/:roomId/:date/:roomName",
    name: "Property",
    component: CreateWalkIN,
  },
  {
    path: "/:orgId/property/details/:id/create/group-booking/:date",
    name: "Property",
    component: CreateGroupBooking,
  },
  {
    path: "/:orgId/property/details/:id/folio/:bookingId",
    name: "Property",
    component: Bookingfolio,
  },
  {
    path: "/:orgId/property/details/:id/settings/tax",
    name: "Property",
    component: PropertyTaxSettings,
  },
  {
    path: "/:orgId/property/details/:id/settings/group-tax",
    name: "Property",
    component: PropertyGroupTaxSettings,
  },
  {
    path: "/:orgId/property/details/:id/settings/slot-mode",
    name: "Property",
    component: EditSlotMode,
  },
  {
    path: "/:orgId/property/details/:id/settings/slot-time",
    name: "Property",
    component: PropertyEditSlotTime,
  },
  {
    path: "/:orgId/property/details/:id/settings/folio",
    name: "Property",
    component: FolioConfiguration,
  },
  {
    path: "/:orgId/property/details/:id/settings/invoice",
    name: "Property",
    component: InvoiceConfiguration,
  },
  {
    path: "/:orgId/property/details/:id/settings/rate-plan",
    name: "Property",
    component: PropertyRatePlanSettings,
  },
];

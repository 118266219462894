import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import { useState } from "react";
import { Button, Modal } from "antd";
// import "./edit-popup.scss";

function UserPopup(props: any) {
  const { request, setRequest, simpleValidator } = props;
  const [editOptions] = useState([
    { value: "owner_id", label: "Owner" },
    { value: "stage_id", label: "Status" },
  ]);
  return (
    <div>
      <Modal
        open={getValue(props, `isOpen`, "")}
        onOk={props.handleSubmit}
        onCancel={props.handleModal}
        footer={[
          <Button
            key="cancel"
            onClick={props.handleModal}
            className="cancel__button"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={props.handleSubmit}
            className="submit__button"
          >
            {props.isLoading ? "Please wait..." : "Invite"}
          </Button>,
        ]}
      >
        {/* <CommonPopupHeader
          title={"User"}
          handleBackButton={props.handleModal}
          height={"60px"}
        /> */}
        <h6 className="header_text__!6">Invite teammates</h6>
        <section className="edit-popup-wrapper">
          <div className="">
            <InputRuleForm
              inputType="EMAIL"
              label="Email"
              placeholder={"Enter Email"}
              name="identifiers"
              value={getValue(request, `identifiers`, "")}
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
              validator={simpleValidator}
              validLeft
            />
          </div>
        </section>
      </Modal>
    </div>
  );
}

export default UserPopup;

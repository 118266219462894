import { replacePlaceholders } from "@common/markdown/markdown-helper";
import { removeNullValuesFromObjectArray } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

/* -------------------------------------------------------------------------- */
/*                                 Helper section                             */
/* -------------------------------------------------------------------------- */
const getVisitorKeyName = (name: string) => {
  switch (name) {
    case "visitor_name":
      return "name";
    case "visitor_email":
      return "email";
    case "visitor_phone":
      return "phone";
    case "company_name":
      return "company";
    case "visitor_datetime":
      return "date";
    default:
      return "";
  }
};

const findActionID = (
  chatRequest: any,
  selectedHandleFlowID: any,
  allChatSetupInfo: any,
  selectedFlowID: string,
  params: any
) => {
  let nodesLength = getValue(allChatSetupInfo, `length`, 0);
  return getValue(chatRequest, `id`, "")
    ? getValue(chatRequest, `action_id`, "")
    : nodesLength > 0
    ? selectedHandleFlowID
      ? selectedHandleFlowID
      : getValue(allChatSetupInfo, `[${nodesLength - 1}].component`, "") ===
        "suggestions"
      ? getValue(
          allChatSetupInfo,
          `[${
            nodesLength - 1
          }].rendering_config.suggestions[${0}].next_action_id`,
          ""
        )
      : findNextActionID(selectedFlowID, allChatSetupInfo)
    : getValue(params, `id`, "");
};

const findFlowID = (
  chatRequest: any,
  allChatSetupInfo: any,
  selectedFlowID: string,
  params: any
) => {
  let nodesLength = getValue(allChatSetupInfo, `length`, 0);
  return getValue(chatRequest, `id`, "")
    ? getValue(chatRequest, `flow_id`, "")
    : nodesLength > 0
    ? selectedFlowID
      ? selectedFlowID
      : getValue(allChatSetupInfo, `[${nodesLength - 1}].component`, "") ===
        "suggestions"
      ? getValue(
          allChatSetupInfo,
          `[${
            nodesLength - 1
          }].rendering_config.suggestions[${0}].next_action_id`,
          ""
        )
      : getValue(allChatSetupInfo, `[${nodesLength - 1}].id`, "")
    : getValue(params, `id`, "");
};

const findNextActionID = (id: string, allChatSetupInfo: any) => {
  let info =
    getValue(allChatSetupInfo, `length`, 0) > 0
      ? allChatSetupInfo.find((item: object) => getValue(item, `id`, "") === id)
      : {};
  return getValue(info, `next_action_id`, "");
};

const commonChatbotRequestPayload = (
  name: string,
  chatRequest: any,
  params: any,
  selectedHandleFlowID: string,
  allChatSetupInfo: any,
  selectedFlowID: string
) => {
  let nodesLength = getValue(allChatSetupInfo, `length`, 0);
  return {
    id: getValue(chatRequest, `id`, ""),
    chatbot_id: getValue(params, `id`, ""),
    // chatbot_group_id: getValue(params, `id`, ""),
    action_id: findActionID(
      chatRequest,
      selectedHandleFlowID,
      allChatSetupInfo,
      selectedFlowID,
      params
    ),
    flow_id: findFlowID(chatRequest, allChatSetupInfo, selectedFlowID, params),
    name: getValue(chatRequest, `name`, ""),
    position: getValue(chatRequest, `position`, {}),
    seq_num: getValue(chatRequest, `id`, "")
      ? getValue(chatRequest, `seq_num`, "")
      : nodesLength + 1,
    component: name,
    source: selectedHandleFlowID,
    is_end_chat: name === "is_end_chat" ? true : false,
    type: "reply",
  };
};

/* -------------------------------------------------------------------------- */
/*                                 Payload section                            */
/* -------------------------------------------------------------------------- */
export const chatbotRequestPayload = (
  name: string,
  chatRequest: object,
  params: any,
  allChatSetupInfo: any,
  selectedFlowID: string,
  selectedHandleFlowID: string
) => {
  switch (name) {
    case "reply":
    case "is_end_chat":
      return {
        ...commonChatbotRequestPayload(
          name,
          chatRequest,
          params,
          selectedHandleFlowID,
          allChatSetupInfo,
          selectedFlowID
        ),
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: replacePlaceholders(
                getValue(chatRequest, `description`, "")
              ),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          _meta: {
            type: "reply",
          },
          behaviour: "goto_next_action",
        },
      };

    case "suggestions":
      let list =
        getValue(chatRequest, `suggestions.length`, 0) > 0
          ? getValue(chatRequest, `suggestions`, []).map((item: object) => ({
              ...item,
              next_action_id: getValue(item, `next_action_id`, "")
                ? getValue(item, `next_action_id`, "")
                : "",
            }))
          : [];
      return {
        ...commonChatbotRequestPayload(
          name,
          chatRequest,
          params,
          selectedHandleFlowID,
          allChatSetupInfo,
          selectedFlowID
        ),
        has_user_input: false,
        has_user_action: true,
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: replacePlaceholders(
                getValue(chatRequest, `description`, "")
              ),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          _meta: {
            type: "reply",
          },
          behaviour: "wait_for_user_input",
          suggestions: removeNullValuesFromObjectArray(list),
        },
      };

    case "singleselect":
      let single_select_options =
        getValue(chatRequest, `suggestions.length`, 0) > 0
          ? getValue(chatRequest, `suggestions`, []).map((item: object) => ({
              ...item,
              next_action_id: getValue(item, `next_action_id`, "")
                ? getValue(item, `next_action_id`, "")
                : "",
            }))
          : [];
      return {
        ...commonChatbotRequestPayload(
          name,
          chatRequest,
          params,
          selectedHandleFlowID,
          allChatSetupInfo,
          selectedFlowID
        ),
        has_user_input: false,
        has_user_action: true,
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: replacePlaceholders(
                getValue(chatRequest, `description`, "")
              ),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          _meta: {
            type: "select",
          },
          behaviour: "wait_for_user_input",
          input: {
            type: "select",
            options: removeNullValuesFromObjectArray(single_select_options),
          },
        },
      };
    case "multiselect":
      let multi_select_options =
        getValue(chatRequest, `suggestions.length`, 0) > 0
          ? getValue(chatRequest, `suggestions`, []).map((item: object) => ({
              ...item,
              next_action_id: getValue(item, `next_action_id`, "")
                ? getValue(item, `next_action_id`, "")
                : "",
            }))
          : [];
      return {
        ...commonChatbotRequestPayload(
          name,
          chatRequest,
          params,
          selectedHandleFlowID,
          allChatSetupInfo,
          selectedFlowID
        ),
        has_user_input: false,
        has_user_action: true,
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: replacePlaceholders(
                getValue(chatRequest, `description`, "")
              ),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          _meta: {
            type: "multiple-select",
          },
          behaviour: "wait_for_user_input",
          input: {
            type: "multiple-select",
            options: removeNullValuesFromObjectArray(multi_select_options),
          },
        },
      };
    case "visitor_name":
    case "visitor_email":
    case "visitor_phone":
    case "company_name":
    case "visitor_datetime":
      return {
        ...commonChatbotRequestPayload(
          name,
          chatRequest,
          params,
          selectedHandleFlowID,
          allChatSetupInfo,
          selectedFlowID
        ),
        source: selectedHandleFlowID,
        has_user_input: true,
        has_user_action: true,
        user_input_key: getVisitorKeyName(name),
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: replacePlaceholders(
                getValue(chatRequest, `description`, "")
              ),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          _meta: {
            type: "reply",
          },
          behaviour: "wait_for_user_input",
        },
      };

    case "file_upload_component":
      return {
        ...commonChatbotRequestPayload(
          name,
          chatRequest,
          params,
          selectedHandleFlowID,
          allChatSetupInfo,
          selectedFlowID
        ),
        source: selectedHandleFlowID,
        file_id: getValue(chatRequest, `file_id`, ""),
        file_path: getValue(chatRequest, `file_path`, ""),
        file_name: getValue(chatRequest, `file_name`, ""),
        file_extension: getValue(chatRequest, `file_extension`, ""),
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: replacePlaceholders(
                getValue(chatRequest, `description`, "")
              ),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          _meta: {
            type: "reply",
          },
          behaviour: "goto_next_action",
        },
      };

    case "template":
      let chatList =
        getValue(chatRequest, `suggestions.length`, 0) > 0
          ? getValue(chatRequest, `suggestions`, []).map((item: object) => ({
              ...item,
              next_action_id: getValue(item, `next_action_id`, "")
                ? getValue(item, `next_action_id`, "")
                : "",
            }))
          : [];
      return {
        ...commonChatbotRequestPayload(
          name,
          chatRequest,
          params,
          selectedHandleFlowID,
          allChatSetupInfo,
          selectedFlowID
        ),
        source: selectedHandleFlowID,
        template_id: getValue(chatRequest, `template_id`, ""),
        is_whatsapp_template: true,
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: replacePlaceholders(
                getValue(chatRequest, `description`, "")
              ),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          _meta: {
            type: "reply",
          },
          behaviour: "goto_next_action",
          suggestions: removeNullValuesFromObjectArray(chatList),
        },
      };

    case "links":
      return {
        ...commonChatbotRequestPayload(
          name,
          chatRequest,
          params,
          selectedHandleFlowID,
          allChatSetupInfo,
          selectedFlowID
        ),
        source: selectedHandleFlowID,
        user_input_key: getVisitorKeyName(name),
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: replacePlaceholders(
                getValue(chatRequest, `description`, "")
              ),
              delay_config: getValue(chatRequest, `delay_config`, ""),
              links:
                getValue(chatRequest, `links.length`, 0) > 0
                  ? getValue(chatRequest, `links`, [])
                  : [],
            },
          ],
          _meta: {
            type: "reply",
          },
          behaviour: "goto_next_action",
        },
      };

    default:
      return {};
  }
};

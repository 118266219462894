import * as React from "react";
import Loading from "./loading.json";
import Lottie from "react-lottie";
// eslint-disable-next-line @typescript-eslint/no-empty-interface

interface IListLoaderProps {}

const ListLoader: React.FunctionComponent<IListLoaderProps> = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="lottie-container">
      <Lottie
        options={defaultOptions}
        height={350}
        width={350}
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
      />
    </div>
  );
};

export default ListLoader;

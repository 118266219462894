import DeleteSvgComponent from "@assets/svg/delete";
import { convertCurrentDate } from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import BotNoDataPage from "@components/common/NoData/bot-nodata";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const BotGroupTableData = (props: any) => {
  const list = props;
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = (id: string) => {
    navigate(
      `/${getValue(
        params,
        `orgId`,
        ""
      )}/chatbot/group/builder/create-flow/${getValue(
        params,
        `chatbot_id`,
        ""
      )}/${id}`
    );
  };
  return (
    <div>
      {getValue(list, `list.length`, 0) > 0 ? (
        <div className="datatable_container">
          <div className=" datatable_table_wrapper">
            <table className="datatable_table w-100">
              <thead className="datatable_table_thead position-relative">
                <tr className="datatable_table_tr">
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">BOT NAME</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">DESCRIPTION</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">PLATFORM</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">DEPARTMENT</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">ADDED ON</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">ACTIONS</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="datatable_table_tbody">
                {getValue(list, `list`, []).map((item: object, key: number) => {
                  return (
                    <tr className="datatable_table_tr " key={key}>
                      <td
                        className="datatable_table_td "
                        style={{ display: "table-cell" }}
                        onClick={() => handleNavigate(getValue(item, `id`, ""))}
                      >
                        <div
                          className="header_blue_text__14 touchable-text"
                          onClick={() =>
                            handleNavigate(getValue(item, `id`, ""))
                          }
                        >
                          {getValue(item, `name`, "")}
                        </div>
                      </td>
                      <td
                        className="datatable_table_td "
                        onClick={() => handleNavigate(getValue(item, `id`, ""))}
                      >
                        {" "}
                        {getValue(item, `description`, "")}
                      </td>
                      <td
                        className="datatable_table_td "
                        onClick={() => handleNavigate(getValue(item, `id`, ""))}
                      >
                        {formatString(getValue(item, `platform`, ""))}
                      </td>
                      <td
                        className="datatable_table_td "
                        onClick={() => handleNavigate(getValue(item, `id`, ""))}
                      >
                        {formatString(getValue(item, `department`, ""))}
                      </td>
                      <td
                        className="datatable_table_td "
                        onClick={() => handleNavigate(getValue(item, `id`, ""))}
                      >
                        {convertCurrentDate(getValue(item, `created_at`, ""))}
                      </td>
                      <td
                        className="datatable_table_td "
                        onClick={() =>
                          props.handleDelete(getValue(item, `id`, ""))
                        }
                      >
                        <DeleteSvgComponent />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <BotNoDataPage />
      )}
    </div>
  );
};

export default BotGroupTableData;

import ChildSvgComponent from "@assets/svg/child";
import { getValue } from "@utils/lodash";

function CarouselNavViews(props: any) {
  const {
    activeTab,
    handleTab,
    showName,
    hideIndexPlusOne,
    addHeading,
    isChild,
    showIndexAsLabel,
  } = props;
  const items = getValue(props, "items", []);
  const length = getValue(props, "length", 0);

  return (
    <ul
      className={`d-flex align-items-center justify-content-between overflow-auto pr-5 ${
        !showName && "mt-3"
      }`}
      style={{ borderBottom: "1px solid #efefef" }}
    >
      <div className="d-flex align-items-center justify-content-start ms-1">
        {Array.from({ length }).map((_, index: number) => {
          const item = items[index];
          const adjustedIndex = showIndexAsLabel ? index + 1 : index;
          return (
            <li
              key={`pending-${index}`}
              className={`${
                activeTab === adjustedIndex
                  ? "activebar__main-body-list_active"
                  : "activebar__main-body-list"
              }`}
              onClick={() => handleTab(adjustedIndex, item?.id)}
            >
              <a className={"activebar__main-body-link p-3 text-nowrap"}>
                {!showIndexAsLabel
                  ? addHeading
                    ? `${addHeading} ${adjustedIndex}`
                    : showName && item
                    ? item.full_name
                    : hideIndexPlusOne
                    ? adjustedIndex
                    : null
                  : index + 1}
              </a>
            </li>
          );
        })}
      </div>
    </ul>
  );
}

export default CarouselNavViews;

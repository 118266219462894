import CloseSvgComponent from "@assets/svg/close";
import { handleDownloadOnClick } from "@common/downloadfile";
import { concatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { config } from "env";
import { useCallback, useRef } from "react";
import LightGalleryPreview from "./lightgallery-preview";

// Define a type that includes the LightGallery instance methods
interface LightGalleryInstance {
  openGallery: (index: number) => void;
}

function CommonDocumentList(props: any) {
  const {
    documentList,
    handleRemoveDocuments,
    isEdit,
    setDeleteId,
    hideRemove,
  } = props;

  const getImages = (key: string) => {
    switch (key) {
      case ".pdf":
        return "/images/extention/pdf.png";
      case ".svg":
        return "/images/extention/pdf.svg";
      case ".png":
        return "/images/extention/pdf.png";
      case ".jpg":
        return "/images/extention/jpg.png";
      case ".jpeg":
        return "/images/extention/jpeg.png";
      case ".csv":
        return "/images/extention/csv.png";
      case ".xls":
      case ".xlsx":
        return "/images/extention/excel.png";
      default:
        return "/images/extention/document.png";
    }
  };

  // Check if the file extension is supported by LightGallery
  const isLightGallerySupported = (extension: string) => {
    const supportedExtensions = [".jpg", ".jpeg", ".png", ".svg"];
    return supportedExtensions.includes(extension.toLowerCase());
  };

  // Initialize the lightGallery ref with the correct type
  const lightGallery = useRef<LightGalleryInstance | null>(null);

  const openGallery = useCallback((index: number) => {
    if (lightGallery.current) {
      lightGallery.current.openGallery(index);
    }
  }, []);

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance as LightGalleryInstance;
    }
  }, []);

  return (
    <div className={`${hideRemove ? "mt-2" : "mt-4"}`}>
      {getValue(documentList, "length", 0) > 0
        ? documentList.map((item: any, index: number) => {
            const extension = getValue(item, "extension", "");
            const isSupported = isLightGallerySupported(extension);

            return (
              <div
                className="document_list_container d-flex align-items-center justify-content-between mb-2"
                key={index}
              >
                <div
                  className={`d-flex align-items-center cursor-pointer ${
                    hideRemove && "py-1"
                  }`}
                  onClick={() => {
                    if (isSupported) {
                      setTimeout(() => {
                        openGallery(index);
                      }, 100);
                    } else {
                      handleDownloadOnClick(
                        `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                          item,
                          "key",
                          ""
                        )}`,
                        "file"
                      );
                    }
                  }}
                >
                  <img
                    src={getImages(extension)}
                    className="document-list-image"
                    alt="Document"
                  />
                  <p className="small_text__14 ms-2 ">
                    {concatString(getValue(item, "filename", ""), 30)}
                  </p>
                </div>
                {hideRemove ? null : isEdit ? (
                  <div
                    onClick={() => {
                      setDeleteId(getValue(item, "id", ""));
                      handleRemoveDocuments();
                    }}
                  >
                    <CloseSvgComponent size={10} />
                  </div>
                ) : (
                  <div onClick={() => handleRemoveDocuments(index)}>
                    <CloseSvgComponent size={10} />
                  </div>
                )}
              </div>
            );
          })
        : null}
      {getValue(documentList, "length", 0) > 0 && (
        <LightGalleryPreview
          onInit={onInit}
          imageList={documentList.map((item: any) => ({
            id: getValue(item, "id", ""),
            size: "1400-933",
            src: `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
              item,
              "key",
              ""
            )}`,
            thumb: `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
              item,
              "key",
              ""
            )}`,
            subHtml: `<div class="lightGallery-captions">
                    <h4><a href="">${getValue(item, "name", "")}</a></h4>
                </div>`,
          }))}
        />
      )}
    </div>
  );
}

export default CommonDocumentList;

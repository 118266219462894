import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import ButtonComponent from "@components/Form/Button/Button";
import PropertySettingsLayout from "@layouts/PropertyLayout/property-settings-layout";
import { useEffect, useState } from "react";
import EditSvgComponent from "@assets/svg/edit";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import { toast } from "sonner";
import Loader from "@components/common/Loader/loading";
import { getAllRatePlan } from "@services/properties/settings/rate-plan.service";
import { getAllRoomType } from "@services/properties/room-type.service";
import {
  createTimeSlotSettings,
  getTimeSlotSettings,
} from "@services/properties/settings/time-slot.service";
import { updateTimeSlotSettings } from "@services/properties/settings/time-slot.service";
import TimeSlotPopup from "./components/time-slot";

const PropertyEditSlotTime = () => {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  let slots = [
    { value: "1 am", label: "1 am" },
    { value: "2 am", label: "2 am" },
    { value: "3 am", label: "3 am" },
    { value: "4 am", label: "4 am" },
    { value: "5 am", label: "5 am" },
    { value: "6 am", label: "6 am" },
    { value: "7 am", label: "7 am" },
    { value: "8 am", label: "8 am" },
    { value: "9 am", label: "9 am" },
    { value: "10 am", label: "10 am" },
    { value: "11 am", label: "11 am" },
    { value: "12 am", label: "12 am" },
    { value: "1 pm", label: "1 pm" },
    { value: "2 pm", label: "2 pm" },
    { value: "3 pm", label: "3 pm" },
    { value: "4 pm", label: "4 pm" },
    { value: "5 pm", label: "5 pm" },
    { value: "6 pm", label: "6 pm" },
    { value: "7 pm", label: "7 pm" },
    { value: "8 pm", label: "8 pm" },
    { value: "9 pm", label: "9 pm" },
    { value: "10 pm", label: "10 pm" },
    { value: "11 pm", label: "11 pm" },
    { value: "12 pm", label: "12 pm" },
  ];
  const [checkinSlots, setCheckinSlots] = useState<any>([]);
  const [checkoutSlots, setCheckoutSlots] = useState<any>([]);

  useEffect(() => {
    getData();
    // getAllRoomList();
    setCheckinSlots(slots);
    setCheckoutSlots(slots);
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
        slot_type: "24_hrs",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getTimeSlotSettings(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.timeSlots`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [rooms, setRooms] = useState([]);
  const getAllRoomList = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomType(queryRequest);
      if (resp) {
        setIsLoading(false);
        setRooms(
          getValue(resp, `data.properties`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Edit Section                                 */
  /* -------------------------------------------------------------------------- */

  const [editId, setEditId] = useState("");
  const handleEdit = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    setRequest({
      ...request,
      checkin: getValue(obj, `checkin`, ""),
      checkout: getValue(obj, `checkout`, ""),
      slot_type: getValue(obj, `slot_type`, ""),
      property_id: getValue(params, `id`, ""),
    });
    toggleRateplan();
  };

  /* -------------------------------------------------------------------------- */
  /*                          Create/Edit Section                               */
  /* -------------------------------------------------------------------------- */
  const [openRatePlan, setopenRatePlan] = useState(false);
  const toggleRateplan = () => {
    setopenRatePlan(!openRatePlan);
  };
  const intialState = {
    checkin: "",
    checkout: "",
    slot_type: "24_hrs",
    property_id: getValue(params, `id`, ""),
  };
  const [request, setRequest] = useState(intialState);

  const reset = () => {
    setRequest(intialState);
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let requestPayload = JSON.parse(JSON.stringify(request));

      let resp;
      if (!editId) {
        resp = await createTimeSlotSettings(
          removeNullOrUndefinedProperties(requestPayload)
        );
      } else {
        resp = await updateTimeSlotSettings(
          editId,
          removeNullOrUndefinedProperties(requestPayload)
        );
      }
      if (resp) {
        toast.success(`${editId ? "Updated" : "Created"} successfully`);
        reset();
        setSubmitLoading(false);
        getData();
        toggleRateplan();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      console.error(error);
      setSubmitLoading(false);
    }
  };

  const handleCheckinSlotChange = (selectedCheckinSlot: any) => {
    // Find the index of the selected check-in slot
    const index = slots.findIndex((slot) => slot.value === selectedCheckinSlot);

    // Filter slots for checkout, starting from the next hour after check-in
    const filteredCheckoutSlots = [
      ...slots.slice(index + 1),
      ...slots.slice(0, index + 1), // wrap around
    ];
    // Update the checkoutSlots state

    setCheckoutSlots(
      filteredCheckoutSlots.filter(
        (item: object) => getValue(item, `value`, "") !== selectedCheckinSlot
      )
    );
  };

  return (
    <PropertyDetailsLayout>
      <PropertySettingsLayout>
        <div className=" w-100 overflow-y-auto">
          <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
            <h3 className="header_text__20">Flexible slot configuration</h3>
            {getValue(list, `length`, 0) < 1 && (
              <ButtonComponent
                buttonType="primary"
                buttonText="Add Time Slot"
                onClickHandler={toggleRateplan}
              />
            )}
          </div>
          <p className="my-3">
            View the Flexible Slotsdata for all your room types. You can only
            view one week's data at a time.You can edit the Timingsfor a given
            day.
          </p>

          {isLoading ? (
            <Loader />
          ) : getValue(list, `length`, 0) > 0 ? (
            list.map((item: object) => {
              return (
                <div className=" my-4  p-3 rounded-3 cursor-pointer room-box-shadow mx-3">
                  <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
                    <h3 className="header_text__16">{"Slot Time"}</h3>
                    <div onClick={() => handleEdit(item)}>
                      <EditSvgComponent />
                    </div>
                  </div>
                  <div className="">
                    <div className="row mt-3">
                      <p className="col-2 small_text__14">checkin:</p>
                      <p className=" col-9 small_text__16">
                        {getValue(item, `checkin`, "")}
                      </p>
                    </div>
                    <div className="row mt-2">
                      <p className="col-2 small_text__14">checkout:</p>
                      <p className=" col-9 small_text__16">
                        {getValue(item, `checkout`, "")}
                      </p>
                    </div>
                    <div className="row mt-2">
                      <p className="col-2 small_text__14">Slot Type:</p>
                      <p className=" col-9 small_text__16">
                        {formatString(getValue(item, `slot_type`, ""))}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : null}
        </div>
      </PropertySettingsLayout>
      <CommonRightPopup
        isPopupVisible={openRatePlan}
        toggle={() => {
          toggleRateplan();
          setEditId("");
          editId && reset();
        }}
        width="500px"
        title="Add time slot"
        hideNew
        second_title={
          submitLoading ? "Please wait..." : editId ? "Update" : "Save"
        }
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
      >
        <TimeSlotPopup
          request={request}
          setRequest={setRequest}
          editId={editId}
          getData={getData}
          rooms={rooms}
          checkinSlots={checkinSlots}
          checkoutSlots={checkoutSlots}
          handleCheckinSlotChange={handleCheckinSlotChange}
        />
      </CommonRightPopup>
    </PropertyDetailsLayout>
  );
};

export default PropertyEditSlotTime;

import React, { useState } from "react";
import CreateSubPipeline from "./components/add-sub-pipeline";
import { concatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import { createSubPipeline } from "@services/common.service";
import { generatePayload } from "@components/helpers/request-helper";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";

function CommonAddSubPipeline(props: any) {
  const {
    isOpen,
    toggle,
    fields,
    setFields,
    simpleValidator,
    singular_label,
    forceUpdate,
    getAllPipelines,
    module,
    staticFieldRequest,
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const [listCreateLoading, setListCreateLoading] = useState(false);

  const hideValidation = () => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.fields = {};
    simpleValidator.current.errorMessages = {};
    forceUpdate(0);
  };
  const showValidation = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };

  const handleCreate = async (val: string) => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      showValidation();
    } else {
      try {
        setListCreateLoading(true);
        let payload = generatePayload(module, fields, staticFieldRequest);
        let resp = await createSubPipeline(payload, module);
        if (resp) {
          toast.success("Created Successfully");
          setListCreateLoading(false);
          if (val) {
            simpleValidator.current.hideMessages();
            forceUpdate(0);
            getAllPipelines();
          } else {
            if (
              module === "tasks" ||
              module === "calls" ||
              module === "meetings"
            ) {
              navigate(
                `/${getValue(params, `orgId`, "")}/crm/${
                  module === "sales" ? "deals" : module
                }/detail/${getValue(resp, `data.id`, "")}`
              );
            } else {
              navigate(
                `/${getValue(params, `orgId`, "")}/crm/${
                  module === "sales" ? "deals" : module
                }/detail/${getValue(resp, `data.id`, "")}`
              );
            }

            toggle(false);
          }
        } else {
          setListCreateLoading(false);
        }
      } catch (error) {
        setListCreateLoading(false);
      }
    }
  };
  const [duplicateAPINames, setDuplicateAPINames] = useState<any>([]);
  return (
    <div>
      <CommonRightPopup
        isPopupVisible={isOpen}
        //header props
        addCustomClass={"rolls-popup"}
        toggle={() => {
          toggle();
          hideValidation();
        }}
        title={
          module === "contacts"
            ? "Create Contact"
            : module === "companies"
            ? "Create Company"
            : module === "calls"
            ? "Log Call"
            : module === "meetings"
            ? "Log Meeting"
            : `Create ${concatString(singular_label, 20)} Form`
        }
        //footer props
        handleBackButton={() => {
          toggle();
          hideValidation();
        }}
        handleSubmit={handleCreate}
        submitLoading={listCreateLoading}
        width={props.width ? props.width : "600px"}
        disabled={duplicateAPINames.length > 0 ? true : false}
      >
        <CreateSubPipeline
          module={module}
          fields={fields}
          setFields={setFields}
          stageList={props.stageList}
          pipelineList={props.pipelineList}
          simpleValidator={simpleValidator}
          hideValidation={hideValidation}
          singular_label={concatString(singular_label, 20)}
          staticFieldRequest={props.staticFieldRequest}
          setStaticFieldRequest={props.setStaticFieldRequest}
          getSpecificPipelineInfo={props.getSpecificPipelineInfo}
          duplicateAPINames={duplicateAPINames}
          setDuplicateAPINames={setDuplicateAPINames}
        />
      </CommonRightPopup>
    </div>
  );
}

export default CommonAddSubPipeline;

import React from "react";
import "./createPipeline.scss";

function Stepper(props: any) {
  return (
    <div className={"wrapper"}>
      <ol className={"c-stepper"}>
        <li className={"c-stepper__item_active"}>
          <p className={"c-stepper__title"}>Basic Info</p>
          {/* <p className="c-stepper__desc">Some desc text</p> */}
        </li>
        <li
          className={
            props.activeStep === 2 || props.activeStep === 3
              ? "c-stepper__item_active"
              : "c-stepper__item"
          }
        >
          <p className={"c-stepper__title"}>Status</p>
          {/* <p className="c-stepper__desc">Some desc text</p> */}
        </li>
        <li
          className={
            props.activeStep === 3
              ? "c-stepper__item_active"
              : "c-stepper__item"
          }
        >
          <p className={"c-stepper__title"}>Fields</p>
          {/* <p className="c-stepper__desc">Some desc text</p> */}
        </li>
      </ol>
    </div>
  );
}

export default Stepper;

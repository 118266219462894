import CloseSvgComponent from "@assets/svg/close";
import CloseMainSvgComponent from "@assets/svg/close-main";
import { handleDownloadOnClick } from "@common/downloadfile";
import { emojisList } from "@common/emojis";
import { concatString } from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";
import { config } from "env";
import React, { useState } from "react";

function BotFileComponent(props: any) {
  const { request, setRequest } = props;

  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState({});
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setUploadedFileInfo(getValue(resp, `data`, {}));
          setRequest({
            ...request,
            file_path: `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
              resp,
              `data.key`,
              ""
            )}`,
            file_id: getValue(resp, `data.id`, ""),
            file_name: getValue(resp, `data.filename`, ""),
            file_extension: getValue(resp, `data.extension`, ""),
          });
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };
  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    setRequest({
      ...request,
      description: value,
    });
  };
  return (
    <div>
      {/* <div className="mb-2">
        <InputRuleForm
          inputType="TEXT"
          value={getValue(request, `name`, "")}
          name="name"
          onChange={(e: any) =>
            setRequest({ ...request, name: e.target.value })
          }
          label="Name"
          placeholder="Enter Name"
          // required
        />
      </div> */}
      <div className="mb-2">
        {/* <InputRuleForm
          inputType="TEXTAREA"
          value={getValue(request, `description`, "")}
          name="description"
          onChange={(e: any) =>
            setRequest({ ...request, description: e.target.value })
          }
          label="Message"
          placeholder="Enter Message"
          // mandatory
        /> */}
        <DraftJSRefEditor
          editorState={getValue(request, `description`, "")}
          handleChangeEditor={handleChangeEditor}
          name="content"
          placeholder="Enter here"
          editorStyle={{
            background: "white",
            paddingLeft: "20px",
            paddingTop: "20px",
            // height: "30vh",
          }}
          focusColor={"#1a73e8"}
          toolbar={{
            options: ["inline", "emoji"],
            inline: {
              options: ["bold", "italic"],
            },
            emoji: {
              emojis: emojisList,
            },
          }}
          hideFocus
          focus
          editorClassName={'bot-editorClassName'}
        />
      </div>
      <div className="px-4">
        <DragAndDropFileComponent
          uploadFile={handleUploadDocuments}
          name="file"
          classes="bulk-upload_drag-n-drop-wrapper position-absolute"
          isLoading={uploadLoading}
        />
      </div>
      {getValue(request, `file_path`, "") && (
        <div
          className="bot_filepath mt-3 d-flex justify-content-between align-items-center cursor-pointer"
          onClick={() =>
            setRequest({
              ...request,
              file_id: "",
              file_path: "",
              file_name: "",
              file_extension: "",
            })
          }
        >
          <p
            className="small_text__16"
            onClick={() =>
              handleDownloadOnClick(getValue(request, `file_path`, ""), "file")
            }
          >
            {concatString(getValue(request, `file_name`, ""), 60)}
          </p>
          <div>
            <CloseMainSvgComponent />
          </div>
        </div>
      )}
    </div>
  );
}

export default BotFileComponent;

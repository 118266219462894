import { getValue } from "@utils/lodash";
import BotReplyOption from "./Options/reply";
import BotsuggestionOption from "./Options/suggestion";
import BotVisitorPhoneOption from "./Options/visitor-phone";
import BotVisitorNameOption from "./Options/visitor-name";
import BotVisitorEmailOption from "./Options/visitor-email";
import BotVisitorCompanyNameOption from "./Options/visitor-company-name";
import BotEndChatOption from "./Options/end-chat";
import BotLinksOption from "./Options/links";
import BotFileOption from "./Options/file";
import BotTemplateOption from "./Options/template";
import BotSingleSelectOption from "./Options/single-select";
import BotMultiSelectOption from "./Options/multi-select";
import BotVisitorDateTimeNameOption from "./Options/visitor-datetime";

function CustomNodeOptions(props: any) {
  const { data, handleDelete } = props;
  const handleStyle = {
    height: "14px",
    width: "14px",
    border: "2px solid #fff",
    borderRadius: "50%",
    background: "#0bc5ea",
  };

  const renderTypes = (data: object) => {
    switch (getValue(data, `component`, "")) {
      case "reply":
        return (
          <BotReplyOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "links":
        return (
          <BotLinksOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );
      case "suggestions":
        return (
          <BotsuggestionOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "singleselect":
        return (
          <BotSingleSelectOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "multiselect":
        return (
          <BotMultiSelectOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "visitor_name":
        return (
          <BotVisitorNameOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "visitor_email":
        return (
          <BotVisitorEmailOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "visitor_phone":
        return (
          <BotVisitorPhoneOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "template":
        return (
          <BotTemplateOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "company_name":
        return (
          <BotVisitorCompanyNameOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "visitor_datetime":
        return (
          <BotVisitorDateTimeNameOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "is_end_chat":
        return (
          <BotEndChatOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "file_upload_component":
        return (
          <BotFileOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      default:
        return null;
    }
  };
  return <>{renderTypes(data)}</>;
}

export default CustomNodeOptions;

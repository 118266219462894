import { getValue } from "@utils/lodash";
import TestChatbotPopup from "../ReactFlowBuilder/popup/test-bot";
import { useState } from "react";

const BotDemo = ({ request, changeHeight }: any) => {
  const [isOpen2, setIsOpen2] = useState(false);

  const handleModal2 = () => {
    setIsOpen2(!isOpen2);
  };

  return (
    <div className="p-4 w-50">
      <div
        className={`w-100 bot-creator-window ${
          changeHeight && "edit-bot-height "
        }`}
      >
        <div className="window-top-bar">
          <div className="window-action-button close-color"></div>
          <div className="window-action-button maximize-color"></div>
          <div className="window-action-button minimize-color"></div>
        </div>
        <div>
          <img src="/images/website-skeleton.webp" />
        </div>
        <button
          className="bot-demo "
          onClick={() => {
            handleModal2();
          }}
          style={{
            right:
              getValue(request, "position", "") === "RIGHT_BOTTOM" ||
              getValue(request, "position", "") === "RIGHT_CENTER"
                ? "30px"
                : "",
            left:
              getValue(request, "position", "") === "LEFT_BOTTOM" ? "30px" : "",
            bottom:
              getValue(request, "position", "") === "LEFT_BOTTOM" ||
              getValue(request, "position", "") === "RIGHT_BOTTOM"
                ? "30px"
                : "50%",
            transform:
              getValue(request, "position", "") === "RIGHT_CENTER"
                ? "translateY(50%)"
                : "",
          }}
        >
          <img
            src={
              getValue(request, "platform", "") === "WEB"
                ? getValue(request, `avatar`, "")
                  ? getValue(request, `avatar`, "")
                  : "/images/bot-loader.gif"
                : "/images/whatsapp.png"
            }
            className={
              getValue(request, "platform", "") === "WEB"
                ? "bot-profile"
                : "wa-bot-profile"
            }
          />
          {isOpen2 && (
            <TestChatbotPopup
              title={getValue(request, `name`, "")}
              botInfo={request}
              show={isOpen2}
              onClose={handleModal2}
            />
          )}
        </button>
        {/* <div
          className="bot-demo"
          style={{
            right:
              getValue(request, "position", "") === "RIGHT_BOTTOM" ||
              getValue(request, "position", "") === "RIGHT_CENTER"
                ? "30px"
                : "",
            left:
              getValue(request, "position", "") === "LEFT_BOTTOM" ? "30px" : "",
            bottom:
              getValue(request, "position", "") === "LEFT_BOTTOM" ||
              getValue(request, "position", "") === "RIGHT_BOTTOM"
                ? "30px"
                : "50%",
            transform:
              getValue(request, "position", "") === "RIGHT_CENTER"
                ? "translateY(50%)"
                : "",
          }}
        >
          <img
            src={
              getValue(request, "avatar", "")
                ? getValue(request, "avatar", "")
                : "/images/bot.png"
            }
            width={60}
            height={60}
          />
        </div> */}
      </div>
    </div>
  );
};

export default BotDemo;

// import CompanyProfileDetailsLayout from "layout/CompanyProfileDetailsLayout/CompanyProfileDetailsLayout";
// import CompanyProfileSubHeader from "@components/v2/Settings/CompanyProfile/CompanyProfileSubHeader/CompanyProfileSubHeader";
// import CompanyProfileForm from "@components/v2/Settings/CompanyProfile/CompanyProfileForm/CompanyProfileForm";
import { useEffect, useRef, useState } from "react";
import ListLoader from "@components/common/Loader";
import SimpleReactValidator from "simple-react-validator";
import {
  getSpecificOrganisations,
  updateOrganisations,
} from "@services/organisations.service";
import { toast } from "sonner";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import CompanyProfileSubHeader from "./components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileForm from "./components/CompanyProfileForm";
import { useStateContext } from "@context/profileProvider";
import useDynamicTitle from "@context/useDynamicTitle";

export default function CompanyProfile() {
  const params = useParams();
  const {handleChangeOrgainization} = useStateContext()
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({
    name: "",
    time_zone: "",
    currency: "",
    date_format: "",
  });
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getData();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    try {
      let resp = await getSpecificOrganisations();
      if (resp) {
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          time_zone: getValue(resp, `data.time_zone`, ""),
          currency: getValue(resp, `data.currency`, ""),
          date_format: getValue(resp, `data.date_format`, ""),
        });
      }
    } catch (error) {}
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp = await updateOrganisations(request);
        if (resp) {
          handleChangeOrgainization()
          toast.success("Updated successfully");
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  useDynamicTitle("CompanyProfile");
  return (
    <>
      <HomeHeader />
      <CompanyProfileSubHeader
        isLoading={submitLoading}
        handleSubmit={handleSubmit}
        showSubmit
      />
      <CompanyProfileDetailsLayout>
        {isLoading ? (
          <ListLoader />
        ) : (
          <CompanyProfileForm request={request} setRequest={setRequest} />
        )}
      </CompanyProfileDetailsLayout>
    </>
  );
}

import React, { useEffect, useState } from "react";
import NotesEditor from "./notes-editor";
import NotesListPage from "./notes-list";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  createNote,
  getAllNotes,
  updateNote,
} from "@services/common-notes.service";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import { deleteNote } from "@services/notes.service";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import { getFutureDateFunctions } from "@common/Date-Helpers/future-date-helpers";
import CommonDetailNotesPopup from "./notes-popup";
import { Modal } from "antd";
import DraftJSEditor from "@components/custom/DraftJSEditor";

function CommonDetailNotes(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const { isNotesPopupVisibile, setNotesPopupVisisbility, handleOpenNotes } =
    props;
  const [notesRequest, setNotesRequest] = useState({
    content: "",
    follow_up_type: "CALL",
    follow_up_datetime: getValue(getFutureDateFunctions(), `[${0}].value`, ""),
  });
  const { openEditNote, setOpenEditNote } = props;

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getNotes();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [notesLoading, setNotesLoading] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const getNotes = async () => {
    try {
      setNotesLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllNotes(
        props.module,
        getValue(props, `params.id`, ""),
        queryRequest
      );
      if (resp) {
        setNotesList(getValue(resp, `data.notes`, []));
        setNotesLoading(false);
      } else {
        setNotesLoading(false);
      }
    } catch (error) {
      setNotesLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Edit Section                                  */
  /* -------------------------------------------------------------------------- */
  const [editId, setEditId] = useState("");
  const notesReset = () => {
    setEditId("");
    setNotesRequest({
      ...notesRequest,
      content: "",
      follow_up_type: "EMAIL",
      follow_up_datetime: getValue(
        getFutureDateFunctions(),
        `[${0}].value`,
        ""
      ),
    });
  };
  const handleEditNotes = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    // setIsTextarea(true);
    setNotesRequest({
      ...notesRequest,
      content: getValue(obj, `content`, null),
      follow_up_type: getValue(obj, `follow_up_type`, null),
      follow_up_datetime: getValue(obj, `follow_up_datetime`, null),
    });
    // handleOpenNotes();
  };

  const [isTextarea, setIsTextarea] = useState(false);

  const handleInputClick = () => {
    setIsTextarea(true);
  };
  const textareaRef = React.createRef<any>();
  // React.useEffect(() => {
  //   if (isTextarea) {
  //     const content = getValue(notesRequest, `content`, "");
  //     if (content) {
  //       textareaRef.current.value = content;
  //       textareaRef.current.selectionStart = content.length;
  //       textareaRef.current.focus();
  //     }
  //   }
  // }, [isTextarea]);

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteNote = async () => {
    try {
      let resp = await deleteNote(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        setDeleteId("");
        // props.getData(false);
        getNotes();
        setEditId("");
        setIsTextarea(false);
        setNotesRequest({
          ...notesRequest,
          content: "",
          follow_up_type: "",
          follow_up_datetime: getValue(
            getFutureDateFunctions(),
            `[${0}].value`,
            ""
          ),
        });
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                              Submit Section                                */
  /* -------------------------------------------------------------------------- */
  const [submitNotesLoading, setSubmitNotesLoading] = useState(false);
  const handleSubmitNotes = async () => {
    try {
      setSubmitNotesLoading(true);
      let payload = {
        note: removeNullOrUndefinedProperties(notesRequest),
        associations: {
          add: [
            {
              api_name: getValue(props, `module`, ""),
              record_id: getValue(props, `params.id`, ""),
            },
          ],
        },
      };
      let resp;
      if (editId) {
        resp = await updateNote(
          props.module,
          getValue(props, `params.id`, ""),
          editId,
          payload
        );
      } else {
        resp = await createNote(
          props.module,
          getValue(props, `params.id`, ""),
          payload
        );
      }
      if (resp) {
        getNotes();
        toast.success(`Notes ${editId ? "updated" : "created"} successfully`);
        notesReset();
        // props.getData(false);
        setEditId("");
        setIsTextarea(false);
        setSubmitNotesLoading(false);
        setNotesPopupVisisbility(false);
      } else {
        setSubmitNotesLoading(false);
      }
    } catch (error) {
      setSubmitNotesLoading(false);
    }
  };

  const handleChangeNoteEditor = (name: string, value: any) => {
    setNotesRequest({
      ...notesRequest,
      [name]: value,
    });
  };

  const showEditNote = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    setNotesRequest({
      ...notesRequest,
      content: getValue(obj, `content`, null),
      follow_up_type: getValue(obj, `follow_up_type`, null),
      follow_up_datetime: getValue(obj, `follow_up_datetime`, null),
    });
    setOpenEditNote(true);
  };

  const handleEditNoteOk = () => {
    handleSubmitNotes();
    setEditId("");
    setNotesRequest({
      ...notesRequest,
      content: "",
    });
    setIsTextarea(false);
    setOpenEditNote(false);
  };

  const handleEditNoteCancel = () => {
    setOpenEditNote(false);
    setNotesRequest({
      ...notesRequest,
      content: "",
    });
    setIsTextarea(false);
    setEditId("");
  };
  return (
    <div>
      {(!getValue(urlParams, `activeTab`, "") ||
        getValue(urlParams, `activeTab`, "") == 1) && (
        <>
          <NotesEditor
            isTextarea={isTextarea}
            setIsTextarea={setIsTextarea}
            notesRequest={notesRequest}
            setNotesRequest={setNotesRequest}
            editId={editId}
            setEditId={setEditId}
            textareaRef={textareaRef}
            handleInputClick={handleInputClick}
            handleSubmitNotes={handleSubmitNotes}
            submitNotesLoading={submitNotesLoading}
            handleChangeEditor={handleChangeNoteEditor}
          />
          <NotesListPage
            notes={notesList}
            loading={notesLoading}
            handleInputClick={handleInputClick}
            handleDeleteView={handleDeleteView}
            handleEditNotes={handleEditNotes}
            showEditNote={showEditNote}
          />
        </>
      )}
      {/* ----------------------------------- Notes Module ----------------------------------- */}
      <CommonDetailNotesPopup
        isRollsPopupVisibile={isNotesPopupVisibile}
        setRollsPopupVisisbility={setNotesPopupVisisbility}
        request={notesRequest}
        setRequest={setNotesRequest}
        handleChangeEditor={handleChangeNoteEditor}
        title={"Notes"}
        handleSubmit={handleSubmitNotes}
        isLoading={submitNotesLoading}
        reset={notesReset}
        open={openEditNote}
        onOk={handleEditNoteOk}
        onCancel={handleEditNoteCancel}
      />
      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDeleteNote}
        deleteValue={deleteValue}
      />
      <Modal
        title="Notes"
        open={openEditNote}
        onOk={handleEditNoteOk}
        onCancel={handleEditNoteCancel}
        okText={submitNotesLoading ? "Loading..." : "Submit"}
        okButtonProps={{
          disabled:
            !getValue(notesRequest, `content`, "") ||
            getValue(notesRequest, `content`, "") === "<p></p>\n"
              ? true
              : false,
        }}
      >
        <DraftJSEditor
          toolbarHidden
          editorState={getValue(notesRequest, `content`, "")}
          handleChangeEditor={handleChangeNoteEditor}
          name="content"
          placeholder={props.placeholder ? props.placeholder : "Add a note..."}
          focus
          editorStyle={{
            background: "white",
            paddingLeft: "10px",
            minHeight: "15vh",
            border: "1px solid #efefef",
          }}
          toolbar={{
            options: ["inline", "fontSize", "list"],
          }}
        />
      </Modal>
    </div>
  );
}

export default CommonDetailNotes;

import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";

const BotBuilderStepTwo = (props: any) => {
  const { request, setRequest } = props;
  const intervelTimeList = [
    { value: 1, label: "1 Second" },
    { value: 2, label: "2 Seconds" },
    { value: 3, label: "3 Seconds" },
    { value: 4, label: "4 Seconds" },
    { value: 5, label: "5 Seconds" },
  ];
  const chatTimeList = [
    { value: 5, label: "5 minute" },
    { value: 10, label: "10 minute" },
    { value: 15, label: "15 minute" },
    { value: 20, label: "20 minute" },
    { value: 25, label: "25 minute" },
  ];
  const channelList = [
    { value: "WEBSITE", label: "Website" },
    { value: "MOBILE", label: "Mobile" },
  ];
  return (
    <div>
      {/* <div className="">
        <h6 className="header_text__16">Channels</h6>
        <p className="small_text__14 mb-2">
          Choose channels to deploy your bot
        </p>
        <div className="d-flex flex-wrap mt-2">
          {channelList.map((item: object) => {
            return (
              <div
                className="platform_container mx-2 cursor-pointer"
                style={{
                  border: request.channels.includes(getValue(item, `value`, ""))
                    ? "1px solid #1a73e8"
                    : "",
                }}
                onClick={() => {
                  if (request.channels.includes(getValue(item, `value`, ""))) {
                    let filterd = request.channels.filter(
                      (ite: object) => ite !== getValue(item, `value`, "")
                    );
                    setRequest({
                      ...request,
                      channels: filterd,
                    });
                  } else {
                    request.channels.push(getValue(item, `value`, ""));
                    setRequest({
                      ...request,
                    });
                  }
                }}
              >
                <h6 className="header_text__14">
                  {getValue(item, `label`, "")}
                </h6>
              </div>
            );
          })}
        </div>
      </div> */}

      <div className="mt-4">
        <p className="header_text__18">Response interval</p>
        <p className="pending-task-commondescription mt-1">
          Add an interval to your bot's response time during interactive chats
          for a more natural conversational flow.
        </p>
        <SearchToolTip
          placeholder="Choose a time"
          data={intervelTimeList}
          label="label"
          value={getValue(request, `response_interval`, "")}
          selectKey={"value"}
          name=""
          required
          onChange={(e: any) =>
            setRequest({
              ...request,
              response_interval: getValue(e, `value`, ""),
            })
          }
        />
      </div>
      <div className="mt-5">
        <div className="d-flex justify-content-between align-items-center">
          <p className="header_text__18">
            What if the contact didn't respond to the bot's questions
          </p>
          <label className={"switch"}>
            <input
              type="checkbox"
              checked={getValue(request, `idle_chat`, false)}
              onChange={() =>
                setRequest({
                  ...request,
                  idle_chat: !getValue(request, `idle_chat`, false),
                })
              }
            />
            <span className={`${"slider"} ${`round`} d-flex`}></span>
          </label>
        </div>
        <p className="pending-task-commondescription mt-1">
          Configure follow-up actions if the bot did not receive a reply for the
          specified duration.
        </p>
      </div>
      <div className="mt-3">
        <h6 className="header_text__14">
          How long the bot can wait for reply?*
        </h6>
        <SearchToolTip
          placeholder="Choose a time"
          data={chatTimeList}
          label="label"
          value={getValue(request, `idle_chat_time`, "")}
          selectKey={"value"}
          name=""
          disabled={!getValue(request, `idle_chat`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              idle_chat_time: getValue(e, `value`, ""),
            })
          }
        />
      </div>
      <div className="mt-3">
        <InputRuleForm
          inputType="TEXTAREA"
          name="idle_chat_response"
          value={getValue(request, `idle_chat_response`, "")}
          placeholder="Enter a Message"
          label="What should the bot say to contact after the designated wait time?*"
          disabled={!getValue(request, `idle_chat`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              idle_chat_response: e.target.value,
            })
          }
        />
      </div>
    </div>
  );
};

export default BotBuilderStepTwo;

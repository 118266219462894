import { Link, useNavigate, useParams } from "react-router-dom";
import "./CommonSubHeader.scss";
import { getValue } from "@utils/lodash";
// import CustomStaticSearchableDropdown from "@components/Custom/CustomSearchableDropdown/static-search";
import BackSvgComponent from "assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { useState } from "react";
import { useStateContext } from "@context/profileProvider";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import HeaderTooltip from "@components/custom/Dropdown/HeaderTooltip";
import EditSvgComponent from "@assets/svg/edit";

export default function SettingsModuleSubHeader(props: any) {
  const {
    pipeline,
    options,
    handleOpenPopup,
    selectedPipeline,
    handleClickPipeline,
    isLoading,
  } = props;
  const mainParams = useParams();
  let main = window.location.pathname.split("/");
  let tab = main[main.length - 3];

  const { allModules } = useStateContext();
  const generateRequestArray = (array: any) => {
    let list = array.filter(
      (item: object) =>
        // getValue(item, `api_name`, "") !== "tasks" &&
        getValue(item, `api_name`, "") !== "dashboard"
    );

    return getValue(list, `length`, 0) > 0
      ? list.map((item: any) => ({
          ...item,
          value: getValue(item, `api_name`, ""),
          label: getValue(item, `linkText`, ""),
        }))
      : [];
  };
  const navigate = useNavigate();
  const handleChange = (value: any) => {
    navigate(
      `/${getValue(
        value,
        `orgID`,
        ""
      )}/settings/module-and-field/manage-fields/fields/${getValue(
        value,
        `id`,
        ""
      )}/${getValue(value, `name`, "")}`
    );
  };
  return (
    <div className={"common-subheader"}>
      <div className={"common-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Link to={props.linkAddress}>
              {/* <img src="/images/icons/close.svg" className="img-fluid" /> */}
              <BackSvgComponent />
            </Link>
            {/* <h6 className={`${"common-subheader-wrapper__submenu-name"} mx-3`}>
              {props.subHeaderTitle}
            </h6> */}
            <TextHeaderTooltip
              data={generateRequestArray(allModules)}
              label={"label"}
              title={getValue(props, `moduleInfo.label`, "")}
              value={getValue(props, `moduleInfo.api_name`, "")}
              onChange={handleChange}
              width={"270px"}
              selectKey={"value"}
              editInline
            />
            {props.isTitleVisible && (
              <>
                <div>
                  <img
                    src="/images/header/right-arrow.svg"
                    className="img-fluid"
                  />
                </div>
                <p className={"Service-text"}>Service</p>
              </>
            )}

            {pipeline ? (
              <SearchToolTip
                label={"label"}
                value={getValue(selectedPipeline, `id`, "")}
                onChange={handleClickPipeline}
                width={"280px"}
                data={options}
                button_title={`Create ${getValue(
                  props,
                  `moduleInfo.pipeline_label_singular`,
                  ""
                )}`}
                handleOpenPopup={props.togglePipeline}
                isLoading={isLoading}
                add
              />
            ) : (
              <div style={{ height: "40px" }}></div>
            )}
          </div>
          {tab !== "stages" && (
            <div className="d-flex align-items-center">
              {props.isTitleVisible && (
                <button className={"preview-btn"}>Preview</button>
              )}
              {props.isSubmitVisible ? (
                <ButtonComponent buttonType="primary" buttonText="Submit" />
              ) : (
                props.isSubmitVisible && (
                  <ButtonComponent
                    buttonType="primary"
                    buttonText={
                      getValue(props, `submitLoading`, false)
                        ? "Please wait..."
                        : "Save"
                    }
                    onClickHandler={props.handleSubmit}
                    disabled={
                      getValue(props, `submitLoading`, false) ? true : false
                    }
                  />
                )
              )}
            </div>
          )}
          {/* {tab == "stages" && (
            <ButtonComponent
              style={{ border: "1px solid white" }}
            ></ButtonComponent>
          )} */}

          {getValue(props, `pipeline`, false) && (
            <div
              className=" d-flex align-items-center border border-radius__5 p-2 cursor-pointer"
              style={{ right: 10 }}
              onClick={props.toggle}
            >
              <EditSvgComponent color={"#408dfb"} />
              <p className="small_text__14 color_primary ms-2">Edit Info</p>
            </div>
          )}
          {/* <div className="" onClick={props.toggle}>
            <ButtonComponent
              buttonType={"secondary"}
              buttonText={"Edit Info"}
              onClickHandler={() => {
                props.toggle();
              }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

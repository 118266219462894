import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";

const TimeSlotPopup = (props: any) => {
  const { request, setRequest, checkoutSlots, checkinSlots } = props;
  return (
    <div className="subpipeline-wrapper px-5 py-4">
      <div className="d-flex flex-column gap-3 mb-4">
        <div>
          <SearchToolTip
            label="label"
            data={checkinSlots}
            value={getValue(request, `checkin`, "")}
            onChange={(e: any) => {
              setRequest({
                ...request,
                checkin: getValue(e, `value`, ""),
                checkout: "",
              });
              props.handleCheckinSlotChange(getValue(e, `value`, ""));
            }}
            selectKey={"value"}
            placeholder="Choose Checkin time"
            name="Checkin Time"
            // required
          />
        </div>
        <div>
          <SearchToolTip
            label="label"
            data={checkoutSlots}
            value={getValue(request, `checkout`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                checkout: getValue(e, `value`, ""),
              })
            }
            selectKey={"value"}
            placeholder="Choose Checkout Time"
            name="Checkout Time"
            // required
          />
        </div>
        <div>
          <SearchToolTip
            label="label"
            data={[{ value: "24_hrs", label: "24 Hours" }]}
            value={getValue(request, `slot_type`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                slot_type: getValue(e, `value`, ""),
              })
            }
            selectKey={"value"}
            placeholder="Choose Slot Type"
            name="Slot Type"
            // required
          />
        </div>
      </div>
    </div>
  );
};

export default TimeSlotPopup;

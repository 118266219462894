import { config } from "../../../env";
import { Delete, get, patch, post } from "../../helpers/http-handler";

export const getTimeSlotSettings = (queryRequest: string) =>
  get(`${config.API_URL}/settings/time-slot?${queryRequest}`);

export const getSpecificTimeSlotSettings = (id: string) =>
  get(`${config.API_URL}/settings/time-slot/${id}`);

export const createTimeSlotSettings = (payload: object) =>
  post(`${config.API_URL}/settings/time-slot`, payload);

export const updateTimeSlotSettings = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/time-slot/${id}`, payload);

import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import React from "react";

function CheckinOutForm(props: any) {
  const { request, setRequest, checkinSlots, checkoutSlots } = props;
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="mt-2 mx-1" style={{ width: props.width }}>
          <InputRuleForm
            inputType="DATE"
            value={getValue(request, `checkin_date`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                checkin_date: e,
                checkout_date: null,
                checkout_time: null,
              })
            }
            minDate={new Date()}
            label="Checkin"
            placeholder="Select Checkin Date"
          />
        </div>
        <div className="mx-1">
          <SearchToolTip
            placeholder="Choose time"
            className="pt-2"
            name="Time"
            value={getValue(request, `checkin_time`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                checkin_time: getValue(e, `value`, ""),
              })
            }
            data={checkinSlots}
            selectKey={"value"}
            label="label"
            width={props.width}
          />
        </div>
        <div className="mt-2 mx-1" style={{ width: props.width }}>
          <InputRuleForm
            inputType="DATE"
            value={getValue(request, `checkout_date`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                checkout_date: e,
              })
            }
            minDate={getValue(request, `checkin_date`, "")}
            label="Checkout"
            placeholder="Select Checkout Date"
          />
        </div>
        <div className="mx-1">
          <SearchToolTip
            placeholder="Choose time"
            className="pt-2"
            value={getValue(request, `checkout_time`, "")}
            name="Time"
            onChange={(e: any) =>
              setRequest({
                ...request,
                checkout_time: getValue(e, `value`, ""),
              })
            }
            data={checkoutSlots}
            selectKey={"value"}
            label="label"
            width={props.width}
          />
        </div>
      </div>
    </div>
  );
}

export default CheckinOutForm;

import InfoSvgComponent from "@assets/svg/info";
import { convertCurrentDate, convertDate } from "@common/date-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import ButtonComponent from "@components/Form/Button/Button";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import InputRuleForm from "@components/InputRuleForm/form";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import PropertySettingsLayout from "@layouts/PropertyLayout/property-settings-layout";
import {
  createBillingConfigSettings,
  getBillingConfigSettings,
  updateBillingConfigSettings,
} from "@services/properties/settings/billing-config.service";
import { getValue } from "@utils/lodash";
import { Checkbox } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { toast } from "sonner";

const InvoiceConfiguration = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
    }
  }, []);

  const initialState = {
    id: "",
    business_legal_name: "",
    business_trade_name: "",
    is_gst_registered: true,
    gst_registered_on: "",
    gstin_no: "",
    enable_composition_scheme: true,
    enable_reverse_charge: true,
    // city: "",
    // state: "",
    // country: "",
    // address: "",
    // pincode: 1,
    property_id: getValue(params, `id`, ""),
  };
  const [request, setRequest] = useState(initialState);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getBillingConfigSettings(getValue(params, `id`, ""));
      if (resp) {
        if (getValue(resp, `data.id`, "")) {
          setRequest({
            ...request,
            business_legal_name: getValue(resp, `data.business_legal_name`, ""),
            business_trade_name: getValue(resp, `data.business_trade_name`, ""),
            is_gst_registered: getValue(resp, `data.is_gst_registered`, false),
            gst_registered_on: getValue(resp, `data.gst_registered_on`, ""),
            gstin_no: getValue(resp, `data.gstin_no`, ""),
            enable_composition_scheme: getValue(
              resp,
              `data.enable_composition_scheme`,
              false
            ),
            enable_reverse_charge: getValue(
              resp,
              `data.enable_reverse_charge`,
              false
            ),
            // city: getValue(resp, `data.city`, ""),
            // state: getValue(resp, `data.state`, ""),
            // country: getValue(resp, `data.country`, ""),
            // address: getValue(resp, `data.address`, ""),
            // pincode: getValue(resp, `data.pincode`, ""),
            id: getValue(resp, `data.id`, ""),
          });
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp;

      let payload = JSON.parse(JSON.stringify(request));
      payload["gst_registered_on"] = convertDate(
        getValue(request, `gst_registered_on`, "")
      );

      if (getValue(request, `id`, "")) {
        resp = await updateBillingConfigSettings(
          getValue(request, `id`, ""),
          removeNullOrUndefinedProperties(payload)
        );
      } else {
        resp = await createBillingConfigSettings(
          removeNullOrUndefinedProperties(payload)
        );
      }
      if (resp) {
        toast.success(
          getValue(params, `id`, "")
            ? "Updated successfully"
            : "Created successfully"
        );
        getData();
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  return (
    <div>
      <PropertyDetailsLayout>
        <PropertySettingsLayout>
          <div className=" w-100 overflow-y-auto">
            <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
              <h3 className="header_text__20">GST settings</h3>
              <ButtonComponent
                buttonType={"primary"}
                buttonText={submitLoading ? "Please wait..." : "Save"}
                onClickHandler={() => {
                  handleSubmit();
                }}
                disabled={submitLoading}
              />
            </div>
          </div>
          <div>
            <div className=" form-label d-flex align-items-center my-3 gap-4">
              <span>Is your business registered for GST?</span>
              <label className={"switch mt-2"}>
                <input
                  type="checkbox"
                  checked={getValue(request, `is_gst_registered`, "")}
                  onChange={() =>
                    setRequest({
                      ...request,
                      is_gst_registered: !getValue(
                        request,
                        `is_gst_registered`,
                        false
                      ),
                    })
                  }
                />
                <span className={`${"slider"} ${`round`} d-flex`}></span>
              </label>
            </div>
            <div className="mt-5">
              <div className="row">
                <div className="col-3 form-label d-flex align-items-center gap-2">
                  GSTIN
                  <div
                    data-tooltip-id="gst"
                    data-tooltip-content="Goods and Services Tax Identification Number for taxation purposes"
                  >
                    <InfoSvgComponent size="18" color="#f1bb00" />
                    <Tooltip id="gst" className="tooltip" />
                  </div>{" "}
                  <p className="small_text__12">(Maximum 15 digits)</p>
                </div>
                <div className="col-4">
                  {" "}
                  <InputRuleForm
                    inputType="TEXT"
                    name="gstin_no"
                    value={getValue(request, `gstin_no`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        gstin_no: e.target.value,
                      })
                    }
                    placeholder={`Enter GSTIN number`}
                    hideFormLabel
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 form-label d-flex align-items-center gap-2">
                  Business legal name
                </div>
                <div className="col-4">
                  {" "}
                  <InputRuleForm
                    inputType="TEXT"
                    name="business_legal_name"
                    value={getValue(request, `business_legal_name`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        business_legal_name: e.target.value,
                      })
                    }
                    placeholder={`Enter Business Legal Name`}
                    hideFormLabel
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 form-label d-flex align-items-center gap-2">
                  Business trade name
                </div>
                <div className="col-4">
                  {" "}
                  <InputRuleForm
                    inputType="TEXT"
                    value={getValue(request, `business_trade_name`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        business_trade_name: e.target.value,
                      })
                    }
                    placeholder={`Enter Business Trade Name`}
                    hideFormLabel
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 form-label d-flex align-items-center gap-2">
                  GST Registered on
                </div>
                <div className="col-4">
                  <InputRuleForm
                    inputType="DATE"
                    placeholder="Select Registered Date"
                    value={getValue(request, `gst_registered_on`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        gst_registered_on: e,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 form-label d-flex align-items-center gap-2">
                  Composition scheme
                </div>
                <div className="col-4">
                  <div className="email-checkbox  align-items-center gap-2">
                    <Checkbox
                      checked={getValue(
                        request,
                        `enable_composition_scheme`,
                        false
                      )}
                      onChange={() =>
                        setRequest({
                          ...request,
                          enable_composition_scheme: !getValue(
                            request,
                            `enable_composition_scheme`,
                            false
                          ),
                        })
                      }
                    />
                    <p className="form-label">
                      My business is registered for composition scheme
                    </p>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 form-label d-flex align-items-center gap-2">
                  Reverse charge
                </div>
                <div className="col-4">
                  <div className="email-checkbox  align-items-center gap-2">
                    <Checkbox
                      checked={getValue(
                        request,
                        `enable_reverse_charge`,
                        false
                      )}
                      onChange={() =>
                        setRequest({
                          ...request,
                          enable_reverse_charge: !getValue(
                            request,
                            `enable_reverse_charge`,
                            false
                          ),
                        })
                      }
                    />
                    <p className="form-label">
                      Enable reverse charge in sales transaction
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PropertySettingsLayout>
      </PropertyDetailsLayout>
    </div>
  );
};

export default InvoiceConfiguration;

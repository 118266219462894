import { getValue } from "@utils/lodash";
import React from "react";

function SortSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "19"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "19"
        }
        viewBox="0 -960 960 960"
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#1a73e8"
        }
      >
        <path d="M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80ZM600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z" />
      </svg>
    </div>
  );
}

export default SortSvgComponent;

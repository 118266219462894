import React, { useState } from "react";
import { Button, ColorPicker, Modal } from "antd";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";

function SettingModuleEditPopup(props: any) {
  const { request, setRequest } = props;
  const [color, setColor] = useState(
    getValue(request, "color_code", "#1677ff")
  );

  const handleColorChange = (colorObj: any) => {
    const selectedColor = colorObj.toHexString();
    setColor(selectedColor);
    setRequest({ ...request, color_code: selectedColor });
  };

  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={() => {
        props.handleSubmit();
      }}
      onCancel={() => {
        props.handleCloseModal();
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            props.handleCloseModal();
          }}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            props.handleSubmit();
          }}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      <h6 className="header_text__16">{`${
        props.editId ? "Update" : "Add"
      } Status`}</h6>
      <div
        className="popup-container_create_stage px-5 mb-3 mt-1"
        style={{ minHeight: "20vh" }}
      >
        <InputRuleForm
          inputType="TEXTAREA"
          label={"Name"}
          placeholder="Enter"
          name={"label"}
          value={getValue(request, `label`, "")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          rows={3}
        />

        {/* Ant Design ColorPicker */}
        <ColorPicker
          value={getValue(request, "color_code", "")} // Set the current color
          size="large"
          showText
          onChange={handleColorChange}
        />
      </div>
    </Modal>
  );
}

export default SettingModuleEditPopup;

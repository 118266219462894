import {
  camelToSnake,
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import { getValue, setValue } from "@utils/lodash";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useDraggableInPortal from "@common/useDraggableInPortal";
import Switch from "@components/Form/Switch/Switch";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import AddSvgComponent from "@assets/svg/add";
import { UpdatePipelineStagePositions } from "@services/pipeline.service";
import { useParams } from "react-router-dom";

function StageList(props: any) {
  const {
    handleEditStage,
    editId,
    payload,
    setPayload,
    isLoading,
    isChanged,
    handleUpdateStage,
  } = props;
  const params = useParams();
  const renderDraggable = useDraggableInPortal();
  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // Moving from same area in top to botomm direction
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "source") {
        const sourceOpenArrayCopy: any = [
          ...getValue(props, `stageRequest[${props.name}]`, []),
        ];
        const [removed] = sourceOpenArrayCopy.splice(source.index, 1);
        sourceOpenArrayCopy.splice(destination.index, 0, removed);
        // setValue(props, `stageRequest[${props.name}]`, sourceOpenArrayCopy);
        // props.setStageRequest({ ...props.stageRequest });
        let list = sourceOpenArrayCopy.map((item: object, index: number) => ({
          ...item,
          seq_num: index + 1,
        }));
        props.setStageRequest({
          ...props.stageRequest,
          [props.name]: removeNullOrUndefinedProperties(list),
        });
        let resp = await UpdatePipelineStagePositions(
          getValue(params, `id`, ""),
          getValue(props, `selectedPipeline`, ""),
          { stages: list }
        );
        if (resp) {
          props.getData(props.selectedPipeline);
        }
      }
    }
  };
  return (
    <div>
      {getValue(props, `list.length`, 0) > 0 && (
        <div>
          <div className="d-flex justify-content-between m-2 align-items-center">
            <h6 className={"header_text__18"}>
              {`${getValue(props, `title`, "")}`}
            </h6>
            <div
              className="d-flex align-items-center cursor-pointer"
              onClick={() => props.handleAddStage(props.type)}
            >
              <AddSvgComponent color={"#1a73e8"} />
              <h6 className={"header_blue_text__16 ms-2"}>
                Add {getValue(props, `title`, "")}
              </h6>
            </div>
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="source">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="w-100"
                >
                  {sortJSONObjectArray(
                    getValue(props, `list`, []),
                    "seq_num"
                  ).map((obj: object, index: number) => {
                    return (
                      <Draggable
                        key={index}
                        draggableId={`${index}open`}
                        index={index}
                        // isDragDisabled={!index}
                      >
                        {renderDraggable((provided: any) => (
                          <div
                            className={`d-flex align-items-center `}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              key={index}
                              className={`status-container d-flex align-items-center w-75`}
                              onClick={() =>
                                handleEditStage(
                                  obj,
                                  getValue(props, `name`, ""),
                                  props.type
                                )
                              }
                              // style={{
                              //   border:
                              //     editId === getValue(obj, `id`, "")
                              //       ? "1px solid #1a73e8"
                              //       : "1px solid #dadce0",
                              //   background:
                              //     editId === getValue(obj, `id`, "")
                              //       ? "white"
                              //       : "#f5f8fa",
                              // }}
                            >
                              <div className="ms-3">
                                <DragIndicatorSvgComponent
                                  color={"lightgray"}
                                />
                              </div>
                              <div
                                className={`status-container__code d-flex align-items-center justify-content-between w-100`}
                              >
                                <div className="d-flex align-items-center gap">
                                  <div
                                    className={`${"box"} ${`box--light-blue`} `}
                                    style={{
                                      background: getValue(
                                        obj,
                                        `color_code`,
                                        ""
                                      ),
                                    }}
                                  ></div>
                                  {/* {editId === getValue(obj, `id`, "") ? (
                                    <input
                                      autoFocus
                                      className={"stage-input"}
                                      value={getValue(payload, `label`, "")}
                                      onChange={(e) => {
                                        setPayload({
                                          ...payload,
                                          label: e.target.value,
                                          name: camelToSnake(e.target.value),
                                        });
                                        props.setStageRequest((x: any) => {
                                          return {
                                            ...x,
                                            [getValue(props, `name`, "")]: x[
                                              getValue(props, `name`, "")
                                            ].map(
                                              (subItem: any, subIndex: any) => {
                                                if (subIndex !== index)
                                                  return subItem;
                                                return {
                                                  ...subItem,
                                                  label: e.target.value,
                                                  name: camelToSnake(
                                                    e.target.value
                                                  ),
                                                };
                                              }
                                            ),
                                          };
                                        });
                                      }}
                                    />
                                  ) : ( */}
                                  <span>{getValue(obj, `label`, "")}</span>
                                  {/* )} */}
                                </div>
                              </div>
                              <label className={"switch mx-4"}>
                                <input
                                  type="checkbox"
                                  checked={!getValue(obj, `archived`, false)}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    props.handleUpdateStageStatus(obj);
                                  }}
                                />
                                <span
                                  className={`${"slider"} ${`round`} d-flex`}
                                ></span>
                              </label>
                            </div>

                            {!isChanged &&
                            getValue(obj, `name`, "") &&
                            editId === getValue(obj, `id`, "") ? (
                              <button
                                className={"main_button__primary"}
                                onClick={() =>
                                  handleUpdateStage(getValue(obj, `id`, ""))
                                }
                                disabled={isLoading}
                              >
                                {isLoading ? "Loading..." : "Update"}
                              </button>
                            ) : null}
                          </div>
                        ))}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
}

export default StageList;

import ContactPipelineDetails from "@components/Pages/Pipeline/Detail/contacts";
import Contacts from "@pages/Private/CRM/Contacts";

export const CrmContactsRoutes = [
  {
    path: "/:orgId/crm/contacts",
    name: "crm_contacts",
    component: Contacts,
  },
  {
    path: "/:orgId/crm",
    name: "crm_contacts",
    component: Contacts,
  },
  {
    path: "/:orgId/crm/contacts/detail/:id",
    name: "crm_contacts",
    component: ContactPipelineDetails,
  },
];

import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React, { useEffect, useRef, useState } from "react";
import WhatsappIconViewPage from "../components/common/whatsapp-icon";
import { getValue } from "@utils/lodash";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import {
  createWhatsappTemplate,
  getSpecificWhatsappTemplate,
} from "@services/fb.service";
import { toast } from "sonner";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useStateContext } from "@context/profileProvider";
import { QueryRequestHelper } from "@common/query-request-helper";
import { listAllPipelines } from "@services/pipeline.service";
import { getAllAvailableTemplateTags } from "@services/email-config.service";
import { mediaUpload } from "@services/upload.service";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import convertMarkdownToHtml from "../../../../common/markdown/useMarkdown";
import draftToHtmlPuri from "draftjs-to-html";
import AddWhatsappVariable from "../components/template-messages/add-variable-popup";
import BackSvgComponent from "@assets/svg/back-link";
import parse from "html-to-md";
import { Button, Dropdown, MenuProps, message, Modal } from "antd";
import { LinkOutlined, PhoneOutlined, SendOutlined } from "@ant-design/icons";
import TemplateCarousel from "./components/template-carousel";
import TemplateFooter from "./components/template-footer";
import TemplateBody from "./components/template-body";
import TemplateHeader from "./components/template-header";
import { createAIRephrase } from "@services/ai.service";
import RephraseModal from "./components/AIRephrase/RephraseModal";

const CreateTemplateMessage = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const [editId, setEditId] = useState(getValue(params, `id`, ""));
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const intialState = {
    id: "",
    moduleId: "",
    pipelineId: "",
    name: "",
    category: "MARKETING",
    language: "en",
    header: {
      type: "",
      text: "",
      link: "",
    },
    body: "<p></p>\n",
    footer: "",
    buttonType: "",
    customTags: [],
    buttons: [],
    status: "",
    isActive: true,
    carousel: [
      {
        header: {
          type: "IMAGE",
          link: "",
        },
        body: "<p></p>\n",
        buttons: [{ action_type: "" }, { action_type: "" }],
      },
    ],
  };
  const initialAiText = {
    key: "",
    text: "",
  };
  const [aiRequest, setAiRequest] = useState(initialAiText);
  const [isRephraseLoading, setIsRephraseLoading] = useState(false);
  const aiRephraseMethods = [
    "Rephrase",
    "Correct Grammar",
    "Change Tone",
    "Empathize",
    "Summarize",
    "Expand",
    "Simplify",
    "Add Examples",
    "Translate",
    "Adjust Politeness",
    "Highlight Key Points",
    "Personalize",
    "Align with Brand Voice",
    "Remove Jargon",
  ];
  const [aiRephrasedText, setAiRephrasedText] = useState("");
  const [previousEditorState, setPreviousEditorState] = useState("");
  const [openAiModal, setOpenAiModal] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState<any>(intialState);
  const resetState = () => {
    setEditId("");
    setErrorMessage("");
    setRequest(intialState);
    setAiRequest(initialAiText);
    // setRequest({ ...request });
  };
  const [templateData, settemplateData] = useState<any>([]);
  /* -------------------------------------------------------------------------- */
  /*                             UseEffect Section                              */
  /* -------------------------------------------------------------------------- */

  const { selectedModuleId } = useStateContext();
  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (!editId) {
      resetState();
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      getData();
    }
  }, [editId]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificWhatsappTemplate(getValue(params, `id`, ""));
      if (resp) {
        let buttons =
          getValue(resp, `data.buttons.length`, 0) > 0
            ? getValue(resp, `data.buttons`, []).map((item: object) => ({
                ...item,
                type: getValue(item, `action_type`, ""),
                action_type: getValue(item, `type`, ""),
              }))
            : [];
        let customTagsList = getValue(resp, `data.tags`, []).map(
          (item: object) => ({
            type: getValue(item, `type`, ""),
            sample: getValue(item, `sample`, ""),
            fieldId: getValue(item, `module_field_id`, ""),
            placeholder: `[[${getValue(item, `module_field.api_name`, "")}]]`,
          })
        );
        settemplateData(getValue(resp, `data`, ""));
        setRequest({
          ...request,
          id: getValue(resp, `data.id`, ""),
          moduleId: getValue(resp, `data.module_id`, ""),
          pipelineId: getValue(resp, `data.pipeline_id`, ""),
          name: getValue(resp, `data.name`, ""),
          category:
            getValue(resp, `data.carousels.length`, 0) > 0
              ? "CAROUSEL"
              : getValue(resp, `data.category`, ""),
          language: getValue(resp, `data.language`, ""),
          header: {
            type: getValue(resp, `data.header_type`, ""),
            text: getValue(resp, `data.header_link`, ""),
            link: getValue(resp, `data.header_file`, ""),
          },
          body: replaceDefaultPlaceholders(
            getValue(resp, `data.body`, ""),
            allTags
          ),
          footer: getValue(resp, `data.footer`, ""),
          customTags: customTagsList,
          buttons: buttons,
          // isActive: true,
          status: getValue(resp, `data.status`, ""),
          carousel: getCarouselPreview(getValue(resp, `data.carousels`, "")),
        });
        setIsLoading(false);
        // handlePreview(editId);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [allTags, setAllTags] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [pipelineId, setPipelineId] = useState("");
  const [allPipelinesList, setAllPipelineList] = useState([]);
  const getTags = async () => {
    try {
      let allPipelines = await listAllPipelines(
        await selectedModuleId("contacts"),
        ""
      );
      setModuleId(await selectedModuleId("contacts"));
      setPipelineId(getValue(allPipelines, `data.[${0}].id`, ""));
      let list = getValue(allPipelines, `data`, []).map((item: object) => ({
        ...item,
        value: getValue(item, `id`, ""),
        label: getValue(item, `label`, ""),
      }));
      setAllPipelineList(list);
      tagsByPipelineID(getValue(allPipelines, `data.[${0}].id`, ""));
    } catch (error) {}
  };

  const tagsByPipelineID = async (id: string) => {
    setPipelineId(id);
    try {
      let payload = {
        pipelineId: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAvailableTemplateTags(
        await selectedModuleId("contacts"),
        queryRequest
      );
      if (resp) {
        setAllTags(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", getValue(file, `name`, ""));
    formData.append("is_public", "true");
    formData.append("collection", "public-images");
    let resp = await mediaUpload(formData);
    if (resp) {
      setRequest({
        ...request,
        header: {
          ...request.header,
          link: getValue(resp, `data`, {}),
        },
      });
    }

    if (!file) {
      console.error("No file selected");
      return;
    }
  };

  const ConvertedComponent = (data: any) => {
    if (getValue(data, `length`, 0) > 0) {
      const convertedData = data.map((item: any, index: number) => {
        if (item.action_type === "CALL_TO_ACTION") {
          return {
            type: "CALL_TO_ACTION",
            callAction: [
              {
                type: item.type === "url" ? "url" : "call",
                text: item.text,
                url: item.url,
                phone: item.phone,
                index: index,
              },
            ],
          };
        } else if (item.action_type === "URL") {
          return {
            type: "CALL_TO_ACTION",
            callAction: [
              {
                type: item.type === "url" ? "url" : "call",
                text: item.text,
                url: item.url,
                phone: item.phone,
                index: index,
              },
            ],
          };
        } else if (item.action_type === "QUICK_REPLY") {
          return {
            type: "QUICK_REPLY",
            quickReply: [
              {
                text: item.text,
                index: index,
              },
            ],
          };
        }
      });
      return convertedData.filter((item: object) => item);
    } else {
      return [];
    }
  };

  const handleNavigateHome = async () => {
    let payload: any = {
      ...UrlParams,
      page_no: 1,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const newfun = (arr1: any, arr2: any, arr3: any) => {
    const arr3Dict = arr3.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});
    const newResult = arr1
      .map((item: any) => {
        const placeholderName = item.placeholder
          .replace("[[", "")
          .replace("]]", "");

        if (arr3Dict[placeholderName]) {
          const arr2Item = arr2.find(
            (x: any) => x.placeholder === item.placeholder
          );
          const fieldId = arr2Item ? arr2Item.fieldId : "";
          const sample = arr2Item ? arr2Item.sample : "";
          const sampleInfo = getValue(findInfo, `tags`, []).find(
            (item: object) => getValue(item, `module_field_id`, "") === fieldId
          );
          return {
            type: item.type,
            label: arr3Dict[placeholderName].label,
            name: arr3Dict[placeholderName].name,
            sample: sample ? sample : getValue(sampleInfo, `sample`, ""),
            placeholder: item.placeholder,
            fieldId: fieldId
              ? fieldId
              : arr3Dict[placeholderName].fieldId
              ? arr3Dict[placeholderName].fieldId
              : getValue(sampleInfo, `module_field_id`, ""),
          };
        }
        return null;
      })
      .filter((item: any) => item !== null);

    return newResult;
  };

  const handleAddTypeButton = (name: string, type: String) => {
    const buttonArray = getValue(request, `buttons`, []);
    if (buttonArray.length < 3) {
      if (name === "QUICK_REPLY") {
        let obj = { text: "", action_type: name };
        buttonArray.push(obj);
        setRequest({ ...request });
      } else {
        let obj = {
          action_type: name,
          type: type,
          text: "",
          url: "",
          phone: "",
        };
        buttonArray.push(obj);
        setRequest({ ...request });
      }
    } else {
      toast.error("you have reached maximum limits");
    }
  };

  const handleRemoveTypeButton = (index: number) => {
    let filtered = getValue(request, `buttons`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      buttons: filtered,
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                          Template Modal section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenTemplate, setIsOpenTemplate] = useState(false);
  const handleOpenTempalte = () => {
    setIsOpenTemplate(!isOpenTemplate);
  };
  /* -------------------------------------------------------------------------- */
  /*                            Variable Modal section                          */
  /* -------------------------------------------------------------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const editorRef = useRef<any>();

  const handleAddTags = (item: any) => {
    const currentBody = getValue(request, `body`, ""); // Get current body content
    const name = getValue(item, `name`, ""); // Get the name from the item
    const updatedBody = currentBody.endsWith("\n")
      ? currentBody.slice(0, -1)
      : currentBody;

    let text: any = insertText(`[[${name}]]`);
    let finalText =
      text && draftToHtmlPuri(convertToRaw(text.getCurrentContent()));
    setRequest({
      ...request,
      body: finalText,
    });
    request.customTags.push({
      type: "body",
      sample: "",
      placeholder: `[[${getValue(item, `name`, "")}]]`,
      fieldId: getValue(item, `fieldId`, ""),
    });
  };

  const insertText = (value: any) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      value
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    return newEditorState;
  };

  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    // Assuming you're using a state variable called "editor" to store the editor state
    setEditorState(editorState);
    setRequest({
      ...request,
      body: value,
    });

    // Extract custom tags from the editor text
    const regex = /\[\[(.*?)\]\]/g;
    const matches = value.match(regex);
    if (matches) {
      const newCustomTags = matches.map((match: any) => ({
        type: "body",
        sample: "",
        placeholder: match,
        fieldId: "",
      }));
      let tags = newfun(newCustomTags, request.customTags, allTags);
      setRequest({
        ...request,
        body: value,
        customTags: tags,
      });
    } else {
      setRequest({
        ...request,
        body: value,
        customTags: [],
      });
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Helper section                             */
  /* -------------------------------------------------------------------------- */

  const replacePlaceholders = (text: any, data: any) => {
    if (text) {
      let replacedText = parse(text);
      let escapedMatch = String(replacedText).replace(/\\/g, "");
      data.forEach((item: any) => {
        escapedMatch = escapedMatch.replace(
          `[[${item.name}]]`,
          `[[${item.label}]]`
        );
      });
      return escapedMatch;
    } else {
      return null;
    }
  };
  const replaceDefaultPlaceholders = (text: any, data: any) => {
    let replacedText = convertMarkdownToHtml(text);
    data.forEach((item: any) => {
      replacedText = replacedText.replace(item.placeHolder, `[[${item.name}]]`);
    });
    return replacedText;
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Preview section                            */
  /* -------------------------------------------------------------------------- */

  const [errorMessage, setErrorMessage] = useState("");
  const [findInfo, setFindInfo] = useState({});

  // const handlePreview = async () => {
  //   setFindInfo(templateData);
  //   let buttons =
  //     getValue(findInfo, `buttons.length`, 0) > 0
  //       ? getValue(findInfo, `buttons`, []).map((item: object) => ({
  //           ...item,
  //           type: getValue(item, `action_type`, ""),
  //           action_type: getValue(item, `type`, ""),
  //         }))
  //       : [];
  //   let customTagsList = getValue(findInfo, `tags`, []).map((item: object) => ({
  //     type: getValue(item, `type`, ""),
  //     sample: getValue(item, `sample`, ""),
  //     fieldId: getValue(item, `module_field_id`, ""),
  //     placeholder: `[[${getValue(item, `module_field.api_name`, "")}]]`,
  //   }));
  //   setRequest({
  //     ...request,
  //     id: getValue(findInfo, `id`, ""),
  //     moduleId: getValue(findInfo, `module_id`, ""),
  //     pipelineId: getValue(findInfo, `pipeline_id`, ""),
  //     name: getValue(findInfo, `name`, ""),
  //     category:
  //       getValue(findInfo, `carousels.length`, 0) > 0
  //         ? "CAROUSEL"
  //         : getValue(findInfo, `category`, ""),
  //     language: getValue(findInfo, `language`, ""),
  //     header: {
  //       type: getValue(findInfo, `header_type`, ""),
  //       text: getValue(findInfo, `header_link`, ""),
  //       link: getValue(findInfo, `header_file`, ""),
  //     },
  //     body: replaceDefaultPlaceholders(getValue(findInfo, `body`, ""), allTags),
  //     footer: getValue(findInfo, `footer`, ""),
  //     customTags: customTagsList,
  //     buttons: buttons,
  //     isActive: true,
  //     status: getValue(findInfo, `status`, ""),
  //     carousel: getCarouselPreview(getValue(findInfo, `carousels`, "")),
  //   });
  // };

  const getCarouselPreview = (carousels: any) => {
    let carousel =
      getValue(carousels, `length`, 0) > 0
        ? carousels.map((item: object) => ({
            header: {
              type: getValue(item, `header_type`, ""),
              link: getValue(item, `header_file.key`, ""),
            },
            body: getValue(item, `body`, ""),
            buttons: convertCarouselButtons(getValue(item, `buttons`, [])),
          }))
        : [];
    return carousel;
  };

  const convertCarouselButtons = (buttons: any) => {
    let button =
      getValue(buttons, `length`, 0) > 0
        ? buttons.map((item: object) => ({
            ...item,
            action_type: getValue(item, `type`, ""),
            type: getValue(item, `phone`, "") ? "call" : "url",
          }))
        : [];
    return button;
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Submit section                             */
  /* -------------------------------------------------------------------------- */
  const getCarouselPayload = () => {
    let carousel =
      getValue(request, `carousel.length`, 0) > 0
        ? getValue(request, `carousel`, []).map((item: object) => ({
            header: {
              type: getValue(item, `header.type`, ""),
              text: getValue(item, `header.text`, ""),
              link: getValue(item, `header.link.id`, "")
                ? getValue(item, `header.link.id`, "")
                : getValue(item, `header.link`, ""),
            },
            body: getValue(item, `body`, ""),
            buttons: ConvertedComponent(getValue(item, `buttons`, [])),
          }))
        : [];
    return carousel;
  };
  const convertPayload = () => {
    let buttons = ConvertedComponent(getValue(request, `buttons`, []));
    let payload = JSON.parse(JSON.stringify(request));
    let obj;
    if (getValue(request, `category`, "") === "CAROUSEL") {
      obj = {
        id: getValue(payload, `id`, ""),
        moduleId: moduleId,
        pipelineId: pipelineId,
        category: "MARKETING",
        name: getValue(payload, `name`, ""),
        language: getValue(payload, `language`, ""),
        body: replacePlaceholders(
          getValue(payload, `body`, ""),
          payload.customTags
        ),
        customTags:
          getValue(request, `customTags.length`, 0) > 0
            ? getValue(request, `customTags`, []).map((item: object) => ({
                type: getValue(item, `type`, ""),
                sample: getValue(item, `sample`, ""),
                fieldId: getValue(item, `fieldId`, ""),
              }))
            : [],
        isActive: true,
        carousel: getCarouselPayload(),
      };
    } else {
      obj = {
        id: getValue(payload, `id`, ""),
        moduleId: moduleId,
        pipelineId: pipelineId,
        category: getValue(payload, `category`, ""),
        name: getValue(payload, `name`, ""),
        language: getValue(payload, `language`, ""),
        body: replacePlaceholders(
          getValue(payload, `body`, ""),
          payload.customTags
        ),
        customTags:
          getValue(request, `customTags.length`, 0) > 0
            ? getValue(request, `customTags`, []).map((item: object) => ({
                type: getValue(item, `type`, ""),
                sample: getValue(item, `sample`, ""),
                fieldId: getValue(item, `fieldId`, ""),
              }))
            : [],
        isActive: true,
        header: {
          type: getValue(payload, `header.type`, ""),
          text: getValue(payload, `header.text`, ""),
          link: getValue(payload, `header.link.id`, "")
            ? getValue(payload, `header.link.id`, "")
            : getValue(payload, `header.link`, ""),
        },
        footer: getValue(payload, `footer`, ""),
        buttons: buttons,
      };
    }
    return removeNullOrUndefinedProperties(obj);
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp = await createWhatsappTemplate(
          removeNullOrUndefinedProperties(convertPayload())
        );
        if (resp) {
          handleOpenTempalte();
          resetState();
          getData();
          toast.success("Submitted successfully");
          handleNavigateHome();
          navigate(
            `/${getValue(params, `orgId`, "")}/broadcast/?activeTab=2&page_no=1`
          );

          hideValidations();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const hideValidations = () => {
    simpleValidator.current.fields = {};
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [selectedType, setButtonType] = useState("QUICK_REPLY");

  const handlChangeType = (
    i: number,
    childIndex: number,
    e: any,
    key: string,
    name: string,
    subName: string,
    setObject: any,
    type: string
  ) => {
    setObject((object: any) => {
      return {
        ...object,
        [key]: object[key].map((x: any, index: number) => {
          if (index !== i) return x;
          return {
            ...x,
            [name]: x[name].map((y: any, p_index: number) => {
              if (childIndex !== p_index) return y;
              return {
                ...y,
                [subName]: type,
              };
            }),
          };
        }),
      };
    });
  };

  const [activeCarouselTab, setActiveCarouselTab] = useState(1);
  const handleCarouselTab = (tab: number) => {
    setActiveCarouselTab(tab);
  };

  /* -------------------------------------------------------------------------- */
  /*                        Carousel Template Section                           */
  /* -------------------------------------------------------------------------- */
  const handleAddCarousel = () => {
    let obj = {
      header: {
        type: "IMAGE",
        link: "",
      },
      body: "",
      buttons: [{ action_type: "" }, { action_type: "" }],
    };
    getValue(request, `carousel`, []).push(obj);
    setRequest({ ...request });
  };

  const handleRemoveCarousel = (index: number) => {
    let filtered = getValue(request, `carousel`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      carousel: filtered,
    });
  };

  const handleCarouseFileChange = async (e: any, i: number) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", getValue(file, `name`, ""));
    formData.append("is_public", "true");
    formData.append("collection", "public-images");
    let resp = await mediaUpload(formData);
    if (resp) {
      setRequest((object: any) => {
        return {
          ...object,
          ["carousel"]: object["carousel"].map((x: any, index: number) => {
            if (index !== i) return x;
            return {
              ...x,
              header: {
                ...x.header,
                link: getValue(resp, "data", {}),
              },
            };
          }),
        };
      });
    }
    if (!file) {
      console.error("No file selected");
      return;
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Quick Reply",
      key: "QUICK_REPLY",
      icon: <SendOutlined />,
    },
    {
      label: "Call to Action",
      key: "CALL_TO_ACTION",
      icon: <PhoneOutlined />,
    },
    {
      label: "URL",
      key: "URL",
      icon: <LinkOutlined />,
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const selectedType = e.key;

    handleAddTypeButton(selectedType, selectedType === "URL" ? "url" : "call");
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(getValue(request, "buttons", []));
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setRequest((prev: any) => ({
      ...prev,
      buttons: newItems,
    }));
  };

  const handleAIRephraseSubmit = async (method: string) => {
    try {
      setIsRephraseLoading(true);
      let payload = {
        key: method,
        text: getValue(request, `body`, ""),
      };
      let resp = await createAIRephrase(payload);
      if (resp) {
        setAiRequest({
          ...aiRequest,
          text: resp.data,
        });
        setAiRephrasedText(resp.data);
      }
    } catch (error) {
      console.log("error", error);
      setIsRephraseLoading(false);
    }
    setIsRephraseLoading(false);
  };

  const toggleAiModal = (method: any) => {
    setOpenAiModal(!openAiModal);
    handleAIRephraseSubmit(method);
  };
  const applyAIContent = () => {
    if (aiRephrasedText) {
      setPreviousEditorState(getValue(request, "body", ""));
      setEditorState(editorState);
      setRequest({
        ...request,
        body: aiRephrasedText,
      });
      setAiRequest({
        ...aiRequest,
        text: "",
      });
    } else {
      toast.error("No content to apply");
    }
  };

  const revertToPreviousState = () => {
    if (previousEditorState) {
      setRequest({
        ...request,
        body: previousEditorState,
      });
    }
  };

  const aIItems: MenuProps["items"] = aiRephraseMethods.map(
    (method: any, index: any) => ({
      key: `${index + 1}`,
      label: method,
      onClick: () => handleAIRephraseSubmit(method),
      disabled: isRephraseLoading === true,
    })
  );

  return (
    <div>
      <HomeHeader />
      <div className="px-3 py-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    `orgId`,
                    ""
                  )}/broadcast?activeTab=2&page_no=1`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">
              {editId ? "Edit" : "Create"} template message
            </p>
          </div>
          <div>
            {getValue(request, `status`, "") === "APPROVED" ||
            getValue(request, `status`, "") === "REJECTED" ||
            getValue(request, `status`, "") === "PAUSED" ? (
              <div className="d-flex justify-content-end">
                <Button
                  size="large"
                  type="primary"
                  onClick={handleSubmit}
                  loading={submitLoading}
                >
                  Submit
                </Button>
              </div>
            ) : !editId ? (
              <div className="d-flex justify-content-end">
                <Button
                  size="large"
                  type="primary"
                  onClick={handleSubmit}
                  loading={submitLoading}
                >
                  Submit for approval
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="template-wrapper">
        <div className="template-container">
          <div className="template-form-container">
            <div className="template-editor-wrapper">
              <TemplateHeader
                request={request}
                setRequest={setRequest}
                editId={editId}
                errorMessage={errorMessage}
                simpleValidator={simpleValidator}
                handleFileChange={handleFileChange}
              />
              <TemplateBody
                request={request}
                setRequest={setRequest}
                handleModal={handleModal}
                handleChangeEditor={handleChangeEditor}
                toggleAiModal={toggleAiModal}
                aiRephraseMethods={aiRephraseMethods}
                isRephraseLoading={isRephraseLoading}
              />
              {getValue(request, `category`, "") === "CAROUSEL" ? (
                <TemplateCarousel
                  request={request}
                  setRequest={setRequest}
                  editId={editId}
                  activeCarouselTab={activeCarouselTab}
                  setActiveCarouselTab={setActiveCarouselTab}
                  handlChangeType={handlChangeType}
                  handleAddCarousel={handleAddCarousel}
                  handleCarouselTab={handleCarouselTab}
                  handleRemoveCarousel={handleRemoveCarousel}
                  handleCarouseFileChange={handleCarouseFileChange}
                />
              ) : (
                <TemplateFooter
                  params={params}
                  request={request}
                  setRequest={setRequest}
                  editId={editId}
                  menuProps={menuProps}
                  handleDragEnd={handleDragEnd}
                  handleRemoveTypeButton={handleRemoveTypeButton}
                />
              )}
            </div>
          </div>
          <WhatsappIconViewPage request={request} />
        </div>
      </div>
      <AddWhatsappVariable
        isOpen={isOpen}
        handleModal={handleModal}
        handleAddTags={handleAddTags}
        allTags={allTags}
        tagsByPipelineID={tagsByPipelineID}
        allPipelinesList={allPipelinesList}
        pipelineId={pipelineId}
      />
      <RephraseModal
        openAiModal={openAiModal}
        setOpenAiModal={setOpenAiModal}
        revertToPreviousState={revertToPreviousState}
        applyAIContent={applyAIContent}
        isRephraseLoading={isRephraseLoading}
        aIItems={aIItems}
        request={request}
        aiRequest={aiRequest}
        handleChangeEditor={handleChangeEditor}
      />
    </div>
  );
};

export default CreateTemplateMessage;

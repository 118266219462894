import StarSvgComponent from "@assets/svg/star";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import React from "react";

const AddRoomTypePopup = () => {
  return (
    <div className="subpipeline-wrapper px-5 py-4">
      <div className="grid-container  row-gap-3 column-gap-3  w-100 border-bottom pb-4">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          placeholder="Enter a room type"
          label="Room type"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter room type description"
          label="Property room type description"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter room no(s)-(comma seperated)"
          label="Room no(s)"
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value="1"
          placeholder="Enter default adults"
          label="Default adults"
          marginZero
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value="4"
          placeholder="Enter max adults"
          label="Max adults"
          marginZero
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value="1"
          placeholder="Enter max children"
          label="Max children"
          marginZero
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value="5"
          placeholder="Enter max room capacity"
          label="Max room capacity"
          marginZero
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value=""
          placeholder="Enter max room price"
          label="Room price"
          marginZero
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value=""
          placeholder="Calculated room price for reference"
          label="Room price with taxes"
          marginZero
          disabled
        />
        <div>
          <p className="form-label">Tax category</p>
          <SearchToolTip placeholder="Select category" />
        </div>
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value=""
          placeholder="Enter price per additional units"
          label="Price per additional units"
          marginZero
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value=""
          placeholder="Enter child price"
          label="Child price"
          marginZero
        />
        <div>
          <p className="form-label">Fee category</p>
          <SearchToolTip placeholder="Select category" />
        </div>
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value=""
          placeholder="Enter min price"
          label="Min price"
          marginZero
        />
      </div>
      <p className="header_text__18 mt-4 mb-2">
        Reservation calendar config- Color / Room Name
      </p>
      <div className="grid-container  row-gap-3 column-gap-3  w-100 border-bottom pb-4">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          placeholder="Enter a room name short (min 3)"
          label="Room name in short "
        />
        <div>
          <p className="form-label">Room color code</p>
          <SearchToolTip placeholder="Select color code" />
        </div>
      </div>
      <p className="header_text__18 mt-4 mb-2">
        Derived Pricing - Room Category
      </p>
      <div className="grid-container  row-gap-3 column-gap-3  w-100 pb-4">
        <div>
          <p className="form-label">Parent room</p>
          <SearchToolTip placeholder="Select parent room" />
        </div>
        <div>
          <p className="form-label">Dependecy</p>
          <SearchToolTip placeholder="Select yes or no" />
        </div>
        <div>
          <p className="form-label">Direction</p>
          <SearchToolTip placeholder="Select + or -" />
        </div>
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value=""
          placeholder="Enter value"
          label="Value"
          marginZero
        />
        <div>
          <p className="form-label">Type</p>
          <SearchToolTip placeholder="Select type" />
        </div>
      </div>
    </div>
  );
};

export default AddRoomTypePopup;

import React, { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import DataAdminstrationNavViews from "../components/nav-views";
import SettingsLayout from "@layouts/Layout/settings-layout";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import { createDataExport } from "@services/imports-exports.service";
import { getDataExports } from "@services/imports-exports.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { listAllModules } from "@services/modules.service";
import {
  capitalizeFirstLetter,
  formatString,
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import SimpleReactValidator from "simple-react-validator";
import ButtonComponent from "@components/Form/Button/Button";
import { listAllPipelines } from "@services/pipeline.service";
import ListLoader from "@components/common/Loader";
import Nodata from "@components/common/NoData/NoData";
import { handleDownloadOnClick } from "@common/downloadfile";
import { convertCurrentDate } from "@common/date-helpers";
import useDynamicTitle from "@context/useDynamicTitle";
import { Button, Table, Tag } from "antd";

function DataAdministrationExports(props: any) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [type, setType] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [request, setRequest] = useState({
    api_name: "",
    module_id: "",
    pipeline_id: "",
  });
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getAllModules();
  }, []);
  useEffect(() => {
    getExports();
  }, [selectedModule]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [allModuls, setAllModules] = useState([]);
  const getAllModules = async () => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        let list = sortJSONObjectArray(getValue(resp, `data`, []), "seq_num");
        setAllModules(list);
        setSelectedModule(getValue(list, `[${0}].id`, ""));
      }
    } catch (error) {}
  };

  const [allPiplelines, setAllPipelines] = useState([]);
  const getAllPipelines = async (id: string) => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await listAllPipelines(id, queryRequest);
      if (resp) {
        setAllPipelines(
          getValue(resp, `data`, []).map((item: object) => ({
            ...item,
            name: getValue(item, `api_name`, ""),
          }))
        );
      }
    } catch (error) {}
  };

  const [isLoading, setIsLoading] = useState(false);
  const [dataExports, setDataExports] = useState([]);
  const getExports = async () => {
    if (selectedModule) {
      try {
        setIsLoading(true);
        let payload = {
          module_id: selectedModule,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getDataExports(queryRequest);
        if (resp) {
          setDataExports(getValue(resp, `data.dataExports`, []));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp = await createDataExport(
          removeNullOrUndefinedProperties(request)
        );
        if (resp) {
          getExports();
          setSubmitLoading(false);
          setRequest({
            ...request,
            api_name: "",
            module_id: "",
            pipeline_id: "",
          });
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleDownload = (path: string) => {
    handleDownloadOnClick(path, "exported_file");
  };
  useDynamicTitle("DataAdministration");

  const columns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      render: (text: any, record: any) =>
        capitalizeFirstLetter(getValue(record, `module.api_name`, "")),
    },
    {
      title: "PIPELINE",
      dataIndex: "pipeline",
      key: "pipeline",
      render: (text: any, record: any) =>
        capitalizeFirstLetter(getValue(record, `pipeline.api_name`, "")),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any) => (
        <Tag color={status === "UPLOADED" ? "success" : "error"}>
          {formatString(status)}
        </Tag>
      ),
    },
    {
      title: "CREATED BY",
      dataIndex: "created_by",
      key: "created_by",
      render: (text: any, record: any) => {
        const firstName = capitalizeFirstLetter(
          getValue(record, `created_by.first_name`, "")
        );
        const lastName = capitalizeFirstLetter(
          getValue(record, `created_by.last_name`, "")
        );
        return `${firstName} ${lastName}`.trim();
      },
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      key: "created_at",
      render: (record: any, text: any) =>
        convertCurrentDate(getValue(text, `created_at`, "")),
    },
    {
      title: "LINK",
      dataIndex: "file_path",
      key: "file_path",
      render: (record: any, text: any) => (
        <div
          className="color_primary cursor-pointer"
          onClick={() => handleDownload(getValue(text, `file_path`, ""))}
        >
          Download
        </div>
      ),
    },
  ];
  return (
    <>
      <SettingsLayout>
        <CompanyProfileSubHeader />
        <div className={`${"email_container"} w-100`}>
          <CompanyProfileNavigation />
          <div className={` manageFieldRight p-4 w-100`}>
            <DataAdminstrationNavViews />
            <div>
              <div className={"module-list_wrapper"}>
                <div className={"module-tab-wrapper"}>
                  <p className={`${"module-tab-wrapper__text"} mb-4`}>
                    You can export records from a specific module in your
                    SuperCRM account as a CSV file.
                  </p>
                  <div className="w-75 d-flex justify-content-between gap-4">
                    <div className="w-100">
                      <SearchToolTip
                        name={"Module"}
                        label="label"
                        required
                        data={allModuls}
                        selectKey={"id"}
                        onChange={(e: object) => {
                          getAllPipelines(getValue(e, `id`, ""));
                          setRequest({
                            ...request,
                            api_name: getValue(e, `api_name`, ""),
                            module_id: getValue(e, `id`, ""),
                            pipeline_id: "",
                          });
                          setType(getValue(e, `type`, ""));
                        }}
                        value={getValue(request, `module_id`, "")}
                        validator={simpleValidator}
                      />
                    </div>
                    <div className="w-100">
                      {(type === "" || !type || type !== "ACTIVITY") && (
                        <SearchToolTip
                          name={"Pipeline"}
                          label="label"
                          required
                          data={allPiplelines}
                          selectKey={"id"}
                          onChange={(e: object) => {
                            setRequest({
                              ...request,
                              pipeline_id: getValue(e, `id`, ""),
                            });
                          }}
                          value={getValue(request, `pipeline_id`, "")}
                          // validator={type !== "ACTIVITY" && simpleValidator}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-2 w-75 d-flex justify-content-end">
                    <Button
                      size="large"
                      type="primary"
                      onClick={handleSubmit}
                      loading={submitLoading}
                    >
                      Export
                    </Button>
                  </div>
                  <div className="mt-3 mb-5 pb-3">
                    <div className="w-25">
                      <SearchToolTip
                        name={"Module"}
                        label="label"
                        data={allModuls}
                        selectKey={"id"}
                        onChange={(e: object) => {
                          setSelectedModule(getValue(e, `id`, ""));
                        }}
                        value={selectedModule}
                        validator={simpleValidator}
                        required={false}
                      />
                    </div>
                    {isLoading ? (
                      <ListLoader />
                    ) : getValue(dataExports, `length`, 0) > 0 ? (
                      <>
                        {/*     <table className={`common_table w-100 mt-4`}>
                        <thead>
                          <tr>
                            <td>Module</td>
                            <td>Pipeline</td>
                            <td>Status</td>
                            <td>Created by</td>
                            <td>Create at</td>

                            <td>Link</td>
                          </tr>
                        </thead>
                        <tbody>
                          {dataExports.map((item: object) => {
                            return (
                              <tr>
                                <td>{getValue(item, `module.api_name`, "")}</td>
                                <td>
                                  {getValue(item, `pipeline.api_name`, "")}
                                </td>
                                <td>
                                  {formatString(getValue(item, `status`, ""))}
                                </td>
                                <td>
                                  {getValue(item, `created_by.first_name`, "")}{" "}
                                  {getValue(item, `created_by.last_name`, "")}
                                </td>
                                <td>
                                  {convertCurrentDate(
                                    getValue(item, `created_at`, "")
                                  )}
                                </td>
                                <td
                                  onClick={() =>
                                    handleDownload(
                                      getValue(item, `file_path`, "")
                                    )
                                  }
                                  className="color_primary cursor-pointer"
                                >
                                  {"Download"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table> */}
                        <Table
                          size="middle"
                          bordered
                          columns={columns}
                          dataSource={dataExports}
                          rowKey="id"
                          // rowSelection={{
                          //   type: selectionType,
                          //   ...rowSelection,
                          // }}
                          // pagination={{
                          //   current: page_no,
                          //   pageSize: 10,
                          //   total: totalCount,
                          //   showSizeChanger: true,
                          //   pageSizeOptions: ["10", "20", "50"],
                          //   onChange: (page_no) => {
                          //     handleChangePagination(page_no);
                          //   },
                          //   showTotal: (total, range) =>
                          //     `${range[0]}-${range[1]} of ${total} items`,
                          // }}
                        />
                      </>
                    ) : (
                      <div className="mt-5">{/* <Nodata /> */}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SettingsLayout>
    </>
  );
}

export default DataAdministrationExports;

/* -------------------------------------------------------------------------- */
/*                          Template Request section                          */
/* -------------------------------------------------------------------------- */

//response cards
export const tempSendMessageRequest = {
  chatbot_id: "",
  action_id: "",
  name: "Send Message Card",
  description: "",
  delay_config: {
    enabled: false,
    info_messages: [""],
    time: null,
    type: "random",
  },
  component: "reply",
  type: "reply",
  flow_id: null,
  seq_num: null,
  position: {},
};

export const tempSendLinkRequest = {
  chatbot_id: "",
  action_id: "",
  name: "Send Link Card",
  component: "links",
  type: "reply",
  description: "",
  links: [
    // {
    //   url: "https://test.com",
    //   text: "Link 1",
    //   target: "_blank",
    // },
    // {
    //   url: "https://test.com",
    //   text: "Link 2",
    //   target: "_blank",
    // },
  ],
  position: {},
};

//input cards

export const tempButtonRequest = {
  chatbot_id: "",
  action_id: "",
  name: "Button Card",
  component: "suggestions",
  type: "reply",
  description: "",
  suggestions: [
    {
      text: "",
    },
  ],
  position: {},
};

export const tempTemplateRequest = {
  chatbot_id: "",
  action_id: "",
  name: "Whatsapp Template",
  component: "template",
  template_id: "",
  is_whatsapp_template: true,
  type: "reply",
  description: "",
  suggestions: [],
  position: {},
};

export const tempFileRequest = {
  chatbot_id: "",
  action_id: "",
  name: "File Card",
  component: "file_upload_component",
  type: "reply",
  description: "",
  suggestions: [
    {
      text: "",
    },
  ],
  position: {},
  file_id: "",
  file_path: "",
  file_name: "",
  file_extension: "",
};

//visitor cards

export const tempNameRequest = {
  name: "Name Card",
  component: "visitor_name",
  type: "reply",
  description: "",
};

export const tempEmailRequest = {
  name: "Email Card",
  component: "visitor_email",
  type: "reply",
  description: "",
};

export const tempPhoneRequest = {
  name: "Phone Card",
  component: "visitor_phone",
  type: "reply",
  description: "",
};

export const tempCompanyNameRequest = {
  name: "Company Name Card",
  component: "company_name",
  type: "reply",
  description: "",
};
export const tempDateTimeRequest = {
  name: "Date",
  component: "visitor_datetime",
  type: "reply",
  description: "",
};


//End Chat
export const tempEndChatRequest = {
  name: "End Chat Card",
  component: "is_end_chat",
  type: "end",
  description: "",
  is_end_chat: true,
};

export const tempSingleSelectRequest = {
  name: "Single Choice Card",
  component: "singleselect",
  type: "reply",
  description: "",
  suggestions: [
    {
      text: "",
    },
  ],
};

export const tempMultiSelectRequest = {
  name: "Multi Choice Card",
  component: "multiselect",
  type: "reply",
  description: "",
  suggestions: [
    {
      text: "",
    },
  ],
  min_selection: "1",
  max_selection: "3",
};

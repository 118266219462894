import { config } from "../../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
} from "../helpers/http-handler";

//tax rate
export const createProperty = (payload: object) =>
  post(`${config.API_URL}/settings/property`, payload);

export const getAllProperty = (queryRequest: string) =>
  get(`${config.API_URL}/settings/property?${queryRequest}`);

export const getSpecificProperty = (id: string) =>
  get(`${config.API_URL}/settings/property/${id}`);

export const updateProperty = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/property/${id}`, payload);

export const bulkUpdateProperty = (payload: object) =>
  patch(`${config.API_URL}/settings/property/bulk-update`, payload);

export const updatePropertyAddress = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/property/address/${id}`, payload);

export const updatePropertyImage = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/property/img/assign/${id}`, payload);

export const getAllPropertyImage = (queryRequest: string) =>
  get(`${config.API_URL}/settings/property/images?${queryRequest}`);

export const getSpecificPropertyImage = (id: string) =>
  get(`${config.API_URL}/settings/property/img/${id}`);

export const removePropertyImage = (id: string) =>
  Delete(`${config.API_URL}/settings/property/img/${id}`);

export const associateAmenityToProperty = (id: string, payload: object) =>
  post(
    `${config.API_URL}/settings/property/${id}/amenity/association/add`,
    payload
  );

export const removeAssociatedAmenityFromProperty = (
  id: string,
  payload: object
) =>
  DeleteWithPayload(
    `${config.API_URL}/settings/property/${id}/amenity/associations/remove`,
    payload
  );

export const updatePropertyStep = (id: string, payload: object) =>
  patch(
    `${config.API_URL}/settings/property/update/current/step/${id}`,
    payload
  );

import { useState } from "react";
// import ModuleAndFieldsTable from "@components/v2/Settings/ModuleAndField/ModuleAndFieldstable";
import { getValue } from "@utils/lodash";
import "./ModuleAndField.scss";
import ModuleAndFieldsTable from "./ModuleAndFieldstable";
import LoaderMain from "@components/common/Loader/loading1";
export default function ModuleFieldDetails(props: any) {
  const { isLoading } = props;
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const buttonArray = [
    {
      title: "Search",
      image: "search.svg",
    },
  ];
  return (
    <div>
      <ul
        className="d-flex align-items-center justify-content-between mt-4"
        style={{ borderBottom: "1px solid #efefef" }}
      >
        <div className="d-flex align-items-center justify-content-start ms-4">
          <li
            key={`pending-`}
            className={`pt-1 ${"activebar__main-body-list_active"}`}
            // onClick={() => toggle(1)}
          >
            <a className={"activebar__main-body-link p-3"}>Modules</a>
          </li>
        </div>
      </ul>
      <div className={"module-list_wrapper"}>
        <div className={"module-tab-wrapper"}>
          <p className={`${"module-tab-wrapper__text"} mb-4`}>
            The Sales Product module manages product-related information and
            facilitates the sales process.
          </p>
        </div>
        {getValue(props, `isLoading`, false) ? (
          <LoaderMain />
        ) : (
          <div>
            <ModuleAndFieldsTable
              modules={getValue(props, `modules`, [])}
              handleEdit={props.handleEdit}
            />
          </div>
        )}
      </div>
    </div>
  );
}

import { config } from "../env";
import { get, patch, post } from "./helpers/http-handler";

export const getAllRoles = (queryRequest: string) =>
  get(`${config.API_URL}/settings/roles?${queryRequest}`);

export const getSpecificRoleData = (id: string) =>
  get(`${config.API_URL}/settings/roles/${id}`);

export const createRole = (payload: object) =>
  post(`${config.API_URL}/settings/roles`, payload);

export const updateRole = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/roles/${id}`, payload);

export const bulkUpdateRole = (id: string) =>
  patch(`${config.API_URL}/settings/roles/bulk-update`);

export const getAllRolePermissions = (queryRequest: string) =>
  get(`${config.API_URL}/settings/roles/permissions?${queryRequest}`);

import "./CompanyProfile.scss";
import { getValue } from "@utils/lodash";
import { handleSelectChange, handleTextChange } from "common/handleChange";
// import currencies from "@common/josn/currency.json";
import currencies from "@common/json/currency.json";
import { useEffect, useState } from "react";
import { StylesConfig } from "react-select";
import moment from "moment";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import timezones from "@common/json/timezone.json";
import AntDropDown from "@components/custom/Dropdown/antooltip";

export default function CompanyProfileForm(props: any) {
  const { request, setRequest, simpleValidator } = props;
  const [date_formats] = useState([
    {
      value: "MM/DD/YYYY",
      label:
        "MM/DD/YYYY" + " [ " + moment(new Date()).format("MM/DD/YYYY") + " ] ",
    },
    {
      value: "DD/MM/YYYY",
      label:
        "DD/MM/YYYY" + " [ " + moment(new Date()).format("DD/MM/YYYY") + " ] ",
    },
    {
      value: "YYYY/MM/DD",
      label:
        "YYYY/MM/DD" + " [ " + moment(new Date()).format("YYYY/MM/DD") + " ] ",
    },
    {
      value: "DD MMM YYYY",
      label:
        "DD MMM YYYY" +
        " [ " +
        moment(new Date()).format("DD MMM YYYY") +
        " ] ",
    },
  ]);
  const [currencyList, setCurrencyList] = useState([]);
  useEffect(() => {
    let codes: any = currencies.map((item: object) => ({
      ...item,
      label: `${getValue(item, `cc`, "")} - ${getValue(item, `name`, "")}`,
      value: `${getValue(item, `cc`, "")}`,
    }));
    setCurrencyList(codes);
  }, []);
  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: 40,
      border: state.isFocused ? "1px solid #dadce0" : "1px solid #dadce0",
      boxShadow: state.isFocused ? "0px 0px 0px #dadce0" : "none",
      // marginTop:'6px',
      "&:hover": {
        // border: "1px solid #ff8b67",
        // boxShadow: "0px 0px 6px #ff8b67"
      },
    }),
  };
  return (
    <>
      <div className={`organisations-wrapper_company`}>
        <div className={"organisations-wrapper__main-section_create"}>
          <div className={`form_wrapper`}>
            <InputRuleForm
              inputType="TEXT"
              label={"Name"}
              placeholder="Enter Name"
              name={"name"}
              value={getValue(request, `name`, "")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, request, setRequest)
              }
              validator={simpleValidator}
              style={{ height: "40px" }}
            />
            {/* <InputForms
              inputType="TEXT"
              label={"Currency"}
              placeholder="Enter Currency"
              name={"currency"}
              value={getValue(request, `currency`, "")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, request, setRequest)
              }
              validator={simpleValidator}
            /> */}
            <SearchToolTip
              placeholder={`Enter Currency`}
              onChange={(e: object) =>
                handleSelectChange(e, "currency", request, setRequest)
              }
              label={`label`}
              width={"365px"}
              data={currencyList}
              value={getValue(request, `currency`, "")}
              selectKey={"value"}
              name={"Currency"}
            />
            {/* <AntDropDown
                placeholder="Enter Time Zone"
                name="Time Zone"
                width="365px"
                data={timezones}
                onChange={(value: any) =>
                  handleSelectChange(value, "time_zone", request, setRequest)
                }
                value={getValue(request, "time_zone", "")}
              /> */}
            <div className="position-relative">
              <SearchToolTip
                placeholder={`Enter Time Zone`}
                onChange={(e: object) =>
                  handleSelectChange(e, "time_zone", request, setRequest)
                }
                label={`label`}
                width={"365px"}
                data={timezones}
                value={getValue(request, `time_zone`, "")}
                selectKey={"value"}
                name={"Time Zone"}
              />
            </div>
            <SearchToolTip
              placeholder={`Enter Date Format`}
              onChange={(e: object) =>
                handleSelectChange(e, "date_format", request, setRequest)
              }
              label={`label`}
              width={"365px"}
              data={date_formats}
              value={getValue(request, `date_format`, "")}
              selectKey={"value"}
              name={"Date Format"}
            />
          </div>
        </div>
      </div>
      {/* <div className={ClassNames['company-profile__form']}>
			<h6 className={ClassNames['company-profile__form-title']}>
				Company ID 7878787
			</h6>
			<div className="d-flex align-items-center gap">
				<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
					<label className={ClassNames['company-profile__form-label']}>
						Organisation Name
					</label>
					<input
						type="text"
						placeholder="Enter Organisation Name"
						className={`${ClassNames['company-profile__form-control']} form-control`}
					/>
				</div>
				<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
					<label className={ClassNames['company-profile__form-label']}>
						Language
					</label>
					<input
						type="text"
						placeholder="Choose Language"
						className={`${ClassNames['company-profile__form-control']} form-control`}
					/>
				</div>
			</div>
			<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
				<label className={ClassNames['company-profile__form-label']}>
					Time Zone
				</label>
				<input
					type="text"
					placeholder="Choose Time Zone"
					className={`${ClassNames['company-profile__form-control']} form-control`}
				/>
			</div>
			<div className="d-flex align-items-center gap">
				<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
					<label className={ClassNames['company-profile__form-label']}>
						Time Format
					</label>
					<input
						type="text"
						placeholder="Enter Organisation Name"
						className={`${ClassNames['company-profile__form-control']} form-control`}
					/>
				</div>
				<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
					<label className={ClassNames['company-profile__form-label']}>
						Date Format
					</label>
					<input
						type="text"
						placeholder="Enter Organisation Name"
						className={`${ClassNames['company-profile__form-control']} form-control`}
					/>
				</div>
			</div>
		</div> */}
    </>
  );
}

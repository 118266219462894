import { getValue } from "@utils/lodash";
import TaskSales from "./TaskSales/TaskSales";
import TaskCompanies from "./TaskCompanies/TaskCompanies";
import TaskContacts from "./TaskContacts/TaskContacts";
import TaskLeads from "./TaskLeads/TaskLeads";
export default function TaskPendingTaskRight(props: any) {
  return (
    <div
    // className={"pendingtask-left_container"}
    >
      {getValue(props, `module`, "") === "contacts" ? (
        <>
          <TaskLeads {...props} create_module={"leads"} />
          <TaskCompanies {...props} create_module={"companies"} />
          <TaskSales {...props} create_module={"deals"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "companies" ? (
        <>
          <TaskLeads {...props} create_module={"leads"} />
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskSales {...props} create_module={"deals"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "deals" ||
      getValue(props, `module`, "") === "sales" ? (
        <>
          <TaskLeads {...props} create_module={"leads"} />
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskCompanies {...props} create_module={"companies"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "tasks" ||
      getValue(props, `module`, "") === "calls" ||
      getValue(props, `module`, "") === "meetings" ? (
        <>
          <TaskLeads {...props} create_module={"leads"} />
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskCompanies {...props} create_module={"companies"} />
          <TaskSales {...props} create_module={"deals"} />
        </>
      ) : null}
    </div>
  );
}

// import SalePipelineDetails from "@components/common/Pipeline/Detail/sales";
import SalePipelineDetails from "@components/Pages/Pipeline/Detail/sales";
import OrganizationSalesDeals from "@pages/Private/CRM/deals";

export const CrmDelasRoutes = [
  //Sales
  {
    path: "/:orgId/crm/deals",
    name: "crm_deals",
    component: OrganizationSalesDeals,
  },
  // {
  //   path: '/:orgId/sales/detail/:id',
  //   name: 'Sales',
  //   component: DealDetails,
  // },
  {
    path: "/:orgId/crm/deals/detail/:id",
    name: "crm_deals",
    component: SalePipelineDetails,
  },
  //Tasks
  // {
  //   path: "/:orgId/sales/tasks",
  //   name: "Sales",
  //   component: OrganizationTasks,
  // },
  // {
  //   path: "/:orgId/sales/tasks/create",
  //   name: "Sales",
  //   component: SalesTaskAdd,
  // },
  // {
  //   path: "/:orgId/sales/task/detail",
  //   name: "Sales",
  //   component: TaskDetails,
  // },
];

import "./template.scss";
import ModuleSettingNavViews from "../components/nav-views";
import SettingsLayout from "@layouts/Layout/settings-layout";
import { useEffect, useState } from "react";
import {
  getAllTemplates,
  updateTemplates,
} from "@services/email-config.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import ConfigPopup from "./components/config-popup";
import { getValue } from "@utils/lodash";
import { defaultFields } from "@services/modules.service";
import TemplatePopup from "./components/template-popup";
import { addTemplates } from "@services/email-config.service";
import { toast } from "sonner";
import ListLoader from "@components/common/Loader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import NotFoundDetail from "@components/common/NoData/Not-Found-Detail";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { useStateContext } from "@context/profileProvider";
import TemplatesNoDataPage from "@components/common/NoData/templates-nodata";
import { Badge, Switch, Table, Tag } from "antd";
import { formatString } from "@common/text-helpers";
function SettingsEmailTemplates(props: any) {
  const { selectedModuleId } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState({
    name: "",
    subject: "",
    content: "",
    isCommon: true,
    moduleId: "",
    pipelineId: "",
  });

  const reset = () => {
    setRequest({
      ...request,
      name: "",
      subject: "",
      content: "",
      isCommon: true,
      moduleId: "",
      pipelineId: "",
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        module_name: "contacts",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTemplates("");
      let response = await defaultFields(queryRequest);
      if (resp) {
        setList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Template section                             */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const [templateOpen, setTemplateOpen] = useState(false);
  const handleTemplateModal = () => {
    setTemplateOpen(!templateOpen);
  };

  const handleChangeEditor = (name: string, value: string) => {
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let payload = {
        name: getValue(request, `name`, ""),
        content: getValue(request, `content`, ""),
        subject: getValue(request, `subject`, ""),
        isCommon: getValue(request, `isCommon`, false),
        moduleId: getValue(request, `moduleId`, ""),
        pipelineId: getValue(request, `pipelineId`, ""),
      };
      let resp;
      if (editId) {
        resp = await updateTemplates(editId, payload);
      } else {
        resp = await addTemplates(payload);
      }
      if (resp) {
        toast.success(`${editId ? "Updated" : "Created"} successfully`);
        setSubmitLoading(false);
        handleTemplateModal();
        getData();
        reset();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const [editId, setEditId] = useState("");
  const handleUpdateTemplate = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    setRequest({
      ...request,
      name: getValue(obj, `name`, ""),
      subject: getValue(obj, `subject`, ""),
      content: getValue(obj, `content`, ""),
      isCommon: true,
      moduleId: getValue(obj, `module_id`, ""),
      pipelineId: getValue(obj, `pipeline_id`, ""),
    });
    setTemplateOpen(true);
  };

  const handleChangeStatus = async (obj: object) => {
    try {
      let payload = {
        isActive: !getValue(obj, `is_active`, false),
      };
      let resp = await updateTemplates(getValue(obj, `id`, ""), payload);
      if (resp) {
        toast.success("Updated successfully");
        getData();
      }
    } catch (error) {}
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: any, record: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() => handleUpdateTemplate(record)}
        >
          {formatString(name)}
        </div>
      ),
    },
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      render: (text: any) => text.label,
    },
    {
      title: "STATUS",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: any) => (
        <div className="d-flex align-items-center gap-2">
          <Badge status={is_active === true ? "success" : "error"} />
          {is_active === true ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: any, record: any) => (
        <div className="d-flex align-items-center gap-2">
          <Switch
            size="small"
            checked={is_active}
            onChange={() => handleChangeStatus(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <SettingsLayout>
      <CompanyProfileSubHeader />
      <div className={`${"email_container"} w-100`}>
        <CompanyProfileNavigation />
        <div className={`manageFieldRight w-100 add-overflow-y p-3`}>
          <ModuleSettingNavViews handleModal={handleModal} />
          {isLoading ? (
            <ListLoader />
          ) : (
            <div className="m-2">
              {getValue(list, `length`, 0) > 0 ? (
                // <table className={`common_table w-100`}>
                //   <thead>
                //     <tr>
                //       <td style={{ width: "70px" }}>
                //         <CustomCheckbox />
                //       </td>
                //       <td>Name</td>
                //       <td>Module</td>
                //       <td>Status</td>
                //       <td>Action</td>
                //     </tr>
                //   </thead>
                //   <tbody>
                //     {list.map((item: object, index: number) => {
                //       return (
                //         <tr key={index}>
                //           <td>
                //             <CustomCheckbox />
                //           </td>
                //           <td onClick={() => handleUpdateTemplate(item)}>
                //             {getValue(item, `name`, "")}
                //           </td>
                //           <td onClick={() => handleUpdateTemplate(item)}>
                //             {getValue(item, `module.label`, "")}
                //           </td>
                //           <td onClick={() => handleUpdateTemplate(item)}>
                //             {getValue(item, `is_active`, false)
                //               ? "Active"
                //               : "Inactive"}
                //           </td>
                //           <td
                //             onClick={(e) => {
                //               e.stopPropagation();
                //             }}
                //           >
                //             <label className={"switch"}>
                //               <input
                //                 type="checkbox"
                //                 checked={getValue(item, `is_active`, false)}
                //                 onChange={() => handleChangeStatus(item)}
                //               />
                //               <span
                //                 className={`${"slider"} ${`round`} d-flex`}
                //               ></span>
                //             </label>
                //           </td>
                //         </tr>
                //       );
                //     })}
                //   </tbody>
                // </table>
                <Table
                  size="middle"
                  className="mt-3"
                  bordered
                  columns={columns}
                  dataSource={list}
                  rowKey="id"
                  pagination={{
                    showTotal: (total, range) =>
                      `${total} ${total >= 2 ? "items" : "item"}`,
                  }}
                  scroll={{
                    y: `calc(100vh - 300px)`,
                    // x: 1400,
                  }}
                />
              ) : (
                <TemplatesNoDataPage />
              )}
            </div>
          )}
          <div></div>
        </div>
      </div>
      <ConfigPopup
        isOpen={isOpen}
        handleModal={handleModal}
        request={request}
        setRequest={setRequest}
        handleTemplateModal={handleTemplateModal}
      />
      <TemplatePopup
        isOpen={templateOpen}
        handleModal={handleTemplateModal}
        module={getValue(request, `forModule`, "")}
        request={request}
        setRequest={setRequest}
        handleChangeEditor={handleChangeEditor}
        submitLoading={submitLoading}
        handleSubmit={handleSubmit}
        editId={editId}
        reset={reset}
      />
    </SettingsLayout>
  );
}

export default SettingsEmailTemplates;

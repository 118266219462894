import Dashboard from "@pages/Private/Dashboard";
import ModuleImportLogs from "@pages/Private/Settings/ImportLogs";

export const DashboardRoutes = [
  // dashboard
  { path: "/:orgId/dashboard", name: "dashboard_crm", component: Dashboard },
  {
    path: "/:orgId/crm/:module/imports",
    name: "dashboard_crm",
    component: ModuleImportLogs,
  },
];

import { Button } from "antd";
import React from "react";
import Popup from "reactjs-popup";

function CancelAlertImportPopup({ open, onClose, onConfirm }: any) {
  return (
    <Popup
      open={open}
      closeOnDocumentClick={false}
      onClose={onClose}
      className="confirmation-message-wrapper"
    >
      <div className="confirmation-message-container">
        <h6>Cancel import?</h6>
        <p>All progress will be lost—sure you want to cancel import?</p>
      </div>
      <div className="button-wrapper">
        <Button onClick={onClose}>Stay on this page</Button>
        <Button onClick={onConfirm} type="primary">
          Cancel Import
        </Button>
      </div>
    </Popup>
  );
}

export default CancelAlertImportPopup;

import { QueryRequestHelper } from "./query-request-helper";

export const allParams = () => {
  // Move the URL search params extraction inside the function
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  let payload = {
    ...UrlParams,
  };

  let queryRequest = QueryRequestHelper(payload);
  return queryRequest;
};

import "./ManagefieldsLeft.scss";
import { getValue } from "@utils/lodash";
import SearchSvgComponent from "assets/svg/search";
import { useEffect, useState } from "react";
import LockSvgComponent from "assets/svg/lock";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import InputRuleForm from "@components/InputRuleForm/form";
import CloseSvgComponent from "@assets/svg/close";
import CloseMainSvgComponent from "@assets/svg/close-main";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import { Checkbox } from "antd";

export default function FieldsLeftMenu(props: any) {
  //------------------------- Fields Section -------------------------//

  const [moduleFields, setModuleFields] = useState([]);
  const [allFields, setAllFields] = useState([]);

  useEffect(() => {
    setModuleFields(getValue(props, `allFields`, []));
    if (search_text) {
      let searched_fields = getValue(props, `allFields`, []).filter(
        (item: object) =>
          getValue(item, `label`, "")
            .toLowerCase()
            .includes(search_text.toLowerCase())
      );
      setAllFields(searched_fields);
    } else {
      setAllFields(getValue(props, `allFields`, []));
    }
  }, [getValue(props, `allFields`, [])]);

  let fields =
    getValue(props, `usedFields.length`, 0) > 0
      ? getValue(props, `usedFields`, []).map((item: object) =>
          getValue(item, `api_name`, "")
        )
      : [];

  //------------------------- Search Section -------------------------//
  const [search_text, setSearchText] = useState("");
  const handleSearch = (e: any) => {
    if (getValue(e, `target.value`, "")) {
      setSearchText(e.target.value);
      let searched_fields = moduleFields.filter((item: object) =>
        getValue(item, `label`, "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
      setAllFields(searched_fields);
    } else {
      setSearchText("");
      setAllFields(moduleFields);
    }
  };

  return (
    <div className={"manage-field__navigation pt-3"}>
      {/* <div className={`manage-field__form-group px-3`}>
        <label className={"manage-field__form-group-label"}>
          Module Fields
        </label>
      </div> */}
      {/* <p className={`${ClassNames["manage-field__text"]} px-3`}>
        Below is a list of all the fields you can select to add to the create
        Deal form. If the field isn’t listed below, you can create a new one.
      </p> */}
      <div className={`px-3 mb-3 position-relative input_container`}>
        <InputRuleForm
          inputType="TEXT"
          style={{ height: "36px", paddingLeft: "30px" }}
          placeholder="Search here"
          onChange={handleSearch}
          value={search_text}
          name="search_text"
        />
        <div className={`position-absolute input_search`}>
          <SearchSvgComponent color={"gray"} size={16} />
        </div>
        {search_text && (
          <div
            className={`position-absolute input_close`}
            onClick={() => {
              handleSearch("");
            }}
          >
            <DropdownCloseSvgComponent color={"gray"} size={16} />
          </div>
        )}
      </div>
      <div className={`${"manage-field__navigation_container"} px-3`}>
        {allFields.map((checkObj: object, index: number) => {
          return (
            <div
              key={`check-${index}`}
              className={`manage-field__checks d-flex align-items-center`}
            >
              {getValue(checkObj, `system_required`, false) ? (
                // <img
                //   src="/images/icons/lock.svg"
                //   className={`${ClassNames["lock"]}`}
                // />
                <div
                  className={``}
                  style={{ marginRight: "-8px", marginLeft: "-4px" }}
                >
                  <LockSvgComponent size={26} />
                </div>
              ) : (
                // <CustomCheckbox
                //   checked={fields.includes(getValue(checkObj, `api_name`, ""))}
                //   disabled={
                //     getValue(checkObj, `system_required`, "") ? true : false
                //   }
                //   onChange={() =>
                //     props.handleEditFields(
                //       getValue(checkObj, `api_name`, ""),
                //       fields.includes(getValue(checkObj, `api_name`, ""))
                //         ? false
                //         : true
                //     )
                //   }
                // />
                <Checkbox
                  onChange={() =>
                    props.handleEditFields(
                      getValue(checkObj, `api_name`, ""),
                      fields.includes(getValue(checkObj, `api_name`, ""))
                        ? false
                        : true
                    )
                  }
                  checked={fields.includes(getValue(checkObj, `api_name`, ""))}
                  disabled={
                    getValue(checkObj, `system_required`, "") ? true : false
                  }
                />
              )}
              <p
                className={
                  getValue(checkObj, `system_required`, false)
                    ? `manage-field__checks-text ms-3`
                    : "manage-field__checks-text"
                }
              >
                {getValue(checkObj, `label`, "")}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import { Table, Checkbox, Switch } from "antd";
import ListLoader from "@components/common/Loader";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function RolesList(props: any) {
  const {
    loading,
    selectedIds,
    rolesList,
    handleChangeAllCheckbox,
    handleChangeCheckbox,
    updateUserStatus,
  } = props;
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = (id: string) => {
    navigate(`/${getValue(params, `orgId`, "")}/settings/roles/${id}`);
  };

  const columns = [
    // {
    //   title: (
    //     <Checkbox
    //       indeterminate={
    //         getValue(selectedIds, `length`, 0) > 0 &&
    //         getValue(selectedIds, `length`, 0) !==
    //           getValue(rolesList, `length`, 0)
    //       }
    //       checked={
    //         getValue(selectedIds, `length`, 0) ===
    //         getValue(rolesList, `length`, 0)
    //       }
    //       onChange={() =>
    //         handleChangeAllCheckbox(
    //           getValue(selectedIds, `length`, 0) ===
    //             getValue(rolesList, `length`, 0)
    //             ? "all_checked"
    //             : "all_not_checked"
    //         )
    //       }
    //     />
    //   ),
    //   dataIndex: "checkbox",
    //   render: (_: any, record: any) => (
    //     <Checkbox
    //       checked={
    //         getValue(selectedIds, `length`, 0) > 0 &&
    //         selectedIds.includes(getValue(record, `id`, ""))
    //       }
    //       onChange={() => handleChangeCheckbox(getValue(record, `id`, ""))}
    //     />
    //   ),
    //   width: 70,
    // },
    {
      title: "Name",
      dataIndex: "title",
      render: (text: string, record: any) => (
        <span onClick={() => handleNavigate(getValue(record, `id`, ""))}>
          {text}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text: string, record: any) => (
        <span onClick={() => handleNavigate(getValue(record, `id`, ""))}>
          {text}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (_: any, record: any) => (
        <Switch
          checked={getValue(record, `is_active`, false)}
          onChange={() =>
            updateUserStatus(
              getValue(record, `id`, ""),
              !getValue(record, `is_active`, false)
            )
          }
        />
      ),
    },
  ];

  return (
    <div className="mt-3 px-3">
      {loading ? (
        <ListLoader />
      ) : getValue(rolesList, `length`, 0) > 0 ? (
        <Table
          rowKey="id"
          columns={columns}
          dataSource={rolesList}
          pagination={false}
        />
      ) : (
        <FiltersNoDataPage module={"roles"} />
      )}
    </div>
  );
}

export default RolesList;

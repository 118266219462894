import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Modal } from "antd";
import { useEffect, useState } from "react";

function PropertyRoomForm(props: any) {
  const {
    request,
    setRequest,
    simpleValidator,
    roomsLength,
    setRoomsLength,
    taxGroupList,
    editRoomId,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allowBackspace, setAllowBackspace] = useState(false);

  const handleChangeRoom = (e: any) => {
    const inputValue = e.target.value.trim();
    const newRoomsLength = inputValue ? parseInt(inputValue) : 0;

    if (newRoomsLength >= 0) {
      let updatedRooms = [...request.rooms];

      // Add new empty objects if the new length is greater than the existing length
      while (updatedRooms.length < newRoomsLength) {
        updatedRooms.push({ value: "" });
      }

      // If reducing room length and not allowing backspace
      if (updatedRooms.length > newRoomsLength && !allowBackspace) {
        setIsModalVisible(true);
        return;
      }

      // If backspace/delete is allowed
      if (allowBackspace) {
        updatedRooms = updatedRooms.slice(0, newRoomsLength);
      }

      setRoomsLength(newRoomsLength);
      setRequest((prevRequest: any) => ({
        ...prevRequest,
        rooms: updatedRooms,
      }));
    }
  };

  const handleKeyPress = (e: any) => {
    // Trigger modal when backspace or delete is pressed
    if (e.key === "Backspace" || e.key === "Delete") {
      setIsModalVisible(true);
    }
  };

  const handleModalOk = () => {
    setAllowBackspace(true);

    // Allow backspace for a limited time
    setTimeout(() => {
      setAllowBackspace(false);
    }, 3000);

    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangeNumber = (e: any) => {
    const value = e.target.value;
    return value;
  };

  useEffect(() => {
    const maxAdult = parseInt(request.max_adult) || 0;
    const maxChildren = parseInt(request.max_children) || 0;
    const maxOccupancy = maxAdult + maxChildren;

    setRequest((prevRequest: any) => ({
      ...prevRequest,
      max_occupancy: maxOccupancy.toString(),
    }));
  }, [request.max_adult, request.max_children]);

  return (
    <div className="w-50 px-5 pt-4 pb-5" style={{ height: "fit-content" }}>
      <InputRuleForm
        inputType="TEXT"
        name="name"
        value={getValue(request, `name`, "")}
        onChange={(e: any) =>
          setRequest({
            ...request,
            name: e.target.value,
          })
        }
        placeholder="Enter room type"
        className="mb-2"
        label="Room type name"
        required
        validator={simpleValidator}
      />
      <InputRuleForm
        inputType="NUMBER"
        name="roomsLength"
        value={roomsLength}
        onChange={handleChangeRoom}
        onKeyDown={handleKeyPress}
        placeholder="Enter number of rooms"
        className="mb-2"
        label="How many rooms under this room type?"
        required
        validator={simpleValidator}
        disabled={editRoomId}
      />

      {getValue(request, `rooms.length`, 0) > 0 && (
        <div>
          <h6 className="header_text__16">Enter Room ID(s)</h6>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {request.rooms.map((item: any, i: number) => (
              <div key={i} className="ms-2 w-30">
                <InputRuleForm
                  inputType="TEXT"
                  name={`room_${i}`}
                  value={getValue(item, `value`, "")}
                  onChange={(e: any) => {
                    const newValue = e.target.value;
                    setRequest((prevRequest: any) => ({
                      ...prevRequest,
                      rooms: prevRequest.rooms.map((room: any, index: number) =>
                        index === i ? { ...room, value: newValue } : room
                      ),
                    }));
                  }}
                  placeholder={``}
                  className="mb-2"
                  label={`Room ${i + 1} ID`}
                  disabled={editRoomId}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="d-flex align-items-center mt-3">
        <div className="w-30">
          <InputRuleForm
            inputType="NUMBER"
            name="max_adult"
            value={getValue(request, `max_adult`, "")}
            onChange={(e: any) => {
              setRequest({
                ...request,
                max_adult: handleChangeNumber(e),
              });
            }}
            placeholder="Enter maximum adult"
            className="mb-2"
            label="Maximum Adult"
          />
        </div>
        <p className="small_text__16 mx-2 pt-4">+</p>
        <div className="w-30">
          <InputRuleForm
            inputType="NUMBER"
            name="max_children"
            value={getValue(request, `max_children`, "")}
            onChange={(e: any) => {
              setRequest({
                ...request,
                max_children: handleChangeNumber(e),
              });
            }}
            placeholder="Enter maximum children"
            className="mb-2"
            label="Maximum children"
          />
        </div>
        <p className="small_text__16 mx-2 pt-4">=</p>
        <div className="w-30">
          <InputRuleForm
            inputType="NUMBER"
            name="max_occupancy"
            value={getValue(request, `max_occupancy`, "")}
            // onChange={(e: any) => {
            //   setRequest({
            //     ...request,
            //     max_occupancy: handleChangeNumber(e),
            //   });
            // }}
            placeholder="Enter Maxiumum occupancy"
            className="mb-2"
            label="Maxiumum occupancy"
            disabled
          />
        </div>
      </div>

      <InputRuleForm
        inputType="TEXT"
        name="default_price"
        value={getValue(request, `default_price`, "")}
        onChange={(e: any) => {
          setRequest({
            ...request,
            default_price: handleChangeNumber(e),
          });
        }}
        placeholder="Enter Default adult price"
        className="mb-2"
        label="Default adult price?"
      />

      <InputRuleForm
        inputType="TEXT"
        name="min_price"
        value={getValue(request, `min_price`, "")}
        onChange={(e: any) => {
          setRequest({
            ...request,
            min_price: handleChangeNumber(e),
          });
        }}
        placeholder="Enter Min adult price"
        className="mb-2"
        label="Min adult price?"
      />
      <InputRuleForm
        inputType="TEXT"
        name="child_price"
        value={getValue(request, `child_price`, "")}
        onChange={(e: any) => {
          setRequest({
            ...request,
            child_price: handleChangeNumber(e),
          });
        }}
        placeholder="Enter Child price"
        className="mb-2"
        label="Child price?"
      />
      <SearchToolTip
        name="Tax Category"
        label="label"
        value={getValue(request, `tax_category_id`, "")}
        onChange={(e: any) => {
          setRequest({
            ...request,
            tax_category_id: getValue(e, `value`, ""),
          });
        }}
        data={taxGroupList}
        placeholder="Choose tax category"
        className="mb-2"
      />
      <Modal
        title="Warning"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>
          If you continue, all room data will be lost. Do you want to proceed?
        </p>
      </Modal>
    </div>
  );
}

export default PropertyRoomForm;

import React, { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { resendVerificationCode, signUpComplete } from "services/auth.service";
import { toast } from "sonner";
import { getValue } from "@utils/lodash";
import "./otp.scss";
import LoginLayout from "@layouts/LoginLayout";

const OTP_INPUTS_PER_GROUP = 3;

export default function RequestOtp() {
  const params = useParams();
  const simpleValidator = useRef(new SimpleReactValidator());
  const inputRefs = useRef<any>([]);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [, forceUpdate] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleOtpInputChange = (groupIdx: any, digitIdx: any, value: any) => {
    const newOtp = [...otp];
    const globalDigitIdx = groupIdx * OTP_INPUTS_PER_GROUP + digitIdx;
    newOtp[globalDigitIdx] = value;
    setOtp(newOtp);
    const moveFocus = (moveIndex: any) => {
      if (moveIndex >= 0 && moveIndex < otp.length) {
        inputRefs.current[moveIndex].focus();
      }
    };
    if (value) {
      moveFocus(
        globalDigitIdx < otp.length - 1 ? globalDigitIdx + 1 : globalDigitIdx
      );
    } else {
      moveFocus(globalDigitIdx > 0 ? globalDigitIdx - 1 : globalDigitIdx);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setSubmitLoading(true);
      try {
        const enteredOtp = otp.join("");

        let obj = {
          verification_id: params.token,
          code: enteredOtp,
        };
        let resp = await signUpComplete(obj);
        if (resp) {
          toast.success("Email Verified Successfully");
          localStorage.setItem(
            "accessToken",
            getValue(resp, `data.access_token`, "")
          );
          window.location.href = "/organisations";
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const resendOTP = async () => {
    try {
      let resp = await resendVerificationCode(getValue(params, `token`, ""));
      if (resp) {
        toast.success("OTP sent successfully");
      }
    } catch (error) {}
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    try {
      const clipboardData =
        event.clipboardData || getValue(window, `clipboardData`, {});
      const pastedData = clipboardData.getData("text");
      if (pastedData) {
        const otpArray = pastedData
          .split("")
          .filter((char) => /\d/.test(char))
          .slice(0, otp.length);

        const newOtp = otpArray.map((digit, idx) => digit || "");
        setOtp(newOtp);
        // Set focus on the last input
        const lastInputIndex = newOtp.length - 1;
        if (inputRefs.current[lastInputIndex]) {
          inputRefs.current[lastInputIndex].focus();
        }
      } else {
        console.error("No text found in clipboard.");
      }
    } catch (error) {
      console.error("Error reading clipboard:", error);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <LoginLayout>
        <div className="container-fluid">
          <div className={`back-link cursor-pointer`}>
            <Link to="/signup">
              <img
                src="/images/icons/back-icon.svg"
                className={`back-link-image img-fluid`}
                alt="img"
              />
            </Link>
          </div>
          <div className="d-flex justify-content-center">
            <div className="login_card">
              <div className={`support-login-wrapper__details m-5`}>
                <h4 className={"support-login-wrapper__title"}>
                  Check your email for a code
                </h4>
                <p className="mt-2 small_text__16">
                  We've sent 6-character code. The code expires shortly, <br />
                  so please enter it soon
                </p>
                <div className={`custom-form-group form-group mt-4`}>
                  <div className="d-flex justify-content-between">
                    {[...Array(otp.length)].map((_, idx) => {
                      const groupIdx = Math.floor(idx / OTP_INPUTS_PER_GROUP);
                      const localIdx = idx % OTP_INPUTS_PER_GROUP;
                      const isLastInGroup =
                        localIdx === OTP_INPUTS_PER_GROUP - 1;
                      const isFirstInGroup = localIdx === 0 && idx !== 0;
                      return (
                        <React.Fragment key={idx}>
                          <input
                            type="text"
                            inputMode="numeric"
                            autoComplete="one-time-code"
                            pattern="\d{1}"
                            name={`otp-${idx}`}
                            onChange={(e) =>
                              handleOtpInputChange(
                                groupIdx,
                                localIdx,
                                e.target.value
                              )
                            }
                            onPaste={handlePaste}
                            onKeyDown={handleKeyDown} // Handle Enter key press
                            maxLength={1}
                            className={`otp-input ${
                              localIdx === 0 ? "first-input" : ""
                            } ${isLastInGroup ? "last-input" : ""}`}
                            value={otp[idx]}
                            ref={(input) => (inputRefs.current[idx] = input)}
                          />
                          {isLastInGroup && idx < otp.length - 1 && (
                            <span
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                              className="mt-2"
                            >
                              _
                            </span>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  {simpleValidator && (
                    <p className={`form-error text-center`}>
                      {simpleValidator.current.message(
                        "OTP",
                        otp,
                        "required|number"
                      )}
                    </p>
                  )}
                  <button
                    className="superteams-button__primary-button transition mt-4"
                    onClick={handleSubmit}
                    disabled={submitLoading}
                  >
                    {submitLoading ? "Please wait..." : "Verify"}
                  </button>
                </div>
                <div className="mt-4 small_text__14">
                  Did not receive an email? <br />
                  Check your spam folder or
                  <a href="#0" className="ms-1" onClick={resendOTP}>
                    Resend Code
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoginLayout>
    </>
  );
}

import { concatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";

function DropdownOptions(props) {
  const {
    handleClickOption,
    option,
    selectKey,
    value,
    label,
    selectedOptionRef,
    id,
    concatLength,
    index
  } = props;
  return (
    <div>
      <div
        onClick={() => {
          handleClickOption(option);
        }}
        className={`popup_options ${
          getValue(option, `[${selectKey ? selectKey : "id"}]`, "") == value
            ? "selected"
            : ""
        }`}
        key={`${id}-${index}`}
        ref={
          getValue(option, `[${selectKey ? selectKey : "id"}]`, "") === value
            ? selectedOptionRef
            : null
        }
      >
        <div className="selected_text hover-text">
          {concatString(`${option[label]}`, concatLength ? concatLength : 40)}
        </div>
        {getValue(option, `[${selectKey ? selectKey : "id"}]`, "") == value && (
          <img src="/dropdown/done.svg" className="done_image" />
        )}
      </div>
    </div>
  );
}

export default DropdownOptions;

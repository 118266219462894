import { replaceDefaultPlaceholders } from "@common/markdown/markdown-helper";
import { getValue } from "@utils/lodash";

const commonChatbotRequest = (
  name: string,
  findSelectedNode: any,
  params: any
) => {
  return {
    id: getValue(findSelectedNode, `id`, ""),
    flow_id: getValue(findSelectedNode, `flow_id`, ""),
    seq_num: getValue(findSelectedNode, `seq_num`, ""),
    chatbot_id: getValue(params, `id`, ""),
    // chatbot_group_id: getValue(params, `id`, ""),
    action_id: getValue(findSelectedNode, `action_id`, ""),
    name: getValue(findSelectedNode, `name`, ""),
    position: getValue(findSelectedNode, `position`, {}),
    description: replaceDefaultPlaceholders(getValue(
      findSelectedNode,
      `rendering_config.replies[${0}].text`,
      ""
    )),
    component: name,
    type: "reply",
  };
};

export const handleChatbotRequest = (
  name: string,
  chatRequest: any,
  setChatRequest: any,
  findSelectedNode: any,
  params: any
) => {
  switch (name) {
    case "reply":
    case "visitor_name":
    case "visitor_email":
    case "visitor_phone":
    case "company_name":
    case "visitor_datetime":
      return setChatRequest({
        ...chatRequest,
        ...commonChatbotRequest(name, findSelectedNode, params),
      });

    case "is_end_chat":
      return setChatRequest({
        ...chatRequest,
        ...commonChatbotRequest(name, findSelectedNode, params),
        is_end_chat: true,
      });

    case "file_upload_component":
      return setChatRequest({
        ...chatRequest,
        ...commonChatbotRequest(name, findSelectedNode, params),
        file_id: getValue(findSelectedNode, `file_id`, ""),
        file_path: getValue(findSelectedNode, `flow_id`, ""),
        file_name: getValue(findSelectedNode, `file_name`, ""),
        file_extension: getValue(findSelectedNode, `file_extension`, ""),
      });

    case "template":
      return setChatRequest({
        ...chatRequest,
        ...commonChatbotRequest(name, findSelectedNode, params),
        template_id: getValue(findSelectedNode, `template_id`, ""),
        is_whatsapp_template: true,
        suggestions: getValue(
          findSelectedNode,
          `rendering_config.suggestions`,
          []
        ),
      });

    case "suggestions":
      return setChatRequest({
        ...chatRequest,
        ...commonChatbotRequest(name, findSelectedNode, params),
        suggestions: getValue(
          findSelectedNode,
          `rendering_config.suggestions`,
          []
        ),
      });

    case "singleselect":
    case "multiselect":
      return setChatRequest({
        ...chatRequest,
        ...commonChatbotRequest(name, findSelectedNode, params),
        suggestions: getValue(
          findSelectedNode,
          `rendering_config.input.options`,
          []
        ),
      });

    case "links":
      return setChatRequest({
        ...chatRequest,
        ...commonChatbotRequest(name, findSelectedNode, params),
        links: getValue(
          findSelectedNode,
          `rendering_config.replies[${0}].links`,
          ""
        ),
      });

    default:
      return {};
  }
};

export const getChatbotRequest = (
  name: string,
  findSelectedNode: any,
  params: any
) => {
  switch (name) {
    case "reply":
    case "visitor_name":
    case "visitor_email":
    case "visitor_phone":
    case "company_name":
    case "visitor_datetime":
      return {
        ...commonChatbotRequest(name, findSelectedNode, params),
      };

    case "is_end_chat":
      return {
        ...commonChatbotRequest(name, findSelectedNode, params),
        is_end_chat: true,
      };

    case "suggestions":
      // case "singleselect":
      // case "multiselect":
      return {
        ...commonChatbotRequest(name, findSelectedNode, params),
        suggestions: getValue(
          findSelectedNode,
          `rendering_config.suggestions`,
          []
        ),
      };

    case "singleselect":
    case "multiselect":
      return {
        ...commonChatbotRequest(name, findSelectedNode, params),
        suggestions: getValue(
          findSelectedNode,
          `rendering_config.input.options`,
          []
        ),
      };

    case "file_upload_component":
      return {
        ...commonChatbotRequest(name, findSelectedNode, params),
        file_path: getValue(findSelectedNode, `flow_id`, ""),
        file_id: getValue(findSelectedNode, `file_id`, ""),
        file_name: getValue(findSelectedNode, `file_name`, ""),
        file_extension: getValue(findSelectedNode, `file_extension`, ""),
      };

    case "template":
      return {
        ...commonChatbotRequest(name, findSelectedNode, params),
        template_id: getValue(findSelectedNode, `template_id`, ""),
      };

    case "links":
      return {
        ...commonChatbotRequest(name, findSelectedNode, params),
        links: getValue(
          findSelectedNode,
          `rendering_config.replies[${0}].links`,
          ""
        ),
      };
    default:
      return {};
  }
};

import CloseMainSvgComponent from "@assets/svg/close-main";
import { getColorCode, getTextColor } from "@common/colors";
import { getOnlyFirstLetterOfWord } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { config } from "../../../../env";
import { useRef, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Navigation } from "swiper/modules";
import "swiper/css";
import ChevronCircleRight from "@assets/svg/chevron-circle-right";
import ChevronCircleLeft from "@assets/svg/chevron-circle-left";
import { useLocation, useParams } from "react-router-dom";
import ImportCheckbox from "@components/BulkImport/components/import-checkbox";
import { ColorPicker, Switch } from "antd";

type MarkerComponent = React.ReactNode;

const BotBuilderStepThree = (props: any) => {
  const { request, setRequest } = props;
  const params = useParams();
  const location = useLocation();
  const handleChange = (emails: string[]) => {
    setRequest({ ...request, send_email_notification_to: emails });
  };
  const fileInputRef = useRef<any>(null);

  const handleAddClick = () => {
    fileInputRef.current.click();
  };

  const handleAvatarCreation = (imageUrl: any) => {
    setRequest({
      ...request,
      avatar: imageUrl,
    });
  };

  const positionList = [
    { value: "LEFT_BOTTOM", label: "Left Bottom" },
    { value: "RIGHT_CENTER", label: "Right Center" },
    { value: "RIGHT_BOTTOM", label: "Right Bottom" },
  ];

  const handlePositionCheckboxChange = (value: string) => {
    setRequest({ ...request, position: value });
  };

  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  const predefinedColors = [
    "#ff1616",
    "#ff5757",
    "#ff66c4",
    "#cb6ce6",
    "#8c52ff",
    "#5e17eb",
    "#03989e",
    "#00c2cb",
    "#38b6ff",
    "#5271ff",
    "#004aad",
    "#008037",
    "#7ed957",
    "#2dc464",
    "#ffde59",
    "#ffbd59",
    "#ff914d",
    "#8d7260",
    "#a6a6a6",
    "#000000",
  ];

  const toggleColorPicker = () => {
    setColorPickerVisible(!colorPickerVisible);
  };

  const handleColorChange = (color: any) => {
    setRequest({
      ...request,
      theme_color: color.hex,
    });
  };

  const markerComponents: Record<string, MarkerComponent> = {
    LEFT_BOTTOM: <div className="bot-left-bottom-marker"></div>,
    RIGHT_CENTER: <div className="bot-right-center-marker"></div>,
    DEFAULT: <div className="bot-right-bottom-marker"></div>,
  };

  function rgbToHex(r: number, g: number, b: number) {
    return "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");
  }

  return (
    <div className="">
      {getValue(request, "platform", "") === "WEB" && (
        <div className="mt-3">
          <div className="d-flex justify-content-between">
            <h6 className="header_text__16">Avatar</h6>
          </div>
          <div className="position-relative">
            <button className="custom-button-prev d-flex justify-content-center align-items-center p-0">
              <ChevronCircleLeft color="#111e3a" />
            </button>
            <button className="custom-button-next d-flex justify-content-center align-items-center p-0">
              <ChevronCircleRight color="#111e3a" />
            </button>
            <Swiper
              spaceBetween={10}
              slidesPerView={7}
              pagination={{
                clickable: true,
              }}
              navigation={{
                prevEl: ".custom-button-prev",
                nextEl: ".custom-button-next",
              }}
              modules={[Pagination, Navigation]}
              className="mx-5 "
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 7,
                  spaceBetween: 10,
                },
              }}
            >
              <SwiperSlide>
                <div className="platform_add_container ">
                  <input
                    type="file"
                    onChange={props.handleUploadDocuments}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                  />
                  <div
                    onClick={handleAddClick}
                    className="cursor-pointer add-rounded bg-white"
                  >
                    <AddCircleSvgComponent size="40" />
                  </div>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>
              <AvatarBuilder
                src="/images/whatsapp.png"
                backgroundColor={getValue(request, "theme_color", "")}
                onImageReady={handleAvatarCreation}
              />
            </SwiperSlide> */}
              {getValue(props, "avatarList", []).map((item: any) => (
                <SwiperSlide key={getValue(item, "key", "")}>
                  <div
                    className="chatbot-avatar-selctor"
                    style={{
                      border:
                        `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                          item,
                          "key",
                          ""
                        )}` === getValue(request, "avatar", "")
                          ? "3px solid #1a73e8"
                          : "",
                    }}
                    onClick={() => {
                      setRequest({
                        ...request,
                        avatar: `https://${
                          config.ASSETS_CDN_ENDPOINT
                        }/${getValue(item, "key", "")}`,
                      });
                    }}
                  >
                    <img
                      src={`https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                        item,
                        "key",
                        ""
                      )}`}
                      height={60}
                      width={60}
                      className="chatbot-all-avatar"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
      <div className="mt-3">
        <h6 className="header_text__14">Position</h6>
        <div className="d-flex gap-3 mt-2">
          {positionList.map((item: any, index: any) => {
            const value = getValue(item, "value", "");
            const isSelected = getValue(request, "position", "") === value;
            const markerComponent =
              markerComponents[value] || markerComponents.DEFAULT;

            return (
              <ImportCheckbox
                key={index}
                value={value}
                label={getValue(item, "label", "")}
                svgComponent={
                  <div
                    className={`bot-position-container ${
                      isSelected ? "add-position-border" : ""
                    }`}
                  >
                    {markerComponent}
                  </div>
                }
                onChange={handlePositionCheckboxChange}
                selectedOption={getValue(request, "position", "")}
                checked={isSelected}
                groupName="position"
              />
            );
          })}
          {/* <SearchToolTip
          placeholder="Choose a time"
          data={positionList}
          label="label"
          value={getValue(request, `position`, "")}
          selectKey={"value"}
          name=""
          onChange={(e: any) =>
            setRequest({
              ...request,
              position: getValue(e, `value`, ""),
            })
          }
        /> */}
        </div>
        {getValue(request, "platform", "") === "WEB" && (
          <div className="position-relative mt-3">
            <h6 className="header_text__14">Theme Color</h6>
            <div className="d-flex">
              <div className="d-flex mb-2 w-50 flex-wrap gap-1">
                {predefinedColors.map((color, index) => (
                  <div
                    key={index}
                    className="color-box border"
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: color,
                      cursor: "pointer",
                      marginRight: "5px",
                      borderRadius: "6px",
                    }}
                    onClick={() =>
                      setRequest({
                        ...request,
                        theme_color: color,
                      })
                    }
                  ></div>
                ))}
                <ColorPicker
                  value={getValue(request, "theme_color", "")}
                  onChange={(e: any) => {
                    const { r, g, b } = e.metaColor;
                    const hexColor = rgbToHex(r, g, b);
                    setRequest({
                      ...request,
                      theme_color: hexColor,
                    });
                  }}
                />
                {/* <div
                className="color-picker-box border position-relative"
                onClick={toggleColorPicker}
              >
                {colorPickerVisible && (
                  <div className="color-picker-wrapper position-absolute mb-5">
                    <SketchPicker
                      color={getValue(request, "theme_color", "")}
                      onChange={handleColorChange}
                    />
                    <div
                      className="close-picker  mb-2 cursor-pointer d-flex justify-content-center"
                      onClick={toggleColorPicker}
                    >
                      Close
                    </div>
                  </div>
                )}
              </div> */}
              </div>
              <div className="d-flex justify-content-center align-items-center w-50">
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    backgroundColor: getValue(request, "theme_color", ""),
                    cursor: "pointer",
                    borderRadius: "6px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
        {location.pathname !==
          `/${getValue(params, "orgId", "")}/chatbot/builder` && (
          <>
            <div className="mt-4">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="header_text__14">Enable Email Notification</h6>
                <Switch
                  size="small"
                  checked={getValue(
                    request,
                    `enable_email_notification`,
                    false
                  )}
                  onChange={() =>
                    setRequest({
                      ...request,
                      enable_email_notification: !getValue(
                        request,
                        `enable_email_notification`,
                        false
                      ),
                    })
                  }
                />
              </div>
            </div>
            <div className="my-4">
              <h6 className="header_text__14">Send Email Notifications to</h6>
              <ReactMultiEmail
                placeholder="Enter recipients"
                emails={
                  getValue(request, `send_email_notification_to.length`, 0) > 0
                    ? getValue(request, `send_email_notification_to`, [])
                    : []
                }
                style={{ minHeight: "30vh" }}
                onChange={handleChange}
                enableSpinner
                allowDuplicate={false}
                allowDisplayName={true}
                getLabel={(
                  email: string,
                  index: number,
                  removeEmail: (index: number) => void
                ) => {
                  return (
                    <div
                      data-tag
                      key={index}
                      className="d-flex align-items-center"
                    >
                      <span
                        data-tag-handle
                        onClick={() => removeEmail(index)}
                        className=""
                      >
                        <div
                          className="profile_circle_email"
                          style={{
                            background: getColorCode(
                              getOnlyFirstLetterOfWord(email)
                            ),
                            // border: "1px solid green",
                          }}
                        >
                          <p
                            className="title"
                            style={{
                              color: getTextColor(
                                getOnlyFirstLetterOfWord(email)
                              ),
                            }}
                          >
                            {getOnlyFirstLetterOfWord(email)}
                          </p>
                        </div>
                      </span>
                      <span className="mx-2">{email}</span>
                      <span
                        data-tag-handle
                        onClick={() => removeEmail(index)}
                        className="react_mail_close_icon"
                      >
                        {/* <DropdownCloseSvgComponent size={18}/> */}
                        <CloseMainSvgComponent size={18} />
                      </span>
                    </div>
                  );
                }}
              />
            </div>
          </>
        )}

        {/* <div className="mb-5">
        <p className="header_text__18">Reminder</p>
        <p className="pending-task-commondescription mt-1">
          Select the type of visitor to initiate proactive conversation.
        </p>
        <p className="pending-task-commondescription mt-4">
          Send first message in:
        </p>
        <SearchToolTip placeholder="Choose a time" />
        <p className="pending-task-commondescription mt-4">Language:</p>
        <SearchToolTip placeholder="Choose language" />
        <p className="pending-task-commondescription mt-4">Message:</p>
        <textarea
          className="tasks_notes-textarea w-100"
          placeholder="Enter notes"
          name="comment"
          // onChange={(e) => props.setComment(e.target.value)}
          // value={props.comment}
          autoFocus
          rows={5}
          value={`{
                    "replies": [
                      {
                        "text": "Is there anything else I might help you with today"
                      }
                    ]
                  }`}
        />
        <p className="touchable-text header_blue_text__16 mt-3">
          Add another message
        </p>
      </div>
      <div className="mb-5">
        <p className="header_text__18">Chat timeout</p>
        <p className="pending-task-commondescription mt-1">
          Select an action for the bot to initiate when the user fails to
          respond to the reminders.
        </p>
        <p className="pending-task-commondescription mt-4">End chat in:</p>
        <SearchToolTip placeholder="Choose a time" />
        <p className="pending-task-commondescription mt-4">Language:</p>
        <SearchToolTip placeholder="Choose language" />
        <p className="pending-task-commondescription mt-4">Message:</p>
        <textarea
          className="tasks_notes-textarea w-100"
          placeholder="Enter notes"
          name="comment"
          // onChange={(e) => props.setComment(e.target.value)}
          // value={props.comment}
          autoFocus
          rows={5}
          value={`{
	"replies": [
		{
			"text": "Is there anything else I might help you with today"
		}
	]
}`}
        />
      </div>
      <div className="mt-5">
        <div className="d-flex justify-content-between align-items-center">
          <p className="header_text__18">Allow hand off to operators</p>
          <label className={"switch"}>
            <input
              type="checkbox"
              checked
              //   onChange={() =>
              //     updateUserStatus(
              //       getValue(item, `id`, ""),
              //       !getValue(item, `is_admin`, false)
              //     )
              //   }
            />
            <span className={`${"slider"} ${`round`} d-flex`}></span>
          </label>
        </div>
        <p className="pending-task-commondescription mt-2">
          Let visitors opt to connect with human operators during an ongoing bot
          conversation.
        </p>
        <InputRuleForm
          //   label="Name your Bot"
          inputType="TEXT"
          name="name"
          placeholder="Enter a Name"
          className="pb-2"
          // onChange={(e: any) =>
          //   setRequest({
          //     ...request,
          //     name: e.target.value,
          //   })
          // }
          value="Connect with a human?"
        />
        <div className="mt-3">
          <SearchToolTip placeholder="Choose " className="pt-3" />
        </div>
      </div>
      <div className="mt-5">
        <div className="d-flex justify-content-between align-items-center">
          <p className="header_text__18">Forward message</p>
          <label className={"switch"}>
            <input
              type="checkbox"
              //   onChange={() =>
              //     updateUserStatus(
              //       getValue(item, `id`, ""),
              //       !getValue(item, `is_admin`, false)
              //     )
              //   }
            />
            <span className={`${"slider"} ${`round`} d-flex`}></span>
          </label>
        </div>
        <p className="pending-task-commondescription mt-2">
          Display a message right after the chat is forwarded to a human
          operator
        </p>
        <div className="mt-3">
          <SearchToolTip placeholder="Choose " className="pt-3" />
        </div>
        <p className="pending-task-commondescription mt-4">Message:</p>
        <textarea
          className="tasks_notes-textarea w-100"
          placeholder="Enter notes"
          name="comment"
          // onChange={(e) => props.setComment(e.target.value)}
          // value={props.comment}
          autoFocus
          rows={5}
          value={`Enter your forward message here`}
        />
        <p className="pending-task-commondescription mt-4">
          Type % for dynamic text suggestions
        </p>
      </div>
      <div className="mt-5">
        <p className="header_text__18">Bot conversation actions</p>
        <p className="pending-task-commondescription mt-1">
          Allow visitors to go back to the previous message in the bot
          conversation or restart the conversation if required.
        </p>
        <div className="d-flex gap-2 align-items-center mt-2">
          <CustomCheckbox />
          <label>Rollback to the previous message</label>
        </div>
        <div className="d-flex gap-2 align-items-center mt-2">
          <CustomCheckbox />
          <label>Restart conversation</label>
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default BotBuilderStepThree;

import AddCircleSvgComponent from "@assets/svg/add-circle";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { emojisList } from "@common/emojis";
import { handleTextArrayChange } from "@common/handleChange";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React from "react";

function BotLinksComponent(props: any) {
  const { request, setRequest } = props;
  const handleAddLink = () => {
    let obj = { name: "", url: "", target: "_blank" };
    request.links.push(obj);
    setRequest({ ...request });
  };

  const handleRemoveLink = (index: number) => {
    let filtered = getValue(request, `links`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      links: filtered,
    });
  };
  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    setRequest({
      ...request,
      description: value,
    });
  };
  return (
    <div>
      {/* <div className="mb-2">
        <InputRuleForm
          inputType="TEXT"
          value={getValue(request, `name`, "")}
          name="name"
          onChange={(e: any) =>
            setRequest({ ...request, name: e.target.value })
          }
          label="Name"
          placeholder="Enter Name"
          // required
        />
      </div> */}
      <div className="mb-2">
        {/* <InputRuleForm
          inputType="TEXTAREA"
          value={getValue(request, `description`, "")}
          name="description"
          onChange={(e: any) =>
            setRequest({ ...request, description: e.target.value })
          }
          label="Message"
          placeholder="Enter Message"
          // mandatory
        /> */}
        <DraftJSRefEditor
          editorState={getValue(request, `description`, "")}
          handleChangeEditor={handleChangeEditor}
          name="content"
          placeholder="Enter here"
          editorStyle={{
            background: "white",
            paddingLeft: "20px",
            paddingTop: "20px",
            // height: "30vh",
          }}
          focusColor={"#1a73e8"}
          toolbar={{
            options: ["inline", "emoji"],
            inline: {
              options: ["bold", "italic"],
            },
            emoji: {
              emojis: emojisList,
            },
          }}
          hideFocus
          focus
          editorClassName={'bot-editorClassName'}
        />
      </div>
      <div className="mt-4">
        <h6 className="header_text__16 color_primary mb-2">Links</h6>
        {getValue(request, `links.length`, 0) > 0 &&
          getValue(request, `links`, []).map((item: object, index: number) => {
            return (
              <div
                className="d-flex align-items-center justify-content-between gap-3"
                key={index}
              >
                <div className="bot_link_container mb-2 w-90">
                  <InputRuleForm
                    inputType="TEXT"
                    value={getValue(item, `name`, "")}
                    name="name"
                    onChange={(e: any) =>
                      handleTextArrayChange(index, e, "links", setRequest)
                    }
                    label="Name"
                    placeholder="Enter Name"
                    // required
                  />
                  <InputRuleForm
                    inputType="TEXT"
                    value={getValue(item, `url`, "")}
                    name="url"
                    onChange={(e: any) =>
                      handleTextArrayChange(index, e, "links", setRequest)
                    }
                    label="URL"
                    placeholder="Enter URL"
                    // required
                  />
                </div>
                <div onClick={() => handleRemoveLink(index)} className="w-10">
                  <RemoveCircleSvgComponent />
                </div>
              </div>
            );
          })}
        <div className="d-flex align-items-center justify-content-end mt-3">
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={handleAddLink}
          >
            <AddCircleSvgComponent />
            <h6 className="header_text__16 color_success mx-1">Add Link</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BotLinksComponent;

import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import React from "react";
import { Button, Modal, Select } from "antd";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import InputRuleForm from "@components/InputRuleForm/form";

function WorkflowInfoPopup(props: any) {
  const {
    request,
    setRequest,
    allModules,
    getAllPipelineList,
    pipelineList,
    getTypes,
  } = props;
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={props.handleSubmit}
      title="Create New Workflow"
      onCancel={props.toggle}
      footer={[
        <Button key="cancel" onClick={props.toggle} className="cancel__button">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => props.handleSubmit()}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      <div className="p-2">
        <div className="">
          <InputRuleForm
            inputType="TEXT"
            label={"Workflow Name"}
            placeholder="Enter Workflow Name"
            name={"name"}
            value={getValue(request, `name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: getValue(e, `target.value`, ""),
              })
            }
            //   validator={simpleValidator}
            // validLeft
            mandatory
            required
          />
        </div>
        {/* <SearchToolTip
            width={450}
            name="Module"
            data={allModules.filter(
              (item: object) =>
                getValue(item, `api_name`, "") !== "calls" &&
                getValue(item, `api_name`, "") !== "meetings"
            )}
            label="label"
            selectKey={"id"}
            onChange={(e: any) => {
              getAllPipelineList(getValue(e, `id`, ""));
              setRequest({
                ...request,
                moduleId: getValue(e, `id`, ""),
                moduleName: getValue(e, `api_name`, ""),
              });
            }}
            value={getValue(request, `moduleId`, "")}
          /> */}
        <p className="form-label">Module</p>
        <Select
          size="large"
          showSearch
          placeholder="Select Module"
          optionFilterProp="label" // Use correct property for filtering
          value={request.moduleId || ""} // Direct access to moduleId
          onChange={(value: any, option: any) => {
            const selectedModule = allModules.find(
              (item: any) => getValue(item, "id", "") === value
            );

            if (selectedModule) {
              getAllPipelineList(value); // Assuming `value` is the module id
              setRequest({
                ...request,
                moduleId: value,
                moduleName: getValue(selectedModule, "api_name", ""),
              });
            }
          }}
          options={allModules
            .filter(
              (item: any) =>
                getValue(item, "api_name", "") !== "calls" &&
                getValue(item, "api_name", "") !== "meetings"
            )
            .map((item: any) => ({
              value: getValue(item, "id", ""), // Value used for selection
              label: getValue(item, "api_name", ""), // Displayed label
            }))}
          className="w-100 cursor-pointer"
        />

        {/* {getTypes(getValue(request, `moduleName`, "")) !== "ACTIVITY" &&
            getValue(request, `moduleName`, "") !== "contacts" &&
            getValue(request, `moduleName`, "") !== "companies" && (
              <SearchToolTip
                width={450}
                name="Pipelines"
                data={pipelineList}
                label="label"
                selectKey={"id"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    pipelineId: getValue(e, `id`, ""),
                  })
                }
                value={getValue(request, `pipelineId`, "")}
              />
            )} */}

        {getTypes(getValue(request, `moduleName`, "")) !== "ACTIVITY" &&
          getValue(request, `moduleName`, "") !== "contacts" &&
          getValue(request, `moduleName`, "") !== "companies" && (
            <>
              <p className="form-label">Pipeline</p>

              <Select
                size="large"
                showSearch
                placeholder="Select Pipeline"
                optionFilterProp="label"
                value={getValue(request, `pipelineId`, "")}
                onChange={(value: any, option: any) => {
                  setRequest({
                    ...request,
                    pipelineId: value,
                  });
                }}
                options={pipelineList.map((item: any) => ({
                  value: getValue(item, `id`, ""),
                  label: getValue(item, `label`, ""),
                }))}
                className="w-100"
              />
            </>
          )}

        {/* <div className="mt-2">
              <InputRuleForm
                inputType="TEXTAREA"
                label={"Description"}
                placeholder="Enter Description"
                rows={4}
                name={"description"}
                value={getValue(request, `description`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    description: getValue(e, `target.value`, ""),
                  })
                }
                //   validator={simpleValidator}
              />
            </div> */}
      </div>
    </Modal>
  );
}

export default WorkflowInfoPopup;

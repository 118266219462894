import Select from "react-select";
import "./form.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import { getDate } from "@common/date-helpers";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: any;
  onChange: any;
  label: string;
  validator: any;
  placeholder: string;
  mandatory: boolean;
  maxDate: any;
  minDate: any;
  hideLabel: boolean;
};

export default function TimePicker({
  value,
  onChange,
  validator,
  label,
  placeholder,
  mandatory,
  hideLabel,
}: Props) {
  return (
    <div
      className={`signup-form-wrapper__form-group ${
        validator && validator.current.message(label, value, "required")
          ? "signup-form-wrapper__form-group--error"
          : ""
      } form-group mb-3`}
    >
      {!hideLabel && (
        <label className="form-label ">
          {label}{" "}
          {/* {mandatory && <span className="color_danger small_text__16">*</span>} */}
        </label>
      )}
      <div>
        <DatePicker
          className={
            mandatory ? `custom-form-control__required` : `custom-form-control`
          }
          selected={value ? getDate(value) : null}
          onChange={onChange}
          placeholderText={placeholder}
          showTimeSelect
          //   showTimeSelectOnly
          showMonthDropdown
          showYearDropdown
          timeIntervals={15}
          dateFormat="d MMM yyyy, p"
          timeCaption="Time"
        />
      </div>
      {validator && (
        <p className={`error-text`}>
          {validator.current.message(label, value, "required")}
        </p>
      )}
    </div>
  );
}

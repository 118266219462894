import React, { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";
import type { InputRef } from "antd";
import { getValue } from "@utils/lodash";

let index = 0;

const SelectDropdown = (props: any) => {
  const {
    placeholder,
    data,
    value,
    selectKey,
    nameKey,
    onAdd,
    isLoading,
    onSelect,
  } = props;
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    onAdd(name);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const findLabel = (value: string) => {
    let info =
      getValue(data, `length`, 0) > 0
        ? data.find(
            (item: object) => getValue(item, `[${selectKey}]`, "") === value
          )
        : {};
    return getValue(info, `label`, "");
  };

  const handleSelect = (value: string, option: any) => {
    onSelect(option);
  };

  return (
    <Select
      style={{ width: 300, height: 40 }}
      placeholder={placeholder}
      value={findLabel(value)}
      onChange={handleSelect}
      dropdownRender={(menu) => {
        return isLoading ? (
          <p className="small_text__12 text-center p-3">Loading...</p>
        ) : (
          <>
            <div
              className="p-2"
              // onClick={() => onSelect()}
            >
              {menu}
            </div>
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              <Input
                placeholder={`Enter ${nameKey}`}
                ref={inputRef}
                value={name}
                onChange={onNameChange}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                Add {nameKey}
              </Button>
            </Space>
          </>
        );
      }}
      options={data}
    />
  );
};

export default SelectDropdown;

import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import "./PendingTaskDetails.scss";
import { deleteModuleCommon } from "@services/common.service";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { getAllModuleFields } from "@services/module-fields.service";
import { sortTaskFieldList } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import { useStateContext } from "@context/profileProvider";
import CommonModulesDetailMiddlePage from "../middle-container";
import CommonModulesDetailLeftPagePage from "../left-container";
import CommonModulesDetailRightPagePage from "../right-container";

export default function CommonModuleDetailPage(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const { allModules, userId } = useStateContext();

  //----------------- Notes Section ----------------//
  const [isNotesPopupVisibile, setNotesPopupVisisbility] = useState(false);
  const handleOpenNotes = () => {
    setNotesPopupVisisbility(!isNotesPopupVisibile);
  };
  //----------------- Email Section ----------------//
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const handleSendEmail = () => {
    setPopupVisibility(!isPopupVisible);
  };
  //----------------- Task Section ------------------//
  const [isRollsPopupVisibile, setRollsPopupVisisbility] = useState(false);
  const handleOpenForm = (module: string) => {
    props.getForm(module);
    setRollsPopupVisisbility(!isRollsPopupVisibile);
  };
  //----------------- Meeting Section ------------------//
  const [isEventVisibility, setIsEventVisibility] = useState(false);
  //----------------- Call Section ------------------//
  const [isCallVisibility, setIsCallVisibility] = useState(false);

  /* ----------------------------- Delete View Section  ------------------------- */
  const [isOpen, setIsOpen] = React.useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteModuleCommon(
        getValue(params, `id`, ""),
        props.module
      );
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        navigate(
          `/${getValue(params, `orgId`, "")}/crm/${getValue(
            props,
            `module`,
            ""
          )}`
        );
      }
    } catch (error) {}
  };

  const handleOpenMeeting = () => {
    getForms("meetings");
    setIsEventVisibility(!isEventVisibility);
  };
  const handleOpenCall = () => {
    getForms("calls");
    setIsCallVisibility(!isCallVisibility);
  };

  const [fields, setFields] = useState([]);
  const getForms = async (module: string) => {
    let info =
      getValue(allModules, `length`, 0) > 0
        ? allModules.find(
            (item: object) => getValue(item, `api_name`, "") === module
          )
        : {};
    let fields = await getAllModuleFields(getValue(info, `id`, ""));
    if (fields) {
      let list = sortTaskFieldList(getValue(fields, `data`, []));
      for (const item of list) {
        if (item.api_name === "owner_id") {
          item.value = userId;
        }
      }
      const aDict = list.reduce((dict: any, item: any) => {
        dict[item.api_name] = item;
        return dict;
      }, {});
      const ReorderedList: any = [];
      // Add elements from b in the order they appear in a
      for (const api_name of getValue(fields, `data`, [])) {
        if (aDict[api_name]) {
          ReorderedList.push(aDict[api_name]);
        }
      }
      // Add elements from a that are not in b to the end
      for (const item of list) {
        if (!getValue(fields, `data`, []).includes(item.api_name)) {
          ReorderedList.push(item);
        }
      }
      setFields(ReorderedList);
    }
  };

  return (
    <div
      className={
        getValue(props, `isChanged`, false)
          ? "pending-wrapper__main-section_after_change"
          : "pending-wrapper__main-section_detail"
      }
    >
      <CommonModulesDetailLeftPagePage
        params={props.params}
        data={getValue(props, `data`, {})}
        request={props.request}
        setRequest={props.setRequest}
        fields={props.fields}
        setFields={props.setFields}
        backUrl={props.backUrl}
        popupFields={props.popupFields}
        setPopupFields={props.setPopupFields}
        getData={props.getData}
        module={props.module}
        isChanged={props.isChanged}
        stageList={props.stageList}
        //notes
        handleOpenNotes={handleOpenNotes}
        //emails
        handleSendEmail={handleSendEmail}
        //tasks
        handleOpenForm={handleOpenForm}
        // meetings
        handleEventVisibility={handleOpenMeeting}
        // calls
        handleCallVisibility={handleOpenCall}
        //delete record
        handleDeleteModal={handleModal}
        duplicateAPINames={props.duplicateAPINames}
        setDuplicateAPINames={props.setDuplicateAPINames}
      />
      <CommonModulesDetailMiddlePage
        params={props.params}
        getForm={props.getForm}
        form={props.associationForm}
        setForm={props.setAssociationForm}
        stage={props.stage}
        setStage={props.setStage}
        module={props.module}
        data={props.data}
        getData={props.getData}
        fields={fields}
        setFields={setFields}
        getForms={getForms}
        //notes
        isNotesPopupVisibile={isNotesPopupVisibile}
        setNotesPopupVisisbility={setNotesPopupVisisbility}
        handleOpenNotes={handleOpenNotes}
        //tasks
        isRollsPopupVisibile={isRollsPopupVisibile}
        setRollsPopupVisisbility={setRollsPopupVisisbility}
        handleOpenForm={handleOpenForm}
        //meetings
        isEventVisibility={isEventVisibility}
        setIsEventVisibility={setIsEventVisibility}
        //calls
        isCallVisibility={isCallVisibility}
        setIsCallVisibility={setIsCallVisibility}
        //emails
        isPopupVisible={isPopupVisible}
        setPopupVisibility={setPopupVisibility}
        handleSendEmail={handleSendEmail}
      />
      <CommonModulesDetailRightPagePage
        data={props.data}
        form={props.associationForm}
        setForm={props.setAssociationForm}
        formLoading={props.formLoading}
        handleAssociate={props.handleAssociate}
        handleDisAssociate={props.handleDisAssociate}
        getForm={props.getForm}
        module={props.module}
        pipelineList={props.pipelineList}
        stageList={props.formStageList}
        staticFieldRequest={props.staticFieldRequest}
        setStaticFieldRequest={props.setStaticFieldRequest}
        getSpecificPipelineInfo={props.getSpecificPipelineInfo}
        getData={props.getData}
      />
      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDeleteFunction}
        deleteValue=""
      />
    </div>
  );
}

export function getTextColor(backgroundColor: any) {
  // Convert hexadecimal color code to RGB values
  const hexToRGB = (hex: any) => {
    const bigint = hex && parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  // Calculate relative luminance of the color
  const getLuminance = (rgb: any) => {
    const [r, g, b] = rgb.map((c: any) => {
      const s = c / 255;
      return s <= 0.03928 ? s / 12.92 : Math.pow((s + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  const rgb = hexToRGB(backgroundColor);
  const luminance = getLuminance(rgb);

  // Check if the luminance is below a threshold (0.5)
  return luminance < 0.5 ? "#ffffff" : "#000000";
}

export const getColorCode = (alphabet: any) => {
  const colorCodes: any = {
    A: "#D93025",
    B: "#FF8C00",
    C: "#FFDD00",
    D: "#008000",
    E: "#0064B0",
    F: "#6A0DAD",
    G: "#8C1D40",
    H: "#EC4C47",
    I: "#FBBC04",
    J: "#009587",
    K: "#00A8C1",
    L: "#0433FF",
    M: "#D500F9",
    N: "#DB4437",
    O: "#F4B400",
    P: "#0F9D58",
    Q: "#00A4FF",
    R: "#616161",
    S: "#9E9E9E",
    T: "#8D6E63",
    U: "#795548",
    V: "#BDBDBD",
    W: "#607D8B",
    X: "#FF8A65",
    Y: "#FF80AB",
    Z: "#FF4081",
  };
  // Convert input to uppercase
  alphabet = alphabet.toUpperCase();
  // Check if the alphabet exists in the dictionary
  if (alphabet in colorCodes) {
    return colorCodes[alphabet];
  } else {
    return null; // Return null if no color code is found for the input
  }
};

export const getModernColorCode = (alphabet: any) => {
  const colorCodes: any = {
    A: "#FFEBEE", // Soft Light Red
    B: "#FFE0B2", // Soft Peach
    C: "#FFF9C4", // Soft Lemon Yellow
    D: "#C8E6C9", // Soft Mint Green
    E: "#BBDEFB", // Soft Sky Blue
    F: "#D1C4E9", // Soft Lavender
    G: "#FFCCBC", // Soft Coral
    H: "#FFECB3", // Soft Light Amber
    I: "#F0F4C3", // Soft Light Lime
    J: "#B2EBF2", // Soft Aqua
    K: "#B3E5FC", // Soft Baby Blue
    L: "#E1BEE7", // Soft Light Lilac
    M: "#FFCDD2", // Soft Blush Pink
    N: "#D7CCC8", // Soft Taupe
    O: "#FFF3E0", // Soft Cream
    P: "#C5E1A5", // Soft Pastel Green
    Q: "#FFECB3", // Soft Light Yellow
    R: "#FFAB40", // Soft Light Orange (Updated)
    S: "#F5F5F5", // Soft Light Gray
    T: "#E6EE9C", // Soft Light Olive
    U: "#C8E6C9", // Soft Mint Green
    V: "#FFCCBC", // Soft Salmon
    W: "#CFD8DC", // Soft Blue Gray
    X: "#FFE082", // Soft Golden Yellow
    Y: "#FFCC80", // Soft Tangerine
    Z: "#FFAB91", // Soft Apricot
  };

  // Convert input to uppercase
  alphabet = alphabet.toUpperCase();

  // Check if the alphabet exists in the dictionary
  if (alphabet in colorCodes) {
    return colorCodes[alphabet];
  } else {
    return null; // Return null if no color code is found for the input
  }
};

export const getColorStyle = (name: string) => {
  let value = name && name.toLowerCase();
  switch (value) {
    case "completed":
    case "whatsapp":
      return {
        color: "#22b378",
        border: "2px solid white",
        background: "#e4f7f1",
        padding: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        // borderRadius: "15px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };
    case "failed":
    case "rejected":
    case "not-completed":
      return {
        color: "#d72e38",
        border: "2px solid white",
        background: "#ffebec",
        padding: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "15px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };
    case "orange":
      return {
        color: "#f27914",
        border: "2px solid white",
        background: "#fff2e8",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };

    case "draft":
      return {
        color: "#797dac",
        border: "2px solid white",
        background: "#f1effb",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };
    case "blue":
    case "web":
      return {
        color: "#408dfb",
        border: "2px solid white",
        background: "#ebf0fe",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };
    case "violet":
      return {
        color: "#8378c3",
        border: "2px solid white",
        background: "#d1d1f9",
        padding: "5px",
        borderRadius: "5px",
        minWidth: "70px",
        maxWidth: "120px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      };

    default:
      return {};
  }
};

export const REACT_COLORS = [
  "#000000",
  "#333333",
  "#4D4D4D",

  "#666666",
  "#808080",
  "#999999",

  "#B3B3B3",
  "#cccccc",
  "#FFFFFF",

  "#9F0500",
  "#D33115",
  "#F44E3B",

  "#C45100",
  "#E27300",
  "#FE9200",

  "#FB9E00",
  "#FCC400",
  "#FCDC00",

  "#808900",
  "#B0BC00",
  "#DBDF00",

  "#194D33",
  "#68BC00",
  "#A4DD00",

  "#0C797D",
  "#16A5A5",
  "#68CCCA",

  "#0062B1",
  "#009CE0",
  "#73D8FF",

  "#653294",
  "#7B64FF",
  "#AEA1FF",

  "#AB149E",
  "#FA28FF",
  "#FDA1FF",
];

import { config } from "../../../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
} from "../../helpers/http-handler";

export const createRatePlan = (payload: object) =>
  post(`${config.API_URL}/settings/rate-plan`, payload);

export const getAllRatePlan = (queryRequest: string) =>
  get(`${config.API_URL}/settings/rate-plan?${queryRequest}`);

export const getSpecificRatePlan = (id: string) =>
  get(`${config.API_URL}/settings/rate-plan/${id}`);

export const updateRatePlan = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/rate-plan/${id}`, payload);

export const bulkUpdateRatePlan = (payload: object) =>
  patch(`${config.API_URL}/settings/rate-plan/bulk-update`, payload);

export const associateRatePlan = (id: string, payload: object) =>
  post(`${config.API_URL}/settings/rate-plan/${id}/association/add`, payload);

export const removeAssociatedRatePlan = (id: string, payload: object) =>
  DeleteWithPayload(
    `${config.API_URL}/settings/rate-plan/${id}/associations/remove`,
    payload
  );

import ActivityCalls from "@pages/Private/CRM/Calls";
import CallsDetailSection from "@pages/Private/CRM/Calls/Detail";

export const CrmCallsRoutes = [
  {
    path: "/:orgId/crm/calls",
    name: "crm_calls",
    component: ActivityCalls,
  },
  {
    path: "/:orgId/crm/calls/detail/:id",
    name: "crm_calls",
    component: CallsDetailSection,
  },
];

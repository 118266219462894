import DeleteSvgComponent from "@assets/svg/delete";
import { getValue } from "@utils/lodash";
import React, { useEffect } from "react";

function PropertyRoomAmenities(props: any) {
  const {
    amentitesList,
    handleDeleteAmenity,
    handleAddRemoveAmenities,
    roomTypeActivetab,
    selectedAmenities,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="px-4 py-3">
        <div className="w-100">
          <div className="amenity_container">
            {amentitesList.map((item: any) => {
              const isSelected = selectedAmenities.includes(
                getValue(item, `id`, "")
              );

              return (
                <div
                  key={getValue(item, `id`, "")}
                  className="position-relative mb-3"
                >
                  <div
                    className="amenity_text"
                    style={{
                      border: isSelected ? "1px solid #408dfb" : "",
                    }}
                    onClick={() =>
                      handleAddRemoveAmenities(
                        getValue(item, `id`, ""),
                        isSelected ? "remove" : "add"
                      )
                    }
                  >
                    <p>{getValue(item, `lable`, "")}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyRoomAmenities;

const ProgressSteps = ({ activeStep, steps, totalSteps }: any) => {
  const progressWidth = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;
  return (
    <div className="progress-bar-wrapper">
      <div
        className="step-container mb-3 mx-5"
        style={{ "--progress-width": progressWidth } as any}
      >
        {steps.map(({ stepNo, label, index }: any) => (
          <div className="step-wrapper" key={index}>
            <div
              className={`step-style ${
                activeStep >= stepNo ? "completed" : ""
              }`}
            >
              {activeStep > stepNo ? (
                <div className="check-mark">L</div>
              ) : (
                <span className="step-count">{stepNo}</span>
              )}
            </div>
            <div className="steps-label-container">
              <span className="small_text__14 text-nowrap">{label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressSteps;

import CloseMainSvgComponent from "@assets/svg/close-main";
import InfoSvgComponent from "@assets/svg/info";
import ProgressBarLoader from "@components/common/Loader/progress-loader";
import { getValue } from "@utils/lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { toast } from "sonner";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px",
  borderWidth: 1,
  borderRadius: "20px",
  borderColor: "#90c6fe",
  borderStyle: "dashed",
  boxShadow: "0px 2px 10px 0px rgba(39,49,58,.2)",
  backgroundColor: "#fff",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
  minHeight: "250px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#adff2f",
};

const rejectStyle = {
  borderColor: "red",
};
const hasDoubleExtension = (filename) => {
  return /\.\w+\.\w+/.test(filename);
};
const hasInvalidFileName = (filename) => {
  // Check for files without a filename (e.g., .htaccess) or specific configuration files (e.g., web.config)
  return filename.startsWith(".") || filename.toLowerCase() === "web.config";
};

function DragAndDropFileComponent(props) {
  const [files, setFiles] = useState([]);

  const supportedFormats =
    props.supportedFormats || ".csv,.xlsx; max file size 5 MB";

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (!props.isLoading) {
        const filteredAcceptedFiles = acceptedFiles.filter(
          (file) =>
            fileTypes.includes(file.type) &&
            !hasDoubleExtension(file.name) &&
            !hasInvalidFileName(file.name)
        );
        const filteredRejectedFiles =
          (rejectedFiles, `length`, 0) > 0
            ? rejectedFiles
            : acceptedFiles.filter(
                (file) =>
                  !fileTypes.includes(file.type) ||
                  hasInvalidFileName(file.name) ||
                  hasDoubleExtension(file.name) ||
                  file.size > 10 * 1024 * 1024
              );

        // Display error messages for rejected files
        filteredRejectedFiles.forEach((rejectedFile) => {
          if (!fileTypes.includes(rejectedFile.type)) {
            toast.error(`File type not supported: ${rejectedFile.name}`);
          } else if (hasDoubleExtension(rejectedFile.name)) {
            toast.error(`File contains double extension: ${rejectedFile.name}`);
          } else if (hasInvalidFileName(rejectedFile.name)) {
            toast.error(`Invalid file name: ${rejectedFile.name}`);
          } else {
            toast.error(
              `File exceeds the maximum size (10MB): ${rejectedFile.name}`
            );
          }
        });

        props.uploadFile(filteredAcceptedFiles);
        setFiles(
          filteredAcceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    },
    [props.uploadFile]
  );

  const fileTypes = [
    "application/pdf", // PDF
    "image/jpeg", // JPEG
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
    "text/csv", // CSV
    "application/vnd.ms-excel.sheet.macroEnabled.12", // XLSM
    "application/vnd.ms-excel", // XLS
    "image/jpeg", // JPG
    "image/png", // PNG
    "image/svg+xml", // SVG
  ];
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: fileTypes.join(","),
    maxSize: 10 * 1024 * 1024, // 10MB in bytes
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = acceptedFiles.map((file) => (
    <div key={file.name}>
      <p>{file.name} </p>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });
  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  return (
    <section style={{ cursor: "pointer" }} className="mt-3">
      <div {...getRootProps({ style })} className="">
        {!props.isLoading ? (
          <>
            <input {...getInputProps()} />
            <img
              src="/images/upload.jpeg"
              className="img-fluid d-flex align-items-center justify-content-center"
              height={"100px"}
              width={"100px"}
            />
            <div className="custom-inputfile">
              <div className="d-block">
                <p className="drag-drop-text text-secondary">
                  Drag & Drop / Browse Files
                </p>
              </div>
              {getValue(props, `file`, "") !== "false" &&
                getValue(props, `fileName`, "") && (
                  <div
                    className="d-flex mb-2 mt-3"
                    style={{ justifyContent: "center" }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "0px",
                        textAlign: "center",
                      }}
                    >
                      {getValue(props, `fileName`, "")}
                    </p>
                    <div
                      onClick={() => props.setImportTedFile("")}
                      style={{ marginLeft: "7px" }}
                    >
                      <CloseMainSvgComponent size={16} />
                    </div>
                  </div>
                )}
            </div>
            <div className="mt-2 mb-2">
              <p className="helper-text mt-3">
                (Supported formats {supportedFormats})
              </p>
              {/* <p className="helper-text text-secondary mt-2 text-center">
            Download a &nbsp;<Link to="#">sample CSV</Link>
          </p> */}
            </div>
            <p style={{ color: "red" }}> {fileRejectionItems} </p>
          </>
        ) : (
          <>
            <ProgressBarLoader />
            <p className="small_text__16 mt-4">
              Uploading in progress, kindly wait.
            </p>
          </>
        )}
      </div>
    </section>
  );
}

export default DragAndDropFileComponent;

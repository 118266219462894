import "./Pending.scss";
import * as React from "react";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import {
  contactAssociationInSale,
  contactDisAssociationInSale,
  getSpecificSale,
  updateSale,
} from "@services/deals.service";
import Loader from "@components/common/Loader/loading";
import { QueryRequestHelper } from "common/query-request-helper";
import _ from "lodash";
import { toast } from "sonner";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import { useStateContext } from "context/profileProvider";
import ButtonComponent from "@components/Form/Button/Button";
import {
  FormRequestHelper,
  FormRequestHelperWithNullFields,
  appendObjectValuesToArray,
} from "@components/helpers/request-helper";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { motion } from "framer-motion";
import { getAllModuleFields } from "@services/module-fields.service";
import CommonModuleDetailPage from "./main/CommonModuleDetail";
import ListLoader from "@components/common/Loader";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPipelineDetailsProps {}

const SalePipelineDetails: React.FunctionComponent<IPipelineDetailsProps> = () => {
  const params = useParams();
  const { userId, selectedModuleId, allModules } = useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    getData(true);
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const [data, setData] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const getData = async (loading: boolean) => {
    try {
      setIsLoading(loading);
      let response = await getSpecificSale(getValue(params, `id`, ""));
      if (response) {
        let resp = await getSpecificPipeline(
          selectedModuleId(),
          getValue(response, `data.pipeline_id`, "")
        );
        setStageList(
          getValue(resp, `data.stages`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `label`, ""),
            value: getValue(item, `name`, ""),
          }))
        );
        let fields =
          getValue(resp, `data.form_fields.length`, 0) > 0
            ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                ...getValue(item, `module_field`, {}),
                name: getValue(item, `name`, ""),
                quick_add: getValue(item, `quick_add`, ""),
                required: getValue(item, `required`, ""),
                seq_num: getValue(item, `seq_num`, ""),
              }))
            : [];
        // ---------------- Appending Fields Data ----------------- //
        let array: any = appendObjectValuesToArray(
          fields,
          getValue(response, `data`, {})
        );
        let fieldReq =
          getValue(array, `length`, 0) > 0
            ? array.filter(
                (item: object) =>
                  getValue(item, `api_name`, "") !== "amount" &&
                  getValue(item, `api_name`, "") !== "closing_date" &&
                  getValue(item, `api_name`, "") !== "name"
              )
            : [];
        let fieldPopReq =
          getValue(array, `length`, 0) > 0
            ? array.filter(
                (item: object) =>
                  getValue(item, `api_name`, "") === "amount" ||
                  getValue(item, `api_name`, "") === "name"
              )
            : [];
        setFields(fieldReq);
        setDirtyFields(fieldReq);
        setPopupFields(fieldPopReq);
        setDirtyPopupFields(fieldPopReq);
        // ---------------- Appending State Data ----------------- //
        setData(getValue(response, `data`, {}));
        let leftReq = {
          name: getValue(response, `data.name`, ""),
          amount: getValue(response, `data.amount`, ""),
          closing_date: getValue(response, `data.closing_date`, ""),
          stage: getValue(response, `data.pipeline_stage.id`, ""),
        };
        setLeftRequest({
          ...leftRequest,
          ...leftReq,
        });
        setDirtyLeftRequest({
          ...dirtyLeftRequest,
          ...leftReq,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const urlParamsFun = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    let request = QueryRequestHelper({ ...urlParams });
    return request;
  };

  const mainQueryRequest = React.useMemo(() => urlParamsFun(), [
    window.location.href,
  ]);

  /* -------------------------------------------------------------------------- */
  /*                               Left Section                                 */
  /* -------------------------------------------------------------------------- */

  const [leftRequest, setLeftRequest] = React.useState({
    name: "",
    amount: "",
    closing_date: "",
    stage: "",
  });
  const [dirtyLeftRequest, setDirtyLeftRequest] = React.useState({
    name: "",
    amount: "",
    closing_date: "",
    stage: "",
  });
  const [fields, setFields] = React.useState([]);
  const [dirtyFields, setDirtyFields] = React.useState([]);

  const [popupFields, setPopupFields] = React.useState([]);
  const [dirtyPopupFields, setDirtyPopupFields] = React.useState([]);

  let checkLeftRequestChanges = _.isEqual(leftRequest, dirtyLeftRequest);
  let checkfieldRequestChanges = _.isEqual(fields, dirtyFields);

  /* -------------------------------------------------------------------------- */
  /*                               Middle Section                               */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Right Section                                */
  /* -------------------------------------------------------------------------- */
  /* ---------------------------   All Pipelines  -------------------------------- */
  const [pipelineList, setPipelineList] = React.useState([]);
  const [formLoading, setFormLoading] = React.useState(false);
  const [formStageList, setFormStageList] = React.useState<any>([]);

  const getForm = async (module: string) => {
    setAssociationForm([]);
    if (module !== "tasks" && module !== "meetings" && module !== "calls") {
      try {
        let payload = {
          module_name: module,
        };
        let queryRequest = QueryRequestHelper(payload);
        setFormLoading(true);
        let findId =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) =>
                  getValue(item, `api_name`, "") ===
                  (module === "sales" ? "deals" : module)
              )
            : [];
        let resp = await allPipelines(getValue(findId, `id`, ""), queryRequest);
        if (resp) {
          getAssociationPipelineInfo(
            getValue(resp, `data[${0}].id`, ""),
            module
          );
          setFormStageList(
            getValue(resp, `data[${0}].stages`, []).map((item: object) => ({
              ...item,
              label: getValue(item, `label`, ""),
              value: getValue(item, `api_name`, ""),
            }))
          );
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {
        setFormLoading(false);
      }
    } else {
      try {
        let code =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) => getValue(item, `api_name`, "") == module
              )
            : [];
        let resp = await getAllModuleFields(getValue(code, `id`, ""));
        if (resp) {
          let fields =
            getValue(resp, `data.length`, 0) > 0
              ? getValue(resp, `data`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `module_field.api_name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setAssociationForm(fields);
        }
      } catch (error) {}
    }
  };
  const [associationForm, setAssociationForm] = React.useState([]);
  const getAssociationPipelineInfo = async (id: string, module: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let fields =
            getValue(resp, `data.form_fields.length`, 0) > 0
              ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            stage: getValue(resp, `data.stages[${0}].id`, ""),
            pipeline_id: id,
          }));
          setAssociationForm(fields);
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    } else {
      setForm([]);
    }
  };

  /* ---------------------------  Pipeline By ID  -------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = React.useState({
    pipeline_id: "",
    stage: "",
  });
  const [stageList, setStageList] = React.useState<any>([]);
  const [form, setForm] = React.useState([]);
  const getSpecificPipelineInfo = async (id: string, module: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let fields =
            getValue(resp, `data.form_fields.length`, 0) > 0
              ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setForm(fields);
          if (module !== "tasks") {
            setStaticFieldRequest((prevStaticFieldRequest) => ({
              ...prevStaticFieldRequest,
              stage: getValue(resp, `data.stages[${0}].name`, ""),
              pipeline_id: getValue(resp, `data.id`, ""),
            }));
            setStageList(
              getValue(resp, `data.stages`, []).map((item: object) => ({
                ...item,
                label: getValue(item, `label`, ""),
                value: getValue(item, `name`, ""),
              }))
            );
          }
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    } else {
      setForm([]);
    }
  };
  /* ---------------------------  Association / DisAssociate Pipeline  --------------------- */

  const handleAssociate = async (id: string, module: string) => {
    if (module) {
      let payload = {
        api_name: module,
        record_id: id,
      };
      let resp = await contactAssociationInSale(
        getValue(params, `id`, ""),
        payload
      );
      if (resp) {
        getData(false);
        toast.success("Associated successfully");
      }
    }
  };
  const handleDisAssociate = async (id: string, module: string) => {
    if (module) {
      let payload = {
        api_name: module,
        record_id: id,
      };
      let resp = await contactDisAssociationInSale(
        getValue(params, `id`, ""),
        payload
      );
      if (resp) {
        getData(false);
        toast.success("Disassociated successfully");
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Footer Section                               */
  /* -------------------------------------------------------------------------- */
  const handleReset = async () => {
    setLeftRequest(dirtyLeftRequest);
    setFields(dirtyFields);
  };
  const handleCancel = () => {
    handleReset();
  };
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const handleSubmit = async () => {
    try {
      let payload: any = {
        name: getValue(leftRequest, `name`, ""),
        amount: parseFloat(getValue(leftRequest, `amount`, 0)),
        closing_date: getValue(leftRequest, `closing_date`, ""),
        pipeline_stage_id: getValue(leftRequest, `stage`, ""),
      };
      // payload["amount"] = parseFloat(getValue(leftRequest, `amount`, 0));
      let fieldReq = JSON.parse(JSON.stringify(fields));
      let payloadNew = FormRequestHelperWithNullFields(fieldReq);
      let requestPayload: any = { ...payload, ...payloadNew };
      setSubmitLoading(true);
      let resp = await updateSale(getValue(params, `id`, ""), requestPayload);
      if (resp) {
        toast.success("Updated successfully");
        getData(false);
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  const [duplicateAPINames, setDuplicateAPINames] = React.useState<any>([]);
  return (
    <MainLayout>
      {isLoading ? (
        <ListLoader />
      ) : (
        <CommonModuleDetailPage
          data={data}
          params={params}
          backUrl={`/${getValue(
            params,
            `orgId`,
            ""
          )}/crm/deals?${mainQueryRequest}`}
          isChanged={!checkLeftRequestChanges || !checkfieldRequestChanges}
          request={leftRequest}
          setRequest={setLeftRequest}
          fields={fields}
          setFields={setFields}
          popupFields={popupFields}
          setPopupFields={setPopupFields}
          getData={getData}
          // ------------ Right Section Props ----------- //
          form={form}
          setForm={setForm}
          formLoading={formLoading}
          handleAssociate={handleAssociate}
          handleDisAssociate={handleDisAssociate}
          getForm={getForm}
          module={"deals"}
          //static dropddowns
          pipelineList={pipelineList}
          stageList={stageList}
          staticFieldRequest={staticFieldRequest}
          setStaticFieldRequest={setStaticFieldRequest}
          getSpecificPipelineInfo={getSpecificPipelineInfo}
          formStageList={formStageList}
          associationForm={associationForm}
          setAssociationForm={setAssociationForm}
          duplicateAPINames={duplicateAPINames}
          setDuplicateAPINames={setDuplicateAPINames}
        />
      )}
      {(!checkLeftRequestChanges || !checkfieldRequestChanges) && (
        <motion.div
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          exit={{
            y: 50,
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" },
          }}
        >
          <div className={"footer_container"}>
            <div className={"button_container"}>
              <div className="d-flex align-items-center">
                <ButtonComponent
                  buttonType={"scecondary"}
                  buttonText={"Cancel"}
                  onClickHandler={() => handleCancel()}
                  // squre
                />
                <ButtonComponent
                  buttonType={"primary"}
                  buttonText={submitLoading ? "Please wait..." : "Update"}
                  onClickHandler={() => handleSubmit()}
                  disabled={submitLoading || duplicateAPINames.length > 0}
                  // squre
                />
              </div>
            </div>
            <p className={"text"}>You have unsaved changes</p>
          </div>
        </motion.div>
      )}
    </MainLayout>
  );
};

export default SalePipelineDetails;

import AddSvgComponent from "@assets/svg/add";
import BackSvgComponent from "@assets/svg/back-link";
import DeleteSvgComponent from "@assets/svg/delete";
import EditSvgComponent from "@assets/svg/edit";
import ImageSvgComponent from "@assets/svg/image";
import MoneySvgComponent from "@assets/svg/money";
import PersonSvgComponent from "@assets/svg/person";
import RoomPreferencesSvgComponent from "@assets/svg/room";
import { capitalizeFirstLetter } from "@common/text-helpers";
import Loader from "@components/common/Loader/loading";
import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

function PropertBookingTypes(props: any) {
  const {
    rooms,
    submitLoading,
    isLoading,
    handleModifyRoom,
    handleEditRoomProfile,
    handleDelete,
    handleEditRoomAmenities,
    handleEditRoomImages,
    setRoomTypeActiveTab,
    setEditRoomId,
    reset,
  } = props;
  const navigate = useNavigate();
  const params = useParams();

  const IconWithTooltip = ({
    onClick,
    tooltipText,
    tooltipClass,
    SvgComponent,
    svgColor,
    children,
  }: {
    onClick: (e: any) => void;
    tooltipText: string;
    tooltipClass: string;
    SvgComponent: React.FC<{ color: string }>;
    svgColor?: string;
    children?: React.ReactNode;
  }) => (
    <div
      onClick={(e: any) => {
        e.stopPropagation();
        onClick(e);
      }}
      className={`cursor-pointer icon_circle ${tooltipClass}`}
    >
      <Tooltip className="tooltip" anchorSelect={`.${tooltipClass}`}>
        {tooltipText}
      </Tooltip>
      {SvgComponent ? <SvgComponent color={svgColor || "#000"} /> : children}
    </div>
  );

  return (
    <div>
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            {
              <div
                onClick={() =>
                  getValue(props, `UrlParams.fromEdit`, "")
                    ? navigate(
                        `/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/property/details/${getValue(
                          params,
                          `id`,
                          ""
                        )}/settings`
                      )
                    : navigate(
                        `/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/property/amenities/${getValue(params, `id`, "")}`
                      )
                }
              >
                <BackSvgComponent />
              </div>
            }

            <p className="small_text__16 ms-3">{"Property room details"}</p>
          </div>
          <div className="d-flex align-items-center">
            <ButtonComponent
              buttonType="primary"
              buttonText={"Finish "}
              onClickHandler={() =>
                navigate(
                  `/${getValue(
                    params,
                    `orgId`,
                    ""
                  )}/property/details/${getValue(params, `id`, "")}/settings`
                )
              }
            />
          </div>
        </div>
      </div>
      <div className={`w-100 manageFieldRight px-5  add-overflow-y`}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="bed_type_container d- flex-wrap py-5">
            <div
              className="bed_type-add d-flex justify-content-center align-items-center flex-column gap-1"
              onClick={() => {
                reset();
                setEditRoomId("");
                setRoomTypeActiveTab(1);
                handleModifyRoom();
              }}
            >
              <AddSvgComponent color="#fff" />
              <p className="text-white small_text__20">Add new room</p>
            </div>
            {getValue(rooms, `length`, 0) > 0 &&
              rooms.map((bed: any, index: any) => (
                <div className="bed_type_details-edit" key={index}>
                  <div className="bed_type-background d-flex justify-content-between flex-column gap-4">
                    <div className="d-flex justify-content-between align-items-start gap-4">
                      <p className="small_text__16 text-white">
                        {capitalizeFirstLetter(getValue(bed, `name`, ""))}
                      </p>
                      <div className="d-flex gap-2 align-items-center">
                        <IconWithTooltip
                          onClick={() =>
                            handleEditRoomProfile(getValue(bed, "id", ""))
                          }
                          tooltipText="Edit room details"
                          tooltipClass="edit-room"
                          SvgComponent={EditSvgComponent}
                        />

                        <IconWithTooltip
                          onClick={() =>
                            handleEditRoomAmenities(getValue(bed, "id", ""))
                          }
                          tooltipText="Add Amenities"
                          tooltipClass="add-room-amenities"
                          SvgComponent={RoomPreferencesSvgComponent}
                        />
                        <IconWithTooltip
                          onClick={() =>
                            handleEditRoomImages(getValue(bed, "id", ""))
                          }
                          tooltipText="Images"
                          tooltipClass="add-room-images"
                          SvgComponent={ImageSvgComponent}
                        />

                        {/* <IconWithTooltip
                          onClick={() => handleDelete(getValue(bed, "id", ""))}
                          tooltipText="Add images"
                          tooltipClass="add-room-images"
                          SvgComponent={DeleteSvgComponent}
                        /> */}
                      </div>
                    </div>
                    <div className="d-grid gap-2 mt-3">
                      <div className="d-flex align-items-center gap-2">
                        <p>
                          <MoneySvgComponent size="18" color="#dddddd" />
                        </p>
                        <span className="small_text__14 card-text-white">
                          {getValue(bed, `default_price`, "")}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <p>
                            <PersonSvgComponent size="18" color="#dddddd" />
                          </p>
                          <span className="small_text__14 card-text-white">
                            Max adults {getValue(bed, `max_adult`, "")}
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <p>
                            <PersonSvgComponent size="18" color="#dddddd" />
                          </p>
                          <span className="small_text__14 card-text-white">
                            Max children {getValue(bed, `max_children`, "")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertBookingTypes;

import DeleteSvgComponent from "@assets/svg/delete";
import { getValue } from "@utils/lodash";
import { Handle, Position } from "reactflow";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import FileSvgComponent from "@assets/svg/file";
import ChatSvgComponent from "@assets/svg/chat";

function BotTemplateOption(props: any) {
  const { data, handleDelete, handleStyle } = props;
  const findConnectedEdgeInfo = (id: string) => {
    let obj =
      getValue(data, `edges.length`, 0) > 0
        ? getValue(data, `edges`, []).find(
            (item: object) => getValue(item, `sourceHandle`, "") === id
          )
        : {};
    return getValue(obj, `sourceHandle`, "");
  };
  return (
    <>
      <div className="custom-node-header">
        <p className="small_text__14 text-black d-flex align-items-center gap-2">
          <ChatSvgComponent color="#0083ff" size="18" />
          {getValue(data, `label`, "")}
        </p>
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(getValue(data, `id`, ""));
          }}
          className="custom-node-delete"
        >
          <DeleteSvgComponent color={"red"} size={18} />
        </div>
      </div>
      {/* <div className="custom-button-body">
        <p
          className="small_text__14"
          contentEditable="true"
          dangerouslySetInnerHTML={{
            __html: getValue(data, `description`, ""),
          }}
        ></p>
      </div> */}
      <Handle type="target" position={Position.Left} style={handleStyle} />
      {/* <Handle type="source" position={Position.Right} style={handleStyle} /> */}
      <div className="custom-node-body-button">
        {getValue(data, `options`, []).map((item: object, index: number) => {
          return (
            <>
              <div key={index} className="output-item">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <p className="small_text__14">
                      {getValue(item, `text`, "")}
                    </p>
                    <div>
                      <Handle
                        type="source"
                        id={getValue(item, `id`, "")}
                        position={Position.Right}
                        style={handleStyle}
                      />
                    </div>
                  </div>
                  {findConnectedEdgeInfo(getValue(item, `id`, "")) !==
                    getValue(item, `id`, "") && (
                    <div
                      style={{
                        // position: "absolute",
                        right: "20px",
                        // top: "50%",
                        // background: "white",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        data.addNodeByID(
                          getValue(data, `id`, ""),
                          getValue(item, `id`, "")
                        );
                      }}
                    >
                      <AddCircleSvgComponent color={"black"} size={28} />
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
      {/* <div className="visitor_section">
        <h6 className="header_text__14 text-center">Button</h6>
      </div> */}
    </>
  );
}

export default BotTemplateOption;

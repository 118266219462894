import PaginationPage from "@components/common/Pagination/pagination";
function Pagination(props: any) {
  const {
    totalCount,
    limit,
    page_no,
    handleChangePagination,
    handleChangeLimit,
    moreLimit,
    showLimit,
    showTotalCount
  } = props;
  return (
    <>
      {totalCount > 1 && (
        <div className={"table_footer_container"}>
          {totalCount > 10 && (
          <PaginationPage
            totalCount={totalCount}
            limit={limit}
            pageNumber={page_no}
            handleChangePagination={handleChangePagination}
            handleChangeLimit={handleChangeLimit}
            moreLimit={moreLimit}
            showLimit={showLimit}
            showTotalCount={showTotalCount}
          />
          )}
        </div>
      )}
    </>
  );
}

export default Pagination;

import React from "react";
import { getValue } from "@utils/lodash";
import DescriptionInfo from "../common/DescInfo";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";

function TaskDeatilLeadsList(props: any) {
  const { isRollsListPopupVisibile, setRollsListPopupVisisbility } = props;
  return (
    <SlideFromRightPopup
      isPopupVisible={isRollsListPopupVisibile}
      addCustomClass={"rolls-popup"}
      toggle={() => {
        setRollsListPopupVisisbility(false);
      }}
      title="Leads"
      width={"570px"}
    >
      <div className={"task_more-list"}>
        {getValue(props, `leads`, []).map((item: object, index: number) => {
          return (
            <div key={index}>
              <DescriptionInfo
                item={item}
                index={index}
                handleDeleteView={props.handleDeleteView}
                module={"leads"}
              />
            </div>
          );
        })}
      </div>
    </SlideFromRightPopup>
  );
}

export default TaskDeatilLeadsList;

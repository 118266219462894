import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllTags = (pipeline_id: string) =>
  get(`${config.API_URL}/settings/module/${pipeline_id}/tags/list`);

export const getAllTagsList = (pipeline_id: string, queryRequest: string) =>
  get(
    `${config.API_URL}/settings/module/${pipeline_id}/tags/list?${queryRequest}`
  );

export const getSpecificTag = (pipeline_id: string, id: string) =>
  get(`${config.API_URL}/settings/module/${pipeline_id}/tags/${id}`);

export const createTag = (pipeline_id: string, payload: object) =>
  post(`${config.API_URL}/settings/module/${pipeline_id}/tags`, payload);

export const updateTag = (pipeline_id: string, id: string, payload: object) =>
  patch(`${config.API_URL}/settings/module/${pipeline_id}/tags/${id}`, payload);

export const deleteTag = (pipeline_id: string, id: string) =>
  Delete(`${config.API_URL}/settings/module/${pipeline_id}/tags/${id}`);

export const updateModuleTags = (module: string, id: string, payload: object) =>
  patch(`${config.API_URL}/records/${module}/${id}`, payload);

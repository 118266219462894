import InfoSvgComponent from "@assets/svg/info";
import { handleNumberChange } from "@common/handleChange";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import InputRuleForm from "@components/InputRuleForm/form";
import { recordPayment } from "@services/properties/property-booking.service";
import { getValue } from "@utils/lodash";
import { useState } from "react";
import { toast } from "sonner";

const paymentList = [
  { value: "cash", label: "Cash" },
  { value: "offline_card_payment", label: "Offline Card Payment" },
  { value: "upi", label: "UPI" },
  { value: "other", label: "Other" },
  { value: "bank_transfer", label: "Bank Transfer" },
];

const SettlePayment = (props: any) => {
  const {
    openCheckin,
    toggleCheckin,
    bookingInfo,
    updatingFlag,
    setUpdatingFlag,
  } = props;

  const [request, setRequest] = useState({
    amount: 0,
    payment_method: "cash",
  });

  const [loading, setLoading] = useState(false);

  const handleRecordPayment = async () => {
    try {
      const payload = {
        ...request,
        amount: parseFloat(getValue(request, `amount`, 0)),
        // room_booking_id: parseFloat(
        //   getValue(bookingInfo, "room_booking_info[0].id", 0)
        // ),
      };

      setLoading(true);

      let resp = await recordPayment(getValue(bookingInfo, "id", ""), payload);

      if (resp) {
        setUpdatingFlag(!updatingFlag);
        toast.success("Updated payment successfully");
        setLoading(false);
        setRequest((prevRequest) => ({
          ...prevRequest,
          amount: 0,
        }));
        toggleCheckin();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toggleCheckin();
    }
  };

  return (
    <CommonRightPopup
      isPopupVisible={openCheckin}
      toggle={toggleCheckin}
      width="500px"
      title="Settle Payment"
      hideNew
      second_title="Update"
      addZIndex
      handleSubmit={handleRecordPayment}
      submitLoading={loading}
    >
      <div className="pt-4 px-5">
        <div className="d-flex justify-content-center">
          <div className="information-container">
            <InfoSvgComponent color="#1a73e8" size="18" />
            You are about to settle amount
          </div>
        </div>
        <div className="mt-4">
          <SearchToolTip
            label="label"
            name="Payment Type"
            data={paymentList}
            value={getValue(request, `payment_method`, "")}
            onChange={(e: object) =>
              setRequest({
                ...request,
                payment_method: getValue(e, `value`, ""),
              })
            }
            selectKey={"value"}
          />
          <InputRuleForm
            inputType="NUMBER"
            name="amount"
            placeholder="Enter Amount"
            label="Amount"
            value={getValue(request, `amount`, "")}
            onChange={(e: any) => handleNumberChange(e, request, setRequest)}
          />
        </div>
      </div>
    </CommonRightPopup>
  );
};

export default SettlePayment;

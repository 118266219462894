import React from "react";
import ConfirmImportPopup from "./popup/confirm-import";
import { getValue } from "@utils/lodash";
import { Button, Tooltip } from "antd";

function ImportSubmitButton(props: any) {
  const {
    currentStep,
    setCurrentStep,
    handleClose,
    setShowConfirmation,
    showConfirmation,
    handleSubmitData,
    submitLoading,
    info,
  } = props;
  const handleNext = () => {
    // if (getValue(info, `column_mapping.length`, 0) > 0) {}
    setCurrentStep((prevStep: any) => prevStep + 1);
  };
  const handleGoBack = () => {
    setCurrentStep((prevStep: any) => Math.max(1, prevStep - 1));
  };

  const handleGoBackWithConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleConfirmGoBack = () => {
    handleGoBack();
    setShowConfirmation(false);
  };
  const handleCancelGoBack = () => {
    setShowConfirmation(false);
  };

  switch (currentStep) {
    case 1:
      return (
        <>
          <Button onClick={handleClose} className="cancel-button">
            Cancel
          </Button>
          <Button
            onClick={handleNext}
            // className="next-button"
            type="primary"
          >
            Next
          </Button>
        </>
      );
    // case 2:
    //   return (
    //     <>
    //       <button onClick={handleGoBack} className="cancel-button">
    //         Go back
    //       </button>
    //       <button onClick={handleNext} className="next-button">
    //         Next
    //       </button>
    //     </>
    //   );

    case 2:
      return (
        <>
          <Button
            onClick={handleGoBack}
            // className="cancel-button"
          >
            Go back
          </Button>
          <Button
            onClick={handleNext}
            type="primary"
            // className="next-button"
            disabled={
              getValue(info, `column_mapping.length`, 0) === 0 ? true : false
            }
          >
            Next
          </Button>
        </>
      );
    case 3:
      return (
        <>
          <Button
            onClick={handleGoBackWithConfirmation}
            // className="cancel-button"
          >
            Go back
          </Button>
          <Button
            type="primary"
            onClick={handleNext}
            //  className="next-button"
          >
            Next
          </Button>
          <ConfirmImportPopup
            open={showConfirmation}
            onClose={handleCancelGoBack}
            onConfirm={() => {
              handleConfirmGoBack();
              props.setInfo({});
            }}
          />
        </>
      );
    case 4:
      return (
        <>
          <Button
            onClick={handleGoBack}
            // className="cancel-button"
          >
            Go back
          </Button>
          <Tooltip
            title={
              props.disabled
                ? "Please fix the matching criteria to proceed"
                : "Import Now"
            }
          >
            <Button
              onClick={handleSubmitData}
              // className="next-button"
              disabled={props.disabled}
              type="primary"
            >
              {submitLoading ? "Importing..." : "Import Now"}
            </Button>
          </Tooltip>
        </>
      );
    default:
      return null;
  }
}

export default ImportSubmitButton;

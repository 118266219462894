import "./custom-check.scss";
export default function CustomAllCheckbox(props: any) {
  return (
    <label className="m-0 position-relative">
      <input
        type="checkbox"
        className={"default-checkbox"}
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      {props.disabled ? (
        <img
          src={`/images/icons/mandatory-lock.svg`}
          className={`mandatory-lock img-fluid`}
        />
      ) : (
        <div className={`custom-checkbox`}>
          <img
            src={`/images/icons/${props.disabled ? "gray" : "blue"}-minus.svg`}
            className={`uploaded-tick img-fluid`}
          />
        </div>
      )}
    </label>
  );
}

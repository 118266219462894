import React, { useEffect, useState } from "react";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import { getValue } from "@utils/lodash";
import { config } from "../../../../env";
import CrossSvgComponent from "@assets/svg/cross";
import EmojiPicker from "emoji-picker-react";
import WhatsappAddHover from "@components/common/Tooltip/whats-app-add";
import AddSvgComponent from "@assets/svg/add";
import SmileSvgComponent from "@assets/svg/smile";
import ResendSvgComponent from "@assets/svg/resend";
import { formHandleTextArrayChangeByName } from "common/handleChange";
import Loader from "@components/common/Loader/loading";
function ChatDocumentPreview(props: any) {
  const {
    showEmojiPicker,
    handleEmojiClick,
    toggleEmojiPicker,
    reference,
    setDocExpanded,
    docExpanded,
    handleUploadDocument,
    documentRequest,
    setDocumentRequest,
    handleSendDocumentMessages,
    isSendLoading,
  } = props;

  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  useEffect(() => {
    setSelectedDocumentIndex(0);
  }, []);

  const handleRemoveDocuemnt = (index: number) => {
    let filtered = documentRequest.filter(
      (item: object, i: number) => i !== index
    );
    setDocumentRequest(filtered);
    setSelectedDocumentIndex(0);
  };
  return (
    <div>
      <div className="chat-doc-header d-flex align-items-center justify-content-between px-4">
        <div onClick={() => setDocExpanded(!docExpanded)}>
          <DropdownCloseSvgComponent />
        </div>
        <div>
          <h6 className="header_text__16">
            {getValue(
              documentRequest,
              `[${selectedDocumentIndex}]fileId.filename`,
              ""
            )}
          </h6>
        </div>
        <div></div>
      </div>

      <div className=" d-flex justify-content-center align-items-center mt-5 position-relative">
        {getValue(documentRequest, `length`, 0) > 0 && (
          <div
            className="chat_cross"
            onClick={() => handleRemoveDocuemnt(selectedDocumentIndex)}
          >
            <CrossSvgComponent />
          </div>
        )}
        {(getValue(
          documentRequest,
          `[${
            selectedDocumentIndex ? selectedDocumentIndex : 0
          }]fileId.extension`,
          ""
        ) === ".jpg" ||
          getValue(
            documentRequest,
            `[${
              selectedDocumentIndex ? selectedDocumentIndex : 0
            }]fileId.extension`,
            ""
          ) === ".png" ||
          getValue(
            documentRequest,
            `[${
              selectedDocumentIndex ? selectedDocumentIndex : 0
            }]fileId.extension`,
            ""
          ) === ".jpeg" ||
          getValue(
            documentRequest,
            `[${
              selectedDocumentIndex ? selectedDocumentIndex : 0
            }]fileId.extension`,
            ""
          ) === ".svg") && (
          <div>
            <div>
              <img
                src={
                  getValue(
                    documentRequest,
                    `[${
                      selectedDocumentIndex ? selectedDocumentIndex : 0
                    }]fileId.key`,
                    ""
                  ) &&
                  `https://${config.URL}/${getValue(
                    documentRequest,
                    `[${
                      selectedDocumentIndex ? selectedDocumentIndex : 0
                    }]fileId.key`,
                    ""
                  )}`
                }
                alt="Selected"
                className="doc_preview_main_image"
              />
            </div>
          </div>
        )}

        {getValue(
          documentRequest,
          `[${
            selectedDocumentIndex ? selectedDocumentIndex : 0
          }]fileId.extension`,
          ""
        ) === ".mp4" && (
          <div className="">
            <div>
              <video controls className="doc_preview_main_image">
                <source
                  src={
                    getValue(
                      documentRequest,
                      `[${
                        selectedDocumentIndex ? selectedDocumentIndex : 0
                      }]fileId.key`,
                      ""
                    ) &&
                    `https://${config.URL}/${getValue(
                      documentRequest,
                      `[${
                        selectedDocumentIndex ? selectedDocumentIndex : 0
                      }]fileId.key`,
                      ""
                    )}`
                  }
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        )}

        {getValue(
          documentRequest,
          `[${
            selectedDocumentIndex ? selectedDocumentIndex : 0
          }]fileId.extension`,
          ""
        ) === ".mp3" && (
          <div className="">
            <div>
              <audio controls className="sender-message">
                <source
                  src={
                    getValue(
                      documentRequest,
                      `[${
                        selectedDocumentIndex ? selectedDocumentIndex : 0
                      }]fileId.key`,
                      ""
                    ) &&
                    `https://${config.URL}/${getValue(
                      documentRequest,
                      `[${
                        selectedDocumentIndex ? selectedDocumentIndex : 0
                      }]fileId.key`,
                      ""
                    )}`
                  }
                  type="audio/ogg"
                />
              </audio>
            </div>
          </div>
        )}

        {(getValue(
          documentRequest,
          `[${
            selectedDocumentIndex ? selectedDocumentIndex : 0
          }]fileId.extension`,
          ""
        ) === ".pdf" ||
          getValue(
            documentRequest,
            `[${
              selectedDocumentIndex ? selectedDocumentIndex : 0
            }]fileId.extension`,
            ""
          ) === ".docx") && (
          <div>
            <iframe
              src={
                getValue(
                  documentRequest,
                  `[${
                    selectedDocumentIndex ? selectedDocumentIndex : 0
                  }]fileId.key`,
                  ""
                ) &&
                `https://${config.URL}/${getValue(
                  documentRequest,
                  `[${
                    selectedDocumentIndex ? selectedDocumentIndex : 0
                  }]fileId.key`,
                  ""
                )}`
              }
              title="PDF Viewer"
              width="100%"
              className="doc_preview_main_image"
              // height="300px"
              style={{
                border: "none",
              }}
            />
          </div>
        )}
      </div>
      <div className="chat-doc-footer">
        <div>
          <div className="message-bottom-section p-2">
            <div
              style={{
                position: "absolute",
                bottom: "60px",
                left: "60px",
              }}
            >
              {showEmojiPicker && (
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              )}
            </div>
            <div className="d-flex justify-content-around align-items-center">
              <div className="cursor-pointer">
                <WhatsappAddHover
                  header={<AddSvgComponent color={"#8696a0"} size={26} />}
                  text={"Please add all required information"}
                  width={"200px"}
                  height={"200px"}
                  hover
                  handleUploadDocument={handleUploadDocument}
                />
              </div>

              <div
                className="d-flex align-items-center emoji-editor"
                onClick={toggleEmojiPicker}
              >
                <SmileSvgComponent color={"#8696a0"} />
              </div>

              <input
                type="text"
                placeholder="Type a message"
                className="message-editor"
                value={getValue(
                  documentRequest,
                  `[${selectedDocumentIndex}].textBody`,
                  ""
                )}
                name={"textBody"}
                ref={reference}
                onChange={(e) =>
                  formHandleTextArrayChangeByName(
                    selectedDocumentIndex,
                    e,
                    setDocumentRequest
                  )
                }
              />
              <button
                onClick={handleSendDocumentMessages}
                className={`cursor-pointer ${isSendLoading && "opacity-25"}`}
                disabled={isSendLoading}
              >
                <ResendSvgComponent color={"#8696a0"} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="doc_thumnail">
        <div className="d-flex align-items-center gap-3 flex-wrap justify-content-center">
          {getValue(documentRequest, `length`, 0) > 0 &&
            documentRequest.map((item: object, key: number) => {
              return (
                <div
                  key={key}
                  className=""
                  onClick={() => setSelectedDocumentIndex(key)}
                >
                  {(getValue(item, `fileId.extension`, "") === ".jpg" ||
                    getValue(item, `fileId.extension`, "") === ".png" ||
                    getValue(item, `fileId.extension`, "") === ".jpeg" ||
                    getValue(item, `fileId.extension`, "") === ".svg") && (
                    <div>
                      <img
                        src={
                          getValue(item, `fileId.key`, "") &&
                          `https://${config.URL}/${getValue(
                            item,
                            `fileId.key`,
                            ""
                          )}`
                        }
                        alt="Selected"
                        className={`doc_preview_image ${
                          selectedDocumentIndex === key ? "active" : ""
                        }`}
                      />
                    </div>
                  )}

                  {getValue(item, `fileId.extension`, "") === ".mp4" && (
                    <div>
                      <video
                        controls
                        className={`doc_preview_image ${
                          selectedDocumentIndex === key ? "active" : ""
                        }`}
                      >
                        <source
                          src={
                            getValue(item, `fileId.key`, "") &&
                            `https://${config.URL}/${getValue(
                              item,
                              `fileId.key`,
                              ""
                            )}`
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  )}

                  {getValue(item, `fileId.extension`, "") === ".mp3" && (
                    <div>
                      <audio
                        controls
                        className={`doc_preview_image ${
                          selectedDocumentIndex === key ? "active" : ""
                        }`}
                      >
                        <source
                          src={
                            getValue(item, `fileId.key`, "") &&
                            `https://${config.URL}/${getValue(
                              item,
                              `fileId.key`,
                              ""
                            )}`
                          }
                          type="audio/ogg"
                        />
                      </audio>
                    </div>
                  )}

                  {(getValue(item, `fileId.extension`, "") === ".pdf" ||
                    getValue(item, `fileId.extension`, "") === ".docx") && (
                    <>
                      <div>
                        <iframe
                          src={
                            getValue(item, `fileId.key`, "") &&
                            `https://${config.URL}/${getValue(
                              item,
                              `fileId.key`,
                              ""
                            )}`
                          }
                          title="PDF Viewer"
                          width="100%"
                          className={`doc_preview_image ${
                            selectedDocumentIndex === key ? "active" : ""
                          }`}
                          // height="300px"
                          style={{
                            border: "none",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default ChatDocumentPreview;

import {
  capitalizeAfterSpace,
  capitalizeFirstLetter,
  formatString,
} from "common/text-helpers";
import "./CommonPopupHeader.scss";
import { getValue } from "@utils/lodash";
import BackSvgComponent from "@assets/svg/back-link";
import EditSvgComponent from "@assets/svg/edit";
import { useState } from "react";
import InputRuleForm from "@components/InputRuleForm/form";
import CloseMainSvgComponent from "@assets/svg/close-main";
import CloseSvgComponent from "@assets/svg/close";

export default function CommonPopupHeader(props: any) {
  const [headerEditor, setHeaderEditor] = useState(false);
  const toggleEditor = () => {
    setHeaderEditor(!headerEditor);
  };

  return (
    <div
      className={`add-more-rolls-wrapper__header d-flex align-items-center justify-content-between`}
      style={{ height: props.height }}
    >
      <div className="d-flex align-items-center">
        {getValue(props, `enableBack`, false) && (
          <div className="mx-2" onClick={props.navigateBack}>
            <BackSvgComponent color="#fff" />
          </div>
        )}
        {!headerEditor ? (
          <h2 className={"add-more-rolls-wrapper__header-title"}>
            {!props.isHeaderEditable ? (
              <>{capitalizeAfterSpace(getValue(props, `title`, ""))}</>
            ) : (
              <>
                {getValue(props, `request.name`, "")}
                <div className="header-edit-option" onClick={toggleEditor}>
                  <EditSvgComponent color="#fff" />
                </div>
              </>
            )}
          </h2>
        ) : (
          <div className="d-flex align-items-center gap-3">
            <InputRuleForm
              inputType="TEXT"
              value={getValue(props, `request.name`, "")}
              name="name"
              onChange={(e: any) =>
                props.setRequest({ ...props.request, name: e.target.value })
              }
              placeholder="Enter Name"
              hideFormLabel={true}
              isHeaderEditable={true}
            />
            {/* <CloseSvgComponent /> */}
          </div>
        )}
        {getValue(props, `moduleName`, "") && (
          <div
            className={`add-more-rolls-wrapper__header-container ms-3 px-3 p-2`}
          >
            <p>{formatString(getValue(props, `moduleName`, ""))}</p>
          </div>
        )}
      </div>
      <div
        className={"add-more-rolls-wrapper__close-btn"}
        onClick={() => {
          props.handleBackButton();
        }}
      >
        <img
          src="/images/icons/close-white.svg"
          className={`add-more-rolls-wrapper__close-icon img-fluid cursor-pointer`}
        />
      </div>
    </div>
  );
}

import { emojisList } from "@common/emojis";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React from "react";

function BotVisitorPhoneComponent(props: any) {
  const { request, setRequest } = props;
  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    setRequest({
      ...request,
      description: value,
    });
  };
  return (
    <div>
      {/* <div className="mb-2">
        <InputRuleForm
          inputType="TEXT"
          value={getValue(request, `name`, "")}
          name="name"
          onChange={(e: any) =>
            setRequest({ ...request, name: e.target.value })
          }
          label="Name"
          placeholder="Enter Name"
          // required
        />
      </div> */}
      <div className="mb-2">
        {/* <InputRuleForm
          inputType="TEXTAREA"
          value={getValue(request, `description`, "")}
          name="description"
          onChange={(e: any) =>
            setRequest({ ...request, description: e.target.value })
          }
          label="Message"
          placeholder="Enter Message"
          // mandatory
        /> */}
        <DraftJSRefEditor
          editorState={getValue(request, `description`, "")}
          handleChangeEditor={handleChangeEditor}
          name="content"
          placeholder="Enter here"
          editorStyle={{
            background: "white",
            paddingLeft: "20px",
            paddingTop: "20px",
            // height: "30vh",
          }}
          focusColor={"#1a73e8"}
          toolbar={{
            options: ["inline", "emoji"],
            inline: {
              options: ["bold", "italic"],
            },
            emoji: {
              emojis: emojisList,
            },
          }}
          hideFocus
          focus
          editorClassName={'bot-editorClassName'}
        />
      </div>
    </div>
  );
}

export default BotVisitorPhoneComponent;

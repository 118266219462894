import React from "react";
import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import { Modal } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";

function FormCreatorInfoPopup(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        open={getValue(props, `isOpen`, "")}
        // toggle={props.toggle}
        className="form-modal"
        onCancel={props.toggle}
      >
        {/* <ModalHeader> */}
        <h6 className="header_text__20">Choose the form type</h6>
        {/* </ModalHeader>
        <ModalBody> */}
        <div className="w-100 p-2 choose-form-type-wrapper">
          <div className="choose-form-type">
            <div className="choose-form-body">
              <img
                src="/images/icons/user-with-document.png"
                className="img-fluid icon"
                alt=""
              />
              <span>Contact forms</span>
              <p>
                Create a form with fields from Contacts and Companies modules.
              </p>
              <button
                className="choose-form-button"
                onClick={() =>
                  navigate(`/${getValue(params, `orgId`, "")}/settings/forms`)
                }
              >
                Create a Contact Form
              </button>
            </div>
            <div className="choose-form-footer">
              <Link to="#">When should i use this?</Link>
            </div>
          </div>
          <div className="choose-form-type">
            <div className="choose-form-body">
              <img
                src="/images/icons/document-blue.svg"
                className="img-fluid icon"
                alt=""
              />
              <span>Pipeline form</span>
              <p>
                Create a form with fields from Pipelines, Contacts and Companies
                modules.
              </p>
              <button
                className="choose-form-button"
                onClick={() =>
                  navigate(`/${getValue(params, `orgId`, "")}/settings/forms`)
                }
              >
                Create a Pipeline Form
              </button>
            </div>
            <div className="choose-form-footer">
              <Link to="#">When should i use this?</Link>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </div>
  );
}

export default FormCreatorInfoPopup;

import React, { useEffect, useState } from "react";
// import "./messages.scss";
import { Link, useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import {
  assignInboxChatbot,
  assignInboxChatbotConversation,
  getWhatsappManagerConfig,
  syncWhatsappPhoneNumbers,
  whatsappManagerAccess,
  whatsappRegisterPhone,
  whatsappWebhookSubscribe,
} from "@services/fb.service";
import { getValue } from "@utils/lodash";
import FacebookLogin from "social/facebook-login";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import RegisterPhonePopup from "../components/register-phone-popup";
import ButtonComponent from "@components/Form/Button/Button";
import AssignPopup from "../components/assign-popup";
import { getAllChatbots } from "@services/chatbot.service";
import {
  capitalizeFirstLetter,
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import WhatsappNoDataPage from "@components/common/NoData/whatsapp-nodata";
import ListLoader from "@components/common/Loader";
import { Button, Table } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

function Messages() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const [available, setAvailable] = useState(false);
  const toggleAvailable = () => {
    setAvailable(!available);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getFbConfig();
    getChatbotData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [fbConfig, setFbConfig] = useState({});
  const [configList, setConfigList] = useState([]);
  const getFbConfig = async () => {
    try {
      let resp = await getWhatsappManagerConfig();
      if (resp) {
        // setFbConfig(getValue(resp, `data`, ""));
        setConfigList(getValue(resp, `data.details`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (accessToken: string) => {
    if (accessToken)
      try {
        let payload = {
          accessCode: accessToken,
        };
        let resp = await whatsappManagerAccess(payload);
        if (resp) {
          toast.success("Subscribed successfully");
          getFbConfig();
        }
      } catch (error) {}
  };

  const handleDeactivateFb = async () => {
    try {
    } catch (error) {}
  };

  const activeTab = getValue(UrlParams, `active_tab`, "1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      delete UrlParams.search_text;
      let payload = {
        ...UrlParams,
        active_tab: tab,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const [accountId, setAccountId] = useState("");
  const handleModal = async () => {
    setIsOpen(!isOpen);
  };

  const handleRegisterPhone = (id: string) => {
    setAccountId(id);
    handleModal();
  };

  const [otpLoading, setOtpLoading] = useState(false);
  const [opt, setOpt] = useState(true);
  const registerPhone = async (otp: string) => {
    try {
      let payload = {
        pinCode: otp,
        waAccountId: accountId,
      };
      setOtpLoading(true);
      let resp = await whatsappRegisterPhone(payload);
      if (resp) {
        toast.success("Submitted successfully");
        handleModal();
        setOpt(true);
        setOtpLoading(false);
        setAccountId("");
        getFbConfig();
      } else {
        setOtpLoading(false);
      }
    } catch (error) {
      setOtpLoading(false);
      handleModal();
    }
  };

  const webhookSubscribe = async (id: string) => {
    try {
      let payload = {
        waAccountId: id,
      };
      let resp = await whatsappWebhookSubscribe(payload);
      if (resp) {
        toast.success("Subscribed successfully");
        getFbConfig();
      }
    } catch (error) {}
  };

  const handleSyncPhoneNumbers = async () => {
    try {
      let resp = await syncWhatsappPhoneNumbers("");
      if (resp) {
        getFbConfig();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Assign section                             */
  /* -------------------------------------------------------------------------- */

  const [isOpen1, setIsOpen1] = useState(false);
  const [assignId, setAssignId] = useState("");
  const handleModal1 = async () => {
    setIsOpen1(!isOpen1);
  };
  const handleAssign = (id: string) => {
    setAssignId(id);
    let findInfo: any = configList.find(
      (item: object) => getValue(item, `id`, "") === id
    );
    setAssignRequest((preState) => ({
      ...preState,
      waAccountId: id,
      chatBotId: getValue(findInfo, `chatBotId`, ""),
      managedBy: getValue(findInfo, `managedBy`, ""),
    }));
    handleModal1();
  };

  const [assignRequest, setAssignRequest] = useState({
    waAccountId: "",
    managedBy: "team",
    chatBotId: "",
  });
  const [chatbotList, setChatbotList] = useState([]);
  const getChatbotData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: 100,
        page_no: 1,
        platform: "WHATSAPP",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbots(queryRequest);
      if (resp) {
        setChatbotList(
          getValue(resp, `data.chatbots`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleAssignConversation = async () => {
    try {
      setSubmitLoading(true);
      let resp = await assignInboxChatbot(
        removeNullOrUndefinedProperties(assignRequest)
      );
      if (resp) {
        setSubmitLoading(false);
        handleModal1();
        toast.success("Assigned successfully");
        setAssignRequest((preState) => ({
          ...preState,
          waAccountId: "",
          managedBy: "team",
          chatBotId: "",
        }));
        getFbConfig();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "verifiedName",
      key: "verifiedName",
    },
    {
      title: "PHONE",
      dataIndex: "displayPhoneNumber",
      key: "displayPhoneNumber",
    },
    {
      title: "PHONE VERIFICATION ",
      dataIndex: "phoneVerificationStatus",
      key: "phoneVerificationStatus",
      render: (phoneVerificationStatus: any) =>
        phoneVerificationStatus ? (
          <>
            <CheckCircleOutlined
              style={{ color: "#3fd1a9", fontSize: "18px" }}
            />
          </>
        ) : (
          <CloseCircleOutlined style={{ color: "#f54d4d", fontSize: "18px" }} />
        ),
    },
    {
      title: "PHONE REGISTERED",
      dataIndex: "phoneRegistered",
      key: "phoneRegistered",
      render: (phoneRegistered: any, record: any) => (
        <div className="d-flex gap-2">
          {phoneRegistered ? (
            <CheckCircleOutlined
              style={{ color: "#3fd1a9", fontSize: "18px" }}
            />
          ) : (
            <CloseCircleOutlined
              style={{ color: "#f54d4d", fontSize: "18px" }}
            />
          )}
          {!phoneRegistered && (
            <Button
              type="primary"
              size="small"
              onClick={() => handleRegisterPhone(record.id)}
            >
              Click to enable
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "WEBHOOK SUBSCRIBED",
      dataIndex: "webhookSubscribed",
      key: "webhookSubscribed",
      render: (webhookSubscribed: any, record: any) => (
        <div className="d-flex gap-2">
          {webhookSubscribed === true ? (
            <CheckCircleOutlined
              style={{ color: "#3fd1a9", fontSize: "18px" }}
            />
          ) : (
            <CloseCircleOutlined
              style={{ color: "#f54d4d", fontSize: "18px" }}
            />
          )}
          {!webhookSubscribed && (
            <Button
              size="small"
              onClick={() => webhookSubscribe(getValue(record, `id`, ""))}
            >
              Click to Enable
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "MANAGED BY",
      dataIndex: "managedBy",
      key: "managedBy",
      render: (managedBy: any) => formatString(managedBy),
    },
    {
      title: "ACTION",
      dataIndex: "managedBy",
      key: "managedBy",
      render: (id: any, record: any) => {
        return (
          <div className="header_blue_text__14 touchable-text">
            <Button
              onClick={() => handleAssign(getValue(record, `id`, ""))}
              size="small"
            >
              Assign
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {isLoading ? (
        <ListLoader />
      ) : (
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center px-3">
            <div></div>
            <div className="py-2 d-flex align-items-center gap-2">
              <Button size="large" onClick={handleSyncPhoneNumbers}>
                Sync
              </Button>
              <FacebookLogin handleSubmit={handleSubmit} />
            </div>
          </div>
          {getValue(configList, `length`, 0) > 0 ? (
            <div className="datatable_container">
              <div className=" datatable_table_wrapper">
                {/* <table className="datatable_table w-100">
                  <thead className="datatable_table_thead position-relative">
                    <tr className="datatable_table_tr">
                      <th className="datatable_table_th ">
                        <h6
                          className="header_text__12"
                          style={{ textAlign: "left" }}
                        >
                          NAME
                        </h6>
                      </th>
                      <th className="datatable_table_th ">
                        <h6 className="header_text__12">PHONE</h6>
                      </th>
                      <th className="datatable_table_th ">
                        <h6 className="header_text__12">
                          PHONE VERIFICATION STATUS
                        </h6>
                      </th>
                      <th className="datatable_table_th ">
                        <h6 className="header_text__12">PHONE REGISTERED</h6>
                      </th>
                      <th className="datatable_table_th ">
                        <h6 className="header_text__12">WEBHOOK SUBSCRIBED</h6>
                      </th>
                      <th className="datatable_table_th ">
                        <h6 className="header_text__12">MANAGED BY</h6>
                      </th>
                      <th className="datatable_table_th ">
                        <h6 className="header_text__12">ACTION</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="datatable_table_tbody">
                    {getValue(configList, `length`, 0) > 0 &&
                      configList.map((item: object, key: number) => {
                        return (
                          <tr className="datatable_table_tr " key={key}>
                            <td
                              className="datatable_table_td "
                              style={{ display: "table-cell" }}
                            >
                              {getValue(item, `verifiedName`, "")}
                            </td>
                            <td className="datatable_table_td ">
                              {getValue(item, `displayPhoneNumber`, "")}
                            </td>
                            <td className="datatable_table_td ">
                              {getValue(item, `phoneVerificationStatus`, false)
                                ? "Yes"
                                : "No"}
                            </td>
                            <td className="datatable_table_td ">
                              <div className="d-flex align-items-center justify-content-between">
                                <p>
                                  {getValue(item, `phoneRegistered`, false)
                                    ? "Yes"
                                    : "No"}
                                </p>
                                {!getValue(item, `phoneRegistered`, false) && (
                                  <ButtonComponent
                                    buttonType="primary"
                                    buttonText="Register Phone"
                                    onClickHandler={() =>
                                      handleRegisterPhone(
                                        getValue(item, `id`, "")
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </td>
                            <td className="datatable_table_td ">
                              <div className="d-flex align-items-center justify-content-between">
                                <p>
                                  {getValue(item, `webhookSubscribed`, false)
                                    ? "Yes"
                                    : "No"}
                                </p>
                                {!getValue(
                                  item,
                                  `webhookSubscribed`,
                                  false
                                ) && (
                                  <button
                                    className="dashboard-button px-4"
                                    onClick={() =>
                                      webhookSubscribe(getValue(item, `id`, ""))
                                    }
                                  >
                                    Webhook Subscribe
                                  </button>
                                )}
                              </div>
                            </td>
                            <td className="datatable_table_td ">
                              {formatString(getValue(item, `managedBy`, ""))}
                            </td>
                            <td className="datatable_table_td ">
                              <div className="header_blue_text__14 touchable-text">
                                <ButtonComponent
                                  buttonType="secondary"
                                  buttonText="Assign"
                                  onClickHandler={() =>
                                    handleAssign(getValue(item, `id`, ""))
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table> */}
                <Table
                  size="middle"
                  bordered
                  columns={columns}
                  dataSource={configList}
                  rowKey="id"
                />
              </div>
            </div>
          ) : (
            <WhatsappNoDataPage />
          )}
        </div>
      )}
      <RegisterPhonePopup
        isOpen={isOpen}
        handleModal={handleModal}
        setAccountId={setAccountId}
        registerPhone={registerPhone}
        submitLoading={otpLoading}
        opt={opt}
        setOpt={setOpt}
      />
      <AssignPopup
        isOpen={isOpen1}
        handleModal={handleModal1}
        setAssignId={setAssignId}
        assignRequest={assignRequest}
        setAssignRequest={setAssignRequest}
        chatbotList={chatbotList}
        submitLoading={submitLoading}
        handleAssignConversation={handleAssignConversation}
      />
    </>
  );
}

export default Messages;

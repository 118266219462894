import React, { useEffect, useRef, useState } from "react";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useNavigate, useParams } from "react-router-dom";
import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import {
  getSpecificProperty,
  updatePropertyAddress,
} from "@services/properties/property.service";
import InputRuleForm from "@components/InputRuleForm/form";
import AutocompleteInput from "./components/AutoComplete";
import { config } from "env";
import SimpleReactValidator from "simple-react-validator";

const PropertyAddress = (props: any) => {
  const apiKey = config.REACT_APP_GOOGLE_MAPS_API_KEY;
  const navigate = useNavigate();
  const params = useParams();

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const formValid = simpleValidator.current.allValid();

  useEffect(() => {
    if (getValue(params, `id`, "")) {
      getData();
    }
  }, []);

  const initialCenter = {
    lat: 12.9716,
    lng: 77.5946,
  };

  const [request, setRequest] = useState({
    city: "",
    state: "",
    country: "",
    address1: "",
    pincode: 0,
    location: {
      lat: initialCenter.lat,
      long: initialCenter.lng,
    },
  });

  const getData = async () => {
    try {
      let resp = await getSpecificProperty(getValue(params, `id`, ""));
      if (resp && resp.data) {
        const location =
          JSON.parse(getValue(resp, `data.location`, "{}")) || initialCenter;
        setRequest({
          city: getValue(resp, `data.city`, ""),
          state: getValue(resp, `data.state`, ""),
          country: getValue(resp, `data.country`, ""),
          address1: getValue(resp, `data.address1`, ""),
          pincode: getValue(resp, `data.pincode`, ""),
          location: location,
        });
      } else {
        setRequest((prevRequest: any) => ({
          ...prevRequest,
          location: initialCenter,
        }));
      }
    } catch (error) {
      console.error("Error fetching property data: ", error);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);

    if (!simpleValidator.current.allValid()) {
      return;
    }

    try {
      setSubmitLoading(true);
      request["pincode"] = parseInt(getValue(request, `pincode`, 0));
      let resp = await updatePropertyAddress(
        getValue(params, `id`, ""),
        removeNullOrUndefinedProperties(request)
      );
      if (resp) {
        toast.success("Updated successfully");
        navigate(
          `/${getValue(params, `orgId`, "")}/property/images/${getValue(
            params,
            `id`,
            ""
          )}`
        );
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      console.error("Error updating property address: ", error);
    }
  };

  return (
    <>
      <HomeHeader />
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(params, "orgId", "")}/property/edit/${getValue(
                    params,
                    `id`,
                    ""
                  )}`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">{"Property details"}</p>
          </div>
          <div className="d-flex align-items-center">
            <ButtonComponent
              buttonType="primary"
              buttonText={submitLoading ? "Submitting" : "Next"}
              onClickHandler={() => handleSubmit()}
              disabled={submitLoading}
            />
          </div>
        </div>
      </div>
      <div className={`w-100 grid-container   add-overflow-y`}>
        <div className="w-100 add-overflow-y manageFieldRight py-4 px-5">
          {apiKey && (
            <AutocompleteInput
              request={request}
              setRequest={setRequest}
              apiKey={apiKey}
              initialCenter={initialCenter}
            />
          )}
        </div>
        <div className="w-100 px-5 py-4 add-overflow-y manageFieldRight">
          <InputRuleForm
            inputType="TEXT"
            name="country"
            value={getValue(request, `country`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                country: e.target.value,
              })
            }
            placeholder="Enter a Country"
            className="mb-2"
            label="Country"
            validator={simpleValidator}
          />
          <InputRuleForm
            inputType="TEXT"
            name="state"
            value={getValue(request, `state`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                state: e.target.value,
              })
            }
            placeholder="Enter a State"
            className="mb-2"
            label="State"
            validator={simpleValidator}
          />
          <InputRuleForm
            inputType="TEXT"
            name="city"
            value={getValue(request, `city`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                city: e.target.value,
              })
            }
            placeholder="Enter a City"
            className="mb-2"
            label="City"
            validator={simpleValidator}
          />
          <InputRuleForm
            inputType="TEXTAREA"
            name="address1"
            placeholder="Enter Address"
            className="mb-2"
            label="Address"
            value={getValue(request, `address1`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                address1: e.target.value,
              })
            }
            validator={simpleValidator}
          />
          <InputRuleForm
            inputType="NUMBER"
            name="pincode"
            value={getValue(request, `pincode`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                pincode: e.target.value,
              })
            }
            placeholder="Enter a Pincode"
            className="mb-2"
            label="Pincode"
            validator={simpleValidator}
          />
        </div>
      </div>
    </>
  );
};

export default PropertyAddress;

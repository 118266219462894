import DeleteSvgComponent from "@assets/svg/delete";
import InfoSvgComponent from "@assets/svg/info";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import {
  associateTaxCategory,
  removeAssociatedTaxCategory,
} from "@services/properties/settings/tax.service";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { toast } from "sonner";

const AddTaxGroupCategory = (props: any) => {
  const { request, setRequest, editId, selectedIds } = props;

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleAddCategory = async (id: any) => {
    setSubmitLoading(true);
    let resp = await associateTaxCategory(editId, {
      ids: [id],
    });
    if (resp) {
      toast.success("Updated successfully");
      props.getData();
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
    }
  };

  const handleRemoveCategory = async (id: string) => {
    try {
      let resp = await removeAssociatedTaxCategory(editId, { ids: [id] });
      if (resp) {
        toast.success("Removed successfully");
        props.getData();
        setSubmitLoading(false);
        // props.getData(false);
      }
    } catch (error) {}
  };
  return (
    <div className="subpipeline-wrapper px-5 py-4">
      <div className="d-flex flex-column gap-3 mb-4">
        <div>
          <InputRuleForm
            inputType="TEXT"
            name="category_name"
            value={getValue(request, `category_name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                category_name: e.target.value,
              })
            }
            placeholder="Enter group tax name"
            label="Group Tax name"
            required
          />
          <div className="mt-2 small_text__13 d-flex align-items-center gap-2 color_desc">
            <InfoSvgComponent size="16" />
            <p>
              Name of the group tax category. For e.g <b>Room Booking</b> or{" "}
              <b>Hotel Amenity</b>. This name will also appear when adding items
              in folios.
            </p>
          </div>
        </div>
        {editId ? (
          <>
            <h6 className="header_text__16 mt-2">Tax Rates</h6>
            <div className="amenity_container mt-2">
              {getValue(props, `taxList`, []).map((item: object) => {
                return (
                  <div className="position-relative mb-3">
                    {getValue(props, `selectedIds`, []).includes(
                      getValue(item, `id`, "")
                    ) && (
                      <div
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "-7px",
                          background: "white",
                          borderRadius: "50%",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #efefef",
                        }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleRemoveCategory(getValue(item, `id`, ""));
                        }}
                      >
                        <DeleteSvgComponent />
                      </div>
                    )}
                    <div
                      className="amenity_text "
                      style={{
                        border: getValue(props, `selectedIds`, []).includes(
                          getValue(item, `id`, "")
                        )
                          ? "1px solid #408dfb"
                          : "",
                      }}
                      onClick={() =>
                        handleAddCategory(getValue(item, `id`, ""))
                      }
                    >
                      <p className="">{getValue(item, `tax_name`, "")}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="mt-2">
            <InputRuleForm
              inputType="REACT_SELECT"
              label={"Tax Rates"}
              placeholder="Enter Tax Rates"
              name={"tax_rate_ids"}
              options={getValue(props, `taxList`, [])}
              value={getValue(request, `tax_rate_ids`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  tax_rate_ids: e,
                })
              }
              isMulti
              // required
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddTaxGroupCategory;

import AddSvgComponent from "@assets/svg/add";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import Loader from "@components/common/Loader/loading";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropertiesList from "./PropertiesList";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllProperty } from "@services/properties/property.service";
import PropertiesNoDataPage from "@components/common/NoData/properties-nodata";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import ListLoader from "@components/common/Loader";
import useDynamicTitle from "@context/useDynamicTitle";

const Property = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (getValue(UrlParams, `search_text`, "")) {
        setSearchInput(UrlParams.search_text);
        setShowSearchTextbox(true);
      }
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getData();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      // let payload = {};
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
        status: getValue(UrlParams, `status`, ""),
        is_active: true,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllProperty(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.properties`, []));
        setIsLoading(false);
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (UrlParams.search_text) {
      delete UrlParams.search_text;
      let payload: any = { ...UrlParams };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };
  const handleChangeSearch = async (e: any) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(e.target.value)) {
      setSearchInput(e.target.value);
      let payload: any = {
        ...UrlParams,
        page_no: 1,
        search_text: e.target.value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      if (!e.target.value) {
        getData();
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  useDynamicTitle("Properties");
  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle={`Property (${totalCount})`}
        isRightArrowVisible={false}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
      />
      <div className={`d-flex w-100`}>
        <div className="manageFieldRight w-100 add-overflow-y">
          {getValue(list, `length`, 0) < 1 && (
            <div className="other-content subheader_filter_section open">
              <div className="filter-wrapper d-flex align-items-center justify-content-between mt-1 mb-3 mx-4">
                <div className="d-flex gap-3 align-items-center">
                  {/* <p>
                    Properties (<span className="form-label">{totalCount}</span>
                    )
                  </p> */}
                </div>
                <div className="d-flex gap-3">
                  <button
                    className=" create-user-button create-user-button--department hover-text touchable-text-white ps-3 pe-4 py-2 gap-1 "
                    onClick={() =>
                      navigate(
                        `/${getValue(params, `orgId`, "")}/property/create`
                      )
                    }
                  >
                    <AddSvgComponent color="#fff" size="18" />
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
          {isLoading ? (
            <ListLoader />
          ) : getValue(list, `length`, 0) > 0 ? (
            <>
              <PropertiesList list={list} />
            </>
          ) : (
            <PropertiesNoDataPage />
          )}
          {totalCount > 10 && (
            <Pagination
              totalCount={totalCount}
              limit={limit}
              page_no={page_no}
              handleChangePagination={handleChangePagination}
              handleChangeLimit={handleChangeLimit}
              showTotalCount
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Property;

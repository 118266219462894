import Tasks from "@pages/Private/CRM/Tasks";
import TaskDetailSection from "@pages/Private/CRM/Tasks/Detail";

export const CrmTaskRoutes = [
  {
    path: "/:orgId/crm/tasks",
    name: "crm_tasks",
    component: Tasks,
  },
  {
    path: "/:orgId/crm/tasks/detail/:id",
    name: "crm_tasks",
    component: TaskDetailSection,
  },
];

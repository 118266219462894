import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityCallPopup from "./popup/call-popup";
import { generatePayload } from "@components/helpers/request-helper";
import { getValue } from "@utils/lodash";
import { createCall } from "@services/calls.service";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import { toast } from "sonner";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import NotFoundDetail from "@components/common/NoData/Not-Found-Detail";
import ActivityNavViews from "../components/nav-views";

function CommonDetailActivitiesCalls(props: any) {
  const { fields, setFields } = props;
  const pendingLinkArray = [
    {
      title: "Meetings",
      linkUrl: "#",
      status: "active",
    },
    {
      title: "Calls",
      linkUrl: "#",
    },
  ];
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  const { isCallVisibility, setIsCallVisibility } = props;
  const [callLoading, setCallLoading] = useState(false);
  const handleSubmitCall = async () => {
    try {
      setCallLoading(true);
      let payload = generatePayload("calls", fields, {});
      let requestPayload = {
        call: payload.call,
        associations: payload.associations.concat({
          api_name: props.module,
          record_id: getValue(props, `params.id`, ""),
        }),
      };
      let resp = await createCall({
        ...removeNullOrUndefinedProperties(requestPayload),
        // associations: [
        //   {
        //     api_name: props.module,
        //     record_id: getValue(props, `params.id`, ""),
        //   },
        // ],
      });
      if (resp) {
        toast.success("Created successfully");
        props.getData(false);
        navigate(
          `/${getValue(props, `params.orgId`, "")}/crm/${getValue(
            props,
            `module`,
            ""
          )}/detail/${getValue(
            props,
            `params.id`,
            ""
          )}?activeTab=3&activeActivitySubTab=2`
        );
        setIsCallVisibility(false);
        setCallLoading(false);
        setFields([]);
      } else {
        setCallLoading(false);
      }
    } catch (error) {
      setCallLoading(false);
    }
  };

  return (
    <div>
      {getValue(urlParams, `activeTab`, "") == 3 && (
        <div className={`email_card px-3 p-2`}>
          <div className={`email_card_body`}>
            <div className="card_tabs">
              <ActivityNavViews pendingLinkArray={pendingLinkArray} />
            </div>
          </div>
          {getValue(urlParams, `activeActivitySubTab`, "") == 2 &&
            (getValue(props, `data.calls.length`, 0) > 0 ? (
              <div className="w-100">
                <table className={`common_detail_table w-100 mt-3`}>
                  <thead>
                    <tr>
                      <td>Type</td>
                      <td>Start Date</td>
                      <td>Outcome</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody className={`card_table_body`}>
                    {getValue(props, `data.calls`, []).map((item: object) => {
                      return (
                        <tr
                          onClick={() =>
                            navigate(
                              `/${getValue(
                                params,
                                `orgId`,
                                ""
                              )}/crm/calls/detail/${getValue(item, `id`, "")}`
                            )
                          }
                        >
                          <td>
                            <div className="d-flex align-items-center">
                              <p className="ms-2">
                                {getValue(item, `type`, "")}
                              </p>
                            </div>
                          </td>

                          <td>
                            <p className={`text`}>
                              {convertCurrentDateWithTime(
                                getValue(item, `start_datetime`, "")
                              )}
                            </p>
                          </td>
                          <td>
                            <p className={`text`}>
                              {" "}
                              {formatString(getValue(item, `outcome`, ""))}
                            </p>
                          </td>
                          <td>
                            <p className={`text`}>
                              {getValue(item, `description`, "")
                                ? getValue(item, `description`, "")
                                : "-"}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NotFoundDetail title={"No available calls."} />
            ))}
        </div>
      )}
      {/* -------------------------------- Activity Call ----------------------------- */}
      <ActivityCallPopup
        isOpen={isCallVisibility}
        setIsOpen={setIsCallVisibility}
        title={"Log Calls"}
        width={"600px"}
        fields={fields}
        setFields={setFields}
        submitLoading={callLoading}
        handleSubmit={handleSubmitCall}
      />
    </div>
  );
}

export default CommonDetailActivitiesCalls;

import { convertCurrentDateWithTime } from "@common/date-helpers";
import { capitalizeEverything } from "@common/text-helpers";
import BroadCastNoDataPage from "@components/common/NoData/broadcast-nodata";
import { getValue } from "@utils/lodash";
import { Table, Tag, Tooltip } from "antd";
import { Link, useParams } from "react-router-dom";

function BroadcastHistoryTable(props: any) {
  const { list, handleChangePagination, page_no, page_size, totalCount } =
    props;

  const params = useParams();
  const broadcastColumn = [
    {
      title: "BROADCAST NAME",
      dataIndex: "name",
      key: "name",
      width: "200px",
      render: (text: any, item: any) => (
        <Link to={`/${getValue(params, "orgId", "")}/broadcast/${item.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "SUCCESSFUL",
      dataIndex: "overview.overview.delivered.count",
      key: "overview.overview.delivered.count",
      width: "100px",
      render: (record: any) =>
        getValue(record, "overview.overview.delivered.count", 0),
    },
    {
      title: "SENT",
      dataIndex: "overview.overview.sent.count",
      key: "overview.overview.sent.count",
      width: "100px",
      render: (record: any) =>
        getValue(record, "overview.overview.sent.count", 0),
    },
    {
      title: "READ",
      dataIndex: "overview.overview.read.count",
      key: "overview.overview.read.count",
      width: "100px",
      render: (record: any) =>
        getValue(record, "overview.overview.read.count", 0),
    },
    {
      title: "REPLIED",
      dataIndex: "overview.overview.replied.count",
      key: "overview.overview.replied.count",
      width: "100px",
      render: (record: any) =>
        getValue(record, "overview.overview.replied.count", 0),
    },
    {
      title: "FAILED",
      dataIndex: "overview.overview.failed.count",
      key: "overview.overview.failed.count",
      width: "100px",
      render: (record: any) =>
        getValue(record, "overview.overview.failed.count", 0),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: "200px",
      render: (status: any) => {
        let color;
        switch (status) {
          case "processed":
            color = "green";
            break;
          case "finished":
            color = "green";
            break;
          case "failed":
            color = "red";
            break;
          case "processing":
            color = "blue";
            break;
          case "queued":
            color = "orange";
            break;
          case "pending":
            color = "blue";
            break;
          case "contacts_processing":
            color = "blue";
            break;
          case "contacts_processed":
            color = "green";
            break;
          default:
            color = "default";
        }

        return (
          <Tag color={color} key={status}>
            {capitalizeEverything(status)}
          </Tag>
        );
      },
    },
    {
      title: "TRIGGERED ON",
      dataIndex: "trigger_at",
      key: "trigger_at",
      width: "200px",
      render: (trigger_at: any) => convertCurrentDateWithTime(trigger_at),
    },
    {
      title: "CREATED ON",
      dataIndex: "created_at",
      key: "created_at",
      width: "200px",
      render: (created_at: any) => convertCurrentDateWithTime(created_at),
    },
    {
      title: "ACTIONS",
      dataIndex: "error_reason_message",
      key: "error_reason_message",
      render: (error_reason_message: any) => (
        <Tooltip title={error_reason_message}>
          <div>
            {error_reason_message
              ? error_reason_message.length > 40
                ? error_reason_message.slice(0, 40) + "..."
                : error_reason_message
              : ""}
          </div>
        </Tooltip>
      ),
    },
  ];
  return (
    <>
      {list.length > 0 ? (
        <Table
          size="middle"
          columns={broadcastColumn}
          dataSource={list}
          rowKey="id"
          pagination={{
            current: page_no,
            pageSize: page_size,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            onChange: (page_no, pageSize) => {
              handleChangePagination(page_no, pageSize);
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{
            y: 506,
            x: 1400,
          }}
          bordered
        />
      ) : (
        <BroadCastNoDataPage />
      )}
    </>
  );
}

export default BroadcastHistoryTable;

import AddCircleSvgComponent from "@assets/svg/add-circle";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import SearchSvgComponent from "@assets/svg/search";
import SettingsSvgComponents from "@assets/svg/settings";
import { getImages } from "@common/image-helper";
import { sortJSONObjectArray } from "@common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import ListLoader from "@components/common/Loader";
import Loader from "@components/common/Loader/loading";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Tooltip } from "react-tooltip";

function ManageFormStep1(props: any) {
  const {
    allModules,
    request,
    setRequest,
    handleSelectModule,
    searchText,
    handleSearch,
    isLoading,
    formFields,
    handleAddField,
    handleOnDragEnd,
    droppedFormFields,
    renderDraggable,
    getItemStyle,
    handleRemoveField,
    handleEditField,
  } = props;
  const handleSubmits = () => {};
  return (
    <>
      <div className="form_leftcontainer">
        <div className="web-form_header p-2">
          <SearchToolTip
            data={
              getValue(allModules, `length`, 0) > 0
                ? allModules
                    .filter(
                      (items: object) =>
                        getValue(items, `type`, "") === "PIPELINE"
                    )
                    .map((item: object) => ({
                      ...item,
                      value: getValue(item, `id`, ""),
                      label: getValue(item, `linkText`, ""),
                    }))
                : []
            }
            value={getValue(request, `module_id`, "")}
            onChange={(e: object) => handleSelectModule(e)}
            label={`label`}
            placeholder="Choose module"
          />
        </div>
        <div className="web-form_field_container">
          <div className="form-search-container">
            <div className={`px-3 pb-3 position-relative input_container`}>
              <InputRuleForm
                inputType="TEXT"
                style={{ height: "36px", paddingLeft: "30px" }}
                placeholder="Search here"
                value={searchText}
                onChange={(e: any) => handleSearch(e)}
                name="search_text"
              />
              <div className={`position-absolute input_search`}>
                <SearchSvgComponent color={"gray"} size={16} />
              </div>
              {searchText && (
                <div
                  className={`position-absolute input_close`}
                  onClick={() => {
                    handleSearch("");
                  }}
                >
                  <DropdownCloseSvgComponent color={"gray"} size={16} />
                </div>
              )}
            </div>
          </div>
          {isLoading ? (
            <ListLoader />
          ) : (
            <>
              {getValue(formFields, `length`, 0) > 0 &&
                formFields
                  .slice()
                  .sort((a: any, b: any) => {
                    const labelA = getValue(a, `label`, "").toLowerCase();
                    const labelB = getValue(b, `label`, "").toLowerCase();
                    if (labelA < labelB) return -1;
                    if (labelA > labelB) return 1;
                    return 0;
                  })
                  .map((item: object, index: number) => {
                    return (
                      <div className="d-flex align-items-center" key={index}>
                        <div
                          className="web-form_left_container d-flex align-items-center position-relative"
                          style={{
                            borderLeft: getValue(item, `system_required`, false)
                              ? "2px solid red"
                              : "",
                          }}
                        >
                          <div>
                            {getImages({
                              name: getValue(item, `input_type`, ""),
                              size: 20,
                            })}
                          </div>
                          <p className="small_text__16 ms-3">
                            {getValue(item, `label`, "")}
                          </p>
                          <div
                            className="web-form_absolute"
                            onClick={() =>
                              handleAddField(getValue(item, `id`, ""))
                            }
                          >
                            <AddCircleSvgComponent />
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      </div>

      <div className="form_rightcontainer">
        <div className="web-form_header"></div>
        <div className="web-form_field_container">
          <div className="editable_form_contaner mt-3 mb-3 pb-5">
            <div className="mt-3 mb-4 mx-4">
              <InputRuleForm
                inputType="TEXT"
                value={getValue(request, `title`, "")}
                placeholder={"Enter Form Title"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    title: e.target.value,
                  })
                }
                required
                label="Form title"
              />
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="source">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <div className="form_dropcontainer">
                      {getValue(droppedFormFields, `length`, 0) > 0 &&
                        sortJSONObjectArray(droppedFormFields, "seq_num").map(
                          (item: object, index: number) => {
                            if (getValue(item, `action`, "") !== "delete")
                              return (
                                <Draggable
                                  key={getValue(item, `api_name`, "")}
                                  draggableId={getValue(item, `api_name`, "")}
                                  index={index}
                                >
                                  {renderDraggable(
                                    (provided: any, snapshot: any) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <div
                                          className="d-flex align-items-center gap-2 draggable-wrapper mb-2"
                                          style={{
                                            width: "100%",
                                            border: "1px dashed #dadce0",
                                            padding: "10px",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <DragIndicatorSvgComponent />
                                          <div
                                            className="web-form_left_container d-flex align-items-center position-relative"
                                            style={{
                                              borderLeft:
                                                getValue(
                                                  item,
                                                  `system_required`,
                                                  false
                                                ) ||
                                                getValue(item, `required`, "")
                                                  ? "2px solid red"
                                                  : "",
                                              cursor: "grabbing",
                                            }}
                                          >
                                            <div>
                                              {getImages({
                                                name: getValue(
                                                  item,
                                                  `input_type`,
                                                  ""
                                                ),
                                                size: 20,
                                              })}
                                            </div>
                                            <p className="small_text__16 ms-3">
                                              {getValue(
                                                item,
                                                `display_label`,
                                                ""
                                              )}
                                            </p>
                                            <div
                                              className="web-form_absolute"
                                              onClick={() =>
                                                handleRemoveField(
                                                  getValue(item, `id`, "")
                                                )
                                              }
                                            >
                                              <RemoveCircleSvgComponent />
                                            </div>
                                          </div>
                                          <div
                                            className="cursor-pointer settings-container mb-2"
                                            onClick={() =>
                                              handleEditField(item)
                                            }
                                          >
                                            <SettingsSvgComponents
                                              color={"gray"}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </Draggable>
                              );
                          }
                        )}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div
              className=" mt-3 dummy-submit"
              style={{
                width: "100%",
                border: "1px dashed #dadce0",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <ButtonComponent
                buttonType="primary"
                buttonText={"Submit"}
                onClickHandler={handleSubmits}
              />
              <Tooltip className="tooltip" anchorSelect={`.dummy-submit`}>
                This is a dummy button of the form
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageFormStep1;

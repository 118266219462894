import DeleteSvgComponent from "@assets/svg/delete";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import {
  AssignImageToRoomType,
  DeleteRoomTypeImage,
  getAllRoomTypeImage,
} from "@services/properties/room-type.service";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";
import { config } from "../../../../../../env";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { QueryRequestHelper } from "@common/query-request-helper";

function PropertyRoomTypeImagePopup() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const id = getValue(UrlParams, "room_id", "");
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [images, setImages] = useState([]);
  const getData = async () => {
    try {
      let payload = {
        room_type_id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomTypeImage(queryRequest);
      if (resp) {
        setImages(getValue(resp, `data.roomTypeImages`, []));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                            Upload Section                                  */
  /* -------------------------------------------------------------------------- */
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          let response = await AssignImageToRoomType(id, {
            images: [
              {
                file_upload_id: getValue(resp, `data.id`, ""),
                order: getValue(images, `length`, 0) + 1,
              },
            ],
          });
          if (response) {
            getData();
          }
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal1();
  };
  const handleDeleteNote = async (deleteId: string) => {
    try {
      let resp = await DeleteRoomTypeImage(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal1();
        getData();
        setDeleteId("");
        // props.getData(false);
      }
    } catch (error) {}
  };
  return (
    <div>
      <div className="">
        <div className="test-bot-container px-4 py-3">
          <div className={`w-100`}>
            <p className="text-center small_text__13">Add room images</p>
            <DragAndDropFileComponent
              uploadFile={handleUploadDocuments}
              name="file"
              classes="bulk-upload_drag-n-drop-wrapper position-absolute"
              isLoading={uploadLoading}
              supportedFormats=".jpg, .png, .jpeg"
            />
          </div>
          <div className="property_image_container">
            {getValue(images, `length`, 0) > 0
              ? images.map((item: object) => {
                  return (
                    <div className="position-relative">
                      <div
                        className="property_delete_icon"
                        onClick={() =>
                          // handleDeleteView(getValue(item, `id`, ""))
                          handleDeleteNote(getValue(item, `id`, ""))
                        }
                      >
                        <DeleteSvgComponent />
                      </div>
                      <img
                        src={
                          getValue(item, `url`, "")
                            ? getValue(item, `url`, "")
                            : `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                                item,
                                `file_upload.key`,
                                ""
                              )}`
                        }
                        className="property_image mb-3"
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyRoomTypeImagePopup;

import { modulesEnum } from "@common/enum";
import ButtonComponent from "@components/Form/Button/Button";
import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { useStateContext } from "@context/profileProvider";
import { allPipelines } from "@services/pipeline.service";
import { getValue } from "@utils/lodash";
import { useState } from "react";
import { toast } from "sonner";
import { Button, Modal } from "antd";

function ConfigPopup(props: any) {
  const { allModules } = useStateContext();
  const { request, setRequest } = props;

  const [pipelineList, setPipelineList] = useState([]);
  const getPipelineList = async (id: string) => {
    try {
      let resp = await allPipelines(id, "");
      if (resp) {
        setPipelineList(
          getValue(resp, `data`, []).map((item: object) => ({
            ...item,
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
      }
    } catch (error) {}
  };

  const getAllModules = () => {
    let list = allModules.filter(
      (item: object) =>
        getValue(item, `api_name`, "") !== "calls" &&
        getValue(item, `api_name`, "") !== "meetings"
    );
    return list.map((item: object) => ({
      ...item,
      name: getValue(item, `api_name`, ""),
      value: getValue(item, `id`, ""),
      label: getValue(item, `linkText`, ""),
    }));
  };
  const handleSubmit = () => {
    if (getValue(request, `moduleId`, "")) {
      if (getValue(pipelineList, `length`, 0) > 0) {
        if (getValue(request, `pipelineId`, "")) {
          props.handleModal();
          props.handleTemplateModal();
        } else {
          toast.error("Pipeline is required");
        }
      } else {
        props.handleModal();
        props.handleTemplateModal();
      }
    } else {
      toast.error("Module is required");
    }
  };
  return (
    <div>
      <Modal
        open={getValue(props, `isOpen`, "")}
        // toggle={props.handleModal}
        onOk={() => handleSubmit()}
        onCancel={props.handleModal}
        footer={[
          <Button
            key="cancel"
            onClick={props.handleModal}
            className="cancel__button"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            className="submit__button"
          >
            Submit
          </Button>,
        ]}
      >
        {/* <CommonPopupHeader
          title={"Email Template"}
          handleBackButton={props.handleModal}
          height={"60px"}
        /> */}
        <h6 className="header_text__16">Email Template</h6>
        <section className="edit-popup-wrapper">
          <SearchToolTip
            placeholder={`Choose Module`}
            label={`label`}
            // width={"400px"}
            data={getAllModules()}
            onChange={(e: any) => {
              setRequest({
                ...request,
                moduleId: getValue(e, `id`, ""),
              });
              getPipelineList(getValue(e, `id`, ""));
            }}
            value={getValue(request, `moduleId`, "")}
            selectKey={"id"}
            name="Module"
            required
          />
          {getValue(pipelineList, `length`, 0) > 0 && (
            <SearchToolTip
              placeholder={`Choose Pipeline`}
              label={`label`}
              // width={"400px"}
              data={pipelineList}
              onChange={(e: any) => {
                setRequest({
                  ...request,
                  pipelineId: getValue(e, `id`, ""),
                });
              }}
              value={getValue(request, `pipelineId`, "")}
              selectKey={"id"}
              name={"Pipeline"}
              required
            />
          )}
        </section>
        {/* <ModalFooter> */}
        {/* <div className={"popup-submit-container"}>
            <ButtonComponent
              buttonType={"scecondary"}
              buttonText="Cancel"
              onClickHandler={props.handleModal}
            />
            <ButtonComponent
              buttonType={"primary"}
              buttonText={"Next"}
              onClickHandler={() => handleSubmit()}
            />
          </div> */}
        {/* </ModalFooter> */}
      </Modal>
    </div>
  );
}

export default ConfigPopup;

// -------------------  Property list ------------------------- //

import { formatString } from "./text-helpers";

export const PropertyTypeEnum: any = {
  HOTEL: "hotel",
  APARTMENT: "apartment",
  VACATION_RENTAL: "vacation_rental",
  MOTEL: "motel",
  BED_AND_BREAKFAST: "bed_and_breakfast",
  COUNTRY_HOUSE: "country_house",
  GUESTHOUSE: "guesthouse",
  HOMESTAY: "homestay",
  FARM_STAY: "farm_stay",
  LODGE: "lodge",
  VILLA: "villa",
  CHALET: "chalet",
  HOSTEL: "hostel",
  INN: "inn",
  CAPSULE_HOTEL: "capsule_hotel",
  CONDO_HOTEL: "condo_hotel",
  RESORT: "resort",
  RESORT_VILLAGE: "resort_village",
};

export const PropertyTypeList = Object.keys(PropertyTypeEnum).map((key) => ({
  label: formatString(key),
  value: PropertyTypeEnum[key],
}));

// -------------------  currency list ------------------------- //

export const CurrencyEnum: any = {
  INR: "inr",
};

export const CurrencyList = Object.keys(CurrencyEnum).map((key) => ({
  label: formatString(key),
  value: CurrencyEnum[key],
}));

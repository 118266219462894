import React from "react";
import Lottie from "react-lottie";
import noaccess from "@lottie/noaccess.json";
function NoAccessComponent(props: any) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noaccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="noaccess-container">
      <Lottie
        options={defaultOptions}
        height={150}
        width={150}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </div>
  );
}

export default NoAccessComponent;

import React, { useEffect, useMemo, useRef, useState } from "react";
import SettingModuleNavbar from "../common/Navbar";
import { getValue } from "@utils/lodash";
import SettingModuleHOCComponent from "../common/detailHoc";
import SettingModuleEditPopup from "./EditPopup";
import { toast } from "sonner";
import StagingLeftMenu from "./Sidebar/ManagefieldsLeft";
import _ from "lodash";
import {
  createPipelineStage,
  updatePipelineStages,
} from "@services/pipeline.service";
import StageList from "./components/StageList";
import Loader from "@components/common/Loader/loading";
import { camelToSnake } from "@common/text-helpers";
import { useParams } from "react-router-dom";

function SettingsModuleStages(props: any) {
  const params = useParams();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [type, setType] = useState("");
  const [editId, setEditId] = useState("");
  const [status, setStaus] = useState("");
  const [payload, setPayload] = useState<any>({
    api_name: "",
    label: null,
    seq_num: null,
    archived: false,
    color_code: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const handleAddStage = (name: string) => {
    setType(name);
    setPayload({ api_name: "", label: "", seq_num: null });
    setEditId("");
    handleModal();
  };
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleCloseModal = () => {
    setPayload({
      ...payload,
      api_name: null,
      label: null,
      seq_num: null,
    });
    setType("");
    handleModal();
  };
  const [isLoading, setIsLoading] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleCreateStage = async () => {
    try {
      setIsLoading(true);
      let resp = await createPipelineStage(
        getValue(params, `id`, ""),
        props.selectedPipeline,
        {
          api_name: camelToSnake(getValue(payload, `label`, "")),
          label: getValue(payload, `label`, ""),
          seq_num:
            getValue(props, `stageRequest[${getTypes(type)}].length`, 0) + 1,
          type: type,
          color_code: getValue(payload, `color_code`, ""),
        }
      );
      if (resp) {
        toast.success("Created successfully");
        setIsLoading(false);
        props.getData(props.selectedPipeline);
        handleReset();
        handleModal();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleUpdateStage = async (id: string) => {
    try {
      setIsLoading(true);
      let resp = await updatePipelineStages(
        getValue(params, `id`, ""),
        props.selectedPipeline,
        id ? id : editId,
        {
          api_name: camelToSnake(getValue(payload, `label`, "")),
          label: getValue(payload, `label`, ""),
          seq_num:
            // getValue(props, `stageRequest[${getTypes(type)}].length`, 0) + 1,
            getValue(payload, `seq_num`, ""),
          type: type,
          color_code: getValue(payload, `color_code`, ""),
        }
      );
      if (resp) {
        toast.success("Updated successfully");
        setIsLoading(false);
        handleReset();
        props.getData(props.selectedPipeline);
        handleModal();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleUpdateStageStatus = async (obj: object) => {
    try {
      setIsLoading(true);
      let resp = await updatePipelineStages(
        getValue(params, `id`, ""),
        props.selectedPipeline,
        getValue(obj, `id`, ""),
        {
          api_name: getValue(obj, `api_name`, ""),
          label: getValue(obj, `label`, ""),
          archived: !getValue(obj, `archived`, ""),
          seq_num: getValue(obj, `seq_num`, ""),
          color_code: getValue(obj, `color_code`, ""),
          // type: type,
        }
      );
      if (resp) {
        toast.success("Updated successfully");
        setIsLoading(false);
        handleReset();

        props.getData(props.selectedPipeline);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                            Helper Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleReset = () => {
    setPayload({
      ...payload,
      name: null,
      label: null,
      seq_num: null,
      archived: false,
    });
    setType("");
    setEditId("");
  };
  const getTypes = (name: string) => {
    switch (name) {
      case "OPEN":
        return "openStages";
      case "CLOSED_WON":
        return "closedWonStages";
      case "CLOSED_LOST":
        return "closedLostStages";
      default:
        return "openStages";
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const handleEditStage = (obj: object, status: string, type: string) => {
    setType(type);
    setEditId(getValue(obj, `id`, ""));
    setStaus(status);
    setPayload({
      label: getValue(obj, `label`, ""),
      api_name: getValue(obj, `api_name`, ""),
      seq_num: getValue(obj, `seq_num`, ""),
      color_code: getValue(obj, `color_code`, ""),
    });
    handleModal();
  };
  const findSelectedPipeline = (id: string, status: string) => {
    let filter1 = getValue(props, `stageRequest[${status}]`, []).filter(
      (item: any) => item.id === id
    );
    let filter2 = getValue(props, `stageRequestDirty[${status}]`, []).filter(
      (item: any) => item.id === id
    );
    // let isChanged = _.isEqual(props.stageRequestDirty, props.stageRequest);
    return _.isEqual(filter1, filter2);
  };
  const isChanged = useMemo(() => findSelectedPipeline(editId, status), [
    payload.name,
  ]);
  const inputRef = useRef<any>(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);
  const handleClickOutside = (e: any) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      // console.log("Clicked Outside DIV");
      // handleUpdateStage();
    } else {
      // console.log("Clicked Inside DIV");
    }
  };

  return (
    <div>
      <div className={`${"main_container"} d-flex`}>
        <StagingLeftMenu />
        <div className="w-100">
          <SettingModuleNavbar
            tab={"stages"}
            params={getValue(props, `params`, {})}
            handleNavigate={props.handleNavigate}
            toggle={props.toggle}
          />
          <div className={`${"main_container_list"}`}>
            {props.isLoading || props.loading ? (
              <Loader small />
            ) : (
              <div
                className={`${"manageFieldRight_stages"} w-100 d-flex`}
                ref={inputRef}
              >
                <div className="p-3  w-100">
                  <StageList
                    handleAddStage={handleAddStage}
                    editId={editId}
                    payload={payload}
                    setPayload={setPayload}
                    isLoading={isLoading}
                    isChanged={isChanged}
                    handleUpdateStage={handleUpdateStage}
                    list={getValue(props, `stageRequest.openStages`, [])}
                    title={"Open Status"}
                    handleModal={handleModal}
                    type={"OPEN"}
                    handleEditStage={handleEditStage}
                    stageRequest={props.stageRequest}
                    setStageRequest={props.setStageRequest}
                    handleUpdateStageStatus={handleUpdateStageStatus}
                    name={"openStages"}
                    getData={props.getData}
                    selectedPipeline={props.selectedPipeline}
                  />
                  {/* <div className={"hr-line"} /> */}
                  <StageList
                    handleAddStage={handleAddStage}
                    editId={editId}
                    payload={payload}
                    setPayload={setPayload}
                    isLoading={isLoading}
                    isChanged={isChanged}
                    handleUpdateStage={handleUpdateStage}
                    list={getValue(props, `stageRequest.closedWonStages`, [])}
                    name={"closedWonStages"}
                    stageRequest={props.stageRequest}
                    setStageRequest={props.setStageRequest}
                    title={"Closed Won Status"}
                    type={"CLOSED_WON"}
                    handleEditStage={handleEditStage}
                    handleUpdateStageStatus={handleUpdateStageStatus}
                    getData={props.getData}
                    selectedPipeline={props.selectedPipeline}
                  />
                  {/* <div className={"hr-line"} /> */}
                  <StageList
                    handleAddStage={handleAddStage}
                    editId={editId}
                    payload={payload}
                    setPayload={setPayload}
                    isLoading={isLoading}
                    isChanged={isChanged}
                    handleUpdateStage={handleUpdateStage}
                    list={getValue(props, `stageRequest.closedLostStages`, [])}
                    name={"closedLostStages"}
                    stageRequest={props.stageRequest}
                    setStageRequest={props.setStageRequest}
                    title={"Closed Lost Status"}
                    type={"CLOSED_LOST"}
                    handleEditStage={handleEditStage}
                    handleUpdateStageStatus={handleUpdateStageStatus}
                    getData={props.getData}
                    selectedPipeline={props.selectedPipeline}
                  />
                  {/* <div className={"hr-line"} /> */}
                </div>
                {/* ---------------New------------- */}
                {/* <EditManageStages
                  request={props.stageRequest}
                  setRequest={props.setStageRequest}
                  addStage={addStage}
                  editStage={editStage}
                /> */}
              </div>
            )}
          </div>
        </div>
      </div>

      <SettingModuleEditPopup
        request={payload}
        setRequest={setPayload}
        isOpen={isOpen}
        toggle={handleModal}
        isLoading={isLoading}
        handleSubmit={editId ? handleUpdateStage : handleCreateStage}
        handleCloseModal={handleCloseModal}
        editId={editId}
      />
    </div>
  );
}

export default SettingModuleHOCComponent(SettingsModuleStages);

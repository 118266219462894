import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useEffect, useRef, useState } from "react";
import "../property.scss";
import CreatePropertyStepOne from "./step1";
import ButtonComponent from "@components/Form/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import BackSvgComponent from "@assets/svg/back-link";
import { toast } from "sonner";
import {
  createProperty,
  getSpecificProperty,
  updateProperty,
} from "@services/properties/property.service";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import SimpleReactValidator from "simple-react-validator";
import currencies from "@common/json/currency.json";

const CreateProperty = () => {
  const navigate = useNavigate();
  const params = useParams();

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  // const [currencyList, setCurrencyList] = useState([]);
  // useEffect(() => {
  //   let codes: any = currencies.map((item: object) => ({
  //     ...item,
  //     label: `${getValue(item, `cc`, "")} - ${getValue(item, `name`, "")}`,
  //     value: `${getValue(item, `cc`, "")}`,
  //   }));
  //   setCurrencyList(codes);
  //   if (getValue(params, `id`, "")) {
  //     getData();
  //   }
  // }, []);
  const [currencyList, setCurrencyList] = useState([]);
  useEffect(() => {
    let codes: any = currencies.map((item: object) => ({
      ...item,
      label: `${getValue(item, `cc`, "")} - ${getValue(item, `name`, "")}`,
      value: `${getValue(item, `cc`, "").toLowerCase()}`,
    }));
    setCurrencyList(codes);
    if (getValue(params, `id`, "")) {
      getData();
    }
  }, []);

  const [activeTab, setActiveTab] = useState(1);
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState({
    name: "",
    type: "hotel",
    room_count: 1,
    description: "",
    contact_no: "",
    email: "",
    timezone: "Asia/Kolkata",
    currency: "inr",
    country_code: "+91",
  });

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  // country_code
  const getData = async () => {
    try {
      let resp = await getSpecificProperty(getValue(params, `id`, ""));
      if (resp) {
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          type: getValue(resp, `data.type`, ""),
          room_count: getValue(resp, `data.room_count`, ""),
          description: getValue(resp, `data.description`, ""),
          contact_no: getValue(resp, `data.contact_no`, ""),
          email: getValue(resp, `data.email`, ""),
          timezone: getValue(resp, `data.timezone`, ""),
          currency: getValue(resp, `data.currency`, ""),
          country_code: getValue(resp, `data.country_code`, ""),
        });
      }
    } catch (error) {}
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);

    if (!simpleValidator.current.allValid()) {
      return;
    }

    try {
      setSubmitLoading(true);
      request["room_count"] = parseInt(getValue(request, `room_count`, 0));
      let resp;
      if (getValue(params, `id`, "")) {
        resp = await updateProperty(
          getValue(params, `id`, ""),
          removeNullOrUndefinedProperties(request)
        );
      } else {
        resp = await createProperty(removeNullOrUndefinedProperties(request));
      }
      if (resp) {
        toast.success(
          getValue(params, `id`, "")
            ? "Updated successfully"
            : "Created successfully"
        );
        navigate(
          `/${getValue(params, `orgId`, "")}/property/address/${getValue(
            resp,
            `data.id`,
            ""
          )}`
        );
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const [roomCount, setRoomCount] = useState<number>(
    getValue(request, "room_count", 1)
  );

  const handleIncrement = () => {
    setRoomCount((prevCount) => {
      const newCount = prevCount + 1;
      setRequest((prevRequest) => ({
        ...prevRequest,
        room_count: newCount,
      }));
      return newCount;
    });
  };

  const handleDecrement = () => {
    setRoomCount((prevCount) => {
      const newCount = prevCount > 1 ? prevCount - 1 : 1;
      setRequest((prevRequest) => ({
        ...prevRequest,
        room_count: newCount,
      }));
      return newCount;
    });
  };

  return (
    <div>
      <HomeHeader />
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div onClick={() => navigate(-1)}>
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">{"Property details"}</p>
          </div>
          <div className="d-flex align-items-center">
            {activeTab === 1 && (
              <ButtonComponent
                buttonType="primary"
                buttonText={submitLoading ? "Submitting" : "Next"}
                onClickHandler={() => handleSubmit()}
                disabled={submitLoading}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`w-100 manageFieldRight add-overflow-y d-flex justify-content-center pb-5`}
      >
        <div className="w-50" style={{ height: "fit-content" }}>
          <h6 className="small_text__22 mb-1 text-center pt-4">
            Tell us about your place
          </h6>
          <p className="text-center small_text__14">
            Share some basic info, such as name, location.
          </p>
          <div className="   mt-4 ">
            <CreatePropertyStepOne
              request={request}
              setRequest={setRequest}
              currencyList={currencyList}
              simpleValidator={simpleValidator}
              roomCount={roomCount}
              setRoomCount={setRoomCount}
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement}
              forceUpdate={forceUpdate}
            />
          </div>
        </div>
        {/* <div className="w-100 add-overflow-y manageFieldRight">
          <PropertyLocation />
        </div> */}
        {/* <div className="w-100 p-5 add-overflow-y manageFieldRight">
          {activeTab === 1 && <CreatePropertyStepOne />}
          {activeTab === 2 && <CreatePropertyStepTwo />}
          {activeTab === 3 && <CreatePropertyStepThree />}
          {activeTab === 4 && (
            <CreatePropertyStepFour toggleAddBedType={toggleAddBedType} />
          )}
          {activeTab === 5 && <CreatePropertyStepFive />}
        </div>
        <div className="w-100 add-overflow-y manageFieldRight">
          {activeTab === 1 && <PropertyLocation />}
          {activeTab === 2 && (
            <div className="p-5">
              <p className="header_text__18">Selected amenities</p>
              <div className="d-flex gap-3 flex-wrap mt-4">
                <button className="dashboard-button   px-4 py-2">Gym</button>
                <button className="dashboard-button   px-4 py-2">
                  Laundry
                </button>
              </div>
            </div>
          )}
          {activeTab === 4 && (
            <AddRoomType
              addBedType={addBedType}
              openModal={openModal}
              toggleModal={toggleModal}
            />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default CreateProperty;

import { config } from "../../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
} from "../helpers/http-handler";

export const createRoomType = (payload: object) =>
  post(`${config.API_URL}/settings/room-type`, payload);

export const getAllRoomType = (queryRequest: string) =>
  get(`${config.API_URL}/settings/room-type?${queryRequest}`);

export const getSpecificRoomType = (id: string) =>
  get(`${config.API_URL}/settings/room-type/${id}`);

export const updateRoomType = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/room-type/${id}`, payload);

export const bulkUpdateRoomType = (payload: object) =>
  patch(`${config.API_URL}/settings/room-type/bulk-update`, payload);

export const AssignImageToRoomType = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/room-type/img/assign/${id}`, payload);

export const DeleteRoomTypeImage = (id: string) =>
  Delete(`${config.API_URL}/settings/room-type/img/${id}`);

export const getSpecificRoomTypeImage = (id: string) =>
  get(`${config.API_URL}/settings/room-type/img/${id}`);

export const getAllRoomTypeImage = (queryRequest: string) =>
  get(`${config.API_URL}/settings/room-type/images?${queryRequest}`);

export const assignAmenitiesToRoomType = (id: string, payload: object) =>
  post(
    `${config.API_URL}/settings/room-type/${id}/amenity/association/add`,
    payload
  );

export const deleteAmenitiesFromRoomType = (id: string, payload: object) =>
  DeleteWithPayload(
    `${config.API_URL}/settings/room-type/${id}/amenity/associations/remove`,
    payload
  );

export const getSpecificRoomTypeRatePlan = (id: string) =>
  get(`${config.API_URL}/settings/room-type/${id}/rate-plan`);

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getValue } from "@utils/lodash";
import _ from "lodash";
import { getAllPermissionsWithToken } from "@services/auth.service";

const PermissionContext = createContext();
export function PermissionProvider({ children }) {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [tabPermissionList, setTabPermissionList] = useState([]);
  const [subTabPermissionList, setSubTabPermissionList] = useState([]);
  const [permissionAPITriggered, setPermissionAPITriggered] = useState(false);
  const [mainPermissions, setMainPermissions] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                                UseEffect Section                           */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    if (token) {
      getPermissions();
    }
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                   API Section                              */
  /* -------------------------------------------------------------------------- */
  const getPermissions = async () => {
    let token = localStorage.getItem("accessToken");
    try {
      let resp = await getAllPermissionsWithToken(token);
      if (resp) {
        let response = getValue(resp, `data`, []).filter((item) => {
          if (item) return item;
        });
        let list =
          getValue(response, `length`, 0) > 0
            ? response.map((item) => {
                return covertSecondString(item).split("-").length === 2
                  ? covertSecondString(item).split("-")
                  : item.split(":");
              })
            : [];

        let arr = [];
        for (let i = 0; i < list.length; i++) {
          arr.push({
            tab: list[i][0],
            permission: list[i][1],
          });
        }
        var groupedPermission =
          getValue(arr, `length`, 0) > 0
            ? _.groupBy(arr, function (item) {
                return item.tab;
              })
            : [];
        var ObjectEntry = Object.entries(groupedPermission);
        const arr1 = [];
        for (let i = 0; i < ObjectEntry.length; i++) {
          arr1.push({
            tab: ObjectEntry[i][0].split(":")[0],
            tabKey:
              ObjectEntry[i][0].split(":").length == 2
                ? ObjectEntry[i][0].split(":")[0] +
                  "_" +
                  ObjectEntry[i][0].split(":")[1]
                : ObjectEntry[i][0].split(":").length == 3
                ? ObjectEntry[i][0].split(":")[0] +
                  "_" +
                  ObjectEntry[i][0].split(":")[1] +
                  "_" +
                  ObjectEntry[i][0].split(":")[2]
                : ObjectEntry[i][0].split(":")[0],
            permissions: ObjectEntry[i][1].map((item) => item.permission),
          });
        }
        setMainPermissions(arr1);
        setTabPermissionList(_.uniq(arr1.map((item) => item.tab)));
        setSubTabPermissionList(_.uniq(arr1.map((item) => item.tabKey)));
        setPermissionAPITriggered(true);
      } else {
        setPermissionAPITriggered(true);
      }
    } catch (error) {
      setPermissionAPITriggered(true);
    }
  };
  const covertSecondString = (s) => {
    var lastIndex = s.lastIndexOf(":");
    if (lastIndex !== -1) {
      s = s.substring(0, lastIndex) + "-" + s.substring(lastIndex + 1);
    }
    return s;
  };

  return (
    <PermissionContext.Provider
      value={{
        tabPermissionList,
        subTabPermissionList,
        permissionAPITriggered,
        mainPermissions,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
}

export function usePermissionContext() {
  return useContext(PermissionContext);
}

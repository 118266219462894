import { useEffect, useRef, useState } from "react";
import React from "react";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "common/query-request-helper";
import { toast } from "sonner";
import { formatString } from "common/text-helpers";
import {
  activateOrgUser,
  cancelInvOrgUser,
  deactivateOrgUser,
  getOrganisationInvitedUsers,
  getOrganisationUsers,
  resendInvOrgUser,
  sendInvOrgUser,
  updateOrganisationUsers,
} from "@services/organisations.service";
import classnames from "classnames";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import CopySvgComponents from "assets/svg/copy";
import { CopyToClipboard } from "common/text-helpers";
import ResendSvgComponent from "assets/svg/resend";
import RemoveCircleSvgComponent from "assets/svg/remove-circle";
import SimpleReactValidator from "simple-react-validator";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import NotificationBar from "@components/Pages/Filter/NotificationBar";
import Filter from "@components/Pages/Filter/filter";
import CustomDataTable from "@components/custom/Datatable";
import CustomAllCheckbox from "@components/Form/CheckBox/CustomAllCheckbox";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import Nodata from "@components/common/NoData/NoData";
import PaginationPage from "@components/common/Pagination/pagination";
import ConfirmModal from "@components/Dialogs/Modals/confirmModal";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import Loader from "@components/common/Loader/loading";
import CompanyProfileSubHeader from "@pages/Private/Settings/CompanyProfile/components/CompanyProfileSubHeader";
import {
  createPropertyAmenity,
  getAllPropertyAmenities,
  updatePropertyAmenity,
} from "@services/properties/master/property-amenities.service";
import {
  createRoomAmenity,
  getAllRoomAmenities,
  updateRoomAmenity,
} from "@services/properties/master/room-amenites.service";
import PropertyNavViews from "./components/nav-views";
import PropertyAmenitiesPopup from "./components/property-amenities-popup";
import DeleteSvgComponent from "@assets/svg/delete";
import RoomAmenitiesPopup from "./components/room-amenities-popup";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import ListLoader from "@components/common/Loader";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUsersProps {}

const PropertyConfiguration = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                     Other Functions Section                                */
  /* -------------------------------------------------------------------------- */

  // const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    setSelectedIds([]);
    if (activeTab !== tab) {
      setList([]);
      delete UrlParams.search_text;
      let payload = {
        ...UrlParams,
        active_tab: tab,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };
  const filterNavigationArray: any = [];
  const subMenuArray: any = [];

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      if (activeTab == 1) {
        getPropertyAmenities();
      } else {
        getRoomAmenities();
      }
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (getValue(UrlParams, `search_text`, "")) {
        setSearchInput(UrlParams.search_text);
        setShowSearchTextbox(true);
      }
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      //   getAllPipelines();
      if (getValue(UrlParams, `active_tab`, "1") == 1) {
        getPropertyAmenities();
      } else if (getValue(UrlParams, `active_tab`, "1") == 2) {
        getRoomAmenities();
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  /* ------------------------     Property Amenities  -------------------------- */

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const getPropertyAmenities = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllPropertyAmenities(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.amenities`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* ------------------------     Room Amenities  -------------------------- */

  const [isLoading1, setIsLoading1] = useState(true);
  const [list1, setList1] = useState([]);
  const getRoomAmenities = async () => {
    try {
      setIsLoading1(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomAmenities(queryRequest);
      if (resp) {
        setList1(getValue(resp, `data.amenities`, []));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (UrlParams.search_text) {
      delete UrlParams.search_text;
      let payload: any = { ...UrlParams };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      if (activeTab == 1) {
        getPropertyAmenities();
      } else {
        getRoomAmenities();
      }
    }
  };
  const handleChangeSearch = async (e: any) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(e.target.value)) {
      setSearchInput(e.target.value);
      let payload: any = {
        ...UrlParams,
        page_no: 1,
        search_text: e.target.value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      if (!e.target.value) {
        if (activeTab == 1) {
          getPropertyAmenities();
        } else {
          getRoomAmenities();
        }
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                    Filter Delete View Section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                        Property Amenity section                            */
  /* -------------------------------------------------------------------------- */
  const [, forceUpdate] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const openEditPopup = () => {
    setIsOpenEditPopup(!isOpenEditPopup);
  };
  const [propertyAmenityLable, setPropertyAmenityLable] = useState("");

  const [invLoading, setInvLoading] = useState(false);
  const handleCreatePropertyAmenity = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setInvLoading(true);
        let resp;
        if (!editId) {
          resp = await createPropertyAmenity({
            lable: propertyAmenityLable,
          });
        } else {
          resp = await updatePropertyAmenity(editId, {
            lable: propertyAmenityLable,
          });
        }
        if (resp) {
          toast.success(
            editId ? "Updated successfully" : "Created successfully"
          );
          setInvLoading(false);
          openEditPopup();
          setPropertyAmenityLable("");
          let payload = {
            active_tab: 1,
          };
          let queryRequest = QueryRequestHelper(payload);
          forceUpdate(0);
          setEditId("");
          simpleValidator.current.hideMessages();
          navigate(`${window.location.pathname}?${queryRequest}`);
        } else {
          setInvLoading(false);
        }
      } catch (error) {
        setInvLoading(false);
      }
    }
  };

  const [editId, setEditId] = useState("");
  const handleEditAmenity = (id: string, value: string) => {
    setEditId(id);
    setPropertyAmenityLable(value);
    openEditPopup();
  };

  /* -------------------------------------------------------------------------- */
  /*                           Room Amenity section                             */
  /* -------------------------------------------------------------------------- */
  const [, forceUpdate1] = useState(0);
  const simpleValidator1 = useRef(new SimpleReactValidator());
  const [isOpenEditPopup1, setIsOpenEditPopup1] = useState(false);
  const openEditPopup1 = () => {
    setIsOpenEditPopup1(!isOpenEditPopup1);
  };
  const [propertyAmenityLable1, setPropertyAmenityLable1] = useState("");

  const [invLoading1, setInvLoading1] = useState(false);
  const handleCreateRoomAmenity = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setInvLoading1(true);
        let resp;
        if (!editId) {
          resp = await createRoomAmenity({
            lable: propertyAmenityLable1,
          });
        } else {
          resp = await updateRoomAmenity(editId1, {
            lable: propertyAmenityLable1,
          });
        }
        if (resp) {
          toast.success(
            editId1 ? "Updated successfully" : "Created successfully"
          );
          setInvLoading1(false);
          openEditPopup1();
          setPropertyAmenityLable1("");
          let payload = {
            active_tab: 2,
          };
          setEditId1("");
          let queryRequest = QueryRequestHelper(payload);
          forceUpdate(0);
          simpleValidator1.current.hideMessages();
          navigate(`${window.location.pathname}?${queryRequest}`);
        } else {
          setInvLoading1(false);
        }
      } catch (error) {
        setInvLoading1(false);
      }
    }
  };

  const [editId1, setEditId1] = useState("");
  const handleEditAmenity1 = (id: string, value: string) => {
    setEditId1(id);
    setPropertyAmenityLable1(value);
    openEditPopup1();
  };

  /* -------------------------------------------------------------------------- */
  /*                                    Checkbox Section                        */
  /* -------------------------------------------------------------------------- */
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const handleChangeCheckbox = (id: any) => {
    let checkIfExist =
      getValue(selectedIds, `length`, 0) > 0
        ? selectedIds.filter((item: any) => item === id)
        : [];
    if (getValue(checkIfExist, `length`, 0) > 0) {
      let filtered =
        getValue(selectedIds, `length`, 0) > 0
          ? selectedIds.filter((item: any) => item !== id)
          : [];
      setSelectedIds(filtered);
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds]);
    }
  };

  const selectedUserInfo: any =
    getValue(list, `length`, 0) > 0 &&
    getValue(selectedIds, `length`, 0) === 1 &&
    list.filter(
      (item: any) =>
        getValue(item, `id`, "") === getValue(selectedIds, `[${0}]`, "")
    );

  const activeTab = getValue(UrlParams, `active_tab`, "1");

  return (
    <>
      <HomeHeader />
      <CompanyProfileSubHeader />
      <div className={`${"users_container"} w-100`}>
        <div className="w-100">
          <div className={`d-flex`}>
            <div className={`${"manageFieldRight"} w-100 add-overflow-y`}>
              <div className="mt-2 mb-2">
                <div className="px-5 ">
                  <div
                    className={`content ${
                      getValue(selectedIds, `length`, 0) > 0 ? "open" : "closed"
                    }`}
                  >
                    <NotificationBar
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      // handleOpenFilter={handleOpenFilter}
                      //   handleDeleteTable={handleDeleteTable}
                      hideStage
                      hideOwner
                      hideDelete
                      usersModule
                      userInfo={getValue(selectedUserInfo, `[${0}]`, {})}
                      handleCancelInvUser={handleModal}
                      activateUser={
                        getValue(UrlParams, `active_tab`, "") == 3
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div
                    className={`other-content subheader_filter_section ${
                      getValue(selectedIds, `length`, 0) === 0
                        ? "open"
                        : "closed"
                    }`}
                  >
                    <Filter
                      isStatusVisible={true}
                      filterNavigationArray={filterNavigationArray}
                      isFilterVisibile={true}
                      isCreateDealVisible={true}
                      SubPiplineTitle={"Add"}
                      SubPiplineTitlePrefix={" "}
                      setVisisbility={
                        activeTab == 1 ? openEditPopup : openEditPopup1
                      }
                      //search section
                      searchInput={search_text}
                      hideSearch
                      showSearchTextbox={showSearchTextbox}
                      toggleSearch={toggleSearch}
                      toggleSearchClose={toggleSearchClose}
                      handleChangeSearch={handleChangeSearch}
                      hideCloseDate
                      hideCreateDate
                      hideStage
                      hideOwner
                      createText={"Add"}
                      hideEditColumns
                      hideUpload
                    />
                  </div>
                </div>
                <PropertyNavViews toggle={toggle} activeTab={activeTab} />
                {activeTab == 1 && (
                  <div className="mt-3 px-3">
                    {isLoading ? (
                      <ListLoader />
                    ) : getValue(list, `length`, 0) > 0 ? (
                      <div className="amenity_container">
                        {list.map((item: object) => {
                          return (
                            <div
                              className="amenity_text "
                              onClick={() =>
                                handleEditAmenity(
                                  getValue(item, `id`, ""),
                                  getValue(item, `lable`, "")
                                )
                              }
                            >
                              <p className="">{getValue(item, `lable`, "")}</p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <FiltersNoDataPage module={"property amenities"} />
                    )}
                  </div>
                )}
                {activeTab == 2 && (
                  <div className="mt-3 px-3">
                    {isLoading1 ? (
                      <Loader />
                    ) : getValue(list1, `length`, 0) > 0 ? (
                      <div className="amenity_container">
                        {list1.map((item: object) => {
                          return (
                            <div
                              className="amenity_text"
                              onClick={() =>
                                handleEditAmenity1(
                                  getValue(item, `id`, ""),
                                  getValue(item, `lable`, "")
                                )
                              }
                            >
                              <p className="">{getValue(item, `lable`, "")}</p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <FiltersNoDataPage module={"property amenities"} />
                    )}
                  </div>
                )}
                <div className={"table_footer_container"}>
                  {totalCount > 10 && getValue(list, `length`, 0) > 0 ? (
                    <PaginationPage
                      totalCount={totalCount}
                      limit={limit}
                      pageNumber={page_no}
                      handleChangePagination={handleChangePagination}
                      handleChangeLimit={handleChangeLimit}
                    />
                  ) : (
                    <p className="show-rows-count mt-3 d-flex">
                      {/* Total Count:&nbsp;&nbsp;{" "}
                            <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p> */}
                    </p>
                  )}
                </div>
                <PropertyAmenitiesPopup
                  isOpen={isOpenEditPopup}
                  handleModal={openEditPopup}
                  request={propertyAmenityLable}
                  setRequest={setPropertyAmenityLable}
                  isLoading={invLoading}
                  handleSubmit={handleCreatePropertyAmenity}
                  simpleValidator={simpleValidator}
                  setEditId={setEditId}
                  editId={editId}
                />
                <RoomAmenitiesPopup
                  isOpen={isOpenEditPopup1}
                  handleModal={openEditPopup1}
                  request={propertyAmenityLable1}
                  setRequest={setPropertyAmenityLable1}
                  isLoading={invLoading}
                  handleSubmit={handleCreateRoomAmenity}
                  simpleValidator={simpleValidator1}
                  setEditId={setEditId1}
                  editId={editId1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyConfiguration;

import { getValue, setValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import "./createPipeline.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { QueryRequestHelper } from "common/query-request-helper";
import RemoveCircleSvgComponent from "assets/svg/remove-circle";
import SearchSvgComponent from "assets/svg/search";
import CloseSvgComponent from "assets/svg/close";
import DragIndicatorSvgComponent from "assets/svg/drag-indicator";
import { getModuleFields } from "@services/module-fields.service";
import useDraggableInPortal from "@common/useDraggableInPortal";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import InputRuleForm from "@components/InputRuleForm/form";

function PipelineStep3(props: any) {
  const { request, setRequest } = props;
  const [mainFields, setMainFields] = useState<any>([]);
  const [unUsedFields, setUnUsedFields] = useState<any>([]);
  useEffect(() => {
    getData();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      let payload = {
        // module_name: "sales",
        module_id: getValue(props, `module_id`, ""),
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getModuleFields(getValue(props, `module_id`, ""));
      if (resp) {
        setUnUsedFields(
          getValue(resp, `data.module_fields`, []).filter(
            (item: object) => !getValue(item, `required`, false)
          )
        );
        setMainFields(
          getValue(resp, `data.module_fields`, []).filter(
            (item: object) => !getValue(item, `required`, false)
          )
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const sourceArrayCopy: any = [...request.fields];
    const targetArrayCopy = [...unUsedFields];

    // Moving from same area in top to botomm direction

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "source") {
        const [removed] = sourceArrayCopy.splice(source.index, 1);
        sourceArrayCopy.splice(destination.index, 0, removed);
        setValue(request, `fields`, sourceArrayCopy);
        setRequest({ ...request });
      }
      if (source.droppableId === "target") {
        const [removed] = targetArrayCopy.splice(source.index, 1);
        targetArrayCopy.splice(destination.index, 0, removed);
        setUnUsedFields(targetArrayCopy);
      }
    }
    // Moving from source to target
    if (
      source.droppableId === "source" &&
      destination.droppableId === "target"
    ) {
      const [removed] = sourceArrayCopy.splice(source.index, 1);
      targetArrayCopy.splice(destination.index, 0, removed);

      setValue(request, `fields`, sourceArrayCopy);
      setRequest({ ...request });
      setUnUsedFields(targetArrayCopy);
    }

    // Moving from target to source
    if (
      source.droppableId === "target" &&
      destination.droppableId === "source"
    ) {
      const [removed] = targetArrayCopy.splice(source.index, 1);
      sourceArrayCopy.splice(destination.index, 0, removed);
      setValue(request, `fields`, sourceArrayCopy);
      setRequest({ ...request });
      setUnUsedFields(targetArrayCopy);
    }
  };

  const handleAddRemoveField = (obj: object) => {
    const index = getValue(request, `fields`, []).findIndex(
      (item: object) => getValue(item, `api_name`, "") === getValue(obj, `api_name`, "")
    );
    if (index !== -1) {
      getValue(request, `fields`, []).splice(index, 1);
      setRequest({ ...request });
    } else {
      getValue(request, `fields`, []).push(obj);
      setRequest({ ...request });
    }
  };

  const handleRemoveField = (name: string) => {
    const index = getValue(request, `fields`, []).findIndex(
      (item: object) => getValue(item, `api_name`, "") === name
    );
    getValue(request, `fields`, []).splice(index, 1);
    setRequest({ ...request });
  };

  const [searchText, setSearchText] = useState("");
  const searchString = (searchString: string) => {
    if (searchString) {
      setSearchText(searchString);
      // Create an array to store the results
      const results = unUsedFields.filter((obj: object) =>
        getValue(obj, `api_name`, "")
          .toLowerCase()
          .includes(searchString.toLowerCase())
      );
      setUnUsedFields(results);
    } else {
      setSearchText(searchString);
      setUnUsedFields(mainFields);
    }
  };

  const renderDraggable = useDraggableInPortal();
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    border: isDragging && "1px solid #dadce0",
    borderRadius: isDragging && "5px",
    padding: isDragging && "10px",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  return (
    <div className={`draganddrop_container`}>
      <div className={"draganddrop_not_selected_container"}>
        <div className="inline-input w-100  align-items-center mb-3">
          <InputRuleForm
            inputType="TEXT"
            style={{ height: "40px" }}
            placeholder="Search fields..."
            hideLabel
            onChange={(e: any) => searchString(e.target.value)}
            value={searchText}
          />
          {searchText ? (
            <div
              className="img mx-2"
              onClick={() => {
                setSearchText("");
                setUnUsedFields(mainFields);
              }}
            >
              <CloseSvgComponent size={18} color={"#1d82b7"} />
            </div>
          ) : (
            <div className="img mt-1">
              <SearchSvgComponent size={16} color={"#1d82b7"} />
            </div>
          )}
        </div>
        <div className={`draganddrop_not_selected_container__main px-2`}>
          {isLoading ? (
            <p className="text-center mt-5">Please wait...</p>
          ) : getValue(unUsedFields, `length`, 0) > 0 ? (
            unUsedFields.map((item: any, index: number) => {
              return (
                <div className={`source-item d-flex justify-content-between`}>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center cursor-pointer">
                      <CustomCheckbox
                        checked={
                          getValue(request, `fields.length`, 0) > 0 &&
                          getValue(request, `fields`, [])
                            .map((item: object) => getValue(item, `api_name`, ""))
                            .includes(getValue(item, `api_name`, ""))
                        }
                        onChange={() => handleAddRemoveField(item)}
                      />
                      <p
                        className="desc_text__16 ms-3"
                        onClick={() => handleAddRemoveField(item)}
                      >
                        {item.label}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : searchText ? (
            <p className="text-center mt-5">Please modify search</p>
          ) : (
            <p className="text-center mt-5">No data found</p>
          )}
        </div>
      </div>
      {/* <div className="ms-3"></div> */}
      <div className={"draganddrop_selected_container"}>
        <p className={"header_blue_text__16 mb-4"}>
          Selected Fields {"( "}
          {getValue(request, `fields.length`, 0)}
          {" )"}
        </p>
        <div className={`draganddrop_selected_container__main`}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div>
              <Droppable droppableId="source">
                {(provided) => (
                  <div
                    className={"draganddrop_list"}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {getValue(request, `fields`, []).map(
                      (item: any, index: number) => (
                        <Draggable
                          key={getValue(item, `api_name`, "")}
                          draggableId={getValue(item, `api_name`, "")}
                          index={index}
                        >
                          {renderDraggable((provided: any, snapshot: any) => (
                            <div
                              className={`source-item d-flex justify-content-between mb-2`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex align-items-center">
                                  <DragIndicatorSvgComponent
                                    size={22}
                                    color={"lightgray"}
                                  />
                                  <p className="desc_text__14 ms-2">
                                    {item.label}
                                  </p>
                                </div>
                                {!getValue(item, `system_required`, false) ? (
                                  <div
                                    onClick={() =>
                                      handleRemoveField(
                                        getValue(item, `api_name`, "")
                                      )
                                    }
                                    className="cursor-pointer"
                                  >
                                    <RemoveCircleSvgComponent />{" "}
                                  </div>
                                ) : (
                                  <div className="cursor-not-allowed">
                                    <RemoveCircleSvgComponent
                                      color={"#F5B7B1"}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
}

export default PipelineStep3;

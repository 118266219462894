import * as React from "react";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
  DraftHandleValue,
  SelectionState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtmlPuri from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "draft-js/dist/Draft.css";
import { getValue } from "@utils/lodash";
import { motion } from "framer-motion";
// https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
const DraftJSRefEditor = (props: any) => {
  const [editor, setEditor] = React.useState(true);
  const [editorState, setEditorState] = React.useState<any>(() =>
    EditorState.createEmpty()
  );
  React.useEffect(() => {
    const blocksFromHtml = htmlToDraft(getValue(props, `editorState`, null));
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
    someRef.current && someRef.current.focusEditor();
  }, []);
  let currentState = draftToHtmlPuri(
    convertToRaw(editorState.getCurrentContent())
  );

  React.useEffect(() => {
    if (getValue(props, `editorState`, null) !== currentState) {
      const blocksFromHtml = htmlToDraft(getValue(props, `editorState`, null));
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);

       // Create a selection state that points to the end of the content
       const lastBlock = contentState.getLastBlock();
       const endOfBlock = lastBlock.getLength();
       const selectionState = SelectionState.createEmpty(lastBlock.getKey()).merge({
         anchorOffset: endOfBlock,
         focusOffset: endOfBlock,
       });
 
       // Apply the selection state to the editor
       const newEditorState = EditorState.forceSelection(editorState, selectionState);
       
      setEditorState(newEditorState);
      setEditor(false);
    }
  }, [getValue(props, `editorState`, null), currentState, editor]);

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    props.handleChangeEditor(
      props.name,
      draftToHtmlPuri(convertToRaw(editorState.getCurrentContent())),
      editorState
    );
  };
  const someRef = React.useRef<any>();

  const [isFocused, setIsFocused] = React.useState(false);
  const editorRef = React.useRef<any>(null);

  const setEditorReference = (ref: any) => {
    if (ref) {
      editorRef.current = ref;
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const pastePlainText = (
    text: any,
    html: any,
    editorState: any,
    onChange: any
  ) => {
    const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
    if (emojiRegex.test(text)) {
      const contentStateWithEmoji = convertToRaw(
        editorState.getCurrentContent()
      );
      const textBlock = contentStateWithEmoji.blocks[0];
      const newText = textBlock.text + text;
      const newContentState = ContentState.createFromText(newText);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-characters"
      );
      onChange(newEditorState);
      return true;
    }
    return false;
  };

  const domEditorRef = React.useRef<any>(null);
  React.useEffect(() => {
    if (domEditorRef.current && !props.hideFocus) {
      domEditorRef.current.focusEditor();
    }
  }, [props.autoFocus]);

  const handleKeyCommand = (command: string, editorState: EditorState): any => {
    if (command === "split-block") {
      if (props.submitOnEnter) {
        props.handleSubmit();
      }
    }
  };
  const myFunction = () => {
    // Your custom function logic here
  };

  return (
    <motion.div
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 20, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Editor
        // handleReturn={props.handleReturn}
        ref={domEditorRef}
        toolbarHidden={props.toolbarHidden}
        // toolbarHidden
        editorState={editorState}
        editorRef={setEditorReference}
        toolbarClassName={
          props.floatToolbar
            ? !props.editorState || props.editorState === "<p></p>\n"
              ? "emailToolbar"
              : "emailToolbarActive"
            : "emailToolbarActive"
        }
        wrapperClassName="wrapperClassName"
        editorClassName={
          props.editorClassName ? props.editorClassName : "editorClassName"
        }
        onEditorStateChange={onEditorStateChange}
        placeholder={props.placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        handlePastedText={pastePlainText}
        editorStyle={{
          ...props.editorStyle,
          border: props.focus
            ? isFocused
              ? `1px solid ${
                  getValue(props, `focusColor`, "")
                    ? getValue(props, `focusColor`, "")
                    : "#0279ff"
                }`
              : "1px solid #efefef"
            : "none",
          transition: "all 1s ease-in-out",
          boxShadow:
            getValue(props, `focusColor`, "") &&
            isFocused &&
            `0px 0px 3px 0px ${
              getValue(props, `focusColor`, "")
                ? getValue(props, `focusColor`, "")
                : "#0279ff"
            }`,
          borderRadius: "8px",
        }}
        toolbar={
          props.toolbar
            ? props.toolbar
            : {
                options: ["inline", "blockType", "fontSize", "list", "link"],
              }
        }
        // handleKeyCommand={handleKeyCommand}
      />
    </motion.div>
  );
};

export default DraftJSRefEditor;

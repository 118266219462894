import CloseSvgComponent from "@assets/svg/close";
import CloseMainSvgComponent from "@assets/svg/close-main";
import { handleDownloadOnClick } from "@common/downloadfile";
import { emojisList } from "@common/emojis";
import { QueryRequestHelper } from "@common/query-request-helper";
import { concatString } from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import WhatsappIconViewPage from "@pages/Private/Broadcast/components/common/whatsapp-icon";
import {
  getWhatsappTemplate,
  getSpecificWhatsappTemplate,
} from "@services/fb.service";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";
import { config } from "env";
import React, { useEffect, useState } from "react";

function BotTemplateComponent(props: any) {
  const { request, setRequest } = props;
  useEffect(() => {
    getTemplateList();
  }, []);

  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    setRequest({
      ...request,
      description: value,
    });
  };
  const [isLoading1, setIsLoading1] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const getTemplateList = async () => {
    try {
      setIsLoading1(true);
      let payload = {
        status: "APPROVED",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappTemplate(queryRequest);
      if (resp) {
        let list = getValue(resp, `data.templates`, []).map((item: object) => ({
          ...item,
          value: getValue(item, `id`, ""),
          label: getValue(item, `name`, ""),
        }));
        setTemplateList(list);
        setIsLoading1(false);
        if (
          getValue(request, `template_id`, "") &&
          getValue(list, `length`, 0) > 0
        ) {
          handleGetTemplateInfo(getValue(props, `request.template_id`, ""));
        }
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  const [templateRequest, setTemplateRequest] = useState({});

  const handleGetTemplateInfo = async (id: string) => {
    if (
      getValue(request, `suggestions.length`, 0) === 0 ||
      getValue(request, `template_id`, "") !== id ||
      !getValue(templateRequest, `body`, "")
    ) {
      if (id) {
        let resp = await getSpecificWhatsappTemplate(id);
        if (resp) {
          let findInfo = getValue(resp, `data`, "");
          let buttons =
            getValue(findInfo, `buttons.length`, 0) > 0
              ? getValue(findInfo, `buttons`, []).map((item: object) => ({
                  ...item,
                  type: getValue(item, `action_type`, ""),
                  action_type: getValue(item, `type`, ""),
                }))
              : [];
          setTemplateRequest({
            ...templateRequest,
            header: {
              type: getValue(findInfo, `header_type`, ""),
              text: getValue(findInfo, `text`, ""),
              link: getValue(findInfo, `header_file.key`, ""),
            },
            body: getValue(findInfo, `body`, ""),
            footer: getValue(findInfo, `footer`, ""),
            buttons: buttons,
          });
          if (
            getValue(request, `suggestions.length`, 0) === 0 ||
            getValue(request, `template_id`, "") !== id
          ) {
            let optionList = getValue(findInfo, `buttons`, []).map(
              (item: object) => ({
                text: getValue(item, `text`, ""),
                type: getValue(item, `type`, ""),
                url: getValue(item, `url`, ""),
                phone: getValue(item, `phone`, ""),
              })
            );
            setRequest({
              ...request,
              suggestions: optionList,
              template_id: id,
            });
          }
        }
      } else {
        setRequest({
          ...request,
          template_id: id,
        });
        setTemplateRequest({});
      }
    }
  };
  return (
    <div>
      {/* <div className="mb-2">
        <InputRuleForm
          inputType="TEXT"
          value={getValue(request, `name`, "")}
          name="name"
          onChange={(e: any) =>
            setRequest({ ...request, name: e.target.value })
          }
          label="Name"
          placeholder="Enter Name"
          // required
        />
      </div> */}
      <div className="mb-2">
        {/* <InputRuleForm
          inputType="TEXTAREA"
          value={getValue(request, `description`, "")}
          name="description"
          onChange={(e: any) =>
            setRequest({ ...request, description: e.target.value })
          }
          label="Message"
          placeholder="Enter Message"
          // mandatory
        /> */}
        <DraftJSRefEditor
          editorState={getValue(request, `description`, "")}
          handleChangeEditor={handleChangeEditor}
          name="content"
          placeholder="Enter here"
          editorStyle={{
            background: "white",
            paddingLeft: "20px",
            paddingTop: "20px",
            // height: "30vh",
          }}
          focusColor={"#1a73e8"}
          toolbar={{
            options: ["inline", "emoji"],
            inline: {
              options: ["bold", "italic"],
            },
            emoji: {
              emojis: emojisList,
            },
          }}
          hideFocus
          focus
          editorClassName={'bot-editorClassName'}
        />
      </div>
      <div className="px-4">
        <SearchToolTip
          label="label"
          data={templateList}
          selectKey={"value"}
          value={getValue(request, `template_id`, "")}
          onChange={(e: any) => {
            setRequest({ ...request, template_id: getValue(e, `id`, "") });
            handleGetTemplateInfo(getValue(e, `id`, ""));
          }}
          name="Template"
          errorLabel={""}
          validator={props.validator}
          concatLength={60}
        />
      </div>
      <div className="template-wrapper">
        <div className="template-container">
          <WhatsappIconViewPage request={templateRequest} />
        </div>
      </div>
    </div>
  );
}

export default BotTemplateComponent;

import ActivityMeetings from "@pages/Private/CRM/Meetings";
import MeetingsDetailSection from "@pages/Private/CRM/Meetings/Detail";

export const CrmMeetingsRoutes = [
  {
    path: "/:orgId/crm/meetings",
    name: "crm_meetings",
    component: ActivityMeetings,
  },
  {
    path: "/:orgId/crm/meetings/detail/:id",
    name: "crm_meetings",
    component: MeetingsDetailSection,
  },
];

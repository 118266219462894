import { config } from "../../../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
} from "../../helpers/http-handler";

//tax rate
export const createPropertyAmenity = (payload: object) =>
  post(`${config.API_URL}/settings/property/amenities`, payload);

export const getAllPropertyAmenities = (queryRequest: string) =>
  get(`${config.API_URL}/settings/property/amenities?${queryRequest}`);

export const getSpecificPropertyAmenity = (id: string) =>
  get(`${config.API_URL}/settings/property/amenities/${id}`);

export const updatePropertyAmenity = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/property/amenities/${id}`, payload);

export const bulkUpdatePropertyAmenity = (payload: object) =>
  patch(`${config.API_URL}/settings/property/amenities/bulk-update`, payload);

import { convertCurrentDate } from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import Switch from "@components/Form/Switch/Switch";
import ActivityLoader from "@components/common/Loader/activity-loader";
import { getValue } from "@utils/lodash";
import { Button, Table, Tag } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function FacebookPageList(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();

  list.map((item: object) => {});

  const columns = [
    {
      title: "PAGE ID",
      dataIndex: "page_id",
      key: "page_id",
      render: (page_id: any, text: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() =>
            getValue(text, `status`, "") === "SUBSCRIBED" &&
            navigate(
              `/${getValue(
                params,
                `orgId`,
                ""
              )}/settings/ads/lead-forms/${getValue(text, `id`, "")}`
            )
          }
        >
          {page_id}
        </div>
      ),
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any) => (
        <Tag
          color={formatString(status) === "SUBSCRIBED" ? "success" : "error"}
        >
          {formatString(status)}
        </Tag>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "status",
      key: "status",
      render: (status: any, record: any) => (
        <div className="d-flex justify-content-center">
          <Button
            type="primary"
            onClick={() => props.handleSubscribePage(record)}
            disabled={status === "SUBSCRIBED"}
          >
            Subscribe
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="px-4 pt-1">
      {isLoading ? (
        <ActivityLoader />
      ) : (
        <Table
          size="middle"
          className="mt-3"
          bordered
          columns={columns}
          dataSource={list}
          rowKey="id"
          pagination={{
            showTotal: (total, range) =>
              `${total} ${total >= 2 ? "items" : "item"}`,
          }}
          scroll={
            {
              // y: `calc(100vh - 300px)`,
              // x: 1400,
            }
          }
        />
        // <table className="common_table w-100">
        //   <thead>
        //     <tr>
        //       <td>Page ID</td>
        //       <td>Name</td>
        //       <td>Category</td>
        //       <td>Status</td>
        //       <td>Action</td>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {list.map((item: object) => {
        //       return (
        //         <tr
        //           onClick={() =>
        //             getValue(item, `status`, "") === "SUBSCRIBED" &&
        //             navigate(
        //               `/${getValue(
        //                 params,
        //                 `orgId`,
        //                 ""
        //               )}/settings/ads/lead-forms/${getValue(item, `id`, "")}`
        //             )
        //           }
        //         >
        //           <td className="header_blue_text__14 touchable-text">
        //             {getValue(item, `page_id`, "")}
        //           </td>
        //           <td>{getValue(item, `name`, "")}</td>
        //           <td>{getValue(item, `category`, "")}</td>
        //           <td className=" ">
        //             {formatString(getValue(item, `status`, "")) === "SUBSCRIBED"
        //               ? "Subscribed"
        //               : "Not Subscribed"}
        //           </td>
        //           {/* <td className="d-flex align-items-center justify-content-center">
        //             <label className="switch">
        //               <input
        //                 type="checkbox"
        //                 checked={getValue(item, "isActive", true)}
        //                 onChange={() =>
        //                   props.handleSubscribePage(
        //                     getValue(item, "status", ""),
        //                     !getValue(item, "isActive", true)
        //                   )
        //                 }
        //               />
        //               <span className="slider round d-flex"></span>
        //             </label>
        //           </td> */}
        //           <td>
        //             <div className="d-flex justify-content-center">
        //               <button
        //                 className={`dashboard-button cursor-pointer ${
        //                   getValue(item, `status`, "") !== "SUBSCRIBED"
        //                     ? "create-user-button me-3 py-2"
        //                     : "me-3"
        //                 }`}
        //                 onClick={() => props.handleSubscribePage(item)}
        //                 disabled={getValue(item, `status`, "") === "SUBSCRIBED"}
        //               >
        //                 Subscribe
        //               </button>
        //             </div>
        //           </td>
        //         </tr>
        //       );
        //     })}
        //   </tbody>
        // </table>
      )}
    </div>
  );
}

export default FacebookPageList;

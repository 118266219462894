import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import ButtonComponent from "@components/Form/Button/Button";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import PropertySettingsLayout from "@layouts/PropertyLayout/property-settings-layout";
import React, { useEffect, useState } from "react";
import AddTaxCategory from "./components/tax-category-popup";
import EditSvgComponent from "@assets/svg/edit";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import {
  createTaxRate,
  getAllTaxCategory,
  getAllTaxRate,
  updateTaxRate,
} from "@services/properties/settings/tax.service";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { toast } from "sonner";
import Loader from "@components/common/Loader/loading";

const PropertyTaxSettings = () => {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
    getCategoryData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTaxRate(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.taxRates`, []));

        if (editId) {
          let filtered = getValue(resp, `data.taxRates`, []).find(
            (item: object) => getValue(item, `id`, "") === editId
          );
          setSelectedIds(
            getValue(filtered, `tax_category`, []).map((item: object) =>
              getValue(item, `id`, "")
            )
          );
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [categoryList, setCategoryList] = useState([]);
  const getCategoryData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTaxCategory(queryRequest);
      if (resp) {
        let list = getValue(resp, `data.taxCategories`, []).map(
          (item: object) => ({
            ...item,
            label: getValue(item, `category_name`, ""),
            value: getValue(item, `value`, ""),
          })
        );
        setCategoryList(list);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Edit Section                                 */
  /* -------------------------------------------------------------------------- */

  const [editId, setEditId] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const handleEdit = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    setSelectedIds(
      getValue(obj, `tax_category`, []).map((item: object) =>
        getValue(item, `id`, "")
      )
    );
    setRequest({
      ...request,
      tax_name: getValue(obj, `tax_name`, ""),
      tax_percentage: getValue(obj, `tax_percentage`, ""),
    });
    toggleTaxCategory();
  };

  /* -------------------------------------------------------------------------- */
  /*                          Create/Edit Section                               */
  /* -------------------------------------------------------------------------- */
  const [openTaxCategory, setopenTaxCategory] = useState(false);
  const toggleTaxCategory = () => {
    setopenTaxCategory(!openTaxCategory);
  };
  const intialState = {
    tax_name: "",
    tax_percentage: 0,
    property_id: getValue(params, `id`, ""),
    tax_category_ids: [],
  };
  const [request, setRequest] = useState(intialState);

  const reset = () => {
    setRequest(intialState);
    setSelectedIds([]);
    setEditId("");
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let requestPayload = JSON.parse(JSON.stringify(request));
      requestPayload["tax_percentage"] = getValue(request, `tax_percentage`, 0)
        ? parseInt(getValue(request, `tax_percentage`, 0))
        : 0;
      requestPayload["tax_category_ids"] = getValue(
        request,
        `tax_category_ids`,
        []
      ).map((item: object) => getValue(item, `id`, ""));
      let resp;
      if (!editId) {
        resp = await createTaxRate(
          removeNullOrUndefinedProperties(requestPayload)
        );
      } else {
        resp = await updateTaxRate(
          editId,
          removeNullOrUndefinedProperties(requestPayload)
        );
      }
      if (resp) {
        toast.success(`${editId ? "Updated" : "Created"} successfully`);
        reset();
        setSubmitLoading(false);
        getData();
        toggleTaxCategory();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      console.error(error);
      setSubmitLoading(false);
    }
  };
  return (
    <PropertyDetailsLayout>
      <PropertySettingsLayout>
        <div className=" w-100 overflow-y-auto">
          <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
            <h3 className="header_text__20">Tax Rate</h3>
            <ButtonComponent
              buttonType="primary"
              buttonText="Add Tax Rate"
              onClickHandler={toggleTaxCategory}
            />
          </div>
          <p className="my-3">
            Use this page to create and edit hotel tax categories. A Tax
            category can used to calculate and apply tax in a variety of places.
            For e.g, defining tax for a roomtype, tax for a item being added in
            invoice or tax for products in a shop.
          </p>

          {isLoading ? (
            <Loader />
          ) : getValue(list, `length`, 0) > 0 ? (
            list.map((item: object) => {
              return (
                <div className=" mt-4 mb-4  p-3 rounded-3 cursor-pointer room-box-shadow mx-3">
                  <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
                    <h3 className="header_text__16">
                      {getValue(item, `tax_name`, "")}
                    </h3>
                    <div onClick={() => handleEdit(item)}>
                      <EditSvgComponent />
                    </div>
                  </div>
                  <div className="">
                    <div className="row mt-3">
                      <p className="col-2 small_text__14">Name:</p>
                      <p className=" col-9 small_text__16">
                        {getValue(item, `tax_name`, "")}
                      </p>
                    </div>
                    <div className="row mt-2">
                      <p className="col-2 small_text__14">Percentage:</p>
                      <p className=" col-9 small_text__16">
                        {getValue(item, `tax_percentage`, 0)}%
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : null}
        </div>
      </PropertySettingsLayout>
      <CommonRightPopup
        isPopupVisible={openTaxCategory}
        toggle={() => {
          toggleTaxCategory();
          setEditId("");
          editId && reset();
          editId && setSelectedIds([]);
        }}
        width="500px"
        title="Add tax rate"
        hideNew
        second_title={
          submitLoading ? "Please wait..." : editId ? "Update" : "Save"
        }
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
      >
        <AddTaxCategory
          request={request}
          setRequest={setRequest}
          categoryList={categoryList}
          editId={editId}
          getData={getData}
          selectedIds={selectedIds}
        />
      </CommonRightPopup>
    </PropertyDetailsLayout>
  );
};

export default PropertyTaxSettings;

import { config } from "../env";
import {
  Delete,
  get,
  patch,
  post,
  postFileUpload,
  postWithoutBody,
} from "./helpers/http-handler";

export const getWhatsappManagerConfig = () =>
  get(`${config.API_URL}/whatsapp-manager/config`);

export const whatsappManagerAccess = (payload: object) =>
  post(`${config.API_URL}/whatsapp-manager/manage`, payload);

//templates

export const getWhatsappTemplate = (queryRequest: string) =>
  get(`${config.API_URL}/templates/whatsapp?${queryRequest}`);

export const createWhatsappTemplate = (payload: object) =>
  post(`${config.API_URL}/templates/whatsapp/manage`, payload);

export const getWhatsappTemplateAvailableList = (queryRequest: string) =>
  get(`${config.API_URL}/templates/whatsapp/available-list?${queryRequest}`);

export const getSpecificWhatsappTemplate = (id: string) =>
  get(`${config.API_URL}/templates/whatsapp/${id}`);

//broadcast history

export const getWhatsappBroadcast = (queryRequest: string) =>
  // get(`${config.API_URL}/whatsapp-manager/broadcast?${queryRequest}`);
  get(
    `${config.API_URL}/whatsapp-manager/broadcast/execution/list?${queryRequest}`
  );

export const manageWhatsappBroadcast = (payload: object) =>
  post(`${config.API_URL}/whatsapp-manager/broadcast/manage`, payload);

export const getSpecificWhatsappBroadcast = (id: string) =>
  get(`${config.API_URL}/whatsapp-manager/broadcast/${id}`);

export const getWhatsappBroadcastContacts = (id: string) =>
  get(
    `${config.API_URL}/whatsapp-manager/broadcast/execution/${id}/details/contacts`
  );

// inbox conversation

export const getWhatsappConversations = (queryRequest: string) =>
  get(`${config.API_URL}/whatsapp-manager/inbox/conversations?${queryRequest}`);

export const getSpecificWhatsappConversation = (
  id: string,
  queryRequest: string
) =>
  get(
    `${config.API_URL}/whatsapp-manager/inbox/conversations/${id}/messages?${queryRequest}`
  );

export const SpecificWhatsappConversationRead = (id: string) =>
  // postWithoutBody(
  //   `${config.API_URL}/whatsapp-manager/inbox/conversations/${id}/message/${msgId}/read`
  // );
  postWithoutBody(
    `${config.API_URL}/whatsapp-manager/inbox/conversations/${id}/read`
  );

export const sendWhatsappConversation = (id: string, payload: object) =>
  post(
    `${config.API_URL}/whatsapp-manager/inbox/conversations/${id}/message`,
    payload
  );

// whatsapp-config

export const getWhatsappConfigSettings = (queryRequest: string) =>
  // get(`${config.API_URL}/whatsapp-manager/config-settings?${queryRequest}`);
  get(
    `${config.API_URL}/whatsapp-manager/feature-settings/opt-out?${queryRequest}`
  );

export const linkWhatsappWeb = (payload: object) =>
  post(
    `${config.API_URL}/whatsapp-manager/inbox/conversation/link-contact`,
    payload
  );

export const syncWhatsappTemplateStatus = (queryRequest: string) =>
  get(
    `${config.API_URL}/whatsapp-manager/sync-template-status?${queryRequest}`
  );

export const addWhatsappConfigSettingOptOut = (payload: object) =>
  // post(`${config.API_URL}/whatsapp-manager/config-settings/opt-out`, payload);
  post(`${config.API_URL}/whatsapp-manager/feature-settings/opt-out`, payload);

//phone register
export const whatsappRegisterPhone = (payload: object) =>
  post(`${config.API_URL}/whatsapp-manager/register-phone`, payload);

//webhook subscribe
export const whatsappWebhookSubscribe = (payload: object) =>
  post(`${config.API_URL}/whatsapp-manager/subscribe-to-app`, payload);

//broadcast history import

export const generateBroadcastTemplate = (payload: object) =>
  post(
    `${config.API_URL}/whatsapp-manager/broadcast/generate-upload-csv`,
    payload
  );

export const downloadBroadcastTemplate = (id: string) =>
  get(
    `${config.API_URL}/whatsapp-manager/broadcast/${id}/generate-completion-log`
  );

//auto response

export const getAutoResponseInfo = (queryRequest: string) =>
  get(
    `${config.API_URL}/whatsapp-manager/feature-settings/auto-response?${queryRequest}`
  );

export const addAutoResponseInfo = (payload: object) =>
  post(
    `${config.API_URL}/whatsapp-manager/feature-settings/auto-response`,
    payload
  );

// fetch usable numbers for an organization for whatsapp functionality (to show whatsapp dropdown list)
export const getAvailableConfigList = (queryRequest: string) =>
  get(`${config.API_URL}/whatsapp-manager/available-config?${queryRequest}`);

//sync numbers for existing businesses on the platform, this api will sync numbers from facebook business and save it in our platform
export const syncWhatsappPhoneNumbers = (queryRequest: string) =>
  get(`${config.API_URL}/whatsapp-manager/sync-phone-numbers?${queryRequest}`);

export const geChatbotAvatar = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/avatar?${queryRequest}`);

export const addChatbotAvatar = (payload: object) =>
  postFileUpload(`${config.API_URL}/chatbot/avatar/upload`, payload);

//asign - resolve users

export const assignConversation = (payload: object) =>
  post(`${config.API_URL}/whatsapp-manager/inbox/conversation/assign`, payload);

export const resolveConversation = (payload: object) =>
  post(
    `${config.API_URL}/whatsapp-manager/inbox/conversation/resolve`,
    payload
  );

export const assignInboxChatbotConversation = (payload: object) =>
  post(
    `${config.API_URL}/whatsapp-manager/inbox/conversation/chat-bot/assign`,
    payload
  );

export const assignInboxChatbot = (payload: object) =>
  post(`${config.API_URL}/whatsapp-manager/chat-bot/assign`, payload);

export const chatbotLogExport = (payload: object) =>
  post(`${config.API_URL}/data-exports/chatbot/log`, payload);

import { getValue } from "@utils/lodash";
import React from "react";

function SMSSvgComponent(props: any) {
  return (
    <div>
      {/* <?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> */}
      <svg
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "19"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "19"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#1a73e8"
        }
        viewBox="0 0 512 512"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        // viewBox="0 0 512 512"
        enable-background="new 0 0 512 512"
        xmlSpace="preserve"
      >
        <path
          d="M448,0H64C28.6,0,0,28.6,0,64v256c0,35.4,28.6,64,64,64h128l-42.7,128l192-128H448c35.4,0,64-28.6,64-64V64
	C512,28.6,483.4,0,448,0z M128,234.7c-23.6,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7s42.7,19.1,42.7,42.7S151.6,234.7,128,234.7z
	 M256,234.7c-23.6,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7s42.7,19.1,42.7,42.7S279.6,234.7,256,234.7z M384,234.7
	c-23.6,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7s42.7,19.1,42.7,42.7S407.6,234.7,384,234.7z"
        />
      </svg>
    </div>
  );
}

export default SMSSvgComponent;

import { getValue } from "@utils/lodash";
import CarouselNavViews from "@pages/Private/Broadcast/components/template-messages/components/carousel-nav-views";
import ButtonComponent from "@components/Form/Button/Button";
import GuestDetailsForm from "../../ReservationCalender/Bookings/WalkIn/forms/guest-details-form";

const CusotmerInformation = (props: any) => {
  const {
    uploadLoading,
    handleSave,
    users,
    activeCarouselTab,
    handleCarouselTab,
    specificUser,
    request,
    setRequest,
    handleUploadId,
  } = props;

  return (
    <>
      {getValue(users, "length", "") > 0 ? (
        <>
          <div className="d-flex justify-content-end position-sticky top-0 bg-white">
            <ButtonComponent
              buttonType="primary"
              buttonText={"Update"}
              onClickHandler={handleSave}
            />
          </div>
          <CarouselNavViews
            length={getValue(users, "length", "")}
            items={users}
            activeTab={activeCarouselTab}
            handleTab={handleCarouselTab}
            showName
            hideIndexPlusOne
            isChild={getValue(specificUser, "type", "") === "child"}
          />
          <GuestDetailsForm
            userDetails={specificUser}
            request={request}
            setRequest={setRequest}
            handleUploadId={handleUploadId}
            uploadLoading={uploadLoading}
          />
        </>
      ) : (
        <p className="small_text__14 text-center mt-5">
          No child details available
        </p>
      )}
    </>
  );
};

export default CusotmerInformation;
